import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
    namespaced: true,
    state: {
        totalRows: 0,
        timeResearchConversionCriteria: [],
        researchRoles: [],
        timeResearchConversionCriteriaTypes: [],
    },
    getters: {
        totalRows: state => state.totalRows,
        timeResearchConversionCriteria: state => state.timeResearchConversionCriteria,
        researchRoles: state => state.researchRoles,
        timeResearchConversionCriteriaTypes: state => state.timeResearchConversionCriteriaTypes,
    },
    mutations: {
        SET_TOTAL_ROWS: (state, totalRows) => {
            state.totalRows = totalRows
        },
        SET_TIME_FRAME_CONVERSION_CRITERIA: (state, timeResearchConversionCriteria) => {
            state.timeResearchConversionCriteria = timeResearchConversionCriteria
        },
        SET_RESEARCH_ROLES: (state, researchRoles) => {
            state.researchRoles = researchRoles
        },
        SET_TIME_RESEARCH_CONVERSION_CRITERIA_TYPES: (state, timeResearchConversionCriteriaTypes) => {
            state.timeResearchConversionCriteriaTypes = timeResearchConversionCriteriaTypes
        },
    },
    actions: {
        async getTimeResearchConversionCriteriaType({ commit }, params) {
            try {
                const response = await axios.get('uni/tw_time_research_conversion_criteria_type', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { records } = data.data
                    commit('SET_TIME_RESEARCH_CONVERSION_CRITERIA_TYPES', records)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getResearchRoles({ commit }, params) {
            try {
                const response = await axios.get('uni/fact_research_roles/getAllActive', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_RESEARCH_ROLES', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async readTimeResearchConversionCriteria({ commit }, params) {
            try {
                const response = await axios.get('uni/tw_time_research_conversion_criteria', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { total, records } = data.data
                    commit('SET_TIME_FRAME_CONVERSION_CRITERIA', records)
                    commit('SET_TOTAL_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async createTimeResearchConversionCriteria({ commit }, params) {
            try {
                const response = await axios.post('uni/tw_time_research_conversion_criteria', params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async updateTimeResearchConversionCriteria({ commit }, params) {
            try {
                const response = await axios.put(`uni/tw_time_research_conversion_criteria/${params.id}`, params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async deleteTimeResearchConversionCriteria({ commit }, id) {
            try {
                const response = await axios.delete(`uni/tw_time_research_conversion_criteria/${id}`)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
    },
}
