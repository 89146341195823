import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { GRADUATION_TYPES, GRADUATION_TYPE_STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    classes: [],
    graduationType: GRADUATION_TYPES,
    graduationTypeStatus: GRADUATION_TYPE_STATUSES,
    registerDataSources: [],
    registeredDataSources: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    classes: state => state.classes,
    graduationType: state => state.graduationType,
    graduationTypeStatus: state => state.graduationTypeStatus,
    registerDataSources: state => state.registerDataSources,
    registeredDataSources: state => state.registeredDataSources,
  },
  mutations: {
    SET_DATA: (state, { records }) => {
      state.dataLists = records
    },
    SET_CLASS: (state, { data }) => {
      state.classes = data
    },
    SET_REGISTER_DATA_SOURCES: (state, data) => { state.registerDataSources = data },
    SET_REGISTERED_DATA_SOURCES: (state, registeredDataSources) => { state.registeredDataSources = registeredDataSources },
  },
  actions: {
    async getClasses({ commit }, params) {
      try {
        const response = await axios.get('uni/classes', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const mapData = data.data.records.map(item => ({ value: item.id, label: item.className }))
          commit('SET_CLASS', {
            data: mapData,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/graduations/graduation-condition', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_DATA', {
            records: response.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateGraduationType({ commit }, params) {
      try {
        const response = await axios.put('uni/graduations/update-graduation-type', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async setGraduationClass({ commit }, params) {
      try {
        const response = await axios.put('uni/graduations/set-graduation-class', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createStudentRegisterSubject({ commit }, params) {
      try {
        const response = await axios.post('uni/portals/studentRegisterGraduationProject', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateStudentRegisterSubject({ commit }, params) {
      try {
        const response = await axios.put(`uni/portals/studentRegisterGraduationProject/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDataGraduationProjectSources({ commit }, params) {
      try {
        const response = await axios.get('uni/portals/getRegisterGraduationProjects', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { registerSubjects, registeredSubjects } = data.data
          commit('SET_REGISTER_DATA_SOURCES', registerSubjects)
          commit('SET_REGISTERED_DATA_SOURCES', registeredSubjects)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
