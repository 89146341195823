<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <validation-observer
      ref="criteriaSaveFormRef"
      #default="{invalid}"
    >
      <b-modal
        id="criteriaSaveModal"
        centered
        size="xl"
        :title="isCreated ? 'Thêm câu hỏi' : 'Cập nhật câu hỏi'"
        no-close-on-backdrop
        @show="onShow"
        @hide="onHide"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label-for="criteriaType"
              >
                <template v-slot:label>
                  Loại câu hỏi <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Loại câu hỏi"
                  rules="required"
                >
                  <v-select
                    id="criteriaType"
                    v-model="targetData.criteriaType"
                    :options="subjectTypeOptions"
                    :reduce="option => option.value"
                    @input="typeOptionChanged"
                    :selectable="option => !option.disabled"
                  />
                  <b-form-invalid-feedback :state="getElementState(errors)">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
              v-if="isShowBodyCommon"
            >
              <b-form-group
                label-for="criteriaCreditClassRoom"
                v-if="evaluateType === EVALUATE_TYPE[0].value"
              >
                <b-form-checkbox
                  id="criteriaCreditClassRoom"
                  v-model="targetData.criteriaCreditClassRoom"
                  value="1"
                  unchecked-value="0"
                  class="mt-2"
                >
                  Là câu hỏi đánh giá phòng học lớp tín chỉ
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="isShowBodyCommon">
            <b-col
              cols="12"
              md="12"
            >
              <b-form-group label-for="name">
                <template v-slot:label>
                  Tên câu hỏi <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Tên câu hỏi"
                  rules="required"
                >
                  <b-form-textarea
                    id="name"
                    placeholder="Nhập tên câu hỏi"
                    rows="3"
                    v-model="targetData.name"
                    :state="getElementState(errors)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="isShowBodyCommon">
            <b-col
              cols="12"
              md="4"
              v-if="isShowMaxPoint"
            >
            <b-form-group label-for="maxPoint">
              <template v-slot:label>
                Điểm tối đa
              </template>
              <validation-provider
                #default="{ errors }"
                name="Điểm tối đa"
                :rules="(setOfEvaluateLevelId ? 'min-value:1|required' : 'min-value:0')"
              >
                <b-form-input
                  id="maxPoint"
                  v-model="targetData.maxPoint"
                  name="maxPoint"
                  placeholder="Điểm tối đa"
                  type="number"
                  :state="getElementState(errors)"
                  @input="maxPointChanged"
                  @keydown="validateKeyDown"
                  
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
            <b-form-group label-for="orderNo">
              <template v-slot:label>
                Thứ tự <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Thứ tự"
                rules="required"
              >
                <b-form-input
                  id="orderNo"
                  v-model="targetData.orderNo"
                  name="orderNo"
                  placeholder="Nhập thứ tự"
                  :state="getElementState(errors)"
                  type="number"
                  @keydown="validateKeyDown"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Bắt buộc trả lời"
                rules="required"
                v-if="targetData.criteriaType === CRITERIA_TYPES[1].value || targetData.criteriaType === CRITERIA_TYPES[2].value"
              >
                <b-form-group label-for="maxPoint">
                  <template v-slot:label>
                    Bắt buộc trả lời <span class="text-danger">*</span>
                  </template>
                  <v-select
                    id="required"
                    v-model="targetData.required"
                    :options="criteriaRequireOptions"
                    :reduce="option => option.value"
                    :state="getElementState(errors)"
                    placeholder="Yêu cầu trả lời"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <div v-if="isShowOptionForACriteria">
            <b-row>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
            <div cols="12">
              <div cols="6" md="6" class="font-weight-bold">
                Danh sách các lựa chọn
              </div>
              <div cols="6" md="6">
                <b-form-group
                  label-for="multipleSelect"
                >
                  <b-form-checkbox
                    id="multipleSelect"
                    v-model="targetData.multipleSelect"
                    value="1"
                    unchecked-value="0"
                    class="mt-2"
                  >
                    Có thể chọn nhiều đáp án
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </div>
            <b-table striped responsive class="mb-0" :items="targetData.criteriaOptions" :fields="criteria_option_fields">
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(name)="data">
                <validation-provider
                  #default="{ errors }"
                  :name="'Tên đáp án ' + (data.index + 1)"
                  rules="required"
                  :id="'criteria_option_name_' + data.index + '_wrap'"
                >
                 <b-form-textarea
                    :id="'textarea_criteria_option_name_' + data.index"
                    placeholder="Nhập tên đáp án"
                    rows="3"
                    v-model="data.item.name"
                  />
                  <small class="text-danger" >{{ errors[0] }}</small>
                </validation-provider>
              </template>
              <template #cell(point)="data">
                <validation-provider
                  #default="{ errors }"
                  :name="'Điểm lựa chọn ' + (data.index + 1)"
                  :rules="('max-value:' + targetData.maxPoint + '|') + (setOfEvaluateLevelId ? 'min-value:1|required' : 'min-value:0')"
                  :id="'criteria_option_point_' + data.index + '_wrap'"
                >
                  
                  <b-form-input
                    :id="'input_criteria_option_point_' + data.index"
                    v-model="data.item.point"
                    placeholder="Điểm"
                    @keydown="validateKeyDown"
                    type="number"
                  />
                  <small class="text-danger" >{{ errors[0] }}</small>
                </validation-provider>
              </template>
              <template #cell(orderNo)="data">
                <validation-provider
                  #default="{ errors }"
                  :name="'Số thứ tự ' + (data.index + 1)"
                  rules="required"
                  :id="'criteria_option_order_no_' + data.index + '_wrap'"
                >
                  
                  <b-form-input
                    :id="'input_criteria_option_order_no_' + data.index"
                    v-model="data.item.orderNo"
                    placeholder="Số thứ tự"
                    @keydown="validateKeyDown"
                    type="number"
                  />
                  <small class="text-danger" >{{ errors[0] }}</small>
                </validation-provider>
              </template>
              <template #cell(action)="data">
                <feather-icon
                  icon="Trash2Icon"
                  class="small-icon"
                  size="18"
                  @click="removeItem(data.index)"
                />
              </template>
            </b-table>
          </div>
        </b-form>
        <div v-if="isShowOptionForACriteria">
          <b-col cols="12">
            <hr>
          </b-col>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="repeatAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Thêm mới một tuỳ chọn</span>
            </b-button>
        </div>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              v-show="isCreated"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid || (!isCreated && allowEditEvaluationCriteria === 0 && countSetOfCriteriaObjectUsing > 0)"
              @click="onSave('hide')"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu lại
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('criteriaSaveModal')"
            >
              <span class="text-right">
                <feather-icon icon="XIcon" /> Hủy
              </span>
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton, BRow, BCol, BFormCheckbox, BTable, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, minValue } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'
import { CRITERIA_TYPES, EVALUATE_TYPE } from '@/const/type'

export default {
  name: 'CriteriaSave',
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BTable,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
    setOfCriteriaId: {
      type: Number,
      default: null,
    },
    setOfEvaluateLevelId: {
      type: Number,
      default: null,
    },
    evaluateType: {
      type: Number,
      default: null,
    },
    allowEditEvaluationCriteria: {
      type: Number,
      default: null,
    },
    countSetOfCriteriaObjectUsing: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      criteria_option_fields: [
        { key: 'index', label: '#', thStyle: { width: "3%", }, },
        { key: 'name', label: 'Tên đáp án', thStyle: { width: "60%" }, },
        { key: 'point', label: 'Điểm', thStyle: { width: "15%" }, thClass: 'text-center', tdClass: 'text-center', },
        { key: 'orderNo', label: 'Số thứ tự', thStyle: { width: "15%" }, },
        { key: 'action', label: '' },
      ],
      targetData: {
        id: null,
        setOfCriteriaId: this.setOfCriteriaId,
        criteriaType: CRITERIA_TYPES[2].value,
        orderNo: 0,
        required: 1,
        name: '',
        maxPoint: null,
        multipleSelect: 0,
        criteriaCreditClassRoom: 0,
        criteriaOptions: [],
      },
      required,
      isShowBodyCommon: false,
      isShowMaxPoint: false,
      isShowOptionForACriteria: false,
      isOptionsGreaterThanMaxPoint: false,
      minValue,
      CRITERIA_TYPES,
      EVALUATE_TYPE,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      criteriaTypes: 'criteria/criteriaTypes',
      maxOrderNo: 'criteria/maxOrderNo',
      criteriaRequires: 'criteria/criteriaRequires',
    }),
    subjectTypeOptions() {
      if(!this.setOfEvaluateLevelId) {
        this.criteriaTypes.forEach((item) => {
          if(item.value === CRITERIA_TYPES[0].value)
            item.disabled = true
        })
      } else {
        this.criteriaTypes.forEach((item) => {
          if(item.value === CRITERIA_TYPES[0].value)
            item.disabled = false
        })
      }
      return this.criteriaTypes
    },
    criteriaRequireOptions() {
      return this.criteriaRequires
    },
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: 'criteria/createData',
      updateData: 'criteria/updateData',
      getMaxOrderNo: 'criteria/getMaxOrderNo',
    }),
    validateKeyDown() {
      // Check if the pressed key is a number or not
      if (!/^\d$|^Backspace$|^Arrow(Up|Down)$/.test(event.key)) {
        // If not a number, suppress the default event and do not allow input
        event.preventDefault();
      }
    },
    maxPointChanged() {
      this.isOptionsGreaterThanMaxPoint = false
      if(this.targetData.criteriaType === CRITERIA_TYPES[1].value) {
        this.targetData.criteriaOptions.forEach((option) => {
          if (!this.isOptionsGreaterThanMaxPoint) {
            if(option.point > this.targetData.maxPoint) {
              this.isOptionsGreaterThanMaxPoint = true
              this.showToast('Điểm tối đa phải lớn hơn bằng điểm của các tuỳ chọn!', 'XCircleIcon', 'danger', 3000)
            }
          }
        })
      }
    },
    typeOptionChanged () {
      this.isShowBodyCommon = false
      this.isShowOptionForACriteria = false
      this.isShowMaxPoint = false
      if(this.targetData.criteriaType === CRITERIA_TYPES[0].value) {
        this.isShowBodyCommon = true
        this.isShowMaxPoint = true
        this.targetData.criteriaOptions = []
        this.targetData.multipleSelect = 0
      }
      if(this.targetData.criteriaType === CRITERIA_TYPES[1].value) {
        this.isShowBodyCommon = true
        this.isShowMaxPoint = true
        this.isShowOptionForACriteria = true
      }
      if(this.targetData.criteriaType === CRITERIA_TYPES[2].value) {
        this.isShowBodyCommon = true
        this.isShowMaxPoint = false
        this.targetData.maxPoint = null
        this.targetData.criteriaOptions = []
        this.targetData.multipleSelect = 0
      }
      setTimeout(() => {
        this.$refs.criteriaSaveFormRef.validate()
      }, 50)
    },
    repeatAgain() {
      var maxOrderNo = 0
      this.targetData.criteriaOptions.forEach((option) => {
        if(option.orderNo > maxOrderNo) {
          maxOrderNo = option.orderNo
        }
      })
      this.targetData.criteriaOptions.push({
        name: '',
        point: '',
        orderNo: maxOrderNo + 1,
      })
      setTimeout(() => {
        this.$refs.criteriaSaveFormRef.validate()
      }, 50)
    },
    removeItem(index) {
      this.targetData.criteriaOptions.splice(index, 1)
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.subjectTypeOptions = this.subjectTypeOptions.filter((obj) => {
          obj.value != 1;
      });
      this.isLoading = true
      if (this.mainData) {
        this.targetData = this.mainData
      }
      if (this.isCreated) {
        await this.getMaxOrderNo({ setOfCriteriaId: this.targetData.setOfCriteriaId })
        this.targetData.orderNo = this.maxOrderNo + 1
      }
      this.targetData.setOfEvaluateLevelId = this.setOfEvaluateLevelId
      this.typeOptionChanged()
      this.isLoading = false
    },
    onHide() {
      this.$refs.criteriaSaveFormRef.reset()
      this.targetData = {
        id: null,
        setOfCriteriaId: this.setOfCriteriaId,
        criteriaType: CRITERIA_TYPES[2].value,
        orderNo: 0,
        required: 1,
        name: '',
        maxPoint: null,
        multipleSelect: 0,
        criteriaCreditClassRoom: 0,
        criteriaOptions: [],
      }
      this.typeOptionChanged()
    },
    async onSave(type = null) {
      if((this.targetData.criteriaType === CRITERIA_TYPES[1].value) && this.targetData.criteriaOptions.length === 0) {
        this.showDanger('Bạn phải tạo ít nhất 1 lựa chọn')
        return
      }
      const valid = this.$refs.criteriaSaveFormRef.validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createData(this.targetData)
            : await this.updateData(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('criteriaSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    showDanger(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    },
    showToast(title, icon, variant, autoHideDelay = 1000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.option-index {
  width: 20px;
}
</style>
