<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <div class="text-right mb-1 mr-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="ml-1"
                @click="downloadFile"
              >
                <span class="text-nowrap text-right">
                  <feather-icon icon="DownloadIcon" /> Xuất báo cáo
                </span>
              </b-button>
            </div>
            <b-row>
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  :columns="columns"
                  :rows="teacherHours"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'action'">
                    <b-button
                      v-if="updatable"
                      v-b-modal.teacherHourSaveModal
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="onEditTeacher(props.row)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-if="deletable"
                      variant="danger"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="onDeleteTeacher(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </span>
                    <span v-else-if="props.column.field === 'rowNum'">
                      {{ (filter.currentPage-1)* filter.itemsPerPage +props.row.rowNum }}
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
      <TeacherHourSave
        ref="saveModalRef"
        :item="currentTeacher"
        @succeed="onSucceed"
      />
    </b-container>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import TeacherHourSave from '@/views/admin/teacherManagement/tw-teacher-hour/TwTeacherHourSave.vue'

export default {
  name: 'TeacherHours',
  directives: {
    Ripple,
  },
  components: {
    TeacherHourSave,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    dataSendClass: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        year: null,
        teacherId: null,
        sort: null,
      },
      currentMainData: undefined,
      currentTeacher: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      selectedRows: [],
      columns: [
        {
          label: 'Học phần',
          field: 'subjectName',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Giờ giảng',
          field: 'teachingHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giờ ra đề',
          field: 'examPreparationHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giờ duyệt đề',
          field: 'examReviewHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giờ coi thi',
          field: 'examProctoringHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giờ chấm thi',
          field: 'examGradingHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giờ hướng dẫn học viên NCTT',
          field: 'researchSupervisionHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giờ chấm bài thu hoạch NCTT',
          field: 'researchGradingHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giờ hướng dẫn KLTN',
          field: 'thesisSupervisionHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giờ hội đồng chấm KLTN',
          field: 'thesisDefenseCommitteeHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giờ thành viên chấm KLTN',
          field: 'thesisGradingMemberHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giờ hội đồng thi TN',
          field: 'graduationExamCommitteeHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giờ hội đồng tuyển sinh',
          field: 'admissionsCommitteeHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giờ hội đồng xét cấp bằng TN',
          field: 'graduationDegreeCommitteeHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Giờ ban quản lý, chủ nhiệm lớp',
          field: 'teacherClassHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tổng giờ khác',
          field: 'otherTotalHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tổng giờ',
          field: 'totalHours',
          tdClass: 'text-center',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      teacherHours: 'twTeacherHour/teacherHours',
      totalRows: 'twTeacherHour/totalRows',
      dataExport: 'twTeacherHour/dataExport',
    }),
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.TW_TEACHER_HOUR)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.TW_TEACHER_HOUR)
    },
  },
  async created() {
    this.isLoading = true
    try {
      this.filter.year = this.dataSendClass.year
      this.filter.teacherId = this.dataSendClass.teacherId
      await this.getTeacherHoursFromStore()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      readTeacherHours: 'twTeacherHour/readTeacherHours',
      getDataExport: 'twTeacherHour/downloadExport',
      deleteTeacherHours: 'twTeacherHour/deleteTeacherHours',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getTeacherHoursFromStore()
    },
    onEditTeacher(teacher) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = teacher
      this.currentTeacher = rest
    },
    onDeleteTeacher(teacherHour) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa giảng viên <span class="text-danger">${teacherHour.teacherName}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteTeacherHours(teacherHour.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.readTeacherHours(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async getTeacherHoursFromStore() {
      this.isLoading = true
      try {
        await this.readTeacherHours(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getTeacherHoursFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)
        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getTeacherHoursFromStore()
    },
    async onSucceed() {
      await this.getTeacherHoursFromStore()
    },
    async downloadFile() {
      this.isLoading = true
      try {
        await this.getDataExport({
          year: this.filter.year,
          teacherId: this.filter.teacherId,
        })
        const a = document.createElement('a')
        document.body.appendChild(a)
        const blob = new Blob([this.dataExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'tong_hop_gio_giang_vien.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
</style>
