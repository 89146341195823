<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <validation-observer
      ref="researchOtherAttachDocumentSaveFormRef"
      #default="{invalid}"
    >
      <b-modal
        id="researchOtherAttachDocumentSaveModal"
        centered
        size="xl"
        :title="isCreated ? 'Thêm trích yếu' : 'Cập nhật trích yếu'"
        no-close-on-backdrop
        @show="onShow"
        @hide="onHide"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
            <b-form-group label-for="name">
              <template v-slot:label>
                Nội dung kế hoạch <span class="text-danger">*</span>
              </template>
              <validation-provider #default="{ errors }" name="Nội dung kết hoạch" rules="required">
                <b-form-input id="name" v-model="targetData.name" placeholder="Nhập nội dung kết hoạch"
                  :state="getElementState(errors)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group>
                <template v-slot:label>
                  Tài liệu <span class="text-danger">*</span>
                </template>
                <b-form-file :placeholder="targetData.fileName"
                  @change="event => uploadFile(event)" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              v-show="isCreated"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu lại
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('researchOtherAttachDocumentSaveModal')"
            >
              <span class="text-right">
                <feather-icon icon="XIcon" /> Hủy
              </span>
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton, BRow, BCol, BFormCheckbox, BTable, BFormTextarea, BFormFile,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'

export default {
  name: 'researchOtherAttachDocumentSave',
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BTable,
    BFormTextarea,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  props: {
    researchConferenceId: {
      type: Number,
      default: undefined,
    },
    mainData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        id: null,
        name: '',
        fileId: null,
        fileName: 'Chọn để tải ...'
      },
      required,
      formDataSave: new FormData(),
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
    }),
    isCreated() {
      return !this.mainData
    },
  },
  methods: {
    ...mapActions({
      createData: 'researchConference/createResearchOtherAttachDocument',
      updateData: 'researchConference/updateResearchOtherAttachDocument',
      uploadSample: 'researchConference/uploadSample',
      removeSample: 'researchConference/removeSample',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onShow() {
      this.isLoading = true
      if (this.mainData) {
        this.targetData = this.mainData
      }
      this.isLoading = false
    },
    onHide() {
      this.$refs.researchOtherAttachDocumentSaveFormRef.reset()
      this.mainData = undefined
      this.targetData = {
        id: null,
        name: '',
        fileId: null,
        fileName: 'Chọn để tải ...'
      }
    },
    async onSave(type = null) {
      const valid = this.$refs.researchOtherAttachDocumentSaveFormRef.validate()
      if (valid) {
        this.formDataSave.delete('researchConferenceId');
        this.formDataSave.append('researchConferenceId', this.researchConferenceId)
        this.formDataSave.delete('id');
        this.formDataSave.append('id', this.targetData.id)
        this.formDataSave.delete('name');
        this.formDataSave.append('name', this.targetData.name)
        this.formDataSave.delete('fileId');
        this.formDataSave.append('fileId', this.targetData.fileId)
        this.formDataSave.delete('fileName');
        this.formDataSave.append('fileName', this.targetData.fileName)
        this.isLoading = true
        try {
          if(!this.mainData && (!this.formDataSave.has('files') || !this.formDataSave.get('files'))) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'File tài liệu đang bị trống',
                icon: 'InfoIcon',
                variant: 'warning',
              },
            })
            this.isLoading = false
            return
          }

          const response = this.targetData.id ? await this.updateData(this.formDataSave) : await this.createData(this.formDataSave)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('researchOtherAttachDocumentSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    showToast(title, icon, variant, autoHideDelay = 1000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },
    async uploadFile(event) {
      this.isLoading = true
      try {
        const { files } = event.target
        if (files.length === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File tài liệu đang bị trống',
              icon: 'InfoIcon',
              variant: 'warning',
            },
          })
          this.isLoading = false
          return
        }
        this.formDataSave.delete('files');
        files.forEach(file => this.formDataSave.append('files', file))
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.option-index {
  width: 20px;
}
</style>
