import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/student-suspend',
    name: 'studentSuspend',
    component: () => import('@/views/admin/studentManagement/suspendByClass/SuspendByClass.vue'),
    meta: {
      pageTitle: 'Xử lý cảnh báo thôi học',
      breadcrumb: [
        {
          text: 'Quản lý học viên',
          active: false,
        },
        {
          text: 'Xử lý cảnh báo thôi học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.STUDENT_SUSPEND,
    },
  },
]
