import { PermissionCode, ResourceCode } from '@/const/code'

export default [
    {
        path: '/configAutoCreateStudentCode',
        name: 'configAutoCreateStudentCode',
        component: () => import('@/views/admin/studentManagement/configAutoCreateStudentCode/ConfigAutoCreateStudentCode.vue'),
        meta: {
            pageTitle: 'Cấu hình tự động tạo mã học viên',
            breadcrumb: [
                {
                    text: 'Hệ thống',
                    active: false,
                },
                {
                    text: 'Cấu hình tự động tạo mã học viên',
                    active: true,
                },
            ],
            action: PermissionCode.READ,
            resource: ResourceCode.CONFIG_CREATE_STUDENT_CODE,
        },
    },
]
