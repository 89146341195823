<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row :class="{'mt-0': isSystemAdmin() || creatable}">
              <b-col cols="12">
                      <b-row>
                        <b-col cols="9">
                          <div class="align-items-center pt-1">
                            <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                              đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                            </span>
                          </div>
                        </b-col>
                        <b-col cols="3">
                          <div class="text-right">
                            <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                variant="outline-primary"
                                class="mb-1 text-right"
                                @click="exportExcel"
                            >
                                    <span class="text-nowrap text-right">
                                      <feather-icon icon="DownloadIcon" /> Xuất excel
                                    </span>
                            </b-button>
                          </div>
                        </b-col>
                      </b-row>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="accounts"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>

                    <span v-else-if="props.column.field === 'action'">
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >
                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          v-if="isSystemAdmin() || updatable"
                          v-b-modal.accountSaveModal
                          @click="onEditAccount(props.row)"
                        >
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">Sửa</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="isSystemAdmin() || accountRoleReadable"
                          @click="onAssignRole(props.row.id)"
                        >
                          <feather-icon icon="TargetIcon" />
                          <span class="align-middle ml-50">Phân vai trò</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="isSystemAdmin() || creatable"
                          @click="onResetPassword(props.row.id)"
                        >
                          <feather-icon icon="RotateCcwIcon" />
                          <span class="align-middle ml-50">Đặt lại mật khẩu</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="(isSystemAdmin() || creatable || updatable) && !!props.row.linkAccountId"
                          @click="onUnlinkAccount(props.row)"
                        >
                          <feather-icon icon="CloudOffIcon" />
                          <span class="align-middle ml-50">Hủy liên kết tài khoản</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="isSystemAdmin() || deletable"
                          @click="onDeleteAccount(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                          <span class="align-middle ml-50">Xóa</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{(filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <AccountSave
      :account="currentAccount"
      @succeed="onSucceed"
    />
    <ResetPassword :id="selectedId" />
    <AssignRole :id="selectedId" />
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import AccountSave from '@/views/authorization/account/AccountSave.vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { STATUSES } from '@/const/status'
import {
  BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import ResetPassword from '@/views/authorization/account/ResetPassword.vue'
import AssignRole from '@/views/authorization/account/AssignRole.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import * as XLSX from "xlsx";

export default {
  name: 'Account',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    AccountSave,
    ResetPassword,
    AssignRole,
    VueGoodTable,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        username: '',
        status: null,
        notInUsername: 'SystemAdmin',
        type: null,
        sort: '',
      },
      currentAccount: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      selectedId: undefined,
      columns: [
        {
          label: 'Tên người dùng',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Tài khoản',
          field: 'username',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tên tài khoản',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            filterDropdownItems: STATUSES.map(status => ({ value: status.value, text: status.label })),
            placeholder: 'Tất cả',
          },
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Phân loại',
          field: 'typeName',
          filterOptions: {
            enabled: true,
            filterDropdownItems: [{ value: 1, text: 'Cán bộ' }, { value: 2, text: 'Học viên' }],
            placeholder: 'Tất cả',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      accounts: 'account/accounts',
      allAccounts: 'account/allAccounts',
      totalRows: 'account/totalRows',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.ACCOUNT)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.ACCOUNT)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.ACCOUNT)
    },
    accountRoleReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.ACCOUNT_ROLE)
    },
  },
  async created() {
    await this.getAccountsFromStore()
  },
  methods: {
    ...mapActions({
      getAccounts: 'account/getAccounts',
      getAllAccounts: 'account/getAllAccounts',
      deleteAccount: 'account/deleteAccount',
      unlinkAccount: 'account/unlinkAccount',
    }),
    isSystemAdmin,
    onEditAccount(account) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = account
      this.currentAccount = rest
    },
    onDeleteAccount(account) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa tài khoản <span class="text-danger">${account.username}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.isLoading = true
            try {
              const response = await this.deleteAccount(account.id)
              if (response) {
                const { isSuccessful, message } = response
                if (isSuccessful) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  try {
                    await this.getAccounts(this.filter)
                  } catch (e) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `[${e.code}] ${e.message}`,
                        icon: 'XCircleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.isLoading = false
            }
          }
        })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getAccountsFromStore()
    },
    async getAccountsFromStore() {
      this.isLoading = true
      try {
        await this.getAccounts(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getAccountsFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const {
          field,
          type,
        } = param[0]
        if (field === 'username') this.updateParams({ sort: `1_${type}` })
        else this.updateParams({ sort: `2_${type}` })
      }
      await this.getAccountsFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'username')) this.updateParams({ username: columnFilters.username })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      if (Object.hasOwn(columnFilters, 'typeName')) this.updateParams({ type: columnFilters.typeName })
      await this.getAccountsFromStore()
    },
    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getAccountsFromStore()
    },
    onResetPassword(id) {
      this.openModal(id, 'resetPasswordModal')
    },
    onAssignRole(id) {
      this.openModal(id, 'assignRoleModal')
    },
    openModal(accountId, modalId) {
      if (!accountId) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không tìm được tài khoản tương ứng',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } else {
        this.selectedId = accountId
        this.$bvModal.show(modalId)
      }
    },
    async exportExcel() {
      this.isLoading = true
      try {
        const params = { ...this.filter, ...{ currentPage: 1, itemsPerPage: 1000000 } }
        await this.getAllAccounts(params)
        if (this.allAccounts.length === 0) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Không có dữ liệu để xuất!',
              icon: 'InfoIcon',
              variant: 'warning',
            },
          })
          return
        }
        const data = []
        const header = {}
        this.columns.forEach(column => {
          header[column.field] = column.label
        })
        const headerKeys = Object.keys(header)
        const dataSourceKeys = Object.keys(this.allAccounts[0])
        const duplicates = dataSourceKeys.filter(val => headerKeys.includes(val))
        duplicates.sort((a, b) => headerKeys.indexOf(a) - headerKeys.indexOf(b))
        this.allAccounts.forEach(dataSource => {
          const elm = {}
          duplicates.forEach(key => {
            let data = dataSource[key]
            if (key === 'status') {
              data = this.getStatusName(dataSource[key])
            }
            elm[header[key]] = data
          })
          data.push(elm)
        })
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Accounts')
        XLSX.writeFile(wb, 'Accounts.xlsx')
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }

    },
    onUnlinkAccount(account) {
      this.$swal({
        title: `Bạn chắc chắn muốn hủy liên kết tài khoản <span class="text-danger">${account.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.isLoading = true
            try {
              const response = await this.unlinkAccount(account.id)
              if (response) {
                const { isSuccessful, message } = response
                if (isSuccessful) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  try {
                    await this.getAccounts(this.filter)
                  } catch (e) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `[${e.code}] ${e.message}`,
                        icon: 'XCircleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.isLoading = false
            }
          }
        })
    },
  },
}
</script>
