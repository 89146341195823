<template>
  <div class="row">
    <b-container fluid>
      <b-card>
        <b-overlay
            :show="isLoading"
            rounded="sm"
        >
          <b-row>
            <b-col>
              <h3>
                Danh sách giảng viên hướng dẫn
              </h3>
            </b-col>
          </b-row>
          <div class="m-s">
            <b-row class="mt-2">
              <b-col cols="12">
                <div>
                  <vue-good-table
                      mode="remote"
                      class="my-table"
                      style-class="vgt-table striped bordered"
                      row-style-class="vgt-row"
                      :columns="columns"
                      :rows="practicalResearchClassGroupTeachers"
                      :pagination-options="paginationOptions"
                      :line-numbers="true"
                  >
                    <div
                        slot="emptystate"
                        style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template
                        slot="table-row"
                        slot-scope="props"
                    >
                      <span
                          v-if="props.column.field === 'action'"
                          class="thead-group-action"
                      >
                        <b-button-group>
                          <b-button
                              v-b-modal.practicalResearchClassGroupTeacherSaveModal
                              class="btn-icon"
                              variant="danger"
                              size="sm"
                              title="Xóa"
                              @click="onEdit(props.row)"
                          >
                            <feather-icon icon="EditIcon" />
                          </b-button>
                        </b-button-group>
                      </span>
                      <span
                          v-else-if="props.column.field === 'departmentId'"
                          class="thead-group-action"
                      >
                        {{ getDepartmentName(props.row.departmentId) }}
                      </span>
                      <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    </template>
                  </vue-good-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
      <PracticalResearchClassGroupTeacherSave
          ref="practicalResearchClassGroupTeacherSaveModal"
          :main-data="mainData"
          @succeed="getDataFromStore"
      />
    </b-container>
  </div>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem, BForm, BFormGroup,
  BFormSelect, BOverlay, BPagination, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import PracticalResearchCreate from '@/views/practical-research-class-group/PracticalResearchCreate.vue'
import PracticalResearchStudentRestAdd
from '@/views/practical-research-class-group/PracticalResearchStudentRestAdd.vue'
import PracticalResearchClassGroupTeacherSave
from '@/views/practical-research-class-group/PracticalResearchClassGroupTeacherSave.vue'

export default {
  name: 'PracticalResearchClassGroupTeacher',
  directives: {
    Ripple,
  },
  components: {
    PracticalResearchClassGroupTeacherSave,
    PracticalResearchStudentRestAdd,
    PracticalResearchCreate,
    BButton,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
  },
  props: {
    dataGroup: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        practicalResearchClassGroupId: null,
      },
      paginationOptions: {
        enabled: false,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      mainData: undefined,
      columns: [
        {
          label: 'Tên giảng viên',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Khoa/Bộ môn',
          field: 'departmentId',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Số tiết thực hiện',
          field: 'practicalPeriodNum',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thời gian bắt đầu thực hiện',
          field: 'timeExecution',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      statues: 'practicalResearchClassGroupTeacher/statuses',
      teachers: 'practicalResearchClassGroupTeacher/teachers',
      departments: 'practicalResearchClassGroupTeacher/departments',
      practicalResearchClassGroupTeachers: 'practicalResearchClassGroupTeacher/practicalResearchClassGroupTeachers',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.getDepartments({ organizationId: getUser().orgId, currentPage: 1, itemsPerPage: 10000 }),
        this.readPracticalResearchClassGroupTeacher({ practicalResearchClassGroupId: this.dataGroup.id }),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTeachers: 'practicalResearchClassGroupTeacher/getTeachers',
      getDepartments: 'practicalResearchClassGroupTeacher/getDepartments',
      readPracticalResearchClassGroupTeacher: 'practicalResearchClassGroupTeacher/readPracticalResearchClassGroupTeacher',
    }),
    onEdit(row) {
      this.mainData = row
    },
    getDepartmentName(id) {
      return this.departments.find(d => d.id === id).name
    },
    async getDataFromStore() {
      this.isLoading = true
      try {
        await this.readPracticalResearchClassGroupTeacher({ practicalResearchClassGroupId: this.dataGroup.id })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
