<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm khoản thu' : 'Cập nhật khoản thu'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="admissionRoundId">
              <template v-slot:label>
                Đợt xét tuyển <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Đợt xét tuyển"
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionRoundId"
                  :options="admissionRounds"
                  :reduce="option => option.value"
                  @input="readAdmissionForm"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
<!--        <b-row>-->
<!--          <b-col cols="12">-->
<!--            <b-form-group label-for="admissionFormId">-->
<!--              <template v-slot:label>-->
<!--                Phương thức xét tuyển <span class="text-danger">*</span>-->
<!--              </template>-->
<!--              <validation-provider-->
<!--                #default="{ errors }"-->
<!--                name="Phương thức xét tuyển"-->
<!--                rules="required"-->
<!--              >-->
<!--                <v-select-->
<!--                  v-model="targetItem.admissionFormId"-->
<!--                  :options="dataListAdmissionForm"-->
<!--                  :reduce="option => option.value"-->
<!--                />-->
<!--                <b-form-invalid-feedback :state="getElementState(errors)">-->
<!--                  {{ errors[0] }}-->
<!--                </b-form-invalid-feedback>-->
<!--              </validation-provider>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
<!--        </b-row>-->
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="admissionRevenueTypeId">
              <template v-slot:label>
                Loại khoản thu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Loại khoản thu "
                rules="required"
              >
                <v-select
                  v-model="targetItem.admissionRevenueTypeId"
                  :options="admissionRevenueTypes"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên khoản thu
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên khoản thu"
              >
                <b-form-input
                  id="name"
                  v-model="targetItem.name"
                  name="name"
                  placeholder="Nhập tên khoản thu"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetItem.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'

export default {
  name: 'Save',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        admissionRoundId: null,
        // admissionFormId: null,
        admissionRevenueTypeId: null,
        name: '',
        status: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'admissionRound/statuses',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionForm/admissionFormByAdmissionRoundId',
      admissionRevenueTypes: 'admissionRevenueType/admissionRevenueTypes',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createAdmissionRevenues: 'admissionRevenue/createAdmissionRevenues',
      updateAdmissionRevenues: 'admissionRevenue/updateAdmissionRevenues',
      readAdmissionFormByAdmissionRoundId: 'admissionForm/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
      getAdmissionRevenueTypes: 'admissionRevenueType/getAdmissionRevenueTypes'
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await this.getAdmissionRoundsByOrganization()
      await this.getAdmissionRevenueTypes()
      if (this.item) {
        this.targetItem = { ...this.item }
        await this.readAdmissionForm(this.targetItem.admissionRoundId)
        await this.getAdmissionRevenueTypes()
      }
    },
    async readAdmissionForm(id) {
      await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: id })
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        admissionRoundId: null,
        // admissionFormId: null,
        numberValue: 0,
        status: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createAdmissionRevenues(this.targetItem)
            : await this.updateAdmissionRevenues(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
