<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body class="mt-md-1">
        <b-overlay variant="white" spinner-variant="primary" blur="0" opacity=".75" rounded="sm" :show="isLoading">
          <div class="m-1">
            <b-row>
              <b-col cols="8" md="8">
                <h3>Danh sách trích yếu</h3>
              </b-col>
              <b-col cols="4" md="4">
                <div class="text-right">
                  <b-button v-if="creatable" v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-modal.researchOtherAttachDocumentSaveModal
                    variant="success" class="ml-1" @click="onCreateResearchOtherAttachDocumentSaveModal">
                    <span class="text-nowrap text-right d-none d-lg-block">
                      <feather-icon icon="PlusIcon" /> Thêm trích yếu
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="12">
                <vue-good-table mode="remote" class="my-table" row-style-class="vgt-row"
                  styleClass="vgt-table striped bordered" :line-numbers="true" :columns="columns" :rows="mainData"
                  :pagination-options="paginationOptions" :total-rows="totalRows" max-height="400px"
                  @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange">
                  <div slot="emptystate" style="text-align: center; font-weight: bold">
                    Không có bản ghi nào !
                  </div>
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'action'">
                      <b-button
                        v-if="updatable"
                        v-b-modal.researchOtherAttachDocumentSaveModal
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        @click="onEditMainData(props.row)"
                        title="Chỉnh sửa"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                      <b-button
                        v-b-tooltip.hover.top="'Tải về'"
                        variant="success"
                        class="btn-icon"
                        size="sm"
                        style="margin-left: 6px"
                        @click="onDownload(props.row.fileName)"
                      >
                        <feather-icon icon="DownloadIcon" />
                      </b-button>
                      <b-button
                        v-if="deletable"
                        variant="danger"
                        class="btn-icon"
                        style="margin-left: 6px"
                        size="sm"
                        @click="onDeleteMainData(props.row)"
                        title="Xoá"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage - 1) + 1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select v-model="filter.itemsPerPage" :options="itemsPerPageOptions" class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination :value="1" :total-rows="totalRows" :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })" />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
          <ResearchOtherAttachDocumentSave ref="researchOtherAttachDocumentSaveFormRef" :researchConferenceId="dataSend.id" :mainData="currentMainData" @succeed="onSucceed"/>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
  BFormFile,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import ResearchOtherAttachDocumentSave from '@/views/research/research-conferences/ResearchOtherAttachDocumentSave.vue'
import { PermissionCode, ResourceCode } from '@/const/code'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'


export default {
  name: 'ResearchOtherAttachDocuments',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    ResearchOtherAttachDocumentSave,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormFile,
  },
  props: {
    dataSend: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        id: null,
        currentPage: 1,
        itemsPerPage: 10,
        name: '',
        sort: null,
      },
      currentMainData: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Nội dung kế hoạch',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tài liệu',
          field: 'fileName',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      mainData: 'researchConference/dataListsOtherAttachDocument',
      totalRows: 'researchConference/totalRowsOtherAttachDocument',
      url: 'researchConference/url',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.RESEARCH_OTHER_ATTACH_DOCUMENT)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.RESEARCH_OTHER_ATTACH_DOCUMENT)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.RESEARCH_OTHER_ATTACH_DOCUMENT)
    },
  },
  async created() {
    this.isLoading = true
    try {
      this.updateParams({ id: this.dataSend.id  })
      await this.getMainDataFromStore()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    isSystemAdmin,
    ...mapActions({
      getMainData: 'researchConference/getResearchOtherAttachDocuments',
      deleteMainData: 'researchConference/deleteResearchOtherAttachDocument',
      downloadResearchOtherAttachDocument: 'researchConference/downloadResearchOtherAttachDocument',
    }),
    onCreateResearchOtherAttachDocumentSaveModal() {
      this.currentMainData = undefined
    },
    onEditMainData(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xoá bỏ trích yếu <span class="text-danger">${mainData.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        size: 'xl',
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainData(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      await this.getMainDataFromStore()
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
    showToast(title, icon, variant, autoHideDelay = 1000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },
    async onDownload(objectPath) {
      this.isLoading = true
      try {
        await this.downloadResearchOtherAttachDocument({ objectPath })
        if (this.url) {
          window.open(this.url, '_blank')
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
  mounted() {
    const grid = document.querySelector('.vgt-responsive')
    grid.classList.add('overlay')
    grid.classList.add('custom')
  },
}
</script>
