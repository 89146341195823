<template>
  <section class="pdf-content">
    <section class="report-info">
      <div>
        <b-container fluid>
          <b-card no-body>
            <div class="m-2">
              <b-row>
                <b-col
                  cols="6"
                  style="text-align: center"
                >
                  <p
                    style="
                      padding-bottom: 0px !important;
                      margin-bottom: 0px !important;
                    "
                  >
                    UBND TỈNH NINH BÌNH
                  </p>
                  <p
                    style="
                      font-weight: bolder;
                      margin-top: 0px !important;
                      padding-top: 0px !important;
                    "
                  >
                    TRƯỜNG ĐẠI HỌC HOA LƯ
                  </p>
                </b-col>
                <b-col
                  cols="6"
                  style="text-align: center; margin-bottom: 10px"
                >
                  <p style="font-weight: bolder">
                    DANH SÁCH PHÁCH HỌC VIÊN
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <p>Chương trình đào tạo/Bồi dưỡng: {{ this.contentPdf.heDaoTao }}</p>
                </b-col>
                <!--                  <b-col cols="4">-->
                <!--                    <p>Lớp: {{ this.contentPdf.lop }}</p>-->
                <!--                  </b-col>-->
              </b-row>
              <b-row>
                <b-col cols="4">
                  <p>
                    Năm học:
                    {{
                      this.contentPdf.hocKy.substr(
                        this.contentPdf.hocKy.length - 9
                      )
                    }}
                  </p>
                </b-col>
                <b-col cols="4">
                  <p>Học kỳ: {{ this.contentPdf.hocKy.substring(0, 8) }}</p>
                </b-col>
                <b-col cols="4">
                  <p>Phòng thi số: {{ this.contentPdf.phongThi }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="8">
                  <p>Học phần: {{ this.contentPdf.monHoc }}</p>
                </b-col>
                <b-col cols="4">
                  <p>Lần thi: {{ this.contentPdf.lanThi }}</p>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="12">
                  <vue-good-table
                    mode="remote"
                    :columns="columns"
                    :rows="listStudentExaminationRoom"
                    :pagination-options="paginationOptions"
                  >
                    <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <span v-if="props.column.field === 'stt'">
                        {{ props.row.originalIndex + 1 }}
                      </span>
                      <span v-else>{{
                        props.formattedRow[props.column.field]
                      }}</span>
                    </template>
                  </vue-good-table>
                </b-col>
              </b-row>
            </div>
            <div
              class="m-2"
              style="text-align: center"
            >
              <b-row class="mt-2">
                <b-col cols="4">
                  <p>Cán bộ làm phách thứ nhất</p>
                </b-col>
                <b-col cols="4">
                  <p>Cán bộ làm phách thứ hai</p>
                </b-col>
                <b-col cols="4">
                  <p
                    style="
                      padding-bottom: 0px !important;
                      margin-bottom: 0px !important;
                    "
                  >
                    Ninh Bình, ngày {{ today.date }} tháng {{ today.month }} năm
                    {{ today.year }}
                  </p>
                  <p
                    style="
                      font-weight: bolder;
                      margin-top: 0px !important;
                      padding-top: 0px !important;
                    "
                  >
                    TP. Quản lý chất lượng
                  </p>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-container>
      </div>
    </section>
  </section>
</template>

<script>
import {
  BCard, BCol, BContainer, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCol,
    BContainer,
    BRow,
    VueGoodTable,
  },
  props: {
    listStudentExaminationRoom: {
      type: Array,
      default: undefined,
    },
    contentPdf: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      paginationOptions: {
        enabled: false,
      },
      columns: [
        {
          label: 'STT',
          field: 'stt',
          width: '50px',
          sortable: false,
          thClass: 'text-center pdf-table-th',
          tdClass: 'text-center pdf-table-td',
        },
        {
          label: 'Mã học viên',
          field: 'code',
          sortable: false,
          width: '100px',
          thClass: 'text-center pdf-table-th',
          tdClass: 'text-center pdf-table-td',
        },
        {
          label: 'Họ đệm',
          field: 'last_name',
          sortable: false,
          width: '120px',
          thClass: 'text-center pdf-table-th',
          tdClass: 'text-center pdf-table-td',
        },
        {
          label: 'Tên',
          field: 'name',
          sortable: false,
          width: '70px',
          thClass: 'text-center pdf-table-th',
          tdClass: 'text-center pdf-table-td',
        },
        {
          label: 'Ngày sinh',
          field: 'birthday',
          sortable: false,
          width: '85px',
          thClass: 'text-center pdf-table-th',
          tdClass: 'text-center pdf-table-td',
        },
        {
          label: 'Lớp học',
          field: 'class_name',
          width: '190px',
          sortable: false,
          thClass: 'text-center pdf-table-th',
          tdClass: 'text-center pdf-table-td',
        },
        {
          label: 'SBD',
          field: 'bib_number',
          sortable: false,
          width: '56px',
          thClass: 'text-center pdf-table-th',
          tdClass: 'text-center pdf-table-td',
        },
        {
          label: 'Phách',
          field: 'header_code',
          width: '66px',
          sortable: false,
          thClass: 'text-center pdf-table-th',
          tdClass: 'text-center pdf-table-td',
        },
      ],
    }
  },
  computed: {
    today() {
      const date = new Date()
      return {
        date: `0${date.getDate()}`.slice(-2),
        month: `0${date.getMonth() + 1}`.slice(-2),
        year: date.getFullYear(),
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.pdf-content {
  width: 100%;
  font-family: "Times New Roman", Times, serif !important;
  .report-info {
    display: flex;
    padding-bottom: 0px;
  }
}

.pdf-table-th {
  font-size: 13px !important;
  padding: 12px 12px 12px 12px !important;
}

.pdf-table-td {
  font-size: 13px !important;
}
</style>
