<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >

    <b-modal
      id="practicalResearchTopicSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm mới đề tài nghiên cứu thực tế' : 'Cập nhật đề tài nghiên cứu thực tế'"
      no-close-on-backdrop
      size="lg"
      @show="onShow"
      @hidden="onHide"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group label-for="practicalResearchProgramId">
              <template v-slot:label>
                Chương trình nghiên cứu thực tế <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chương trình nghiên cứu thực tế"
                rules="required"
              >
                <v-select
                  v-model="targetItem.practicalResearchProgramId"
                  label="name"
                  :options="practicalResearchPrograms"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên đề tài nghiên cứu thực tế <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Tên đề tài nghiên cứu thực tế"
                  rules="required"
              >
                <b-form-input
                    id="description"
                    v-model="targetItem.name"
                    placeholder="Tên đề tài nghiên cứu thực tế"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="description">
              <template v-slot:label>
                Nội dung đề tài nghiên cứu thực tế <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Nội dung đề tài nghiên cứu thực tế"
                  rules="required"
              >
                <b-form-textarea
                    id="description"
                    v-model="targetItem.description"
                    type="text"
                    placeholder="Nội dung đề tài nghiên cứu thực tế"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Trạng thái"
                  rules="required"
              >
                <v-select
                    v-model="targetItem.status"
                    :options="statusOptions"
                    :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" />Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('practicalResearchTopicSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" />Hủy
            </span>

          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BModal, BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { required } from '@validations'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'PracticalResearchSave',
  components: {
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BButton,
    vSelect,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        practicalResearchProgramId: null,
        name: '',
        description: '',
        address: '',
        timeStudy: null,
        status: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statues: 'practicalResearchTopic/statuses',
      practicalResearchPrograms: 'practicalResearchTopic/practicalResearchPrograms',
    }),
    statusOptions() {
      return this.statues
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      getPracticalResearchPrograms: 'practicalResearchTopic/getPracticalResearchPrograms',
      createPracticalResearchTopic: 'practicalResearchTopic/createPracticalResearchTopic',
      updatePracticalResearchTopic: 'practicalResearchTopic/updatePracticalResearchTopic',
    }),
    async onSave(type = null) {
      try {
        const response = this.isCreated ?
          await this.createPracticalResearchTopic(this.targetItem)
          : await this.updatePracticalResearchTopic(this.targetItem)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('practicalResearchTopicSaveModal')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async onShow() {
      if (this.item) {
        this.targetItem = { ...this.item }
      }
      this.isLoading = true
      try {
        await this.getPracticalResearchPrograms({
          currentPage: 1,
          itemsPerPage: 1000,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.targetItem = {
        practicalResearchProgramId: null,
        name: '',
        description: '',
        address: '',
        timeStudy: null,
        status: 1,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped lang="scss">

</style>
