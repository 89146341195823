<template src="./index.html">
</template>

<script>
import {
  BBreadcrumb, BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BLink, BTooltip, VBTooltip
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import * as XLSX from 'xlsx';
import {Workbook} from "@syncfusion/ej2-excel-export";
import VueHtml2pdf from 'vue-html2pdf'
import PdfListStudents from "@/views/report/students_subject_coursesemester/PdfListStudents";
export default {
  name: 'index',
  components: {
    BButton,
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    VueGoodTable,
    BOverlay,
    vSelect,
    XLSX,
    VueHtml2pdf,
    PdfListStudents
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataSend: {
      type: Object,
      default: {
        subjectId: null,
        courseSemesterId: null
      }
    },
  },
  data() {
    return {
      isLoading: false,
      table: {
        fields: [
          {
            label: '#',
            field: 'rowNum',
            sortable: false,
            width: '5%',
            tdClass: 'text-center',
            thClass: 'text-center'
          },
          {
            label: 'Mã học viên',
            field: 'code',
            sortable: false,
          },
          {
            label: 'Tên học viên',
            field: 'name',
            sortable: false,
          },
          {
            label: 'Căn cước công dân',
            field: 'cccd',
            sortable: false,
            tdClass: 'text-center'
          },
          {
            label: 'Mã lớp',
            field: 'subjectCode',
            sortable: false,
          },
        ],
        rows: []
      },
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        courseSemesterId: '',
        subjectId: '',
      },
      controlValue: {
        showLayout: false,
        floatLayout: true,
        enableDownload: false,
        previewModal: true,
        paginateElementsByHeight: 1100,
        manualPagination: true,
        filename: 'BaoCaoKetQuaGiangDay',
        pdfQuality: 2,
        pdfFormat: 'a4',
        pdfOrientation: 'portrait',
        pdfContentWidth: '800px',
      },
      dataSources: [],
    }
  },

  async created() {
    //init data FROM API
    this.filter = {...this.filter, ...this.dataSend }
    await this.getMainDataFromStore(this.filter)
    this.table.rows = this.dataRows
  },

  computed: {
    // calculate data from init data
    ...mapGetters({
      total: 'studentsRegisteredBySubject/total',
      dataRows: 'studentsRegisteredBySubject/dataRows',
    }),
    htmlToPdfOptions() {
      return {
        margin: [0.3, 0, 0.8, 0],
        filename: 'LichThi.pdf',
        image: {
          type: 'jpeg',
          quality: 0.98,
        },
        enableLinks: true,
        html2canvas: {
          scale: this.controlValue.pdfQuality,
          useCORS: true,
        },
        jsPDF: {
          unit: 'in',
          format: this.controlValue.pdfFormat,
          orientation: this.controlValue.pdfOrientation,
        },
      }
    },
  },
  watch: {
    // listening change data init

  },
  methods: {
    // handle even
    ...mapActions({
      getDataRows: 'studentsRegisteredBySubject/getDetailDataRows',
    }),

    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore(this.filter)
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore(this.filter)
    },
    async getMainDataFromStore(params) {
      this.isLoading = true
      try {
        await this.getDataRows(params)
        // this.table.rows = this.dataRows
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'errors', '')
      } finally {
        this.isLoading = false
      }
    },

    async exportPdf(){
      this.isLoading = true
      try {
        const params = {...this.filter, ...{ itemsPerPage: 1000000}}
        await this.getMainDataFromStore(params)
        this.dataSources = this.dataRows
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'errors', '')
      } finally {
        this.isLoading = false
      }
      this.$refs.html2PdfRef.generatePdf()
    },

    async exportExcel(){
      this.isLoading = false
      try {
        let columns = [
          { index: 1, width: 50 },
          { index: 2, width: 150 },
          { index: 3, width: 150 },
          { index: 4, width: 150 },
          { index: 5, width: 130 },
        ];

        let rows = [];
        let headerRow = {
          index: 1,
          cells: [
            {
              index: 1,
              value: "STT",
              colSpan: 1,
              style: {
                borders: {
                  color: "#00000000",
                  lineStyle: "thin",
                },
                fontColor: "#ffffff",
                backColor: "#000000"
              },
            },
            {
              index: 2,
              value: "Mã học viên",
              colSpan: 1,
              style: {
                borders: {
                  color: "#00000000",
                  lineStyle: "thin",
                },
                fontColor: "#ffffff",
                backColor: "#000000"
              },
            },
            {
              index: 3,
              value:  "Họ tên học viên",
              colSpan: 1,
              style: {
                borders: {
                  color: "#00000000",
                  lineStyle: "thin",
                },
                fontColor: "#ffffff",
                backColor: "#000000"
              },
            },
            {
              index: 4,
              value:  "Căn cước công dân",
              colSpan: 1,
              style: {
                borders: {
                  color: "#00000000",
                  lineStyle: "thin",
                },
                fontColor: "#ffffff",
                backColor: "#000000"
              },
              textAlign: 'Right'
            },
            {
              index: 5,
              value:  "Mã lớp",
              colSpan: 1,
              style: {
                borders: {
                  color: "#00000000",
                  lineStyle: "thin",
                },
                fontColor: "#ffffff",
                backColor: "#000000"
              },
            },
          ],
        };
        rows.push(headerRow)
        const params = {...this.filter, ...{ itemsPerPage: 1000000}}
        await this.getMainDataFromStore(params)
        if (this.dataRows.length > 0){
          for (let i = 0; i < this.dataRows.length; i++){
            let dataRow = this.dataRows[i]
            let itemRow = {
              index: i+2,
            }
            let cells = []
            let cellItem = {
              index: 1,
              value:  i+1,
              colSpan: 1,
            }
            let cellItem1 = {
              index: 2,
              value:  dataRow.code,
              colSpan: 1,
            }
            let cellItem2 = {
              index: 3,
              value:  dataRow.name,
              colSpan: 1,
            }
            let cellItem3 = {
              index: 4,
              value:  dataRow.cccd,
              colSpan: 1,
              textAlign: 'Right'
            }
            let cellItem4 = {
              index: 5,
              value:  dataRow.subjectCode,
              colSpan: 1,
            }
            for (let j = 1; j < 5; j++){

            }
            cells.push(cellItem, cellItem1, cellItem2, cellItem3, cellItem4)
            itemRow.cells = cells
            // dataExcel.push(itemRow)
            rows.push(itemRow)
          }
        }
        let worksheets = [{ columns, rows }]
        let workbook = new Workbook({ worksheets }, "xlsx")
        workbook.save("dsSinhVienDangKyTheoMonHoc.xlsx")
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'errors', '')
      } finally {
        this.isLoading = false
      }

    },

    showToast(title, icon ,variant) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      });
    },
  },
}
</script>

<style scoped>

</style>

0701103
