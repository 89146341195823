import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STANDARD_SCORE_STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    statuses: STANDARD_SCORE_STATUSES,
    resourceName: {
      fullName: 'Xử lý cảnh báo thôi học',
      shortName: 'Cảnh báo',
    },
    codeByAdmissionMajor: [],
    countAcceptTemp: null,
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
    codeByAdmissionMajor: state => state.codeByAdmissionMajor,
    countAcceptTemp: state => state.countAcceptTemp,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_CODE_BY_ADMISSION_MAJOR: (state, { data }) => {
      state.codeByAdmissionMajor = data
    },
    SET_COUNT_ACCEPT_TEMP: (state, data) => { state.countAcceptTemp = data },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_major_form/getStandardScore', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.put('admissions/admission_major_form/updateStandardScore', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCodeByAdmissionMajor({ commit }, params) {
      try {
        const response = await axios.get('admissions/admission_major_form/codes', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_CODE_BY_ADMISSION_MAJOR', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCountAcceptTemp({ commit }, params) {
      try {
        const response = await axios.get(`/admissions/admission_major_form/countAcceptTemp/${params.id}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_COUNT_ACCEPT_TEMP', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
