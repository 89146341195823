<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div>
            <b-row>
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  :line-numbers="true"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                      slot="table-row"
                      slot-scope="props"
                  >
                    <span
                        v-if="props.column.field === 'action'"
                        class="thead-group-action"
                    >
                      <b-button-group>
                        <b-button
                            variant="warning"
                            class="btn-icon"
                            size="sm"
                            title="Đánh dấu đã học bù"
                            @click="onReLearning(props.row)"
                        >
                          <feather-icon icon="CheckSquareIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                    <span
                        v-else-if="props.column.field === 'status'"
                        :class="props.row.status?'text-success':'text-danger'"
                        v-html="getAbsentStatusName(props.row.status)"
                    />
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>
                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage - 1) + 1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ mainData.length }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  name: "DetailAbsentLogs",
  directives: {
  },
  components: {
    BButton,
    VueGoodTable,
    BRow,
    BCol,
    BCard,
    BContainer,
    BOverlay,
    BFormSelect,
    BPagination
  },
  props: {
    dataSend: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        creditClassId: this.dataSend.id || this.$route.params.id,
        isEligible: '',
        sort: '1_asc',
      },
      mainData: [],
      totalRows: 0
    }
  },
  computed: {
    ...mapGetters({
      turns: 'turn/turns',
      listSchedule: 'schedule/schedulers',
      absentStatus: 'creditClassStudent/absentStatus',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    eligibleFilterDropdownItems() {
      return this.eligible.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: `Ngày nghỉ`,
          field: 'absentDate',
          sortable: false,
          thClass: 'text-center',
          dateInputFormat: 'dd/mm/yyyy',
          dateOutputFormat: 'dd/mm/yyyy'
        },
        {
          label: `Tiết vắng`,
          field: 'turnList',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: `Lý do`,
          field: 'reason',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: `Trang thái học bù`,
          field: 'status',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
        },
      ]
    }

  },
  watch: {
  },
  async created() {
    this.isLoading = true
    try {
      const data = await this.getAbsentTimeByStudentCreditClassId({student_credit_class_id:this.dataSend.id})
      data.forEach(item => {
        item.absentDate = this.formatDate(item.absentDate)
      })
      this.mainData = data
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTurns: 'turn/getTurns',
      getSchedulersByDayAndCreditClass: 'schedule/getSchedulersByDayAndCreditClass',
      getAbsentTimeByStudentCreditClassId: 'creditClassStudent/getAbsentTimeByStudentCreditClassId',
      updateStudentAbsentLogStatus: 'creditClassStudent/updateStudentAbsentLogStatus',
    }),

    formatDate(dateStr) {
      const date = new Date(dateStr);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();
      return `${day}/${month}/${year}`
    },

    sortTurnList(turnList) {
      return turnList.split(',').map(Number).sort((a, b) => a - b).join(',');
    },

    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },

    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onReLearning(row) {
      this.$swal({
        title: 'Bạn có chắc chắn muốn cập nhật trạng thái học bù?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            row.absentDate = new Date(row.absentDate)
            const response = await this.updateStudentAbsentLogStatus(row)
            const { isSuccessful, message } = response
            if (response) {
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  const data = await this.getAbsentTimeByStudentCreditClassId({student_credit_class_id:this.dataSend.id})
                  data.forEach(date => {
                    date.absentDate = this.formatDate(date.absentDate)
                  })
                  this.mainData = data
                  this.$emit('succeed')
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    getAbsentStatusName(id) {
      return this.absentStatus.find(item => item.value === id).label
    },
  },
}
</script>

<style scoped>

</style>
