import axios from '@/libs/axios'
import { ApiCode} from '@/const/api'
import { handleCatch } from '@/utils'
import { YEARS } from '@/const/status'

export default {
    namespaced: true,
    state: {
        dataLists: [],
        allYears: YEARS,
        allTrainingSystems: [],
        listTrainingSystemCourses: [],
        listCourseMajors: [],
        listCourseClasses: [],
        listYearSemesters: [],
        listSubjectsOrder: undefined,
        dataExport: null,
    },
    getters: {
        dataLists: state => state.dataLists,
        allYears: state => state.allYears,
        allTrainingSystems: state => state.allTrainingSystems,
        listTrainingSystemCourses: state => state.listTrainingSystemCourses,
        listCourseMajors: state => state.listCourseMajors,
        listCourseClasses: state => state.listCourseClasses,
        listYearSemesters: state => state.listYearSemesters,
        listSubjectsOrder: state => state.listSubjectsOrder,
        dataExport: state => state.dataExport,
    },
    mutations: {
        SET_ALLTRAININGSYSTEMS: (state, { data }) => {
            state.allTrainingSystems = data
        },
        SET_LISTTRAININGSYSTEMCOURSES: (state, { data }) => {
            state.listTrainingSystemCourses = data
        },
        SET_LISTCOURSEMAJORS: (state, { data }) => {
            state.listCourseMajors = data
        },
        SET_LISTCOURSECLASSES: (state, { data }) => {
            state.listCourseClasses = data
        },
        SET_LISTYEARSEMESTERS: (state, { data }) => {
            state.listYearSemesters = data
        },
        SET_LISTSUBJECTSORDER: (state, { data }) => {
            state.listSubjectsOrder = data
        },
        SET_DATA: (state, { data }) => {
            state.dataLists = data
        },
        SET_DATA_EXPORT: (state, { dataExport }) => {
            state.dataExport = dataExport
        },
    },
    actions: {
        async getData({ commit }, params) {
            try {
                const response = await axios.get('uni/reports/reportCourseScoreClass', { params })
                if (response.data.code === ApiCode.SUCCESS) {
                    commit('SET_DATA', {
                        data: response.data.data,
                    })
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async downloadExport({ commit }, params) {
            try {
                const responseStudentInfo = await axios({
                    method: 'get',
                    url: 'uni/reports/reportCourseScoreClassExport',
                    responseType: 'blob',
                    params,
                })
                commit('SET_DATA_EXPORT', { dataExport: responseStudentInfo })
            } catch (e) {
                handleCatch(e)
            }
        },
        async begin({ commit }) {
            try {
                const response = await axios.get('uni/exemptions/training_systems')
                if (response.data.code === ApiCode.SUCCESS) {
                    commit('SET_ALLTRAININGSYSTEMS', {
                        data: response.data.data.rs,
                    })
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getListTrainingSystemCourses({ commit }, params) {
            try {
                const response = await axios.get('uni/exemptions/courses', { params })
                if (response.data.code === ApiCode.SUCCESS) {
                    commit('SET_LISTTRAININGSYSTEMCOURSES', {
                        data: response.data.data.rs,
                    })
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async resetData({ commit }) {
            try {
                commit('SET_DATA', {
                    data: [],
                })
            } catch (e) {
                handleCatch(e)
            }
        },
        async getListCourseMajors({ commit }, params) {
            try {
                const response = await axios.get('uni/reports/reportAvgStudentClass/selectCoursesMajors', { params })
                if (response.data.code === ApiCode.SUCCESS) {
                    commit('SET_LISTCOURSEMAJORS', {
                        data: response.data.data.rs,
                    })
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getListCourseClasses({ commit }, params) {
            try {
                const response = await axios.get('uni/reports/reportScoreStudentClass/selectCoursesClasses', { params })
                if (response.data.code === ApiCode.SUCCESS) {
                    commit('SET_LISTCOURSECLASSES', {
                        data: response.data.data.rs,
                    })
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getListSubjectsOrder({ commit }, params) {
            try {
                const response = await axios.get('uni/reports/reportGraduationConditionScoreClass/selectListSubjectClassProgram', { params })
                if (response.data.code === ApiCode.SUCCESS) {
                    commit('SET_LISTSUBJECTSORDER', {
                        data: response.data.data,
                    })
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getListYearSemesters({ commit }, params) {
            try {
                const response = await axios.get('uni/reports/reportAvgStudentClass/selectSemesterYears', { params })
                if (response.data.code === ApiCode.SUCCESS) {
                    commit('SET_LISTYEARSEMESTERS', {
                        data: response.data.data.rs,
                    })
                }
            } catch (e) {
                handleCatch(e)
            }
        },
    },
}
