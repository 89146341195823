import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { CREDIT_CLASS_STATUSES } from '@/const/status'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.creditClass,
  maxOrderNoPath: ApiExtRoutes.creditClassMaxOrderNo,
  copyPath: ApiExtRoutes.creditClassCopy,
  planPath: ApiExtRoutes.planCreditClass,
  bulkApprove: ApiExtRoutes.bulkApproveCreditClass,
})
export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    statuses: CREDIT_CLASS_STATUSES,
    resourceName: {
      fullName: 'Lớp độc lập',
      shortName: 'Lớp ĐL',
    },
    maxOrderNo: 0,
    practiceScoreStudentOptions: [],
    planDataLists: [],
    planTotalRows: 0,
    processScores: [],
    nextCode: '',
    creditClassesBySetOfCriteriaObjectDataLists: [],
    creditClassRoomsBySetOfCriteriaObjectDataLists: [],
    countCreditClassesBySetOfCriteriaObject: 0,
    countCreditClassRoomsBySetOfCriteriaObject: 0,
    classes: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
    maxOrderNo: state => state.maxOrderNo,
    planDataLists: state => state.planDataLists,
    processScores: state => state.processScores,
    nextCode: state => state.nextCode,
    creditClassesBySetOfCriteriaObjectDataLists: state => state.creditClassesBySetOfCriteriaObjectDataLists,
    creditClassRoomsBySetOfCriteriaObjectDataLists: state => state.creditClassRoomsBySetOfCriteriaObjectDataLists,
    countCreditClassesBySetOfCriteriaObject: state => state.countCreditClassesBySetOfCriteriaObject,
    countCreditClassRoomsBySetOfCriteriaObject: state => state.countCreditClassRoomsBySetOfCriteriaObject,
    classes: state => state.classes,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => {
      state.maxOrderNo = maxOrderNo
    },
    SET_NEXT_CODE: (state, nextCode) => {
      state.nextCode = nextCode
    },
    SET_CREDIT_CLASSES: (state, data) => {
      state.dataLists = data
    },
    SET_PLAN_LISTS: (state, data) => {
      state.planDataLists = data
    },
    SET_PROCESS_SCORES: (state, data) => {
      state.processScores = data
    },
    SET_CREDIT_CLASSES_BY_SET_OF_CRITERIA_OBJECT_LISTS: (state, data) => {
      state.creditClassesBySetOfCriteriaObjectDataLists = data
    },
    SET_CREDIT_CLASS_ROOMS_BY_SET_OF_CRITERIA_OBJECT_LISTS: (state, data) => {
      state.creditClassRoomsBySetOfCriteriaObjectDataLists = data
    },
    SET_COUNT_CREDIT_CLASSES_BY_SET_OF_CRITERIA_OBJECT: (state, data) => {
      state.countCreditClassesBySetOfCriteriaObject = data
    },
    SET_COUNT_CREDIT_CLASS_ROOMS_BY_SET_OF_CRITERIA_OBJECT: (state, data) => {
      state.countCreditClassRoomsBySetOfCriteriaObject = data
    },
    SET_CLASSES: (state, data) => {
      state.classes = data
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get(baseAttr.basePath, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createData({ commit }, params) {
      try {
        const response = await axios.post(baseAttr.basePath, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async copyData({ commit }, params) {
      try {
        const response = await axios.post(`${baseAttr.copyPath}${params.classId}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`${baseAttr.basePath}/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.maxOrderNoPath}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getNextCode({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/next-code`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { code } = data.data
          commit('SET_NEXT_CODE', code)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async importStudentCreditClass({ commit }, params) {
      try {
        const response = await axios.post('uni/studentsCreditClasses/importStudent', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getScoreLockCreditClasses({ commit }, params) {
      try {
        const response = await axios.get('uni/credit_classes/scoreLock', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_LISTS', { total, records })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async updateScoreLockCreditClasses({ commit }, params) {
      try {
        const response = await axios.put('uni/credit_classes/scoreLock', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async getPlanData({ commit }, params) {
      try {
        const response = await axios.get(baseAttr.planPath, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_PLAN_LISTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async createPlanData({ commit }, params) {
      try {
        const response = await axios.post(baseAttr.planPath, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getProcessScores({ commit }, params) {
      try {
        const response = await axios.get('uni/studentsCreditClasses/processScores', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_PROCESS_SCORES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async saveProcessScores({ commit }, params) {
      try {
        const response = await axios.post('uni/studentsCreditClasses/processScores', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
          countEmptyScore: data.data,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async bulkApproveData({ commit }, params) {
      try {
        const response = await axios.put(baseAttr.bulkApprove, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async exportPdfProcessScores({ commit }, params) {
      try {
        const response = await axios.get('uni/reports/exportPdfProcessScores', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCreditClassesBySetOfCriteriaObject({ commit }, params) {
      try {
        const response = await axios.get('surveys/getCreditClassesBySetOfCriteriaObject', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CREDIT_CLASSES_BY_SET_OF_CRITERIA_OBJECT_LISTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getClassesByCourseIdAndMajorId({ commit }, params) {
      try {
        const response = await axios.get('uni/classes/getByCourseIdAndMajorId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CLASSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getClassesByCourseId({ commit }, params) {
      try {
        const response = await axios.get('uni/classes/getByCourseId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CLASSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCreditClassRoomsBySetOfCriteriaObject({ commit }, params) {
      try {
        const response = await axios.get('surveys/getCreditClassRoomsBySetOfCriteriaObject', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CREDIT_CLASS_ROOMS_BY_SET_OF_CRITERIA_OBJECT_LISTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getProgressLms({ commit }, params) {
      try {
        const response = await axios.get('uni/credit_classes/getProgressLms', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCountCreditClassesBySetOfCriteriaObject({ commit }, params) {
      try {
        const response = await axios.get('surveys/countCreditClassesBySetOfCriteriaObject', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCountCreditClassRoomsBySetOfCriteriaObject({ commit }, params) {
      try {
        const response = await axios.get('surveys/countCreditClassRoomsBySetOfCriteriaObject', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async getDiemThiByCode({ commit }, params) {
      try {
        const response = await axios.post('uni/credit_classes/getDiemThiByCode', params)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

      async syncDataLms({ commit }, params) {
          try {
              const response = await axios.post('uni/credit_classes/syncScoreLmsToUni', params)
              const { data } = response
              return {
                  isSuccessful: data.code === ApiCode.SUCCESS,
                  message: data.message,
              }
          } catch (e) {
              handleCatch(e)
          }
          return null
      },
  },
}
