<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay :show="isLoading" rounded="sm">
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col cols="12" md="4">
                  <b-form-group
                      label="Chương trình đào tạo/Bồi dưỡng"
                      label-for="trainingSystemId"
                  >
                    <v-select
                        v-model="filter.trainingSystemId"
                        :options="trainingSystems"
                        :reduce="option => option.value"
                        @input="onTrainingSystemsChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group
                      label="Khóa học"
                      label-for="courseId"
                  >
                    <v-select
                        v-model="filter.courseId"
                        :options="courses"
                        :reduce="option => option.value"
                        @input="onCoursesChange"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group
                      label="Lớp cố định"
                      label-for="classId"
                  >
                    <v-select
                        v-model="classId"
                        :options="classes"
                        :reduce="option => option.value"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12">
                  <div class="text-right" style="padding-top: 20px;">
                    <b-button v-show="classId > 0"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              @click="onSelect"
                    >
                      <feather-icon
                        icon="SearchIcon"
                      />
                      Danh sách
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="mt-2">
              <b-col cols="12">
                <div>
                  <vue-good-table
                      mode="remote"
                      :columns="columns"
                      :rows="dataLists"
                      :pagination-options="paginationOptions"
                      :line-numbers="true"
                      styleClass="vgt-table condensed bordered"
                  >
                    <div
                        slot="emptystate"
                        style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template
                        slot="table-row"
                        slot-scope="props"
                    >
                    <span v-if="props.column.field === 'stt'">
                      {{ props.row.originalIndex + 1 }}
                    </span>
                    <span v-else-if="props.column.field === 'action'">
                      <b-button
                        v-b-modal.subjectSaveModal
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        @click="onDownloadPDFCoursePoint(props.row)"
                      >
                        <feather-icon icon="DownloadIcon" />
                      </b-button>
                    </span>
                      <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    </template>
                  </vue-good-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BOverlay,
  BRow, BFormGroup, BForm, VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/extensions
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'CreditClass',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    BFormGroup,
    BForm,
    vSelect,
    VueGoodTable,
  },
  data() {
    return {
      isLoading: false,
      graduation_type_id: undefined,
      selectedItem: undefined,
      filter: {
        currentPage: 1,
        itemsPerPage: 1000,
        organizationId: '',
        trainingSystemId: '',
        courseId: '',
      },
      classId: null,
      paginationOptions: {
        enabled: false,
      },
      columns: [
        {
          label: 'Mã học viên',
          field: 'student_code',
          sortable: false,
          width: '12%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Họ và tên',
          field: 'full_name',
          sortable: false,
          width: '18%',
          thClass: 'text-center',
        },
        {
          label: 'Ngày sinh',
          field: 'birthday',
          sortable: false,
          width: '10%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Hệ số 10',
          field: 'CGPA_10',
          sortable: false,
          width: '8%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Hệ số 4',
          field: 'CGPA_4',
          sortable: false,
          width: '8%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Loại tốt nghiệp',
          field: 'graduation_text',
          sortable: false,
          width: '16%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Lý do',
          field: 'reason_fail_graduation',
          width: '23%',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Xuất PDF',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      dataLists: 'graduationClass/dataLists',
      classes: 'graduationCondition/classes',
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      graduationType: 'graduationCondition/graduationType',
    }),
  },
  async created() {
    this.isLoading = true
    try {
      const cUser = getUser()
      this.filter.organizationId = (cUser.orgId ? cUser.orgId : 0)
      await this.getTrainingSystems({ organizationId: this.filter.organizationId })
      if (this.trainingSystems.length > 0) {
        this.filter.trainingSystemId = this.trainingSystems[0].value
        await this.getCourses(this.filter)
      }
      this.$store.commit('graduationCondition/SET_DATA', { records: [] })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getData: 'graduationClass/getData',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getClasses: 'graduationCondition/getClasses',
      exportPdfAcademicAndConductResults: 'graduationClass/exportPdfAcademicAndConductResults',
    }),
    async onTrainingSystemsChange(event) {
      this.filter.courseId = null
      await this.getCourses(this.filter)
    },
    async onCoursesChange(event) {
      await this.getClasses(this.filter)
    },
    async onSelect() {
      this.isLoading = true
      try {
        await this.getData({
          class_id: this.classId,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onDownloadPDFCoursePoint(graduationStudent) {
      this.isLoading = true
      try {
        const data = await this.exportPdfAcademicAndConductResults({
          classId: this.classId,
          studentId: graduationStudent.student_id,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
