<template src="./index.html" />

<script>
import * as XLSX from 'xlsx'
import {
  BBreadcrumb, BButton, BFormFile, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BFormCheckbox, BFormRadio,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import { Workbook } from '@syncfusion/ej2-excel-export'
import { getUser } from '@/auth/utils'
import { handleCatch, hasPermissionForResource, isSystemAdmin } from '@/utils'
import { ApiCode } from '@/const/api'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'Student',
  components: {
    BBreadcrumb,
    BButton,
    BFormFile,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    XLSX,
    VueGoodTable,
    BFormCheckbox,
    BFormRadio,
    ToastificationContent,
  },
  props: {

  },
  data() {
    return {
      user: getUser(),
      table: {
        fields: [
          {
            field: 'stt', label: 'STT', sortable: false, type: 'string',
          },
          {
            field: 'code', label: 'Mã học viên(*)', sortable: false, type: 'string',
          },
          {
            field: 'lastName', label: 'Họ đêm(*)', sortable: false, type: 'string',
          },
          {
            field: 'name', label: 'Tên(*)', sortable: false, type: 'string',
          },
          {
            field: 'identifyCode', label: 'CCCD(*)', sortable: false, type: 'string',
          },
          {
            field: 'passportCode', label: 'Hộ khẩu', sortable: false, type: 'string',
          },
          {
            field: 'birthday', label: 'Ngày sinh(*)', sortable: false, type: 'string',
          },
          {
            field: 'gender', label: 'Giới tính(*)', sortable: false, type: 'string',
          },
          {
            field: 'birthPlace', label: 'Nơi sinh', sortable: false, type: 'string',
          },
          {
            field: 'permanentResidence', label: 'Hộ khẩu', sortable: false, type: 'string',
          },
          {
            field: 'classCode', label: 'Lớp(*)', sortable: false, type: 'string',
          },
          {
            field: 'districtCode', label: 'Mã huyện', sortable: false, type: 'string',
          },
          {
            field: 'provinceCode', label: 'Mã tỉnh', sortable: false, type: 'string',
          },
          {
            field: 'address', label: 'Địa chỉ LH', sortable: false, type: 'string',
          },
          {
            field: 'religionCode', label: 'Mã tôn giáo', sortable: false, type: 'string',
          },
          {
            field: 'ethnicCode', label: 'Mã dân tộc', sortable: false, type: 'string',
          },
          {
            field: 'policyId', label: 'Mã CĐCS', sortable: false, type: 'string',
          },
          {
            field: 'profileId', label: 'Mã hồ sơ', sortable: false, type: 'string',
          },
        ],
        items: [],
      },
      tenFile: '',
      dataExcel: [],
      totalRecords: 0,
      serverParams: {
        page: 1,
        perPage: 10,
      },
      loading: false,
      selected: '',
      numberConfig: 1,
      distinctClassCode: [],
      valueSetup: 0,
    }
  },
  computed: {
    ...mapGetters({
      total: 'rooms/total',
      accounts: 'account/accounts',
      configStudentCode: 'configStudentCode/dataLists',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.IMPORT_STUDENT)
    },
  },
  async created() {
    this.loading = true
    try {
      await this.getConfigStudentCode()
      this.valueSetup = parseInt(this.configStudentCode.find(item => item.code === 'COUNT').value)
      this.numberConfig = parseInt(this.configStudentCode.find(item => item.code === 'NUMBER').value)
    } catch (e) {
      this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger', '')
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions({
      importStudents: 'importStudent/importStudents',
      selectCourseByClassCode: 'importStudent/selectCourseByClassCode',
      selectMajorByClassCode: 'importStudent/selectMajorByClassCode',
      classes: 'classes/getClasses',
      getListAccount: 'account/getListAccount',
      getConfigStudentCode: 'configStudentCode/getData',
    }),
    async importExcel(e) {
      const headerItem = {
        field: 'codeOld', label: 'Mã học viên cũ', sortable: false, type: 'string',
      }
      if (this.selected) {
        this.table.fields.push(headerItem)
      }
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if (files[0].name) {
        this.tenFile = files[0].name
      }
      this.readFile(files)
    },

    readFile(files) {
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsname = workbook.SheetNames[0]
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            header: 0,
            defval: '',
          })
          const excellist = []
          // Edit data
          if (ws.length > 0) {
            for (let i = 0; i < ws.length; i++) {
              excellist.push(ws[i])
            }
          } else {
            this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', '')
            return
          }
          if (
            !excellist[0].hasOwnProperty('MA_SINH_VIEN')
            || !excellist[0].hasOwnProperty('HO_DEM')
            || !excellist[0].hasOwnProperty('TEN')
            || !excellist[0].hasOwnProperty('CCCD')
            || !excellist[0].hasOwnProperty('HO_CHIEU')
            || !excellist[0].hasOwnProperty('NGAY_SINH')
            || !excellist[0].hasOwnProperty('GIOI_TINH')
            || !excellist[0].hasOwnProperty('NOI_SINH')
            || !excellist[0].hasOwnProperty('HO_KHAU')
            || !excellist[0].hasOwnProperty('MA_LOP')
            || !excellist[0].hasOwnProperty('MA_HUYEN')
            || !excellist[0].hasOwnProperty('MA_TINH')
            || !excellist[0].hasOwnProperty('DIA_CHI_LH')
            || !excellist[0].hasOwnProperty('MA_TON_GIAO')
            || !excellist[0].hasOwnProperty('MA_DAN_TOC')
            || !excellist[0].hasOwnProperty('MA_CDCS')
            || !excellist[0].hasOwnProperty('MA_HS')

          ) {
            this.showToast('File chưa đúng định dạng', 'XCircleIcon', 'danger', '')
            return
          }

          this.dataExcel = []
          // eslint-disable-next-line no-plusplus
          for (let k = 0; k < excellist.length; k++) {
            const item = excellist[k]
            const obj = {
              stt: k + 1,
              name: item.TEN,
              lastName: item.HO_DEM,
              code: item.MA_SINH_VIEN,
              identifyCode: item.CCCD,
              passportCode: item.HO_CHIEU,
              orderNo: '',
              birthday: item.NGAY_SINH,
              relatedId: null,
              organizationId: this.user.orgId,
              studentId: null,
              classCode: item.MA_LOP,
              birthPlace: item.NOI_SINH,
              gender: item.GIOI_TINH ? item.GIOI_TINH : 0,
              address: item.DIA_CHI_LH,
              permanentResidence: item.HO_KHAU,
              districtCode: item.MA_HUYEN,
              provinceCode: item.MA_TINH,
              religionCode: item.MA_TON_GIAO,
              ethnicCode: item.MA_DAN_TOC,
              policyCode: item.MA_CDCS,
              profileId: item.MA_HS,
              dateEnter: '',
              status: 1,
              delFlag: 0,
              createdBy: null,
              codeOld: item.MA_SINH_VIEN_CU ? item.MA_SINH_VIEN_CU : '',
              isLienThong: this.selected ? '1' : '0',
            }
            this.dataExcel.push(obj)
          }
          this.table.items = this.dataExcel
          this.totalRecords = this.dataExcel.length
          if (this.totalRecords > 10) {
            this.table.items = this.dataExcel.slice(0, 10)
          }

          // insert ban ghi
        } catch (e) {
          this.showToast(e, 'XCircleIcon', 'danger', '')
        }
      }
      fileReader.readAsBinaryString(files[0])
    },

    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },

    isValidDateFormat(dateString) {
      const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/
      return regex.test(dateString)
    },

    checkDataBeforeImport(dataArray) {
      if (dataArray.length === 0) {
        this.showToast('Chưa có dữ liệu để thực hiện', 'InfoIcon', 'warning', '')
        return false
      }
      const studentCodes = new Set()

      const cccdNumbers = new Set()
      // Kiểm tra các điều kiện trong từng object của mảng
      for (const subArray of dataArray) {
        const {
          code, lastName, name, gender, classCode, birthday, identifyCode,
        } = subArray

        // Kiểm tra các trường không được rỗng
        if (!code || !lastName || !name || (gender === null || gender === undefined || gender.toString().trim() === '') || !classCode || !birthday || !identifyCode
        ) {
          this.showToast('Các trường thông tin [MA_SINH_VIEN, HO_DEM, TEN, GIOI_TINH, MGAY_SINH, MA_LOP, CCCD] không được trống', 'InfoIcon', 'warning', '')
          return false
        }

        if (studentCodes.has(code)) {
          this.showToast(`Mã học viên  ${code} đang bị trùng lặp, vui lòng kiểm tra lại dữ liệu, `, 'InfoIcon', 'warning', '')
          return false
        }
        studentCodes.add(code)

        if (cccdNumbers.has(identifyCode)) {
          this.showToast(`Mã CCCD  ${identifyCode} đang bị trùng lặp, vui lòng kiểm tra lại dữ liệu, `, 'InfoIcon', 'warning', '')
          return false
        }
        cccdNumbers.add(identifyCode)

        // Kiểm tra trường GIOI_TINH chỉ nhận giá trị '0', '1' hoặc '2'
        if (gender.toString() !== '0' && gender.toString() !== '1' && gender.toString() !== '2') {
          this.showToast('Thông tin giới tính vui lòng chỉ điền 0, 1 hoặc 2', 'InfoIcon', 'warning', '')
          return false
        }

        // Kiểm tra trường NGAY_SINH
        if (!this.isValidDateFormat(birthday)) {
          this.showToast(`Thông tin ngày sinh ${birthday} không đúng dịnh dạng quy định dd/MM/yyyy`, 'InfoIcon', 'warning', '')
          return false
        }
      }

      return true
    },

    async importStudent() {
      const data = this.dataExcel
      if (this.checkDataBeforeImport(data)) {
        try {
          this.loading = true
          const res = await this.importStudents(data)
          if (res.code === ApiCode.SUCCESS) {
            this.showToast('Lưu thành công', 'CheckIcon', 'success', '')
            this.resetInputFile()
          } else {
            this.showToast('Có lỗi xảy ra', 'XCircleIcon', 'danger', res.message)
          }
        } catch (e) {
          this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger', '')
        } finally {
          this.loading = false
        }
      }
    },

    async createCode() {
      this.loading = true
      try {
        // Lấy danh sách classCode
        const uniqueClassCodes = new Set(this.dataExcel.map(item => item.classCode))
        // Chuyển đổi Set thành mảng
        const uniqueClassCodesArray = Array.from(uniqueClassCodes)
        const strClassCode = uniqueClassCodesArray.toString()
        const param = {
          strClassCode,
        }
        const [data, dataMajor, listAccount] = await Promise.all([
          this.selectCourseByClassCode(param),
          this.selectMajorByClassCode(param),
          this.getListAccount(),
        ])

        // lay danh sách khóa học theo mã lớp
        const arrData = []
        for (let i = 0; i < uniqueClassCodesArray.length; i++) {
          const obj = {
            id: uniqueClassCodesArray[i],
            name: data.data[i].code,
            majorCode: dataMajor.data[i].code,
            maxUsername: this.findMaxStructuredUsername(data.data[i].code, dataMajor.data[i].code),
          }
          arrData.push(obj)
        }
        for (let i = 0; i < this.dataExcel.length; i++) {
          const courseCode = arrData.find(item => item.id === this.dataExcel[i].classCode).name
          const { majorCode } = arrData.find(item => item.id === this.dataExcel[i].classCode)
          this.dataExcel[i].courseCode = courseCode
          this.dataExcel[i].majorCode = majorCode
        }
        this.dataExcel.sort((a, b) => {
          if (a.classCode < b.classCode) return -1
          if (a.classCode > b.classCode) return 1
          return 0
        })

        let currentClass = this.dataExcel[0].classCode
        let maxUser = arrData.find(item => item.id === this.dataExcel[0].classCode).maxUsername
        let j = 0
        for (let i = 0; i < this.dataExcel.length; i++) {
          const { courseCode, majorCode } = this.dataExcel[i]
          if (currentClass !== this.dataExcel[i].classCode) {
            j = 1
            currentClass = this.dataExcel[i].classCode
            maxUser = arrData.find(item => item.id === currentClass).maxUsername
          } else {
            j++
          }

          const generatedUsername = this.generateUsername(courseCode, majorCode, maxUser + j)
          this.dataExcel[i].code = generatedUsername
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger', '')
      } finally {
        this.loading = false
      }
    },

    findMaxStructuredUsername(courseCode, majorCode) {
      let currentYear = new Date().getFullYear()
      currentYear = currentYear.toString().slice(-2)
      let maxNumber = -1
      const negativeNumber = -this.numberConfig
      let findRegex = '^'
      const configs = this.configStudentCode.filter(item => item.active === 1)

      configs.forEach(config => {
        switch (config.code) {
          case 'NAM': // Năm hiện tại
            findRegex += currentYear
            break
          case 'CODE_COURSE': // Mã khóa học
            findRegex += courseCode
            break
          case 'CODE_MAJOR': // Mã chuyên ngành
            findRegex += majorCode
            break
          case 'NUMBER': // Số random
            findRegex += `\\d{${config.value}}`
            this.numberConfig = config.value
            break
        }
      })

      findRegex += '$'
      this.accounts.forEach(username => {
        if (findRegex.test(username)) {
          const number = parseInt(username.value.slice(negativeNumber)) // Lấy n ký tự số cuối cùng
          if (number > maxNumber) {
            maxNumber = number
          }
        }
      })

      if (maxNumber < this.valueSetup) {
        maxNumber = this.valueSetup
      }

      return maxNumber
    },

    generateUsername(courseCode, majorCode, number) {
      let currentYear = new Date().getFullYear()
      // lấy 2 số cuối cùng của năm
      currentYear = currentYear.toString().slice(-2)
      const configs = this.configStudentCode.filter(item => item.active === 1)
      let username = ''
      configs.forEach(config => {
        switch (config.code) {
          case 'NAM': // Năm hiện tại
            username += currentYear
            break
          case 'CODE_COURSE': // Mã khóa học
            username += courseCode
            break
          case 'CODE_MAJOR': // Mã chuyên ngành
            username += majorCode
            break
          case 'NUMBER': // Số random
            username += number.toString().padStart(this.numberConfig - (number.toString().length), '0')
            break
          default:
            username += ''
            break
        }
      })
      return username
    },

    downloadFile() {
      const item = { index: 18, width: 100 }
      const columns = [
        { index: 1, width: 130 },
        { index: 2, width: 130 },
        { index: 3, width: 100 },
        { index: 4, width: 130 },
        { index: 5, width: 130 },
        { index: 6, width: 200 },
        { index: 7, width: 200 },
        { index: 8, width: 130 },
        { index: 9, width: 130 },
        { index: 10, width: 130 },
        { index: 11, width: 130 },
        { index: 12, width: 130 },
        { index: 13, width: 130 },
        { index: 14, width: 130 },
        { index: 15, width: 130 },
        { index: 16, width: 130 },
        { index: 17, width: 130 },
      ]

      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'MA_SINH_VIEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 2,
            value: 'HO_DEM',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 3,
            value: 'TEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 4,
            value: 'CCCD',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 5,
            value: 'HO_CHIEU',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 6,
            value: 'NGAY_SINH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 7,
            value: 'GIOI_TINH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 8,
            value: 'NOI_SINH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 9,
            value: 'HO_KHAU',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 10,
            value: 'MA_LOP',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 11,
            value: 'MA_HUYEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 12,
            value: 'MA_TINH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 13,
            value: 'DIA_CHI_LH',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 14,
            value: 'MA_TON_GIAO',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 15,
            value: 'MA_DAN_TOC',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 16,
            value: 'MA_CDCS',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 17,
            value: 'MA_HS',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
        ],
      }
      const cellsItem = {
        index: 18,
        value: 'MA_SINH_VIEN_CU',
        colSpan: 1,
        style: {
          borders: {
            color: '#00000000',
            lineStyle: 'thin',
          },
          fontColor: '#ffffff',
          backColor: '#000000',
        },
      }
      if (this.selected) {
        columns.push(item)
        headerRow.cells.push(cellsItem)
      }
      headerRow.cells.push()
      rows.push(headerRow)
      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')
      workbook.save('import_SinhVienLopCoDinh.xlsx')
    },
    // handle even

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    onPageChange(params) {
      this.serverParams.page = params.currentPage
      const start = params.currentPerPage * (params.currentPage - 1)
      const end = this.dataExcel.length > (params.currentPerPage * params.currentPage) ? (params.currentPerPage * params.currentPage) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end)
    },

    onPerPageChange(params) {
      this.serverParams.perPage = params.currentPerPage
      const start = params.currentPerPage * (params.currentPage - 1)
      const end = this.dataExcel.length > (params.currentPerPage * params.currentPage) ? (params.currentPerPage * params.currentPage) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end)
    },

    handleChecked() {
    },

  },
}
</script>

<style type="text/css">
.form_file{
  width: auto !important;
}
</style>
