import { STATUSES } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
    namespaced: true,
    state: {
        statuses: STATUSES,
        totalRows: 0,
        researchReviewPresent: [],
        researchRole: [],
        employees: [],
    },
    getters: {
        statuses: state => state.statuses,
        totalRows: state => state.totalRows,
        researchReviewPresent: state => state.researchReviewPresent,
        researchRole: state => state.researchRole,
        employees: state => state.employees,
    },
    mutations: {
        SET_STATUSES: (state, statuses) => {
            state.statuses = statuses
        },
        SET_TOTAL_ROWS: (state, data) => {
            state.totalRows = data
        },
        SET_RESEARCH_REVIEW_PRESENT: (state, data) => {
            state.researchReviewPresent = data
        },
        SET_RESEARCH_ROLE: (state, data) => {
            state.researchRole = data
        },
        SET_EMPLOYEES: (state, data) => {
            state.employees = data
        },
    },
    actions: {
        async readResearchReviewPresent({ commit }, params) {
            try {
                const response = await axios.get('uni/research_review_present', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { total, records } = data.data
                    commit('SET_RESEARCH_REVIEW_PRESENT', records)
                    commit('SET_TOTAL_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        // eslint-disable-next-line no-unused-vars
        async addResearchReviewPresent({ commit }, params) {
            try {
                const response = await axios.post('uni/research_review_present', params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        // // eslint-disable-next-line no-unused-vars
        async deleteResearchReviewPresent({ commit }, id) {
            try {
                const response = await axios.delete(`uni/research_review_present/${id}`)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async getResearchRole({ commit }, params) {
            try {
                const response = await axios.get('uni/fact_research_roles/getAllByType', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_RESEARCH_ROLE', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getEmployee({ commit }, params) {
            try {
                const response = await axios.get('uni/employees', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { records } = data.data
                    commit('SET_EMPLOYEES', records)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
    },
}
