<template>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
          <b-tabs
            content-class="pt-1"
            fill
          >
            <b-tab>
              <template #title>
                <feather-icon icon="FileTextIcon" />
                <span>Câu hỏi</span>
              </template>
              
              <AddQuestionSave
                :data-form="dataForm"
                @succeedAddQuestion="onSucceedAddQuestion"
              />
            </b-tab>
            <b-tab
              :disabled="checkAddAnswer"
            >
              <template #title>
                <feather-icon icon="CheckIcon" />
                <span>Câu trả lời</span>
              </template>
              <div class="m-2">
                <b-row v-show="!checkTNQuestion">
                  <b-col cols="12">
                    <div class="text-right">
                      <b-button
                        v-if="creatable"
                        v-b-modal.answerSaveModal
                        variant="success"
                        @click="onCreate"
                      >
                        <span class="text-nowrap">
                          <feather-icon icon="PlusIcon" /> Thêm mới
                        </span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-show="checkTNQuestion" class="mb-1">
                  <b-col cols="12">
                    <div class="text-right">
                      <b-button
                        v-if="creatableMultiChoices && resources.length == 0"
                        v-b-modal.addMultipleChoiceAnswerModal
                        variant="success"
                        @click="onCreate"
                      >
                        <span class="text-nowrap">
                          <feather-icon icon="PlusIcon" /> Thêm mới
                        </span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                
                <b-row v-show="!checkTNQuestion">
                  <b-col cols="12">
                    <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                    </div>
                    <vue-good-table
                      mode="remote"
                      :columns="columns"
                      :rows="dataSources"
                      :pagination-options="paginationOptions"
                      :total-rows="totalRows"
                      :line-numbers="true"
                      @on-page-change="onPageChange"
                      @on-column-filter="onColumnFilter"
                      @on-per-page-change="onPerPageChange"
                    >
                      <div
                        slot="emptystate"
                        style="text-align: center; font-weight: bold"
                      >
                        Không có bản ghi nào !
                      </div>
                      <template
                        slot="table-row"
                        slot-scope="props"
                      >
                    <span v-if="props.column.field === 'content'">
                      <span v-html="decodeHtml(props.row[props.column.field])"></span>
                    </span>
                        <span v-else-if="props.column.field === 'status'">
                      {{ props.row.status === 1 ? 'Đang hoạt động' : 'Không hoạt động' }}
                    </span>
                        <span v-else-if="props.column.field === 'action'">
                      <b-dropdown right variant="success" no-caret size="sm">
                        <template #button-content>
                          <feather-icon
                            icon="MoreHorizontalIcon"
                            class="align-middle text-body"
                          />
                        </template>
                        <b-dropdown-item
                          v-if="updatable"
                          v-b-modal.answerSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          @click="onEdit(props.row)"
                        >
                          <feather-icon icon="Edit2Icon"/> Chỉnh sửa câu trả lời
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="deletable"
                          variant="danger"
                          class="btn-icon"
                          size="sm"
                          @click="onDelete(props.row)"
                        >
                          <feather-icon icon="TrashIcon"/> Xóa câu trả lời
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                        <!-- Column: Common -->
                        <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                      </template>
                      <!-- pagination -->
                      <template
                        slot="pagination-bottom"
                        slot-scope="props"
                      >
                        <div class="d-flex justify-content-between flex-wrap">
                          <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                          </div>
                          <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap"> Hiển thị </span>
                            <b-form-select
                              v-model="filter.itemsPerPage"
                              :options="itemsPerPageOptions"
                              class="mx-1"
                              @input="(value) => props.perPageChanged({ currentPerPage: value })"
                            />
                            <span class="text-nowrap"> bản ghi/trang</span>
                          </div>
                          <div>
                            <b-pagination
                              :value="1"
                              :total-rows="totalRows"
                              :per-page="filter.itemsPerPage"
                              class="mt-1 mb-0"
                              @input="(value) => props.pageChanged({ currentPage: value })"
                            />
                          </div>
                        </div>
                      </template>
                    </vue-good-table>
                  </b-col>
                </b-row>
                <b-row v-show="checkTNQuestion">
                  <b-col cols="12">
                    <vue-good-table
                      mode="remote"
                      :columns="columnMultis"
                      :rows="resources"
                      :pagination-options="paginationOptions"
                      :total-rows="totalRowResources"
                      :line-numbers="true"
                      @on-page-change="onPageChange"
                      @on-column-filter="onColumnFilter"
                      @on-per-page-change="onPerPageChange"
                    >
                      <div
                        slot="emptystate"
                        style="text-align: center; font-weight: bold"
                      >
                        Không có bản ghi nào !
                      </div>
                      <template
                        slot="table-row"
                        slot-scope="props"
                      >
                    <span
                      v-if="props.column.field === 'isCorrect'"
                      :class="props.row.isCorrect !== 1 ? 'text-danger':'text-success font-weight-bolder'"
                      v-html="getIsCorrect(props.row.isCorrect)"
                    />
                        <span
                          v-else-if="props.column.field === 'multiple'"
                          :class="props.row.multiple !== 1 ? 'text-danger':'text-success font-weight-bolder'"
                          v-html="getIsCorrect(props.row.multiple)"
                        />
                        <span v-else-if="props.column.field === 'action'">
                      <b-button
                        variant="danger"
                        class="btn-icon"
                        size="sm"
                        @click="onDeleteResource(props.row)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </span>
                        
                        <!-- Column: Common -->
                        <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                      </template>
                      
                      <!-- pagination -->
                      <template
                        slot="pagination-bottom"
                        slot-scope="props"
                      >
                        <div class="d-flex justify-content-between flex-wrap">
                          <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap"> Hiển thị 1 đến </span>
                            <b-form-select
                              v-model="filter.itemsPerPage"
                              :options="itemsPerPageOptions"
                              class="mx-1"
                              @input="(value) => props.perPageChanged({ currentPerPage: value })"
                            />
                            <span class="text-nowrap">của {{ resources.length }} bản ghi</span>
                          </div>
                          <div>
                            <b-pagination
                              :value="1"
                              :total-rows="totalRows"
                              :per-page="filter.itemsPerPage"
                              class="mt-1 mb-0"
                              @input="(value) => props.pageChanged({ currentPage: value })"
                            />
                          </div>
                        </div>
                      </template>
                    </vue-good-table>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        <AnswerSave
          :qb-question-id="qbQuestionId"
          :data-form="currentDataSource"
          @succeed="onSucceed"
        />
        <AddMultipleChoiceAnswerResource
          :qb-question-id="qbQuestionId"
          @succeed="onSucceed"
        />
      </b-overlay>
    </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BContainer,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BTab,
  BTabs,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import FormAddEmployee from '@/views/research/formAddEmployee.vue'
import FormAddReviewPresent from '@/views/research/review-present/FormAddReviewPresent.vue'
import ResearchReviewFormResult from '@/views/research/review-present/ResearchReviewFormResult.vue'
import AnswerSave from "@/views/exam-question/qb-answer/AnswerSave.vue";
import AddQuestionSave from "@/views/exam-question/qb-question/AddQuestion.vue";
import { hasPermissionForResource, isSystemAdmin } from "@/utils";
import { PermissionCode, ResourceCode } from "@/const/code";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AddMultipleChoiceAnswerResource
  from "@/views/exam-question/qb-multiple-choice-answer/AddMultipleChoiceAnswerResource.vue";
import { STATUS_SYMBOLS } from "@/const/status";

export default {
  name: 'AddQuestionAndAnswer',
  components: {
    BDropdown, BDropdownItem,
    ResearchReviewFormResult,
    FormAddReviewPresent,
    AnswerSave,
    AddQuestionSave,
    AddMultipleChoiceAnswerResource,
    VueGoodTable,
    BButton,
    BCard,
    BCol,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BTabs,
    BTab,
    BCardText,
    FormAddEmployee,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
  
  },
  data() {
    return{
      isLoading: false,
      dataForm: undefined,
      checkAddAnswer: true,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        qbQuestionId: null,
        code: null,
        name: null,
        status: null,
      },
      currentDataSource: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      dataSendClass: {},
      qbQuestionId: null,
      checkTNQuestion: false,
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'qbAnswer/dataSources',
      totalRows: 'qbAnswer/totalRows',
      resources: 'qbMultipleChoiceAnswer/dataSources',
      totalRowResources: 'qbMultipleChoiceAnswer/totalRows',
    }),
    columnMultis() {
      let data = [
        {
          label: 'Tên đáp án',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Nội dung',
          field: 'content',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Là đáp án đúng',
          field: 'isCorrect',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Câu hỏi có nhiều đáp án',
          field: 'multiple',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
      if (this.deletable) {
        data = [...data, {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        }]
      }
      return data
    },
    columns() {
      return [
        {
          label: 'Tiêu đề',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Nội dung câu trả lời',
          field: 'content',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Điểm',
          field: 'score',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: [
              { value: 1, text: 'Hoạt động' },
              { value: 0, text: 'Không hoạt động' },
            ],
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.QB_ANSWER)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.QB_ANSWER)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.QB_ANSWER)
    },
    creatableMultiChoices() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.QB_MULTIPLE_CHOICE_ANSWER)
    },
    deletableMultiChoices() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.QB_MULTIPLE_CHOICE_ANSWER)
    },
  },
  
  methods: {
    ...mapMutations({
      setDataSources: 'qbAnswer/SET_DATA_SOURCES',
    }),
    ...mapActions({
      getDataSources: 'qbAnswer/getDataSources',
      getResources: 'qbMultipleChoiceAnswer/getResources',
      deleteDataSource: 'qbAnswer/deleteDataSource',
      deleteResource: 'qbMultipleChoiceAnswer/delete',
    }),
    getIsCorrect(id) {
      return STATUS_SYMBOLS.find(status => status.value === id).label
    },
    decodeHtml(html) {
      const txt = document.createElement('textarea')
      txt.innerHTML = html
      return txt.value
    },
    onCreate() {
      this.currentDataSource = undefined
    },
    onEdit(dataSource) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = dataSource
      this.currentDataSource = rest
    },
    onDeleteResource(resource) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa dữ liệu?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteResource({
              id: resource.id,
            })
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getResources({
                    params: this.filter,
                  })
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    onDelete(dataSource) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa câu trả lời <span class="text-danger">${dataSource.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteDataSource(dataSource.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getDataSourcesFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      this.filter.qbQuestionId = this.qbQuestionId
      try {
        this.checkTNQuestion ? await this.getResources({
          params: this.filter,
        }) : await this.getDataSources(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getDataSourcesFromStore()
    },
    async onSucceedAddQuestion(id, qbQuestionType) {
      this.checkAddAnswer = false
      this.filter.qbQuestionId = id
      this.qbQuestionId = id
      this.checkTNQuestion = qbQuestionType === 'TN'
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
    },
  },
}
</script>

<style scoped>

</style>
