import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/student-allowance',
    name: 'studentAllowance',
    component: () => import('@/views/student-allowance/StudentAllowance.vue'),
    meta: {
      pageTitle: 'Danh sách Học viên hưởng trợ cấp sư phạm',
      breadcrumb: [
        {
          text: 'Công tác học viên',
          active: false,
        },
        {
          text: 'Danh sách HV hưởng trợ cấp sư phạm',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.STUDENTALLOWANCE,
    },
  },
]
