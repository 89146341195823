<template>
  <validation-observer
    ref="resourceSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="timeResearchTeacherAddModal"
      ref="timeResearchTeacherAddModal"
      body-class="position-static"
      centered
      size="xl"
      :title="`Cập nhật thời gian NCKH của giảng viên - ${dataForm.name}`"
      :no-close-on-backdrop="true"
      @hide="onHide"
      @show="onShow"
    >
      <b-row>
        <b-col
          cols="12"
          md="5"
        >
          <b-form-group>
            <v-select
              v-model="yearId"
              :options="factYears"
              :reduce="option => option.id"
              label="name"
              placeholder="Chọn năm học"
              @input="onYearChange"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form
            ref="formRef"
            :style="{height: trHeight}"
          >
            <b-row
              v-for="(item, index) in timeResearchCriteria"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col md="2">
                <b-form-group label-for="researchType">
                  <template v-slot:label>
                    Chọn loại đề tài
                  </template>
                  <v-select
                      v-model="item.researchType"
                      :options="timeResearchTypes"
                      :reduce="option => option.value"
                      @input="event => onResearchTypeChange(event)"
                  />
                </b-form-group>

              </b-col>
              <b-col md="3">
                <b-form-group label-for="researchId">
                  <template v-slot:label>
                    Chọn đề tài nghiên cứu
                  </template>
                  <v-select
                    v-model="item.researchId"
                    :options="getResearchesByType(item.researchType)"
                    label="name"
                    :reduce="option => option.id"
                  />
                </b-form-group>

              </b-col>
              <b-col>
                <b-form-group label-for="numberMember">
                  <template v-slot:label>
                    Số lượng thành viên<span class="text-danger">*</span>
                  </template>
                  <validation-provider
                      #default="{ errors }"
                      name="Tên tiêu chí"
                      rules="required"
                  >
                    <b-form-input
                        id="code"
                        v-model="item.numberMember"
                        placeholder="Nhập tên tiêu chí"
                        :state="getElementState(errors)"
                    />
                    <p style="color: red; font-style: italic;">
                      * Vai trò là chủ nhiệm hoặc thư ký có giá trị là 1
                    </p>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label-for="name">
                  <template v-slot:label>
                    Chọn tiêu chí quy đổi giờ <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Tiêu chí quy đổi giờ"
                    rules="required"
                  >
                    <v-select
                      v-model="item.twTimeResearchConversionCriteriaId"
                      :options="allTimeResearchConversionCriteria"
                      label="name"
                      :reduce="option => option.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

              </b-col>
              <b-col
                md="1"
                class="mb-50"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <span>Xóa</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="repeatAgain"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Thêm mới</span>
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="invalid || timeResearchCriteria.length === 0"
            variant="primary"
            class="mr-1"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('timeResearchTeacherAddModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormFile, BFormGroup, BFormInput, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import { required } from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {TIME_RESEARCH_TYPES} from "@/const/status";
import {hasPermissionForResource, isSystemAdmin} from "@/utils";
import {PermissionCode, ResourceCode} from "@/const/code";

export default {
  name: 'TimeResearchTeacherAddModal',
  directives: {
    Ripple,
  },
  components: {
    BFormFile,
    BModal,
    BOverlay,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [heightTransition],
  props: {
    dataForm: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      timeResearchCriteria: [{
        id: 1,
        researchId: null,
        twTimeResearchConversionCriteriaId: null,
        factYearId: null,
        teacherId: this.dataForm?.id || null,
        researchType: null,
        numberMember: 1,
      }],
      yearId: null,
      nextId: 2,
      required,
      filterGetAll: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
      dataSave: {
        timeResearchCriteria: null,
        teacherId: null,
        yearId: null,
        numberMember: 1,
      },
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'role/permissionOptions',
      resources: 'role/resourcesOptions',
      certificateProcessTypes: 'certificateProcessType/certificateProcessTypes',
      credentialFilesRequire: 'credentialProcessType/credentialFilesRequire',
      researches: 'twTimeResearchTeacherResult/researches',
      researchArticles: 'twTimeResearchTeacherResult/researchArticles',
      researchInventions: 'twTimeResearchTeacherResult/researchInventions',
      researchTechTransfers: 'twTimeResearchTeacherResult/researchTechTransfers',
      factYears: 'twTimeResearchTeacherResult/factYears',
      allTimeResearchConversionCriteria: 'twTimeResearchTeacherResult/allTimeResearchConversionCriteria',
      timeResearchTeacherCriteria: 'twTimeResearchTeacherResult/timeResearchTeacherCriteria',
    }),
    timeResearchTypes() {
      return TIME_RESEARCH_TYPES
    },
  },
  mounted() {
    this.initTrHeight()
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions({
      readCertificateProcessTypes: 'certificateProcessType/readCertificateProcessTypes',
      createCredentialProcessTypes: 'credentialProcessType/createCredentialProcessTypes',
      readCredentialFileRequire: 'credentialProcessType/readCredentialFileRequire',
      createCredentialFileRequire: 'credentialProcessType/createCredentialFileRequire',
      getResearches: 'twTimeResearchTeacherResult/getResearches',
      getResearchesArticles: 'twTimeResearchTeacherResult/getResearchesArticles',
      getResearchInventions: 'twTimeResearchTeacherResult/getResearchInventions',
      getResearchTechTransfers: 'twTimeResearchTeacherResult/getResearchTechTransfers',
      getYears: 'twTimeResearchTeacherResult/getYears',
      getTimeResearchConversionCriteria: 'twTimeResearchTeacherResult/getTimeResearchConversionCriteria',
      getTimeResearchTeachers: 'twTimeResearchTeacherResult/getTimeResearchTeachers',
      createTimeResearchTeachers: 'twTimeResearchTeacherResult/createTimeResearchTeachers'
    }),
    getResearchesByType(type) {
      if (type === null) {
        return []
      }
      switch (type) {
        case 1:
          return this.researches
        case 2:
          return this.researchInventions
        case 3:
          return this.researchArticles
        case 4:
          return this.researchTechTransfers
        default:
          return []
      }
    },
    onResearchTypeChange(event) {
      if (event === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn loại đề tài hợp lệ',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async onYearChange() {
      if(this.yearId === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn năm học',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.isLoading = true
      try {
        await this.getTimeResearchTeachers({
          yearId: this.yearId,
          teacherId: this.dataForm.id,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
      if (this.timeResearchTeacherCriteria.length > 0) {
        this.timeResearchCriteria = []
        this.nextId = 0
      } else {
        this.timeResearchCriteria = [{
          id: 1,
          researchId: null,
          twTimeResearchConversionCriteriaId: null,
          factYearId: null,
          teacherId: this.dataForm?.id || null,
          researchType: null,
          numberMember: 1,
        }]
        return
      }
      this.timeResearchTeacherCriteria.forEach(tc => {
        this.timeResearchCriteria.push({
          id: this.nextId + 1,
          researchId: tc.researchId,
          twTimeResearchConversionCriteriaId: tc.twTimeResearchConversionCriteriaId,
          factYearId: tc.factYearId,
          teacherId: tc.teacherId,
          researchType: tc.researchType,
          numberMember: tc.numberMember,
        })
        this.nextId += 1
      })
    },
    async onShow() {
      this.isLoading = true
      try {
        await Promise.all([
          this.getResearches({}),
          this.getResearchInventions({}),
          this.getResearchesArticles({}),
          this.getResearchTechTransfers({}),
          this.getTimeResearchConversionCriteria({}),
        ])
        this.allTimeResearchConversionCriteria.forEach(item => {
          item.name = item.name + '(' + item.timeConversion + 'h)'
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
      if (this.timeResearchTeacherCriteria.length > 0) {
        this.timeResearchCriteria = []
        this.nextId = 0
      } else {
        this.timeResearchCriteria = [{
          id: 1,
          researchId: null,
          twTimeResearchConversionCriteriaId: null,
          factYearId: null,
          teacherId: this.dataForm?.id || null,
          researchType: null,
          numberMember: 1,
        }]
        return
      }
      this.timeResearchTeacherCriteria.forEach(tc => {
        this.timeResearchCriteria.push({
          id: this.nextId + 1,
          researchId: tc.researchId,
          twTimeResearchConversionCriteriaId: tc.twTimeResearchConversionCriteriaId,
          factYearId: tc.factYearId,
          teacherId: tc.teacherId,
          researchType: tc.researchType,
          numberMember: tc.numberMember,
        })
        this.nextId += 1
      })
    },
    repeatAgain() {
      this.timeResearchCriteria.push({
        id: this.nextId += this.nextId,
        researchId: null,
        twTimeResearchConversionCriteriaId: null,
        factYearId: null,
        teacherId: this.dataForm?.id || null,
        researchType: null,
        numberMember: 1,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.timeResearchCriteria.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.assignPermissionResourceModalRef.scrollHeight)
      })
    },
    onHide() {
      this.timeResearchCriteria = [{
        id: 1,
        researchId: null,
        twTimeResearchConversionCriteriaId: null,
        factYearId: null,
        teacherId: this.dataForm?.id || null,
        researchType: null,
        numberMember: 1,
      }]
      this.nextId = 2
      this.yearId = null
      this.$store.commit('twTimeResearchTeacherResult/SET_TIME_RESEARCH_TEACHERS', {
        data: [],
      })
      this.dataSave = {
        timeResearchCriteria: null,
        teacherId: null,
        yearId: null,
        numberMember: 1,
      }
    },
    async onSave(type = null) {
      if (this.yearId === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn năm học',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.timeResearchCriteria.forEach(item => {
        item.factYearId = this.yearId
      })
      this.dataSave.timeResearchCriteria = this.timeResearchCriteria
      this.dataSave.yearId = this.yearId
      this.dataSave.teacherId = this.dataForm.id
      const valid = this.$refs
          .resourceSaveFormRef
          .validate()
      if (valid && this.timeResearchCriteria.length > 0) {
        this.isLoading = true
        try {
          const response = await this.createTimeResearchTeachers(this.dataSave)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('timeResearchTeacherAddModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
