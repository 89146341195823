<template>
  <b-modal
    id="conductPointModal"
    body-class="position-static"
    centered
    title="Nhập điểm rèn luyện học viên"
    size="xl"
    :no-close-on-backdrop="true"
    @shown="onShown"
    @hide="onHide"
  >
    <b-row class="justify-content-end">
      <b-col
        v-show="false"
        sm="12"
        md="8"
        lg="4"
        xl="4"
      >
        <v-select
          v-model="courseSemesterId"
          label="name"
          :options="courses"
          :reduce="option => option.id"
        />
      </b-col>
      <b-col
        sm="12"
        md="4"
        lg="4"
        xl="4"
      >
        <b-dropdown
          v-if="conductPointCreatable && dataSources.length > 0"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          text="Nhập file"
          right
          variant="outline-primary"
          class="justify-content-end"
        >
          <b-dropdown-item @click="downloadFile">
            <span class="text-nowrap text-right">
              <feather-icon icon="DownloadIcon" /> Tải về file mẫu
            </span>
          </b-dropdown-item>
          <b-dropdown-item @click="$refs.inputFileRef.$el.click()">
            <span class="text-nowrap text-right">
              <feather-icon icon="UploadIcon" /> Tải lên file
            </span>
          </b-dropdown-item>
          <b-form-file
            ref="inputFileRef"
            accept=".xls, .xlsx"
            :hidden="true"
            plain
            @change="importExcel"
          />
        </b-dropdown>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="dataSources"
          :pagination-options="paginationOptions"
          :line-numbers="true"
        >
          <div
            slot="emptystate"
            style="text-align: center; font-weight: bold"
          >
            Không có dữ liệu hoặc chưa đến thời điểm nhập điểm rèn luyện!
          </div>
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field === 'gender'">
              {{ getGender(props.row.gender) }}
            </span>

            <span v-else-if="props.column.field === 'diligencePoint'">
              <validation-provider
                #default="{ errors }"
                rules="required|between:0,4"
                :name="props.column.label"
              >
                <b-form-input
                  v-model.number="props.row.diligencePoint"
                  min="0"
                  max="4"
                  @change="changeCellItem(props.row.diligencePoint, props.column.field,props.row.originalIndex)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </span>
            <span v-else-if="props.column.field === 'rulesPoint'">
              <validation-provider
                #default="{ errors }"
                rules="required|between:0,4"
                :name="props.column.label"
              >
                <b-form-input
                  v-model.number="props.row.rulesPoint"
                  min="0"
                  max="4"
                  @change="changeCellItem(props.row.rulesPoint, props.column.field,props.row.originalIndex)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </span>

            <span v-else-if="props.column.field === 'activitiesPoint'">
              <validation-provider
                #default="{ errors }"
                rules="required|between:0,2"
                :name="props.column.label"
              >
                <b-form-input
                  v-model.number="props.row.activitiesPoint"
                  min="0"
                  max="2"
                  @change="changeCellItem(props.row.activitiesPoint, props.column.field,props.row.originalIndex)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </span>

            <span v-else-if="props.column.field === 'conductPoint'">
              <b-form-input
                v-model.number="props.row.conductPoint"
                :disabled="!isLaoCai"
                type="number"
                min="0"
                max="100"
                @change="changeCell(props.row.conductPoint, props.column.field, props.row.originalIndex)"
              />
            </span>

            <span v-else-if="props.column.field === 'rank'">
              {{ getRank(props.row.conductPoint) }}
            </span>

            <!-- Column: Common -->
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Hiển thị 1 đến </span>
                <b-form-select
                  v-model="itemsPerPage"
                  :options="itemsPerPageOptions"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap">của {{ props.total }} bản ghi</span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="itemsPerPage"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                />
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button
          v-if="conductPointCreatable"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          @click="onSave(dataSources)"
        >
          <span class="text-right">
            <feather-icon icon="CheckIcon" /> Lưu lại
          </span>
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="$bvModal.hide('conductPointModal')"
        >
          <span class="text-right">
            <feather-icon icon="XIcon" /> Hủy
          </span>
        </b-button>
      </div>
    </template>
    <b-overlay
      no-wrap
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      :show="isLoading"
    />
  </b-modal>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BCol,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BFormInput,
  BFormFile,
  BDropdown,
  BDropdownItem,
  BModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { GENDERS } from '@/const/status'
import { getUser } from '@/auth/utils'
import * as XLSX from 'xlsx'
import vSelect from 'vue-select'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { Flag } from '@/const/flag'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'ConductPoint',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BCol,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BFormInput,
    BFormFile,
    BDropdown,
    BDropdownItem,
    BModal,
    VueGoodTable,
    vSelect,
    ValidationProvider,
  },
  props: {
    targetClass: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      courseSemesterId: null,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'classes/studentConducts',
      courses: 'classes/cpCourses',
      rankConducts: 'classes/rankConducts',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    user() {
      return getUser()
    },
    isLaoCai() {
      return this.user.orgCode.includes('LCI')
    },
    columns() {
      const baseColumns = [
        {
          label: 'Mã học viên',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập mã học viên',
          },
          thClass: 'text-center',
        },
        {
          label: 'Họ',
          field: 'lastName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập họ',
          },
          thClass: 'text-center',
        },
        {
          label: 'Tên',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên',
          },
          thClass: 'text-center',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: GENDERS.map(status => ({ value: status.value, text: status.label })),
            placeholder: 'Chọn giới tính',
          },
          thClass: 'text-center',
        },
        {
          label: 'Ngày sinh',
          field: 'birthday',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày sinh',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ]
      if (!this.isLaoCai) {
        baseColumns.push(
          {
            label: 'Điểm chuyên cần',
            field: 'diligencePoint',
            thClass: 'text-center',
          },
          {
            label: 'Điểm thực hiện nội quy',
            field: 'rulesPoint',
            thClass: 'text-center',
          },
          {
            label: 'Điểm hoạt động tập thể',
            field: 'activitiesPoint',
            thClass: 'text-center',
          },
        )
      }
      baseColumns.push(
        {
          label: 'Điểm rèn luyện',
          field: 'conductPoint',
          thClass: 'text-center',
        },
        {
          label: 'Xếp loại',
          field: 'rank',
          thClass: 'text-center',
        },
      )
      return baseColumns
    },
    conductPointCreatable() {
      return (isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.STUDENT_CONDUCT))
        && this.user.teacherId
        && this.targetClass.teachers
        && this.targetClass.teachers.length > 0
        && this.targetClass.teachers.find(item => item.id === this.user.teacherId)
    },
  },
  watch: {
    async courseSemesterId(val) {
      if (val) {
        await this.getStudentsConductsFromStore()
      } else {
        this.setDataSources([])
      }
    },
  },
  methods: {
    ...mapMutations({
      setDataSources: 'classes/SET_STUDENT_CONDUCTS',
    }),
    ...mapActions({
      getStudentsConducts: 'classes/getStudentsConducts',
      getCourseSemestersByClassId: 'classes/getCourseSemestersByClassId',
      saveStudentConducts: 'classes/saveStudentConducts',
      getConductRanks: 'classes/getConductRanks',
    }),
    async onShown() {
      this.isLoading = true
      try {
        await Promise.all([
          this.getCourseSemestersByClassId({ classId: this.targetClass.id }),
          this.getConductRanks({}),
        ])
        if (this.courses.length > 0) {
          const found = this.courses.find(course => course.status === Flag.ACTIVE)
          if (found) {
            this.courseSemesterId = found.id
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async getStudentsConductsFromStore() {
      this.isLoading = true
      try {
        await this.getStudentsConducts({ classId: this.targetClass.id, courseSemesterId: this.courseSemesterId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getGender(id) {
      if (!id) return GENDERS[0].label
      return GENDERS.find(gender => gender.value === id).label
    },
    changeCell(data, column, row) {
      this.dataSources[row][column] = data
    },

    changeCellItem(data, column, row) {
      this.dataSources[row][column] = data
      this.dataSources[row].conductPoint = this.dataSources[row].diligencePoint + this.dataSources[row].rulesPoint + this.dataSources[row].activitiesPoint
    },
    async onSave(data) {
      if (data.some(element => element.conductPoint == null)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng nhập đầy đủ dữ liệu điểm',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (data.some(element => !element.studentClassId)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thiếu dữ liệu học viên thuộc lớp',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const students = data.map(element => ({
        id: element.id,
        courseSemestersId: this.courseSemesterId,
        studentClassId: element.studentClassId,
        conductPoint: element.conductPoint,
        diligencePoint: element.diligencePoint,
        activitiesPoint: element.activitiesPoint,
        rulesPoint: element.rulesPoint,
      }))
      this.isLoading = true
      try {
        const response = await this.saveStudentConducts({ students })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getStudentsConductsFromStore()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    downloadFile() {
      let data = []
      if (this.dataSources.length > 0) {
        data = this.dataSources.map(element => ({
          id: element.id,
          code: element.code,
          lastName: element.lastName,
          name: element.name,
          gender: this.getGender(element.gender),
          birthday: element.birthday,
          diligencePoint: element.diligencePoint,
          rulesPoint: element.rulesPoint,
          activitiesPoint: element.activitiesPoint,
          conductPoint: element.conductPoint,
        }))
      }
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'scores')
      XLSX.writeFile(wb, 'importDiemRenLuyen.xlsx')
    },
    importExcel(e) {
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File dữ liệu đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return false
      }
      if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File đầu vào phải có kiểu định dạng xlsx hoặc xls',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return false
      }
      const fileReader = new FileReader()
      fileReader.addEventListener(
        'load',
        async ev => {
          const { result } = ev.target
          const workbook = XLSX.read(result, { type: 'binary' })
          const wsName = workbook.SheetNames[0]
          let data = XLSX.utils.sheet_to_json(workbook.Sheets[wsName])
          if (data.length > 0) {
            data = data.map(element => {
              const param = this.getParameterByCode(element.code)
              return { ...element, ...param }
            })
            await this.onSave(data)
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'File không có dữ liệu',
                icon: 'InfoIcon',
                variant: 'warning',
              },
            })
          }
          this.resetInputFile()
        },
        false,
      )
      fileReader.readAsBinaryString(files[0])
      return true
    },
    getParameterByCode(code) {
      const found = this.dataSources.find(element => element.code === code)
      if (found) {
        return {
          studentCgpaId: found.studentCgpaId,
          studentClassId: found.studentClassId,
        }
      }
      return {}
    },
    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },
    getRank(value) {
      if (this.rankConducts.length === 0 || !value) return ''
      const found = this.rankConducts.find(element => element.minValue <= value && value <= element.maxValue)
      if (found) return found.name
      return ''
    },
    onHide() {
      this.courseSemesterId = null
      this.setDataSources([])
    },
  },
}
</script>
