<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <b-link class="brand-logo">
          <Logo />
          <h2 class="brand-text text-primary ml-1">
            {{ appName }}
          </h2>
        </b-link>
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Đăng nhập"
            />
          </div>
        </b-col>
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              Chào mừng bạn đến với website quản trị {{ appName }}! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              Vui lòng đăng nhập để tiếp tục
            </b-card-text>

            <validation-observer
              ref="loginFormRef"
              #default="{invalid}"
            >
              <b-form class="auth-login-form mt-2">
                <b-form-group
                  label="Tài khoản"
                  label-for="username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tài khoản"
                    vid="username"
                    rules="required"
                  >
                    <b-form-input
                      id="username"
                      v-model="username"
                      name="username"
                      tabindex="1"
                      :state="getElementState(errors)"
                      @keyup.enter="onLogin"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="password">Mật khẩu</label>
                    <b-link :to="{ name: 'forgotPassword' }">
                      <small>Quên mật khẩu?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Mật khẩu"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        class="form-control-merge"
                        name="password"
                        tabindex="2"
                        :type="passwordFieldType"
                        :state="getElementState(errors)"
                        @keyup.enter="onLogin"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button
                  variant="primary"
                  block
                  :disabled="invalid"
                  @click="onLogin"
                >
                  Đăng nhập
                </b-button>

                <b-button
                  variant="warning"
                  block
                  @click="onLoginSso"
                >
                  Đăng nhập SSO
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend,
  BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import { LOGIN_TYPE } from '@/const/type'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BOverlay,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      isLoading: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      required,
      appName: process.env.VUE_APP_NAME,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      verify: 'auth/verify',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onLogin() {
      const valid = await this.$refs
        .loginFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          await this.$recaptchaLoaded()
          const recaptchaToken = await this.$recaptcha('login')
          await this.login({
            username: this.username,
            password: this.password,
            loginType: +LOGIN_TYPE.NORMAL,
            recaptchaToken,
          })
          const user = await this.verify()
          if (user) {
            this.$ability.update(user.abilities)
            try {
              await this.$router.replace({ name: 'dashboard' })
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          } else {
            useJwt.handleLogout()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Không lấy được thông tin người dùng',
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    onLoginSso() {
      const { protocol, hostname } = window.location
      const redirectUri = `${protocol}//${hostname}/auth/login/ssoCallback&scope=openid+profile&nonce=asd`
      window.location.href = `${process.env.VUE_APP_SSO_URL}/oauth2/authorize?response_type=code+id_token+token&client_id=${process.env.VUE_APP_SSO_CLIENT_ID}&redirect_uri=${redirectUri}`
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
