<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <div class="text-right mb-1 mr-1">
              <b-dropdown
                class="ml-1"
                right
                variant="danger"
                icon="InfoIcon"
                text="Xuất pdf"
              >
                <b-dropdown-item
                  variant="danger"
                  class="btn-icon"
                  size="sm"
                  @click="downloadFileTeacherPdf()"
                >
                  Xuất đề thi cho GV
                </b-dropdown-item>
                <b-dropdown-item
                  variant="danger"
                  class="btn-icon"
                  size="sm"
                  @click="downloadFilePdf()"
                >
                  Xuất đề thi cho HV
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown
                v-if="wordExam"
                class="ml-1"
                right
                variant="danger"
                icon="InfoIcon"
                text="Xuất word"
              >
                <b-dropdown-item
                  variant="danger"
                  class="btn-icon"
                  size="sm"
                  @click="downloadFileTeacherWord()"
                >
                  Xuất đề thi cho GV
                </b-dropdown-item>
                <b-dropdown-item
                  variant="danger"
                  class="btn-icon"
                  size="sm"
                  @click="downloadFileWord()"
                >
                  Xuất đề thi cho HV
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  :columns="columns"
                  :rows="dataSources"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  @on-page-change="onPageChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'content'">
                      <span v-html="decodeHtml(props.row[props.column.field])"></span>
                    </span>
                    <span v-else-if="props.column.field === 'action'">
                        <b-button
                          v-b-modal.exchangeExamQuestionModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          @click="onExchange(props.row)"
                        >
                        <feather-icon icon="ShuffleIcon" />
                      </b-button>
                    </span>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <b-modal
      ref="exchange-exam-question-modal"
      id="exchange-exam-question-modal"
      centered
      size="lg"
      :title="`Danh sách câu hỏi có thể thay thế: ${dataSendClass.name}`"
      :hide-footer=true
      no-close-on-backdrop
    >
      <ExchangeExamQuestions :qb-exam-question="dataSendClass" @succeed="onSucceed"/>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard, BCol, BContainer, BDropdown, BDropdownItem, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import ExchangeExamQuestions from '@/views/exam-question/qb-exam/ExchangeExamQuestions.vue'
import { hasPermissionForResource, isSystemAdmin } from "@/utils";
import { PermissionCode, ResourceCode } from "@/const/code";

export default {
  name: 'Index',
  directives: {
    Ripple,
  },
  components: {
    BDropdownItem,
    BDropdown,
    ExchangeExamQuestions,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    qbExamId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        qbExamId: null,
        code: null,
        name: null,
        status: null,
      },
      currentDataSource: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      dataSendClass: {},
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'qbExams/dataSourcesQuestions',
      totalRows: 'qbExams/totalRowsQuestions',
    }),
    wordExam() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.EXPORT_WORD_EXAM)
    },
    columns() {
      return [
        {
          label: 'Thứ tự câu hỏi',
          field: 'orderNo',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tên câu hỏi',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Câu hỏi',
          field: 'content',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Điểm',
          field: 'score',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  watch: {
    async resource() {
      await this.onLoad()
    },
  },
  async created() {
    await this.onLoad()
  },
  methods: {
    ...mapMutations({
      setDataSources: 'qbExams/SET_DATA_SOURCES_QUESTIONS',
    }),
    ...mapActions({
      getDataSources: 'qbExams/getExamQuestionDataSources',
      exportExam: 'qbExams/exportPdf',
      exportWordExam: 'qbExams/exportWordExam',
      exportExamTeacher: 'qbExams/exportExamTeacher',
      exportWordExamTeacher: 'qbExams/exportWordExamTeacher',
    }),
    async onLoad() {
      this.isLoading = true
      try {
        await this.getDataSourcesFromStore()
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.setDataSources([])
      } finally {
        this.isLoading = false
      }
    },
    async downloadFilePdf() {
      if (this.dataSources.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const data = await this.exportExam({
          qbExamId: this.qbExamId,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async downloadFileTeacherPdf() {
      this.isLoading = true
      try {
        const data = await this.exportExamTeacher({
          qbExamId: this.qbExamId,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async downloadFileWord() {
      this.isLoading = true
      try {
        const data = await this.exportWordExam({
          qbExamId: this.qbExamId,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async downloadFileTeacherWord() {
      this.isLoading = true
      try {
        const data = await this.exportWordExamTeacher({
          qbExamId: this.qbExamId,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    decodeHtml(html) {
      const txt = document.createElement('textarea')
      txt.innerHTML = html
      return txt.value
    },
    onCreate() {
      this.currentDataSource = undefined
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    onExchange(data) {
      this.dataSendClass = data
      this.$refs['exchange-exam-question-modal'].show()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      this.filter.qbExamId = this.qbExamId
      try {
        await this.getDataSources(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getDataSourcesFromStore()
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
