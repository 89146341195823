<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm đợt khảo sát/đánh giá' : 'Cập nhật đợt khảo sát/đánh giá'"
      @show="onShow"
      @hide="onHide"
      size="lg"
      no-close-on-backdrop
    >
    <b-form>
      <b-row>
        <b-col sm="12" md="8" lg="8" xl="8">
          <b-form-group label-for="name">
            <template v-slot:label>
              Tên đợt đánh giá <span class="text-danger">*</span>
            </template>
            <validation-provider
                #default="{ errors }"
                name="Tên đợt đánh giá"
                rules="required"
              >
                <b-form-textarea
                  id="name"
                  placeholder="Nhập tên đợt đánh giá"
                  rows="3"
                  v-model="targetItem.name"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="4" lg="4" xl="4">
          <b-form-group label-for="name">
            <template v-slot:label>
              Mã đợt đánh giá <span class="text-danger">*</span>
            </template>
            <validation-provider
                #default="{ errors }"
                name="Mã đợt đánh giá"
                rules="required"
              >
                 <b-form-input id="code" v-model="targetItem.code" name="code" placeholder="Nhập mã đợt đánh giá"
                  :state="getElementState(errors)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group label-for="evaluateObjectGroupId">
            <template v-slot:label>
              Nhóm đối tượng thực hiện khảo sát/đánh giá <span class="text-danger">*</span>
            </template>
            <validation-provider #default="{ errors }" name="Nhóm đối tượng thực hiện khảo sát/đánh giá" rules="required">
              <v-select
                v-model="targetItem.evaluateObjectGroupId"
                placeholder="Nhóm đối tượng thực hiện khảo sát/đánh giá"
                label="name"
                :options="evaluateObjectGroups"
                :reduce="(option) => option.id"
                @input="onChangeEvaluateObjectGroup"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="12">
          <b-form-group label-for="name">
            <template v-slot:label>
              Tên phiếu khảo sát/đánh giá<span class="text-danger">*</span>
            </template>
            <validation-provider #default="{ errors }" name="Tên phiếu khảo sát/đánh giá" rules="required">
              <v-select
                v-model="targetItem.setOfCriteriaId"
                placeholder="Chọn tên phiếu khảo sát/đánh giá"
                label="name"
                :options="setOfCriterias"
                :reduce="(option) => option.id"
                :selectable="option => !option.disabled"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label-for="start_date">
            <template v-slot:label>
              Ngày bắt đầu <span class="text-danger">*</span>
            </template>
            <validation-provider #default="{ errors }" name="Ngày bắt đầu" rules="required">
              <b-form-datepicker
                id="datepicker-dateformat1"
                v-model="targetItem.startDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="vi"
                format="dd/mm/yyyy"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label-for="end_date">
            <template v-slot:label>
              Ngày kết thúc <span class="text-danger">*</span>
            </template>
            <validation-provider #default="{ errors }" name="Ngày kết thúc" :rules="'required|after_or_equal:' + targetItem.startDate">
              <b-form-datepicker
                id="datepicker-dateformat2"
                v-model="targetItem.endDate"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="vi"
                format="dd/mm/yyyy"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6" lg="6" xl="6">
          <b-form-group label-for="status">
            <template v-slot:label>
              Trạng thái <span class="text-danger">*</span>
            </template>
            <validation-provider #default="{ errors }" name="Trạng thái" rules="required">
              <v-select v-model="targetItem.status" :options="statuses" :reduce="(option) => option.value" />
              <b-form-invalid-feedback :state="getElementState(errors)">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
        no-wrap
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BModal, BOverlay, BButton, BFormGroup, BFormInput, BFormDatepicker, BRow, BCol, BFormTextarea, BFormInvalidFeedback, VBTooltip
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, minValue, after_or_equal } from '@validations'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { STATUSES } from '@/const/status'
import { OBJECT_GROUP_TYPES } from '@/const/type'
import vSelect from "vue-select";
import { getUser } from '@/auth/utils'

export default {
  name: 'WardSave',
  components: {
    vSelect,
    BFormInput,
    ValidationProvider,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BButton,
    ValidationObserver,
    BFormDatepicker,
    BRow,
    BCol,
    BFormTextarea,
    BFormInvalidFeedback,
    VBTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: { 
        id: null,
        organizationId: getUser().orgId,
        code: '',
        name: '',
        setOfCriteriaId: null,
        evaluateObjectGroupId: null,
        startDate: null,
        endDate: null,
        status: 1,
        orderNo: 0,
      },
      required,
      isShowChildsIfEvaluateObjectGroupIsStudent: false,
      minValue,
      after_or_equal,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'subject/statuses',
      setOfCriterias: 'setOfCriteriaObject/setOfCriterias',
      evaluateObjectGroups: 'setOfCriteriaObject/evaluateObjectGroups',
      departments: 'dropdown/departments',
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      courseSemesters: 'dropdown/courseSemesters',
      programmes: 'dropdown/programmes',
      programmeSubjects: 'dropdown/programmeSubjects',
    }),
    statuses() {
      return STATUSES
    },
    isCreated() {
      return !this.item
    },
  },
  async mounted() {
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      createData: 'setOfCriteriaObject/createData',
      updateData: 'setOfCriteriaObject/updateData',
    }),
    async onChangeEvaluateObjectGroup(statusModel = 'onShow') {
      this.targetItem.setOfCriteriaId = null
      let objectGroupType = null
      for (let i = 0; i < this.evaluateObjectGroups.length; i++) {
        if (this.evaluateObjectGroups[i].id === this.targetItem.evaluateObjectGroupId) {
          objectGroupType =  this.evaluateObjectGroups[i].objectGroupType
          break;
        }
      }
      this.setOfCriterias.forEach((item) => {
        item.disabled = false
        if(objectGroupType === OBJECT_GROUP_TYPES[1].value) {
          if(item.numberCreditClassRoomCriteria >  0) {
            item.disabled = true
          } else {
            item.disabled = false
          }
        }
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.item) {
        this.targetItem = { ...this.item }
      } else {
        this.setOfCriterias.forEach((item) => {
          item.disabled = false
        })
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
        this.targetItem = {
          id: null,
          organizationId: getUser().orgId,
          code: '',
          name: '',
          setOfCriteriaId: null,
          evaluateObjectGroupId: null,
          startDate: null,
          endDate: null,
          status: 1,
          orderNo: 0,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createData(this.targetItem)
            : await this.updateData(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    getCode(arrayObject, id) {
      return arrayObject.find(object => object.id === id)?.code
    },
  },
}
</script>
