<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <b-form-group
                    label="Chương trình đào tạo/Bồi dưỡng"
                    label-for="trainingSystem"
                  >
                    <v-select
                      id="trainingSystem"
                      v-model="filter.trainingSystemId"
                      label="name"
                      :options="trainingSystems"
                      :reduce="(option) => option.id"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <b-form-group
                    label="Khóa học"
                    label-for="course"
                  >
                    <v-select
                      id="course"
                      v-model="filter.courseId"
                      label="name"
                      :options="courses"
                      :reduce="(option) => option.id"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <b-form-group
                    label="Học kỳ"
                    label-for="semester"
                  >
                    <v-select
                      id="semester"
                      v-model="filter.courseSemesterId"
                      label="name"
                      :options="semesters"
                      :reduce="(option) => option.id"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <b-form-group
                    label="Học phần"
                    label-for="subject"
                  >
                    <v-select
                      id="subject"
                      v-model="filter.subjectId"
                      label="name"
                      :options="subjects"
                      :reduce="(option) => option.id"
                      :clearable="false"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <b-form-group
                    label="Lớp độc lập"
                    label-for="creditClass"
                  >
                    <v-select
                      id="creditClass"
                      v-model="filter.creditClassId"
                      label="code"
                      :options="creditClasses"
                      :reduce="(option) => option.id"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="w-100 d-flex justify-content-center">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click="getDataSourcesFromStore"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="SearchIcon" /> Tìm kiếm
                      </span>
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      @click="exportExcel"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="DownloadIcon" /> Xuất excel
                      </span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-form>
            <b-row class="mt-2">
              <b-col cols="12">
                <vue-good-table
                  :columns="columns"
                  :rows="dataSources"
                  :pagination-options="paginationOptions"
                  :line-numbers="true"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'creditClassCode' || props.column.field === 'creditClassName'">
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                    <span v-else>
                      <b-link @click="onShowStudents(props.row.creditClassId, props.column.field, props.formattedRow[props.column.field])">
                        {{ props.formattedRow[props.column.field] }}
                      </b-link>
                    </span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị 1 đến </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>

    <StudentPoint
      :credit-class-id="currentCreditClassId"
      :point="currentPoint"
    />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import { Flag } from '@/const/flag'
import { VueGoodTable } from 'vue-good-table'
import StudentPoint from '@/views/report/credit-class-by-subject/StudentPoint.vue'
import * as XLSX from 'xlsx'

export default {
  name: 'ReportCreditClassBySubject',
  directives: {
    Ripple,
  },
  components: {
    StudentPoint,
    BOverlay,
    BPagination,
    vSelect,
    BFormSelect,
    BFormGroup,
    BCol,
    BContainer,
    BButton,
    BForm,
    BCard,
    BRow,
    BLink,
    VueGoodTable,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        itemsPerPage: 20,
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        subjectId: null,
        creditClassId: null,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [],
      currentCreditClassId: null,
      currentPoint: null,
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'creditClassBySubject/trainingSystems',
      courses: 'creditClassBySubject/courses',
      semesters: 'creditClassBySubject/semesters',
      subjects: 'creditClassBySubject/subjects',
      creditClasses: 'creditClassBySubject/creditClasses',
      dataSources: 'creditClassBySubject/points',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.filter.itemsPerPage,
      }
    },
  },
  watch: {
    'filter.trainingSystemId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getCoursesByTrainingSystemId({ trainingSystemId: val })
          if (this.courses.length > 0) {
            this.updateParams({ courseId: this.courses[0].id })
          } else {
            this.updateParams({
              courseId: null,
              courseSemesterId: null,
              subjectId: null,
              creditClassId: null,
            })
            this.setSemesters([])
            this.setSubjects([])
            this.setCreditClasses([])
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({
          courseId: null,
          courseSemesterId: null,
          subjectId: null,
          creditClassId: null,
        })
        this.setCourses([])
        this.setSemesters([])
        this.setSubjects([])
        this.setCreditClasses([])
      }
    },
    'filter.courseId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getSemestersByCourseId({ courseId: val })
          if (this.semesters.length > 0) {
            const found = this.semesters.find(semester => semester.status === Flag.ACTIVE)
            if (found) {
              this.updateParams({ courseSemesterId: found.id })
            } else {
              this.updateParams({ courseSemesterId: this.semesters[0].id })
            }
          } else {
            this.updateParams({
              courseSemesterId: null,
              subjectId: null,
              creditClassId: null,
            })
            this.setSubjects([])
            this.setCreditClasses([])
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({
          courseSemesterId: null,
          subjectId: null,
          creditClassId: null,
        })
        this.setSemesters([])
        this.setSubjects([])
        this.setCreditClasses([])
      }
    },
    'filter.courseSemesterId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getSubjectsByCourseSemesterId({ courseSemesterId: val })
          if (this.subjects.length > 0) {
            this.updateParams({ subjectId: this.subjects[0].id })
          } else {
            this.updateParams({ subjectId: null, creditClassId: null })
            this.setCreditClasses([])
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({ subjectId: null, creditClassId: null })
        this.setSubjects([])
        this.setCreditClasses([])
      }
    },
    'filter.subjectId': async function (val) {
      if (val) {
        this.isLoading = true
        try {
          await this.getCreditClassesByCourseSemesterAndSubject({
            courseSemesterId: this.filter.courseSemesterId,
            subjectId: val,
            status: Flag.CREDIT_CLASS_STUDYING,
            type: 'greaterThanEqual',
          })
          if (this.creditClasses.length > 0) {
            this.updateParams({ creditClassId: this.creditClasses[0].id })
          } else {
            this.updateParams({ creditClassId: null })
          }
          const found = this.subjects.find(element => element.id === val)
          if (found) {
            const { countedGpa } = found
            this.columns = [
              {
                label: 'Mã lớp',
                field: 'creditClassCode',
                filterOptions: {
                  enabled: true,
                  trigger: 'enter',
                  placeholder: 'Nhập mã lớp',
                },
                sortable: true,
                thClass: 'text-center',
              },
            ]
            if (countedGpa === 1) {
              this.columns = [...this.columns, ...[
                {
                  label: 'Điểm A',
                  field: 'A',
                  sortable: false,
                  thClass: 'text-center',
                  tdClass: 'text-center',
                },
                {
                  label: 'Điểm B',
                  field: 'B',
                  sortable: false,
                  thClass: 'text-center',
                  tdClass: 'text-center',
                },
                {
                  label: 'Điểm C',
                  field: 'C',
                  sortable: false,
                  thClass: 'text-center',
                  tdClass: 'text-center',
                },
                {
                  label: 'Điểm D',
                  field: 'D',
                  sortable: false,
                  thClass: 'text-center',
                  tdClass: 'text-center',
                },
              ]]
            } else {
              this.columns = [...this.columns, ...[
                {
                  label: 'Điểm P',
                  field: 'P',
                  sortable: false,
                  thClass: 'text-center',
                  tdClass: 'text-center',
                },
              ]]
            }
            this.columns = [...this.columns, ...[
              {
                label: 'Điểm F',
                field: 'F',
                sortable: false,
                thClass: 'text-center',
                tdClass: 'text-center',
              },
              {
                label: 'Điểm I',
                field: 'I',
                sortable: false,
                thClass: 'text-center',
                tdClass: 'text-center',
              },
              {
                label: 'Điểm X',
                field: 'X',
                sortable: false,
                thClass: 'text-center',
                tdClass: 'text-center',
              },
              {
                label: 'Điểm R',
                field: 'R',
                sortable: false,
                thClass: 'text-center',
                tdClass: 'text-center',
              },
            ]]
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      } else {
        this.updateParams({ creditClassId: null })
        this.setCreditClasses([])
      }
    },
  },
  async created() {
    this.reset()
    this.isLoading = true
    try {
      await this.getTrainingSystemsByOrganizationId({
        organizationId: this.user.orgId,
      })
      if (this.trainingSystems.length > 0) {
        this.updateParams({ trainingSystemId: this.trainingSystems[0].id })
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapMutations({
      setTrainingSystems: 'creditClassBySubject/SET_TRAINING_SYSTEMS',
      setCourses: 'creditClassBySubject/SET_COURSES',
      setSemesters: 'creditClassBySubject/SET_SEMESTERS',
      setSubjects: 'creditClassBySubject/SET_SUBJECTS',
      setCreditClasses: 'creditClassBySubject/SET_CREDIT_CLASSES',
      setDataSources: 'creditClassBySubject/SET_POINTS',
    }),
    ...mapActions({
      getTrainingSystemsByOrganizationId: 'creditClassBySubject/getTrainingSystemsByOrganizationId',
      getCoursesByTrainingSystemId: 'creditClassBySubject/getCoursesByTrainingSystemId',
      getSemestersByCourseId: 'creditClassBySubject/getSemestersByCourseId',
      getSubjectsByCourseSemesterId: 'creditClassBySubject/getSubjectsByCourseSemesterId',
      getCreditClassesByCourseSemesterAndSubject: 'creditClassBySubject/getCreditClassesByCourseSemesterAndSubject',
      countAlphabetPointCreditClass: 'creditClassBySubject/countAlphabetPointCreditClass',
    }),
    reset() {
      this.updateParams({
        trainingSystemId: null,
        courseId: null,
        courseSemesterId: null,
        subjectId: null,
        creditClassId: null,
      })
      this.setTrainingSystems([])
      this.setCourses([])
      this.setSemesters([])
      this.setSubjects([])
      this.setCreditClasses([])
      this.setDataSources([])
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.countAlphabetPointCreditClass(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onShowStudents(creditClassId, point, quantity) {
      if (quantity > 0) {
        this.currentCreditClassId = creditClassId
        this.currentPoint = point
        this.$bvModal.show('studentPointModal')
      }
    },
    exportExcel() {
      if (this.dataSources.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const data = []
      const header = {}
      this.columns.forEach(column => {
        header[column.field] = column.label
      })
      const headerKeys = Object.keys(header)
      const dataSourceKeys = Object.keys(this.dataSources[0])
      const duplicates = dataSourceKeys.filter(val => headerKeys.includes(val))
      duplicates.sort((a, b) => headerKeys.indexOf(a) - headerKeys.indexOf(b))
      this.dataSources.forEach(dataSource => {
        const elm = {}
        duplicates.forEach(key => {
          elm[header[key]] = dataSource[key]
        })
        data.push(elm)
      })
      const ws = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'SoLuong')
      XLSX.writeFile(wb, 'ThongKeDiemLopDocLap.xlsx')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
