<template>
  <validation-observer
    ref="programmeCopyFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="programmeCopyModal"
      body-class="position-static"
      centered
      :title="`Sao chép ${resourceName.fullName}`"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="name">
          <template v-slot:label>
            Tên {{ resourceName.fullName }} <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            :name="`Tên ${resourceName.fullName}`"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="targetData.name"
              name="name"
              :placeholder="`Nhập tên ${resourceName.fullName}`"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="code">
          <template v-slot:label>
            Mã {{ resourceName.fullName }} <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            :name="`Mã ${resourceName.fullName}`"
            rules="required"
          >
            <b-form-input
              id="code"
              v-model="targetData.code"
              name="code"
              :placeholder="`Nhập mã ${resourceName.fullName}`"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group label-for="avgCreditNum">
              <template v-slot:label>
                Số tiết tối thiểu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số tiết tối thiểu"
                rules="required"
              >
                <b-form-input
                  id="avgCreditNum"
                  v-model="targetData.avgCreditNum"
                  name="avgCreditNum"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group label-for="trainingSystemId">
              <template v-slot:label>
                Chương trình đào tạo/Bồi dưỡng <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chương trình đào tạo/Bồi dưỡng"
                rules="required"
              >
                <v-select
                  v-model="targetData.trainingSystemId"
                  :options="trainingSystems"
                  :reduce="option => option.value"
                  @input="onTrainingSystemsChange($event)"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label-for="courseId">
          <template v-slot:label>
            Khóa học <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Khóa học"
            rules="required"
          >
            <v-select
              v-model="targetData.courseId"
              :options="courses"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetData.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Thứ tự"
              label-for="orderNo"
            >
              <b-form-input
                id="orderNo"
                v-model="targetData.orderNo"
                name="orderNo"
                placeholder="Nhập thứ tự"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Sao chép
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('programmeCopyModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  BOverlay,
  BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import * as events from 'events'
import { getUser } from '@/auth/utils'

export default {
  name: 'ProgrammeCopy',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    mainData: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetData: {
        name: '',
        code: '',
        status: 1,
        orderNo: 1,
        avgCreditNum: 1,
        courseId: null,
        majorId: null,
        departmentId: null,
      },
      required,
    }
  },
  computed: {
    events() {
      return events
    },
    ...mapGetters({
      statuses: 'programme/statuses',
      resourceName: 'programme/resourceName',
      maxOrderNo: 'programme/maxOrderNo',
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      majors: 'dropdown/majors',
    }),
    statusOptions() {
      return this.statuses.map(item => ({ value: item.value, label: item.label }))
    },
    isCreated() {
      return !this.mainData
    },
    getOrganizationId() {
      const cUser = getUser()
      return cUser.orgId ? cUser.orgId : 0
    },
  },
  methods: {
    ...mapActions({
      copyProgramme: 'programme/copyProgramme',
      getMaxOrderNo: 'programme/getMaxOrderNo',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
    }),
    async onTrainingSystemsChange(event) {
      this.targetData.courseId = null
      await this.getCourses({ trainingSystemId: event })
    },
    // eslint-disable-next-line no-unused-vars
    async onMajorChange(event) {
      this.targetData.name = `${this.majors.find(item => item.value === this.targetData.majorId).label} ${
        this.courses.find(item => item.value === this.targetData.courseId).label.substring(0, 3)}`
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      if (this.mainData) {
        this.targetData = { ...this.mainData }
        await Promise.all([
          this.getTrainingSystems({ organizationId: this.getOrganizationId }),
          this.getCourses({ trainingSystemId: this.mainData.trainingSystemId }),
          this.getMaxOrderNo(),
        ])

        this.targetData.orderNo = this.maxOrderNo + 1
        this.targetData.name = `${this.targetData.name} (${this.targetData.orderNo})`
        this.targetData.code = `${this.targetData.code} (${this.targetData.orderNo})`
      }
      this.isLoading = false
    },
    onHide() {
      this.$refs
        .programmeCopyFormRef
        .reset()
      this.targetData = {
        name: '',
        code: '',
        status: 1,
        orderNo: 1,
        avgCreditNum: 1,
        courseId: null,
        majorId: null,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .programmeCopyFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.copyProgramme(this.targetData)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('programmeCopyModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
