import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/student-policies',
    name: 'student_policies',
    component: () => import('@/views/student-policies/StudentPolicies.vue'),
    meta: {
      pageTitle: 'Đối tượng hưởng chế độ chính sách',
      breadcrumb: [
        {
          text: 'Công tác học viên',
          active: false,
        },
        {
          text: 'Đối tượng hưởng chế độ chính sách',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.STUDENT_POLICIES,
    },
  },
]
