import { STATUSES } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
    namespaced: true,
    state: {
        statuses: STATUSES,
        totalRows: 0,
        dataSources: [],
    },
    getters: {
        statuses: state => state.statuses,
        totalRows: state => state.totalRows,
        dataSources: state => state.dataSources,
    },
    mutations: {
        SET_STATUSES: (state, statuses) => {
            state.statuses = statuses
        },
        SET_TOTAL_ROWS: (state, data) => {
            state.totalRows = data
        },
        SET_DATA_SOURCES: (state, dataSources) => {
            state.dataSources = dataSources
        },
    },
    actions: {
        async getDataSources({ commit }, params) {
            try {
                const response = await axios.get('uni/fact_teacher_type', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { total, records } = data.data
                    commit('SET_DATA_SOURCES', records)
                    commit('SET_TOTAL_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async createTeacherType({ commit }, params) {
            try {
                const response = await axios.post('uni/fact_teacher_type', params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        // eslint-disable-next-line no-unused-vars
        async updateTeacherType({ commit }, params) {
            try {
                const response = await axios.put(`uni/fact_teacher_type/${params.id}`, params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        // eslint-disable-next-line no-unused-vars
        async deleteTeacherType({ commit }, id) {
            try {
                const response = await axios.delete(`uni/fact_teacher_type/${id}`)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
    },
}
