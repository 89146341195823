<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm loại phòng ban' : 'Cập nhật loại phòng ban'"
      @show="onShow"
      @hide="onHide"
      :no-close-on-backdrop="true"
    >
      <b-form>
        <b-form-group label-for="code">
          <template v-slot:label>
            Mã <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Mã"
            rules="required"
          >
            <b-form-input
              id="code"
              v-model="targetDataSource.code"
              name="code"
              placeholder="Nhập mã"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <CategorySave :target-data-source="targetDataSource" >
        </CategorySave>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
        no-wrap
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BModal, BOverlay, BButton, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import CategorySave from '@/components/category-save/CategorySave.vue'
import { Flag } from '@/const/flag'

export default {
  name: 'DepartmentTypeSave',
  components: {
    BFormInput,
    ValidationProvider,
    BFormGroup,
    CategorySave,
    BModal,
    BForm,
    BOverlay,
    BButton,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dataSource: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetDataSource: { status: Flag.ACTIVE, orderNo: 1 },
      required,
    }
  },
  computed: {
    ...mapGetters({
      maxOrderNo: 'departmentType/maxOrderNo',
    }),
    isCreated() {
      return !this.dataSource
    },
  },
  methods: {
    ...mapActions({
      createDataSource: 'departmentType/createDataSource',
      updateDataSource: 'departmentType/updateDataSource',
      getMaxOrderNo: 'departmentType/getMaxOrderNo',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if ( this.dataSource ) {
        this.targetDataSource = { ...this.dataSource }
      } else {
        await this.getMaxOrderNo()
        this.targetDataSource.orderNo = this.maxOrderNo + 1
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetDataSource = { status: Flag.ACTIVE, orderNo: 1 }
    },
    async onSave( type = null ) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if ( valid ) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createDataSource( this.targetDataSource )
            : await this.updateDataSource( this.targetDataSource )
          if ( response ) {
            const { isSuccessful, message } = response
            if ( isSuccessful ) {
              this.$toast( {
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              } )
              if ( type === 'hide' ) {
                this.$bvModal.hide( 'saveModal' )
              }
              this.$emit( 'succeed' )
            } else {
              this.$toast( {
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              } )
            }
          }
        } catch ( e ) {
          this.$toast( {
            component: ToastificationContent,
            props: {
              title: `[${ e.code }] ${ e.message }`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          } )
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
