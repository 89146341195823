import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import {STATUSES} from '@/const/status'

export default {
  namespaced: true,
  state: {
    courses: [],
    trainingCourses: [],
    total: 0,
    trainingTotal: 0,
    statuses: STATUSES,
    maxOrderNo: 0,
    scoringFormulas: [],
  },
  getters: {
    courses: state => state.courses,
    total: state => state.total,
    statuses: state => state.statuses,
    maxOrderNo: state => state.maxOrderNo,
    scoringFormulas: state => state.scoringFormulas,
  },
  mutations: {
    SET_COURSES: (state, courses) => { state.courses = courses },
    SET_TOTAL: (state, total) => { state.total = total },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => {
      state.maxOrderNo = maxOrderNo
    },
    SET_SCORING_FORMULAS: (state, data) => {
      state.scoringFormulas = data
    },
  },
  actions: {
    async getCourses({ commit }, params) {
      try {
        const response = await axios.get('uni/courses', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const { total } = data.data
          commit('SET_COURSES', records)
          commit('SET_TOTAL', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createCourse({ commit }, params) {
      try {
        const response = await axios.post('uni/courses', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateCourse({ commit }, params) {
      try {
        const response = await axios.put(`uni/courses/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteCourse({ commit }, id) {
      try {
        const response = await axios.delete(`uni/courses/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCoursesByTrainingSystemId({ commit }, params) {
      try {
        const response = await axios.get('uni/courses/getByTrainingSystemId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_COURSES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getMaxOrderNo({ commit }, params) {
      try {
        const response = await axios.get('uni/courses/max/order-no', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_MAX_ORDER_NO', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getScoringFormulasByOrganization({ commit }, params) {
      try {
        const response = await axios.get('uni/scoringFormulas/getByOrganization', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SCORING_FORMULAS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
