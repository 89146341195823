<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-form>
            <b-row>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Năm học"
                  label-for="yearId"
                >
                  <v-select
                    id="yearId"
                    v-model="yearId"
                    label="name"
                    :options="years"
                    :reduce="option => option.id"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Học kỳ"
                  label-for="semesterId"
                >
                  <v-select
                    id="semesterId"
                    v-model="semesterId"
                    label="name"
                    :options="semesters"
                    :reduce="option => option.id"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="w-100 d-flex justify-content-center">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="getDataSourcesFromStore"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="SearchIcon" /> Tìm kiếm
                    </span>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="mr-1"
                    @click="exportExcel"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="DownloadIcon" /> Xuất excel
                    </span>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    @click="exportPdfFile"
                  >
                    <b-img
                      :src="require('@/assets/images/icons/file-icons/pdf.png')"
                      height="auto"
                      width="12"
                      class="mr-1"
                    />
                    <span class="align-items-center">Xuất PDF</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-form>
          <b-row class="mt-2">
            <b-col cols="12">
              <b-table-simple
                id="semesterExamTable"
                class="table-bordered"
                hover
                small
                responsive
              >
                <b-thead>
                  <b-tr class="text-center">
                    <b-th>Thứ/<br>Ngày</b-th>
                    <b-th>Buổi</b-th>
                    <b-th>Học phần thi</b-th>
                    <b-th>Số phòng</b-th>
                    <b-th>Đơn vị tổ chức thi</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-for="dataSource in dataSources">
                    <template v-for="partOfDay in dataSource.partOfDays">
                      <template v-for="department in partOfDay.departments">
                        <b-tr
                          v-for="subject in department.subjects"
                          :key="`${dataSource.date}${partOfDay.name}${subject.idx}`"
                        >
                          <b-td
                            v-if="subject.idx === 0"
                            :rowspan="dataSource.rowSpan"
                            class="text-center font-weight-bolder"
                          >
                            {{ dataSource.day }} <br> {{ dataSource.date }}
                          </b-td>
                          <b-td
                            v-if="partOfDay.rowSpan === 1 || (subject.idx === 0 && partOfDay.rowSpan > 1)"
                            :rowspan="partOfDay.rowSpan"
                            class="text-center font-weight-bolder"
                          >
                            {{ partOfDay.name }}
                          </b-td>
                          <b-td>{{ subject.name }}</b-td>
                          <b-td class="text-center">
                            {{ subject.numberExamRoom }}
                          </b-td>
                          <b-td
                            v-if="department.rowSpan === 1 || (subject.idx === 0 && department.rowSpan > 1)"
                            :rowspan="department.rowSpan"
                            class="text-center font-weight-bolder"
                          >
                            {{ department.name }}
                          </b-td>
                        </b-tr>
                      </template>
                    </template>
                  </template>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </b-container>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BOverlay,
  BRow,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as XLSX from 'xlsx'

export default {
  name: 'SemesterExam',
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    BContainer,
    BFormGroup,
    BCol,
    BRow,
    BForm,
    BButton,
    BCard,
    BOverlay,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BImg,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      yearId: null,
      semesterId: null,
    }
  },
  computed: {
    ...mapGetters({
      years: 'semesterExam/years',
      semesters: 'semesterExam/semesters',
      dataSources: 'semesterExam/semesterExams',
    }),
    currentYear() {
      return new Date().getFullYear()
    },
  },
  async created() {
    await this.begin()
  },
  methods: {
    ...mapMutations({
      setSemesters: 'semesterExam/SET_SEMESTERS',
    }),
    ...mapActions({
      reportSemesterExamBegin: 'semesterExam/reportSemesterExamBegin',
      reportSemesterExam: 'semesterExam/reportSemesterExam',
      exportPdf: 'semesterExam/exportPdf',
    }),
    async begin() {
      this.isLoading = true
      try {
        await this.reportSemesterExamBegin()
        if (this.years.length > 0) {
          const found = this.years.find(year => year.endYear === this.currentYear)
          this.yearId = found ? found.id : this.years[0].id
        }
        if (this.semesters.length > 0) {
          this.semesterId = this.semesters[0].id
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.reportSemesterExam({
          yearId: this.yearId,
          semesterId: this.semesterId,
          organizationId: this.user.orgId,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    exportExcel() {
      if (this.dataSources.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      const table = document.getElementById('semesterExamTable')
      const wb = XLSX.utils.table_to_book(table)
      XLSX.writeFile(wb, 'SheetJSTable.xlsx')
    },
    async exportPdfFile() {
      if (this.dataSources.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất file!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        const data = await this.exportPdf({
          yearId: this.yearId,
          semesterId: this.semesterId,
          organizationId: this.user.orgId,
        })
        if (data) {
          const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }))
          window.open(url, '_blank')
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File không có dữ liệu',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
