<template>
  <validation-observer
    ref="resourceSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="credentialProcessTypeSaveModal"
      ref="credentialProcessTypeSaveModal"
      body-class="position-static"
      centered
      size="l"
      title="Thêm loại yêu cầu"
      :no-close-on-backdrop="true"
      @hide="onHide"
      @show="onShow"
    >
      <b-row>
        <b-col cols="12">
          <b-form
            ref="formRef"
            :style="{height: trHeight}"
          >
            <b-row
              v-for="(item, index) in certificateProcessTypeIds"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col md="10">
                <b-form-group label-for="certificateProcessTypeId">
                  <template v-slot:label>
                    Loại yêu cầu <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Loại yêu cầu"
                    rules="required"
                  >
                    <v-select
                      id="certificateProcessTypeId"
                      v-model="item.certificateProcessTypeId"
                      label="name"
                      :options="certificateProcessTypes"
                      :reduce="option => option.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                lg="2"
                md="1"
                class="mb-50"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <span>Xóa</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="repeatAgain"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Thêm mới</span>
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="invalid || certificateProcessTypeIds.length === 0"
            variant="primary"
            class="mr-1"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('credentialProcessTypeSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import { required } from '@core/utils/validations/validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CredentialProcessType',
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BOverlay,
    BButton,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [heightTransition],
  props: {
    idCredential: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      certificateProcessTypeIds: [{
        id: 1,
        certificateProcessTypeId: null,
      }],
      nextId: 2,
      required,
      filterGetAll: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'role/permissionOptions',
      resources: 'role/resourcesOptions',
      certificateProcessTypes: 'certificateProcessType/certificateProcessTypes',
    }),
  },
  mounted() {
    this.initTrHeight()
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
    await this.readCertificateProcessTypes(this.filterGetAll)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions({
      readCertificateProcessTypes: 'certificateProcessType/readCertificateProcessTypes',
      createCredentialProcessTypes: 'credentialProcessType/createCredentialProcessTypes',
    }),
    async onShow() {
      await this.readCertificateProcessTypes(this.filterGetAll)
    },
    repeatAgain() {
      this.certificateProcessTypeIds.push({
        id: this.nextId += this.nextId,
        certificateProcessTypeId: null,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.certificateProcessTypeIds.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.assignPermissionResourceModalRef.scrollHeight)
      })
    },
    onHide() {
      this.certificateProcessTypeIds = [{
        id: 1,
        certificateProcessTypeId: null,
      }]
      this.nextId = 2
    },
    async onSave(type = null) {
      const valid = this.$refs
        .resourceSaveFormRef
        .validate()
      if (valid && this.certificateProcessTypeIds.length > 0) {
        this.isLoading = true
        try {
          const response = await this.createCredentialProcessTypes({
            id: this.idCredential,
            params: { certificateProcessTypeIds: this.certificateProcessTypeIds },
          })
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('credentialProcessTypeSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
