import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch, mapDropdown } from '@/utils'
import { CREDIT_CLASS_TEACHER_STATUSES } from '@/const/status'
import { defaultParams } from '@/store/common/dropdown'

export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    statuses: CREDIT_CLASS_TEACHER_STATUSES,
    resourceName: {
      fullName: 'Giảng viên chủ nhiệm',
      shortName: 'Giảng viên chủ nhiệm',
    },
    departments: [],
    teachers: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
    departments: state => state.departments,
    teachers: state => state.teachers,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_DEPARTMENTS: (state, records) => {
      state.departments = records
    },
    SET_TEACHERS: (state, records) => {
      state.teachers = records
    },
  },
  actions: {
    async getTeachers({ commit }, parameters) {
      try {
        const ownParams = { departmentId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get('uni/teachers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_TEACHERS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDepartments({ commit }, parameters) {
      try {
        const ownParams = { organizationId: '', leaderId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get('uni/departments/getByOrganizationId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DEPARTMENTS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/teacherClasses', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createData(commit, params) {
      try {
        const response = await axios.post('uni/teacherClasses', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`uni/teacherClasses/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`uni/teacherClasses/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
