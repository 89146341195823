import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    turns: [],
    total: 0,
  },
  getters: {
    turns: state => state.turns,
    total: state => state.total,
  },
  mutations: {
    SET_TURNS: (state, turns) => { state.turns = turns },
    SET_TOTAL: (state, total) => { state.total = total },
  },

  actions: {
    async getTurns({ commit }) {
      try {
        const response = await axios.get('uni/turns/get_all_turn')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const records = data.data
          commit('SET_TURNS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
