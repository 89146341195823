<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-group label-for="name-trainingSystem">
            <template v-slot:label>
              Tên chương trình đào tạo/Bồi dưỡng <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Tên chương trình đào tạo/Bồi dưỡng"
              rules="required"
            >
              <b-form-input
                id="name-trainingSystem"
                v-model="dataTrainingSystem.name"
                name="nameTrainingSystem"
                placeholder="Tên chương trình đào tạo/Bồi dưỡng"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label-for="trainingForms">
            <template v-slot:label>
              Loại hình đào tạo <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Loại hình đào tạo"
              rules="required"
            >
              <v-select
                v-model="dataTrainingSystem.trainingFormId"
                :options="optTrainingForms"
                :reduce="option => option.value"
                label="text"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group label-for="yearNum">
            <template v-slot:label>
              Thời gian đào tạo (tháng) <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Thời gian đào tạo (tháng)"
              rules="required|positive_integer"
            >
              <b-form-input
                id="yearNum"
                v-model="dataTrainingSystem.yearNum"
                name="yearNum"
                placeholder="Thời gian đào tạo (tháng)"
                :state="getElementState(errors)"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Số thứ tự"
            label-for="status"
          >
            <b-form-input
              id="order_no"
              v-model="dataTrainingSystem.orderNo"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Trạng thái"
            label-for="status"
          >
            <b-form-select
              id="status"
              v-model="dataTrainingSystem.status"
              :options="optionsStatus"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <div class="float-left">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="handleSaveData"
            >
              Lưu
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, positive_integer } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'FormTrainingSystem',
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    vSelect
  },
  props: {
    dataTrainingSystem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formErr: {
        nameTrainingSystem: '',
        maHocKy: '',
      },
      optionsStatus: [],
      optTrainingForms: [],
      optOrganizations: [],
      required,
      positive_integer,
    }
  },

  async created() {
    // init data FROM API
    await this.getInit()
    this.optTrainingForms = this.trainingForms.map(item => ({ value: item.id, text: item.name }))
    if (!this.dataTrainingSystem.trainingForm) {
      this.dataTrainingSystem.trainingForm = this.optTrainingForms[0].value
    }
    this.optionsStatus = this.statuses.map(item => ({ value: item.id, text: item.name }))
    if (!this.dataTrainingSystem.status) {
      this.dataTrainingSystem.status = 1
    }
  },

  computed: {
    // calculate data from init data
    ...mapGetters({
      trainingForms: 'trainingSystem/trainingForms',
      statuses: 'trainingSystem/statuses',
    }),
  },
  methods: {
    ...mapActions({
      getInit: 'trainingSystem/getInit',
    }),
    // handle even
    handleSaveData(e) {
      const data = this.dataTrainingSystem
      this.$emit('saveDataTrainingSystem', data)
      e.preventDefault()
    },

    resetForm() {
      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped>

</style>
