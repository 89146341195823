<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >

    <b-modal
        id="timeResearchConversionCriteriaSaveModal"
        body-class="position-static"
        centered
        :title="isCreated ? 'Thêm mới dữ liệu' : 'Cập nhật dữ lệu'"
        no-close-on-backdrop
        size="lg"
        @show="onShow"
        @hidden="onHide"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên tiêu chí<span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Nhập tên tiêu chí"
                  rules="required"
              >
                <b-form-input
                    id="name"
                    v-model="targetItem.name"
                    placeholder="Nhập số giờ chuẩn"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="timeResearchConversionCriteriaTypeId">
              <template v-slot:label>
                Loại tiêu chí <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Tên loại tiêu chí"
                  rules="required"
              >
                <v-select
                    v-model="targetItem.timeResearchConversionCriteriaTypeId"
                    :options="timeResearchConversionCriteriaTypes"
                    label="name"
                    :reduce="option => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="factResearchRoleId">
              <template v-slot:label>
                Chức vụ áp dụng tiêu chí
              </template>
                <v-select
                    v-model="targetItem.factResearchRoleId"
                    :options="researchRolesOptions"
                    label="name"
                    :reduce="option => option.id"
                />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="timeConversion">
              <template v-slot:label>
                Số giờ quy đổi<span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Nhập số giờ quy đổi"
                  rules="required"
              >
                <b-form-input
                    id="name"
                    v-model="targetItem.timeConversion"
                    placeholder="Nhập số giờ quy đổi"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Trạng thái"
                  rules="required"
              >
                <v-select
                    v-model="targetItem.status"
                    :options="statusOptions"
                    :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" />Lưu lại
            </span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('timeResearchConversionCriteriaSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" />Hủy
            </span>

          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { required } from '@validations'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {STATUSES} from "@/const/status";

export default {
  name: 'TimeResearchConversionCriteriaSaveModal',
  components: {
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        timeResearchConversionCriteriaTypeId: null,
        name: '',
        factResearchRoleId: null,
        timeConversion: null,
        status: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      researchRoles: 'twTimeResearchConversionCriteria/researchRoles',
      timeResearchConversionCriteriaTypes: 'twTimeResearchConversionCriteria/timeResearchConversionCriteriaTypes',
    }),
    statusOptions() {
      return STATUSES
    },
    isCreated() {
      return !this.item
    },
    researchRolesOptions() {
      return [{id: null, name: 'Thành viên đề tài'}, ...this.researchRoles]
    },
  },
  methods: {
    ...mapActions({
      createTimeResearchConversionCriteria: 'twTimeResearchConversionCriteria/createTimeResearchConversionCriteria',
      updateTimeResearchConversionCriteria: 'twTimeResearchConversionCriteria/updateTimeResearchConversionCriteria',
      getResearchRoles: 'twTimeResearchConversionCriteria/getResearchRoles',
      getTimeResearchConversionCriteriaType: 'twTimeResearchConversionCriteria/getTimeResearchConversionCriteriaType',
    }),
    async onSave(type = null) {
      try {
        const response = this.isCreated ? await this.createTimeResearchConversionCriteria(this.targetItem) : await this.updateTimeResearchConversionCriteria(this.targetItem)
        if (response) {
          const {isSuccessful, message} = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('timeResearchConversionCriteriaSaveModal')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async onShow() {
      if (this.item) {
        this.targetItem = {...this.item}
      }
      this.isLoading = true
      try {
        await Promise.all([
          this.getTimeResearchConversionCriteriaType({ currentPage: 1, itemsPerPage: 1000 }),
          this.getResearchRoles({}),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.targetItem = {
        timeResearchConversionCriteriaTypeId: null,
        name: '',
        factResearchRoleId: null,
        timeConversion: null,
        status: 1,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped lang="scss">

</style>
