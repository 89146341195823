<template>
  <validation-observer
    ref="revenuePlanSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="revenuePlanSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm kế hoạch thu' : 'Cập nhật kế hoạch thu'"
      @show="onShow"
      @hide="onHide"
      size="lg"
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group label-for="revenue_id">
          <template v-slot:label>
            Khoản thu <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Khoản thu"
              rules="required"
          >
            <v-select
                v-model="targetRevenuePlan.revenue_id"
                :options="revenueOptions"
                :reduce="option => option.value"
                :disabled="!isCreated"
                @input="onChangeRevenue"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="training_system_id">
          <template v-slot:label>
            Chương trình đào tạo/Bồi dưỡng <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Chương trình đào tạo/Bồi dưỡng"
            rules="required"
          >
            <v-select
              v-model="targetRevenuePlan.training_system_id"
              :options="trainingSystemOptions"
              :reduce="option => option.value"
              :disabled="!isCreated"
              @input="onChangeTrainingSystemSave"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="course_id">
              <template v-slot:label>
                Khóa học <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Khóa học"
                  rules="required"
              >
                <v-select
                    v-model="targetRevenuePlan.course_id"
                    :options="courseOptions"
                    :reduce="option => option.value"
                    :disabled="!isCreated"
                    @input="onChangeCourseSave"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="semester_id">
              <template v-slot:label>
                Kỳ học <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Kỳ học"
                  rules="required"
              >
                <v-select
                    v-model="targetRevenuePlan.semester_id"
                    :options="semesterOptions"
                    :reduce="option => option.value"
                    :disabled="!isCreated"
                    @input="onChangeSemesterSave"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group v-if="code === 'hocphi'" label-for="price">
          <template v-slot:label>
            Số tiền một tín chỉ <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Số tiền một tín chỉ"
              rules="required"
          >
            <b-form-input
                id="price"
                v-model="targetRevenuePlan.price"
                type="number"
                min="0"
                name="price"
                placeholder="Nhập số tiền"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group v-if="code !== 'hocphi'" label-for="total">
          <template v-slot:label>
            Số tiền <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Số tiền khoản thu"
              rules="required"
          >
            <b-form-input
                id="total"
                v-model="targetRevenuePlan.total"
                type="number"
                min="0"
                name="total"
                placeholder="Nhập số tiền"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="start_at">
              <template v-slot:label>
                Thời gian bắt đầu <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Thời gian bắt đầu"
                  rules="required"
              >
                <flat-pickr
                    v-model="targetRevenuePlan.start_at"
                    :config="config"
                    class="form-control"
                    placeholder="Thời gian bắt đầu"
                    name="start_at"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="start_at">
              <template v-slot:label>
                Thời gian kết thúc <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Thời gian kết thúc"
                  rules="required"
              >
                <flat-pickr
                    v-model="targetRevenuePlan.end_at"
                    :config="config"
                    :disabled="!targetRevenuePlan.start_at"
                    class="form-control"
                    placeholder="Thời gian kết thúc"
                    :min-date="targetRevenuePlan.start_at"
                    name="end_at"
                    @on-change="valTime"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('revenuePlanSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BRow, BCol, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/extensions,import/no-extraneous-dependencies
import { vn } from 'flatpickr/dist/l10n/vn.js'
import {getUser} from "@/auth/utils";

export default {
  name: 'RevenuePlanSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BRow,
    BCol,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    revenuePlan: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      code: '',
      targetRevenuePlan: {
        training_system_id: null,
        course_id: null,
        semester_id: null,
        major_id: null,
        revenue_id: null,
        code: '',
        price: null,
        total: null,
        start_at: null,
        end_at: null,
        course_semester_id: null,
        id: null,
      },
      config: {
        wrap: true,
        enableTime: false,
        altInput: true,
        altFormat: 'Y-m-d',
        dateFormat: 'Y-m-d',
        locale: vn,
        // minDate: 'today',
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      allTrainingSystemsSave: 'revenuePlan/allTrainingSystems',
      allMajorsSave: 'revenuePlan/allMajors',
      allRevenuesSave: 'revenuePlan/allRevenues',
      listCoursesSave: 'revenuePlan/listCourses',
      listSemestersSave: 'revenuePlan/listSemesters',
    }),
    isCreated() {
      return this.revenuePlan.id == null
    },
    trainingSystemOptions() {
      return this.allTrainingSystemsSave.map(item => ({ value: item.id, label: item.name }))
    },
    courseOptions() {
      return this.listCoursesSave.map(item => ({ value: item.id, label: item.name }))
    },
    semesterOptions() {
      return this.listSemestersSave.map(item => ({ value: item.id, label: item.name }))
    },
    revenueOptions() {
      return this.allRevenuesSave.map(item => ({ value: item.id, label: item.name }))
    },
  },
  methods: {
    ...mapActions({
      begin: 'revenuePlan/begin',
      createRevenuePlan: 'revenuePlan/createRevenuePlan',
      updateRevenuePlan: 'revenuePlan/updateRevenuePlan',
      getCourses: 'revenuePlan/getCourses',
      getSemesters: 'revenuePlan/getSemesters',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      if (this.revenuePlan) {
        this.targetRevenuePlan = { ...this.revenuePlan }
        this.code = this.revenuePlan.code
      } else {
        this.code = null
      }
      this.isLoading = true
      try {
        await this.begin({ organization_id: getUser().orgId })
        if (this.allTrainingSystemsSave.length > 0) {
          this.targetRevenuePlan.training_system_id = this.allTrainingSystemsSave[0].id
        }
        await this.getCourses({
          training_system_id: this.targetRevenuePlan.training_system_id,
        })
        if (this.listCoursesSave.length > 0) {
          this.targetRevenuePlan.course_id = this.listCoursesSave[0].id
        }
        await this.getSemesters({
          courses_id: this.targetRevenuePlan.course_id,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .revenuePlanSaveFormRef
        .reset()
      this.targetRevenuePlan = {
        training_system_id: null,
        courseId: null,
        semester_id: null,
        major_id: null,
        revenue_id: null,
        price: null,
        start_at: null,
        end_at: null,
        courseSemesterId: null,
        id: null,
        total: null,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .revenuePlanSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createRevenuePlan(this.targetRevenuePlan)
            : await this.updateRevenuePlan(this.targetRevenuePlan)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('revenuePlanSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    valTime(selectedDates, dateStr, instance) {
      if (dateStr !== '') {
        if (dateStr <= this.targetRevenuePlan.start_at) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thời gian kết thúc phải lớn hơn bắt đầu!',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    onChangeTrainingSystemSave() {
      if (this.targetRevenuePlan.training_system_id == null) {
        this.$store.commit('revenuePlan/SET_COURSES', { data: [] })
        this.$store.commit('revenuePlan/SET_SEMESTERS', { data: [] })
        this.targetRevenuePlan.course_id = null
        this.targetRevenuePlan.semester_id = null
        this.targetRevenuePlan.course_semester_id = null
      } else {
        this.getCourses({
          training_system_id: this.targetRevenuePlan.training_system_id,
        })
      }
    },
    onChangeCourseSave() {
      if (this.targetRevenuePlan.course_id == null) {
        this.$store.commit('revenuePlan/SET_SEMESTERS', { data: [] })
        this.targetRevenuePlan.semester_id = null
        this.targetRevenuePlan.course_semester_id = null
      } else {
        this.getSemesters({
          courses_id: this.targetRevenuePlan.course_id,
        })
      }
    },
    onChangeSemesterSave() {
      if (this.targetRevenuePlan.semester_id !== null) {
        const item = this.listSemestersSave.find(x => x.id === this.targetRevenuePlan.semester_id)
        this.targetRevenuePlan.course_semester_id = item.courses_semesters_id
      }
    },
    onChangeRevenue() {
      if (this.targetRevenuePlan.revenue_id !== null) {
        const item = this.allRevenuesSave.find(x => x.id === this.targetRevenuePlan.revenue_id)
        this.code = item.code
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
