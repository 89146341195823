import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { defaultParams } from '@/store/common/dropdown'

export default {
    namespaced: true,
    state: {
        totalRows: 0,
        teacherType: [],
        timeFrameResearch: [],
        departments: [],
        teachers: [],
    },
    getters: {
        totalRows: state => state.totalRows,
        teacherType: state => state.teacherType,
        timeFrameResearch: state => state.timeFrameResearch,
        departments: state => state.departments,
        teachers: state => state.teachers,
    },
    mutations: {
        SET_TOTAL_ROWS: (state, totalRows) => {
            state.totalRows = totalRows
        },
        SET_TEACHER_TYPE: (state, teacherType) => {
            state.teacherType = teacherType
        },
        SET_TIME_FRAME_RESEARCH: (state, timeFrameResearch) => {
            state.timeFrameResearch = timeFrameResearch
        },
        SET_DEPARTMENTS: (state, departments) => {
            state.departments = departments
        },
        SET_TEACHERS: (state, teachers) => {
            state.teachers = teachers
        },
    },
    actions: {
        async getTeachers({ commit }, params) {
            try {
                const response = await axios.get('uni/teachers', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { total, records } = data.data
                    commit('SET_TEACHERS', records)
                    commit('SET_TOTAL_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getDepartments({ commit }, parameters) {
            try {
                const ownParams = { organizationId: '', leaderId: '' }
                const params = {
                    ...defaultParams,
                    ...ownParams,
                    ...parameters,
                }
                const response = await axios.get('uni/departments', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_DEPARTMENTS', data.data.records)
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async getTeacherType({ commit }, params) {
            try {
                const response = await axios.get('uni/fact_teacher_type/getAllActive', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_TEACHER_TYPE', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async readTimeFrameResearch({ commit }, params) {
            try {
                const response = await axios.get('uni/tw_time_frame_research', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { total, records } = data.data
                    commit('SET_TIME_FRAME_RESEARCH', records)
                    commit('SET_TOTAL_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async createTimeFrameResearch({ commit }, params) {
            try {
                const response = await axios.post('uni/tw_time_frame_research', params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async updateTimeFrameResearch({ commit }, params) {
            try {
                const response = await axios.put(`uni/tw_time_frame_research/${params.id}`, params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async deleteTimeFrameResearch({ commit }, id) {
            try {
                const response = await axios.delete(`uni/tw_time_frame_research/${id}`)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
    },
}
