import { STATUSES } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch, mapDropdown } from '@/utils'
import { defaultParams } from '@/store/common/dropdown'

export default {
  namespaced: true,
  state: {
    statuses: STATUSES,
    totalRows: 0,
    practicalResearchStudentsCheckScore: [],
    trainingSystems: [],
    courses: [],
    departments: [],
    majors: [],
    classes: [],
    teachers: [],
  },
  getters: {
    statuses: state => state.statuses,
    totalRows: state => state.totalRows,
    practicalResearchStudentsCheckScore: state => state.practicalResearchStudentsCheckScore,
    trainingSystems: state => state.trainingSystems,
    teachers: state => state.teachers,
    courses: state => state.courses,
    departments: state => state.departments,
    majors: state => state.majors,
    classes: state => state.classes,
  },
  mutations: {
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
    SET_PRACTICAL_RESEARCH_STUDENTS_CHECK_SCORE: (state, data) => {
      state.practicalResearchStudentsCheckScore = data
    },
    SET_TRAINING_SYSTEMS: (state, records) => {
      state.trainingSystems = records
    },
    SET_TEACHERS: (state, records) => {
      state.teachers = records
    },
    SET_COURSES: (state, records) => {
      state.courses = records
    },
    SET_DEPARTMENTS: (state, records) => {
      state.departments = records
    },
    SET_MAJORS: (state, records) => {
      state.majors = records
    },
    SET_CLASSES: (state, records) => {
      state.classes = records
    },
  },
  actions: {
    async readPracticalResearchStudentCheckScore({ commit }, params) {
      try {
        const response = await axios.get('uni/practical_research_check_score', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_PRACTICAL_RESEARCH_STUDENTS_CHECK_SCORE', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updatePracticalResearchCheckScore({ commit }, params) {
      try {
        const response = await axios.put('uni/practical_research_check_score', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getTrainingSystems({ commit }, parameters) {
      try {
        const ownParams = { organizationId: null, trainingFormId: '', sort: '1_asc' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.trainingSystem, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_TRAINING_SYSTEMS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCourses({ commit }, parameters) {
      try {
        const ownParams = { trainingSystemId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(`${ApiExtRoutes.course}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_COURSES', mapData)
          const mapDataYear = records.map(item => ({
            value: item.id,
            label: item.startDate.substring(0, 4),
          }))
          commit('SET_COURSE_YEARS', mapDataYear)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDepartments({ commit }, parameters) {
      try {
        const ownParams = { organizationId: '', leaderId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.department, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_DEPARTMENTS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMajors({ commit }, parameters) {
      try {
        const ownParams = { departmentId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(`${ApiExtRoutes.major}`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_MAJORS', mapData)
          const mapDataCode = records.map(item => ({
            value: item.id,
            label: item.code,
          }))
          commit('SET_MAJOR_CODES', mapDataCode)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getClasses({ commit }, parameters) {
      try {
        const ownParams = { majorId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get(ApiExtRoutes.class, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          // const mapData = mapDropdown(records)
          const mapData = records.map(item => ({
            value: item.id,
            label: item.name,
            capacity: item.capacity,
          }))
          commit('SET_CLASSES', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTeachersByOrganizationId({ commit }) {
      try {
        const response = await axios.get('uni/teachers/getByOrganizationId')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TEACHERS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
