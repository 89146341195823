<template>
  <validation-observer
    ref="saveModalRef"
    #default="{invalid}"
  >
    <b-modal
      id="teacherQuotaSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm định mức giờ giảng' : 'Cập nhật định mức giờ giảng'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="year">
          <template v-slot:label>
            Năm <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Năm"
            :rules="{ required: true, regex: /^[0-9]{4}$/ }"
          >
            <b-form-input
              id="year"
              v-model="targetItem.year"
              name="year"
              type="number"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="department">
          <template v-slot:label>
            Khoa/bộ môn <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Khoa/bộ môn"
            rules="required"
          >
            <v-select
              label="name"
              v-model="targetItem.departmentId"
              :options="departments"
              :reduce="option => option.id"
              :disabled="!isCreated"
              @input="onChangeDepartments"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="teacherId">
          <template v-slot:label>
            Giảng viên <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Giảng viên"
            rules="required"
          >
            <v-select
              label="name"
              v-model="targetItem.teacherId"
              :options="teachers"
              :reduce="option => option.id"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="quotaHours">
          <template v-slot:label>
            Số định mức giờ giảng
          </template>
          <b-form-input
            id="quotaHours"
            v-model="targetItem.quotaHours"
            name="quotaHours"
            type="number"
          />
        </b-form-group>

      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('teacherQuotaSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@core/utils/validations/validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'

export default {
  name: 'TeacherSave',
  components: {
    BFormInput,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        status: 1,
        year: null,
        departmentId: null,
        teacherId: null,
        quotaHours: 0,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      departments: 'twTeacherQuota/departments',
      teachers: 'twTeacherHour/teachers',
    }),
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createTeachers: 'twTeacherQuota/createTeacherQuotas',
      updateTeachers: 'twTeacherQuota/updateTeacherQuotas',
      getDepartment: 'twTeacherQuota/getDepartments',
      readTeachers: 'twTeacherHour/getTeachers',
    }),
    async onChangeDepartments(id) {
      await this.readTeachers({
        currentPage: 1,
        itemsPerPage: 1000,
        departmentId: id,
        status: 1,
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      try {
        await Promise.all([
          this.getDepartment({
            currentPage: 1,
            itemsPerPage: 1000,
            status: 1,
            organizationId: this.user.orgId,
          }),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
      if (this.item) {
        this.targetItem = this.item
        await this.onChangeDepartments()
      }
    },
    onHide() {
      this.$refs
        .saveModalRef
        .reset()
      this.targetItem = {
        status: 1,
        year: null,
        departmentId: null,
        teacherId: null,
        quotaHours: 0,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveModalRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createTeachers(this.targetItem)
            : await this.updateTeachers(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('teacherQuotaSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
