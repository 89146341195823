import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/practicalResearchProgram',
    name: 'practicalResearchProgram',
    component: () => import('@/views/practical-research-program/PracticalResearchProgram.vue'),
    meta: {
      pageTitle: 'Quản lý chương trình nghiên cứu thực tế',
      breadcrumb: [
        {
          text: 'Nghiên cứu thực tế',
          active: false,
        },
        {
          text: 'Quản lý chương trình nghiên cứu thực tế',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.PRACTICAL_RESEARCH_PROGRAM,
    },
  },
  {
    path: '/practicalResearchTopic',
    name: 'practicalResearchTopic',
    component: () => import('@/views/practical-research-topic/PracticalResearchTopic.vue'),
    meta: {
      pageTitle: 'Quản lý đề tài nghiên cứu thực tế',
      breadcrumb: [
        {
          text: 'Nghiên cứu thực tế',
          active: false,
        },
        {
          text: 'Quản lý đề tài nghiên cứu thực tế',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.PRACTICAL_RESEARCH_TOPIC,
    },
  },
  {
    path: '/practicalResearchGroup',
    name: 'practicalResearchGroup',
    component: () => import('@/views/practical-research-class-group/PracticalResearchClassGroup.vue'),
    meta: {
      pageTitle: 'Danh sách đoàn nghiên cứu thực tế của lớp',
      breadcrumb: [
        {
          text: 'Nghiên cứu thực tế',
          active: false,
        },
        {
          text: 'Danh sách đoàn nghiên cứu thực tế',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.PRACTICAL_RESEARCH_GROUP,
    },
  },
  {
    path: '/practicalResearchScore',
    name: 'practicalResearchScore',
    component: () => import('@/views/practical-research-score/PracticalResearchScore.vue'),
    meta: {
      pageTitle: 'Nhập điểm cho học viên đi nghiên cứu thực tế',
      breadcrumb: [
        {
          text: 'Nghiên cứu thực tế',
          active: false,
        },
        {
          text: 'Nhập điểm cho học viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.PRACTICAL_RESEARCH_SCORE,
    },
  },
  {
    path: '/practicalResearchCheckScore',
    name: 'practicalResearchCheckScore',
    component: () => import('@/views/practical-research-check-score/PracticalResearchCheckScore.vue'),
    meta: {
      pageTitle: 'Phúc khảo điểm cho học viên đi nghiên cứu thực tế',
      breadcrumb: [
        {
          text: 'Nghiên cứu thực tế',
          active: false,
        },
        {
          text: 'Phúc khảo điểm cho học viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.PRACTICAL_RESEARCH_CHECK_SCORE,
    },
  },
]
