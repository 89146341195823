<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label="Chương trình đào tạo/Bồi dưỡng"
                  label-for="trainingSystemSelected"
                >
                  <v-select
                    v-model="trainingSystemSelected"
                    :options="allTrainingSystems"
                    :reduce="(option) => option.id"
                    label="name"
                    @input="onChangeTrainingSystem"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label="Khóa học"
                  label-for="courseSelected"
                >
                  <v-select
                    v-model="courseSelected"
                    :options="listTrainingSystemCourses"
                    :reduce="(option) => option.id"
                    label="name"
                    @input="onChangeCourse"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                <b-form-group
                  label="Lớp học"
                  label-for="classSelect"
                >
                  <v-select
                    v-model="classSelect"
                    :options="listCourseClasses"
                    :reduce="(option) => option.id"
                    label="name"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    @click="onSelect"
                  >
                    <span class="text-nowrap text-right"><feather-icon icon="ListIcon"/> Danh sách</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="mr-1"
                    @click="exportBangXetTotNghiep"
                  >
                    <span class="text-nowrap text-right"><feather-icon icon="DownloadIcon" /> Xuất excel xét TN</span>
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="mr-1"
                    @click="exportBangXetDieuKienThiTotNghiep"
                  >
                    <span class="text-nowrap text-right"><feather-icon icon="DownloadIcon" /> Xuất excel xét điều kiện thi TN </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div style="overflow: auto;">
                  <b-table
                    ref="table"
                    :small="true"
                    :hover="true"
                    :items="itemsTable"
                    :fields="fieldsTable"
                  >
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer, BFormCheckbox,
  BFormGroup,
  BOverlay,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'GraduationConditionScore',
  directives: {
    Ripple,
  },
  components: {
    BFormCheckbox,
    BTable,
    BButton,
    BFormGroup,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    vSelect,
  },
  data() {
    return {
      trainingSystemSelected: undefined,
      courseSelected: undefined,
      majorSelected: undefined,
      classSelect: undefined,
      firstExam: 0,
      isLoading: false,
      fieldsTable: [
        'Mã học viên',
        'Họ và tên',
        'Ngày, tháng, năm sinh',
        'Quê quán',
        'Trung bình thi tốt nghiệp',
        'Khóa luận tốt nghiệp',
        'Tổng điểm trung bình toàn khóa',
        'Xếp loại học tập',
        'Điểm rèn luyện',
        'Xếp loại rèn luyện',
        'Ghi chú',
      ],
      itemsTable: [],
    }
  },
  computed: {
    ...mapGetters({
      dataLists: 'graduationConditionScoreClass/dataLists',
      allYears: 'graduationConditionScoreClass/allYears',
      allTrainingSystems: 'graduationConditionScoreClass/allTrainingSystems',
      listTrainingSystemCourses: 'graduationConditionScoreClass/listTrainingSystemCourses',
      listCourseMajors: 'graduationConditionScoreClass/listCourseMajors',
      listCourseClasses: 'graduationConditionScoreClass/listCourseClasses',
      listYearSemesters: 'graduationConditionScoreClass/listYearSemesters',
      listSubjectsOrder: 'graduationConditionScoreClass/listSubjectsOrder',
      dataExport: 'graduationConditionScoreClass/dataExport',
      dataConditionExamExport: 'graduationConditionScoreClass/dataConditionExamExport',
    }),
  },
  async created() {
    this.isLoading = true
    try {
      await this.begin()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      begin: 'graduationConditionScoreClass/begin',
      getData: 'graduationConditionScoreClass/getData',
      getListTrainingSystemCourses:
          'graduationConditionScoreClass/getListTrainingSystemCourses',
      getListCourseMajors: 'graduationConditionScoreClass/getListCourseMajors',
      getListCourseClasses: 'graduationConditionScoreClass/getListCourseClasses',
      getListSubjectsOrder: 'graduationConditionScoreClass/getListSubjectsOrder',
      getListYearSemesters: 'graduationConditionScoreClass/getListYearSemesters',
      resetData: 'graduationConditionScoreClass/resetData',
      getDataExport: 'graduationConditionScoreClass/downloadExport',
      getDataConditionExamExport: 'graduationConditionScoreClass/downloadConditionExamExport',
    }),

    async onChangeTrainingSystem() {
      if (this.trainingSystemSelected === null) {
        this.reset()
        this.$store.commit('graduationConditionScoreClass/SET_LISTTRAININGSYSTEMCOURSES', {
          data: [],
        })
        this.$store.commit('graduationConditionScoreClass/SET_LISTCOURSEMAJORS', {
          data: [],
        })
        this.$store.commit('graduationConditionScoreClass/SET_LISTCOURSECLASSES', {
          data: [],
        })
        this.courseSelected = null
        this.majorSelected = null
        this.classSelect = null
      } else {
        this.isLoading = true
        try {
          await this.getListTrainingSystemCourses({
            training_system_id: this.trainingSystemSelected,
          })
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    async onChangeCourse() {
      if (this.courseSelected == null) {
        this.reset()
        this.$store.commit('graduationConditionScoreClass/SET_LISTCOURSEMAJORS', {
          data: [],
        })
        this.$store.commit('graduationConditionScoreClass/SET_LISTCOURSECLASSES', {
          data: [],
        })
        this.majorSelected = null
        this.classSelect = null
      } else {
        this.isLoading = true
        try {
          await this.getListCourseClasses({ course_id: this.courseSelected })
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },

    reset() {
      this.$store.commit('graduationConditionScoreClass/SET_DATA', { data: [] })
      this.itemsTable = this.dataLists
      this.$refs.table.refresh()
      this.$store.commit('graduationConditionScoreClass/SET_LISTSUBJECTSORDER', {
        data: null,
      })
      this.fieldsTable = [
        'Mã học viên',
        'Họ và tên',
        'Ngày, tháng, năm sinh',
        'Quê quán',
        'Trung bình thi tốt nghiệp',
        'Khóa luận tốt nghiệp',
        'Tổng điểm trung bình toàn khóa',
        'Xếp loại học tập',
        'Điểm rèn luyện',
        'Xếp loại rèn luyện',
        'Ghi chú',
      ]
    },
    async onSelect() {
      if (!this.trainingSystemSelected) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn chương trình đào tạo/Bồi dưỡng!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (!this.courseSelected) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn khóa học!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (!this.classSelect) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn lớp!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        await this.getListSubjectsOrder({
          class_id: this.classSelect,
        })
        this.fieldsTable = [
          'Mã học viên',
          'Họ và tên',
          'Ngày, tháng, năm sinh',
          'Quê quán',
          'Trung bình thi tốt nghiệp',
          'Khóa luận tốt nghiệp',
          'Tổng điểm trung bình toàn khóa',
          'Xếp loại học tập',
          'Điểm rèn luyện',
          'Xếp loại rèn luyện',
          'Ghi chú',
        ]
        if (this.listSubjectsOrder !== null) {
          // eslint-disable-next-line prefer-spread
          this.fieldsTable.splice.apply(
            this.fieldsTable,
            [4, 0].concat(this.listSubjectsOrder.split(';')),
          )
          try {
            await this.getData({
              class_id: this.classSelect,
            })
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
          this.itemsTable = this.dataLists
        } else {
          this.reset()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Không có học phần nào!',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportBangXetTotNghiep() {
      if (this.itemsTable.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất báo cáo!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        await this.getDataExport({
          class_id: this.classSelect,
        })
        const a = document.createElement('a')
        document.body.appendChild(a)
        const blob = new Blob([this.dataExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'bang_diem_xet_tot_nghiep.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportBangXetDieuKienThiTotNghiep() {
      if (this.itemsTable.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất báo cáo!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.isLoading = true
      try {
        await this.getDataConditionExamExport({
          class_id: this.classSelect,
        })
        const a = document.createElement('a')
        document.body.appendChild(a)
        const blob = new Blob([this.dataConditionExamExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'bang_diem_xet_dieu_kien_thi_tot_nghiep.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>
