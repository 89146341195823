<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >

    <b-modal
        id="practicalResearchTopicStudentSaveModal"
        body-class="position-static"
        centered
        :title="'Gán đề tài nghiên cứu thực tế'"
        no-close-on-backdrop
        size="l"
        @show="onShow"
        @hidden="onHide"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group label-for="practicalResearchTopicId">
              <template v-slot:label>
                Đề tài nghiên cứu thực tế <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Đề tài nghiên cứu thực tế"
                  rules="required"
              >
                <v-select
                    v-model="targetItem.practicalResearchTopicId"
                    label="name"
                    :options="practicalResearchTopics"
                    :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" />Lưu lại
            </span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('practicalResearchTopicStudentSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" />Hủy
            </span>

          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BModal, BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { required } from '@validations'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'PracticalResearchTopicStudent',
  components: {
    BFormInvalidFeedback,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BButton,
    vSelect,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    students: {
      type: Array,
      default: () => [],
    },
    dataGroup: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        practicalResearchTopicId: null,
        practicalResearchClassGroupId: null,
        studentClassGroupIds: [],
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statues: 'practicalResearchClassStudent/statuses',
      practicalResearchTopics: 'practicalResearchClassStudent/practicalResearchTopics',
    }),
    statusOptions() {
      return this.statues
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      updateStudentTopicPracticalResearchClass: 'practicalResearchClassStudent/updateStudentTopicPracticalResearchClass',
      getPracticalResearchTopic: 'practicalResearchClassStudent/getPracticalResearchTopic',
    }),
    async onSave(type = null) {
      try {
        const response = await this.updateStudentTopicPracticalResearchClass(this.targetItem)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('practicalResearchTopicStudentSaveModal')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async onShow() {
      if (this.students) {
        this.targetItem.studentClassGroupIds = this.students
      }
      if (this.dataGroup){
        this.targetItem.practicalResearchClassGroupId = this.dataGroup.id
      }
      this.isLoading = true
      try {
        await this.getPracticalResearchTopic({
          currentPage: 1,
          itemsPerPage: 1000,
          practicalResearchProgramId: this.dataGroup.practicalResearchProgramId,
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.targetItem = {
        practicalResearchTopicId: null,
        practicalResearchClassGroupId: null,
        studentClassGroupIds: [],
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped lang="scss">

</style>
