import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataLists: [],
  },
  getters: {
    dataLists: state => state.dataLists,
  },
  mutations: {
    SET_DATA: (state, { records }) => {
      state.dataLists = records
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/graduations/graduation-class', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_DATA', {
            records: response.data.data,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async exportPdfAcademicAndConductResults({ commit }, params) {
      try {
        const response = await axios.get('uni/graduations/exportPdfAcademicAndConductResults', { params, responseType: 'blob' })
        if (response.status === 200) {
          return response.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
