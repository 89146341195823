import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/twTimeFrameResearch',
    name: 'twTimeFrameResearch',
    component: () => import('@/views/teacher-workload/time-research/tw-time-frame-research/TimeFrameResearch.vue'),
    meta: {
      pageTitle: 'Khung định mức giờ chuẩn nghiên cứu khoa học',
      breadcrumb: [
        {
          text: 'Quản lý giờ nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Khung định mức giờ chuẩn nghiên cứu khoa học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TW_TIME_FRAME_RESEARCH,
    },
  },
  {
    path: '/twTimeQuotaResearch',
    name: 'twTimeQuotaResearch',
    component: () => import('@/views/teacher-workload/time-research/tw-time-quota-research/TimeQuotaResearch.vue'),
    meta: {
      pageTitle: 'Định mức giờ chuẩn nghiên cứu khoa học',
      breadcrumb: [
        {
          text: 'Quản lý giờ nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Định mức giờ chuẩn nghiên cứu khoa học',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TW_TIME_QUOTA_RESEARCH,
    },
  },
  {
    path: '/twTimeResearchConversionCriteriaType',
    name: 'twTimeResearchConversionCriteriaType',
    component: () => import('@/views/teacher-workload/time-research/tw-time-research-conversion-criteria-type/TimeResearchConversionCriteriaType.vue'),
    meta: {
      pageTitle: 'Loại tiêu chí quy đổi giờ NCKH',
      breadcrumb: [
        {
          text: 'Quản lý giờ nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Loại tiêu chí quy đổi giờ NCKH',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TW_TIME_RESEARCH_CONVERSION_CRITERIA_TYPE,
    },
  },
  {
    path: '/twTimeResearchConversionCriteria',
    name: 'twTimeResearchConversionCriteria',
    component: () => import('@/views/teacher-workload/time-research/tw-time-research-conversion-criteria/TimeResearchConversionCriteria.vue'),
    meta: {
      pageTitle: 'Tiêu chí quy đổi giờ NCKH',
      breadcrumb: [
        {
          text: 'Quản lý giờ nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Tiêu chí quy đổi giờ NCKH',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TW_TIME_RESEARCH_CONVERSION_CRITERIA,
    },
  },
  {
    path: '/twTimeResearchTeacher',
    name: 'twTimeResearchTeacher',
    component: () => import('@/views/teacher-workload/time-research/tw_time_research_teacher/TimeResearchTeacher.vue'),
    meta: {
      pageTitle: 'Giờ NCKH của giảng viên',
      breadcrumb: [
        {
          text: 'Quản lý giờ nghiên cứu khoa học',
          active: false,
        },
        {
          text: 'Giờ NCKH của giảng viên',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.TW_TIME_RESEARCH_TEACHER,
    },
  },
]
