import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUS_SYMBOLS } from '@/const/status'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.maintenanceBackup,
})
export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    url: '',
    statuses: STATUS_SYMBOLS,
    resourceName: {
      fullName: 'Sao lưu hệ thống',
      shortName: 'Sao lưu',
    },
    maxOrderNo: 0,
    countStorageUsed: 0,
    dataGrowths: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    resourceName: state => state.resourceName,
    countStorageUsed: state => state.countStorageUsed,
    dataGrowths: state => state.dataGrowths,
    url: state => state.url,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_STORAGE_USED: (state, countStorageUsed) => {
      state.countStorageUsed = countStorageUsed
    },
    SET_DATA_GROWTH: (state, dataGrowths) => {
      state.dataGrowths = dataGrowths
    },
    SET_URL: (state, data) => { state.url = data },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get(baseAttr.basePath, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getStorageChart({ commit }) {
      try {
        const response = await axios.get('recoveries/maintenance/backups/storageChart')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data
          commit('SET_STORAGE_USED', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataGrowth({ commit }, params) {
      try {
        const response = await axios.get('recoveries/maintenance/backups/dataGrowthChart', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { dataGrowthChart } = data.data
          commit('SET_DATA_GROWTH', dataGrowthChart)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createData({ commit }, params) {
      try {
        const response = await axios.post(baseAttr.basePath, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`${baseAttr.basePath}/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async downloadFile({ commit }, params) {
      try {
        const response = await axios.get(`${baseAttr.basePath}/download`, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_URL', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
