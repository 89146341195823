<template>
  <div>
    <b-container fluid>
      <b-card no-body class="mb-md-0">
        <b-overlay
            :show="isLoading"
            rounded="sm"
        >
          <div class="mx-2 my-1">
            <b-row>
              <b-col cols="12"><h3>{{modalTitle}}</h3></b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="12">
                <vue-good-table
                    mode="remote"
                    class="my-table"
                    row-style-class="vgt-row"
                    styleClass="vgt-table striped bordered"
                    :columns="columns"
                    :rows="mainData"
                    :pagination-options="paginationOptions"
                    :total-rows="totalRows"
                    @on-page-change="onPageChange"
                    @on-sort-change="onSortChange"
                    @on-column-filter="onColumnFilter"
                    @on-per-page-change="onPerPageChange"
                    max-height="350px"
                >
                  <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template slot="table-row" slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'classIndex'">
                      {{ getPlanClassCode(props.row.classIndex) }}
                    </span>
                    <span v-else-if="props.column.field === 'action'" class="thead-group-action">
                      <b-button-group>
                        <b-button
                            v-b-modal.diplomaStudentClassSaveModal
                            variant="primary"
                            class="btn-icon"
                            size="sm"
                            title="Chỉnh sửa"
                            @click="onEditMainData(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                        <b-button
                            variant="danger"
                            class="btn-icon"
                            size="sm"
                            title="Xóa"
                            @click="onDeleteMainData(props.row)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </b-button-group>
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                            v-model="filter.itemsPerPage"
                            :options="itemsPerPageOptions"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                            :value="1"
                            :total-rows="totalRows"
                            :per-page="filter.itemsPerPage"
                            class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'

export const Attr = Object.freeze({
  store: 'diplomaPlanStudentClass',
})
export default {
  name: 'DiplomaStudentClassSave',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    dataSend: {
      type: Object,
      default: () => {},
    },
    extData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 100,
        diplomaId: this.dataSend.id,
        planClassNum: this.extData.planClassNum,
      },
      currentMainData: undefined,
      // modalTitle: `Dự thảo lớp cố định chương trình 2 - ${this.dataSend.programmeName} - [${this.dataSend.courseName} - ${this.dataSend.semesterName}]`,
      modalTitle: 'Dự thảo học viên lớp cố định chương trình 2',
      extMainData: {
        parentId: this.dataSend.id,
        courseId: this.dataSend.courseId,
        courseSemesterId: this.dataSend.courseSemesterId,
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  watch: {
    // listening change data init
    'extData.planClassNum': async function (newExtData, oldExtData) {
      if (newExtData !== oldExtData) {
        // const request = { ...this.filter }
        // request.planClassNum = this.extData.planClassNum
        await this.getMainDataFromStore()
      }
    },
  },
  computed: {
    ...mapGetters({
      mainData: `${Attr.store}/dataLists`,
      totalRows: `${Attr.store}/totalRows`,
      statuses: `${Attr.store}/statuses`,
      resourceName: `${Attr.store}/resourceName`,
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    classFilterDropdownItems() {
      return this.extData.planClassData.map(item => ({ id: item.classIndex, text: item.code }))
    },
    columns() {
      return [

        {
          label: '#',
          field: 'rowNum',
          width: '30px',
        },
        {
          label: 'Họ tên',
          field: 'studentName',
          sortable: true,
        },
        {
          label: 'Mã SV',
          field: 'studentCode',
        },
        {
          label: 'Lớp ưu tiên',
          field: 'classCode',
        },
        {
          label: 'Lớp ngành 2',
          field: 'classIndex',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.classFilterDropdownItems,
            placeholder: 'Tất cả',
          },
        },
      ]
    },
  },
  async created() {
    this.isLoading = true
    try {
      // await this.getMainData(this.filter)
      await this.getMainDataFromStore()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: `${Attr.store}/getData`,
      deleteMainData: `${Attr.store}/deleteData`,
    }),
    onCreateMainData() {
      this.currentMainData = undefined
      this.extMainData = {
        parentId: this.dataSend.id || this.$route.params.id,
        courseId: this.dataSend.courseId,
      }
    },
    onEditMainData(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onDeleteMainData(mainData) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xóa?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getMainData(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        const request = { ...this.filter }
        request.planClassNum = this.extData.planClassNum
        await this.getMainData(request)
        this.extData.planStudentClassData = this.mainData
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    getPlanClassCode(id) {
      if (this.extData.planClassData.length === 0) return ''
      const found = this.extData.planClassData.find(e => e.classIndex === id)
      return found ? found.code : ''
    },
    async onViewPlanData() {
      await this.getMainDataFromStore()
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
  },
}
</script>
