<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox, BFormTextarea,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { between, required } from '@validations'
import Select2 from 'v-select2-component'
import { Flag } from '@/const/flag'
import {ResearchRoleType} from "@/const/type";

export default {
  name: 'ResearchReviewFormResult',
  directives: {
    Ripple,
  },
  components: {
    BFormTextarea,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    VueGoodTable,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    Select2,
  },
  props: {
    research: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Nội dung đánh giá',
          field: 'nameCriteria',
          thClass: 'text-center',
        },
        {
          label: 'Đạt',
          field: 'pass',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Không đạt',
          field: 'fail',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'note',
          thClass: 'text-center',
        },
      ],
      required,
      between,
      dataFormat: [],
      passSelected: [],
      failSelected: [],
      commentContent: {
        comment: null,
        researchId: this.research.id,
      },
      presidentId: [],
    }
  },
  computed: {
    ...mapGetters({
      researchReviewFormResult: 'researchReviewFormResult/researchReviewFormResult',
      comment: 'researchReviewFormResult/comment',
      researchReviewPresent: 'researchReviewPresent/researchReviewPresent',
      researchRole: 'researchReviewFormResult/researchRole',
    }),
    statusLockData() {
      const hasInvalidStatus = this.researchReviewFormResult.some(item => item.status === 1 || item.status === null)
      return !hasInvalidStatus
    },
  },
  async created() {
    await this.getDataFromStore()
  },
  methods: {
    ...mapActions({
      readResearchReviewFormResult: 'researchReviewFormResult/readResearchReviewFormResult',
      updateResearchReviewFormResult: 'researchReviewFormResult/updateResearchReviewFormResult',
      readResearchReviewComment: 'researchReviewFormResult/readResearchReviewComment',
      updateResearchReviewComment: 'researchReviewFormResult/updateResearchReviewComment',
      readResearchReviewPresent: 'researchReviewPresent/readResearchReviewPresent',
      getResearchRole: 'researchReviewFormResult/getResearchRole',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    changeCell(data, column, row) {
      this.$set(this.practicalResearchStudentsCheckScore[row], column, data)
    },
    formatData(data) {
      this.passSelected = []
      this.failSelected = []
      data.forEach(item => {
        if (item.value === 1) {
          this.passSelected.push(item.codeCriteria)
        } else if (item.value === 0) {
          this.failSelected.push(item.codeCriteria)
        }
      })
    },
    async getDataFromStore() {
      this.isLoading = true
      this.commentContent = {
        comment: null,
        researchId: this.research.id,
      }
      try {
        await Promise.all([
          this.readResearchReviewFormResult({
            researchId: this.research.id,
          }),
          this.readResearchReviewComment({
            researchId: this.research.id,
          }),
          this.readResearchReviewPresent({
            currentPage: 1,
            itemsPerPage: 1000,
            researchId: this.research.id ? this.research.id : null,
          }),
          this.getResearchRole({type: ResearchRoleType.REVIEWER}),
        ])
        if (this.comment !== null) {
          this.commentContent = this.comment
        }
        this.researchReviewPresent.forEach(item => {
          if (this.researchRole.find(role => role.id === item.researchRoleId).name === 'Chủ tịch'){
            this.presidentId.push(item.employeeId)
          }
        })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
      this.formatData(this.researchReviewFormResult)
    },
    changeStatusPass(row) {
      if (this.failSelected.indexOf(row.codeCriteria) !== -1) {
        this.failSelected.splice(this.failSelected.indexOf(row.codeCriteria), 1)
      }
      this.researchReviewFormResult.forEach(item => {
        if (item.codeCriteria === row.codeCriteria) {
          item.value = 1
        }
      })
    },
    changeStatusFail(row) {
      if (this.passSelected.indexOf(row.codeCriteria) !== -1) {
        this.passSelected.splice(this.passSelected.indexOf(row.codeCriteria), 1)
      }
      this.researchReviewFormResult.forEach(item => {
        if (item.codeCriteria === row.codeCriteria) {
          item.value = 0
        }
      })
    },
    async onSave() {
      this.isLoading = true
      try {
        this.researchReviewFormResult.forEach(item => {
          item.researchId = this.research.id
          item.status = 2
        })
        const response = await Promise.all([
          this.updateResearchReviewFormResult(this.researchReviewFormResult),
          this.updateResearchReviewComment(this.commentContent),
        ])
        if (response) {
          if (response[0].isSuccessful && response[1].isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response[0].message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response[0].message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getDataFromStore()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response[0].message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response[0].message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onSaveCraft() {
      this.isLoading = true
      try {
        this.researchReviewFormResult.forEach(item => {
          item.researchId = this.research.id
          item.status = 1
        })
        const response = await Promise.all([
          this.updateResearchReviewFormResult(this.researchReviewFormResult),
          this.updateResearchReviewComment(this.commentContent),
        ])
        if (response) {
          if (response[0].isSuccessful && response[1].isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response[0].message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response[0].message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            await this.getDataFromStore()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response[0].message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response[0].message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <validation-observer
              ref="checkExaminationFormRef"
              #default="{invalid}"
            >
              <b-form>
                <b-row class="mt-2">
                  <b-col cols="12">
                    <h4>Các tiêu chí đánh giá</h4>
                    <vue-good-table
                      :columns="columns"
                      :rows="researchReviewFormResult"
                      :line-numbers="true"
                    >
                      <div
                        slot="emptystate"
                        style="text-align: center; font-weight: bold"
                      >
                        Không có bản ghi nào !
                      </div>

                      <template
                        slot="table-row"
                        slot-scope="props"
                      >
                        <template>
                          <span v-if="props.column.field === 'pass'">
                            <validation-provider
                              #default="{ errors }"
                              name="Đánh giá"
                            >
                              <b-form-checkbox
                                v-model="passSelected"
                                :value="props.row.codeCriteria"
                                @change="changeStatusPass(props.row)"
                              />
                            </validation-provider>
                          </span>
                          <span v-else-if="props.column.field === 'fail'">
                            <validation-provider
                              #default="{ errors }"
                              name="Đánh giá"
                            >
                              <b-form-checkbox
                                v-model="failSelected"
                                :value="props.row.codeCriteria"
                                @change="changeStatusFail(props.row)"
                              />
                            </validation-provider>
                          </span>

                          <span v-else-if="props.column.field === 'note'">
                            <b-form-input
                              v-model="researchReviewFormResult[props.row.originalIndex].note"
                            />
                          </span>
                          <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                        </template>
                      </template>
                    </vue-good-table>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="my-2">
                    <h4>Ý kiến và kiến nghị khác</h4>
                    <b-form-textarea
                      id="comment"
                      v-model="commentContent.comment"
                      type="text"
                      placeholder="Ý kiến và kiến nghị khác"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    class="text-right"
                  >
                    <b-button
                      v-if="presidentId.includes(user.employeeId)"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :disabled="statusLockData"
                      variant="success"
                      class="my-2"
                      title="Lưu đánh giá"
                      @click="onSaveCraft"
                    >
                      <feather-icon icon="CheckIcon" /> Lưu nháp
                    </b-button>
                    <b-button
                      v-if="presidentId.includes(user.employeeId)"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :disabled="statusLockData"
                      variant="primary"
                      class="my-2 mx-2"
                      title="Lưu đánh giá"
                      @click="onSave"
                    >
                      <feather-icon icon="LockIcon" /> Chốt duyệt
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<style scoped lang="scss">

</style>
