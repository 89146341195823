import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    dataArr: [],
    totalRows: 0,
    researchAdministrativeLevels: [],
    dataListsOtherAttachDocument: [],
    totalRowsOtherAttachDocument: 0,
    dataListsConferenceMember: [],
    totalRowsConferenceMember: 0,
    url: '',
  },
  getters: {
    dataArr: state => state.dataArr,
    totalRows: state => state.totalRows,
    researchAdministrativeLevels: state => state.researchAdministrativeLevels,
    dataListsOtherAttachDocument: state => state.dataListsOtherAttachDocument,
    totalRowsOtherAttachDocument: state => state.totalRowsOtherAttachDocument,
    dataListsConferenceMember: state => state.dataListsConferenceMember,
    totalRowsConferenceMember: state => state.totalRowsConferenceMember,
    url: state => state.url,
  },
  mutations: {
    SET_DATA: (state, dataArr) => { state.dataArr = dataArr },
    SET_TOTAL_ROWS: (state, totalRows) => { state.totalRows = totalRows },
    SET_RESEARCH_ADMINISTRATIVE_LEVELS: (state, researchAdministrativeLevels) => { state.researchAdministrativeLevels = researchAdministrativeLevels },
    SET_DATA_LISTS_OTHER_ATTACH_DOCUMENT: (state, dataListsOtherAttachDocument) => { state.dataListsOtherAttachDocument = dataListsOtherAttachDocument },
    SET_TOTALS_OTHER_ATTACH_DOCUMENT: (state, totalRowsOtherAttachDocument) => { state.totalRowsOtherAttachDocument = totalRowsOtherAttachDocument },
    SET_DATA_LISTS_CONFERENCE_MEMBER: (state, dataListsConferenceMember) => { state.dataListsConferenceMember = dataListsConferenceMember },
    SET_TOTALS_CONFERENCE_MEMBER: (state, totalRowsConferenceMember) => { state.totalRowsConferenceMember = totalRowsConferenceMember },
    SET_URL: (state, data) => { state.url = data },
  },

  actions: {
    async begin({ commit }, params) {
      try {
          const response = await axios.get('uni/researchConferences/begin', { params })
          const { data } = response
          if (data.code === ApiCode.SUCCESS) {
              const { researchAdministrativeLevels } = data.data
              commit('SET_RESEARCH_ADMINISTRATIVE_LEVELS', researchAdministrativeLevels)
          }
      } catch (e) {
          handleCatch(e)
      }
    },

    async getResearchConferences({ commit }, params) {
      try {
        const response = await axios.get('uni/researchConferences', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records, total } = data.data
          commit('SET_DATA', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    
    async createResearchConference({ commit }, params) {
        try {
            const response = await axios.post('uni/researchConferences', params)
            const { data } = response;
            return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
        } catch (e) {
            handleCatch(e)
        }
        return null
    },

    async updateResearchConference({ commit }, params) {
      try {
          const response = await axios.put(`uni/researchConferences/${params.id}`, params)
          const { data } = response
          return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
          handleCatch(e)
      }
      return null
    },

  async createResearchConferenceMember({ commit }, params) {
    try {
        const response = await axios.post('uni/researchConferences/researchConferenceMember', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
        handleCatch(e)
    }
    return null
  },

  async updateResearchConferenceMember({ commit }, params) {
    try {
        const response = await axios.put(`uni/researchConferences/researchConferenceMember/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
        handleCatch(e)
    }
    return null
  },

  async getResearchOtherAttachDocuments({ commit }, params) {
    try {
      const response = await axios.get(`uni/researchConferences/${params.id}/getResearchOtherAttachDocuments`, { params })

      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { total, records } = data.data
        commit('SET_DATA_LISTS_OTHER_ATTACH_DOCUMENT', records)
        commit('SET_TOTALS_OTHER_ATTACH_DOCUMENT', total)
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },

  async createResearchOtherAttachDocument({ commit }, params) {
    try {
        const response = await axios.post('uni/researchConferences/createResearchOtherAttachDocument', params)
        const { data } = response;
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
        handleCatch(e)
    }
    return null
  },

  async updateResearchOtherAttachDocument({ commit }, params) {
    try {
        const response = await axios.put('uni/researchConferences/updateResearchOtherAttachDocument', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
    } catch (e) {
        handleCatch(e)
    }
      return null
  },

  async uploadSample({ commit }, params) {
    try {
      const response = await axios.post('uni/researchConferences/uploadSample', params)
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        return data.data
      }
      return []
    } catch (e) {
      handleCatch(e)
    }
    return null
  },

  async removeSample({ commit }, id) {
    try {
      const response = await axios.delete(`uni/researchConferences/file/${id}`)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },

  async deleteResearchOtherAttachDocument({ commit }, id) {
    try {
      const response = await axios.delete(`uni/researchConferences/researchOtherAttachDocument/${id}`)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
    
  async deleteResearchConference({ commit }, id) {
    try {
      const response = await axios.delete(`uni/researchConferences/${id}`)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },

  async getResearchConferenceMembers({ commit }, params) {
    try {
      console.log('params', params)
      const response = await axios.get(`uni/researchConferences/${params.id}/getResearchConferenceMembers`, { params })

      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        const { total, records } = data.data
        commit('SET_DATA_LISTS_CONFERENCE_MEMBER', records)
        commit('SET_TOTALS_CONFERENCE_MEMBER', total)
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },

  async deleteResearchConferenceMember({ commit }, id) {
    try {
      const response = await axios.delete(`uni/researchConferences/researchConferenceMember/${id}`)
      const { data } = response
      return {
        isSuccessful: data.code === ApiCode.SUCCESS,
        message: data.message,
      }
    } catch (e) {
      handleCatch(e)
    }
    return null
  },
  async downloadResearchOtherAttachDocument({ commit }, params) {
    try {
      const response = await axios.get('uni/researchConferences/researchOtherAttachDocument/download', { params })
      const { data } = response
      if (data.code === ApiCode.SUCCESS) {
        commit('SET_URL', data.data)
      }
    } catch (e) {
      handleCatch(e)
    }
  },
  },
}
