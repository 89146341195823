import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'
import { defaultParams } from '@/store/common/dropdown'

export default {
  namespaced: true,
  state: {
    teachers: [],
    teacherHours: [],
    teacherHoursSum: [],
    totalRows: 0,
    totalRowsSum: 0,
    statuses: STATUSES,
    years: [],
    subjects: [],
    teachingHours: 0,
    teacherClassHours: 0,
    researchSupervisionHours: 0,
    dataExport: null,
    dataExportByYear: null,
  },
  getters: {
    teachers: state => state.teachers,
    teacherHours: state => state.teacherHours,
    teacherHoursSum: state => state.teacherHoursSum,
    totalRows: state => state.totalRows,
    totalRowsSum: state => state.totalRowsSum,
    statuses: state => state.statuses,
    years: state => state.years,
    subjects: state => state.subjects,
    teachingHours: state => state.teachingHours,
    teacherClassHours: state => state.teacherClassHours,
    researchSupervisionHours: state => state.researchSupervisionHours,
    dataExport: state => state.dataExport,
    dataExportByYear: state => state.dataExportByYear,
  },
  mutations: {
    SET_TEACHERS: (state, records) => {
      state.teachers = records
    },
    SET_TEACHER_HOURS: (state, records) => {
      state.teacherHours = records
    },
    SET_TEACHER_HOURS_SUM: (state, records) => {
      state.teacherHoursSum = records
    },
    SET_TEACHING_HOURS: (state, data) => {
      state.teachingHours = data
    },
    SET_RESEARCH_SUPERVISION_HOURS: (state, data) => {
      state.researchSupervisionHours = data
    },
    SET_TEACHER_CLASS_HOURS: (state, data) => {
      state.teacherClassHours = data
    },
    SET_TOTAL_ROWS: (state, totalRows) => {
      state.totalRows = totalRows
    },
    SET_TOTAL_ROWS_SUM: (state, totalRows) => {
      state.totalRowsSum = totalRows
    },
    SET_YEARS: (state, data) => { state.years = data },
    SET_SUBJECTS: (state, records) => {
      state.subjects = records
    },
    SET_DATA_EXPORT: (state, { dataExport }) => {
      state.dataExport = dataExport
    },
    SET_DATA_EXPORT_BY_YEAR: (state, { dataExportByYear }) => {
      state.dataExportByYear = dataExportByYear
    },
  },
  actions: {
    async getYears({ commit }, params) {
      try {
        const response = await axios.get('categories/factYears/all', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_YEARS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTeachers({ commit }, parameters) {
      try {
        const ownParams = { departmentId: '' }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get('uni/teachers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TEACHERS', data.data.records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getSubjects({ commit }, parameters) {
      try {
        const ownParams = {
          subjectTypeId: null,
          departmentId: '',
          programmeId: '',
          itemsPerPage: 1000,
        }
        const params = {
          ...defaultParams,
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get('uni/subjects', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SUBJECTS', data.data.records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async readTeacherHours({ commit }, params) {
      try {
        const response = await axios.get('uni/tw_teacher_hours', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_TEACHER_HOURS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async readTeacherHoursSum({ commit }, params) {
      try {
        const response = await axios.get('uni/tw_teacher_hours/sum', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_TEACHER_HOURS_SUM', records)
          commit('SET_TOTAL_ROWS_SUM', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTeachingHours({ commit }, params) {
      try {
        const response = await axios.get('uni/tw_teacher_hours/getTeachingHours', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TEACHING_HOURS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getResearchSupervisionHours({ commit }, params) {
      try {
        const response = await axios.get('uni/tw_teacher_hours/getResearchSupervisionHours', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_RESEARCH_SUPERVISION_HOURS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTeacherClassHours({ commit }, params) {
      try {
        const response = await axios.get('uni/tw_teacher_hours/getTeacherClassHours', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_TEACHER_CLASS_HOURS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createTeacherHours({ commit }, params) {
      try {
        const response = await axios.post('uni/tw_teacher_hours', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateTeacherHours({ commit }, params) {
      try {
        const response = await axios.put(`uni/tw_teacher_hours/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteTeacherHours({ commit }, id) {
      try {
        const response = await axios.delete(`uni/tw_teacher_hours/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async downloadExport({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/tw_teacher_hours/exportTeacherHours',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_EXPORT', { dataExport: responseStudentInfo })
      } catch (e) {
        handleCatch(e)
      }
    },
    async downloadExportByYear({ commit }, params) {
      try {
        const responseStudentInfo = await axios({
          method: 'get',
          url: 'uni/tw_teacher_hours/exportTeacherHoursByYear',
          responseType: 'blob',
          params,
        })
        commit('SET_DATA_EXPORT_BY_YEAR', { dataExportByYear: responseStudentInfo })
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
