<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>
        Danh sách thành viên hội đồng: <span class="text-primary">{{ reviewBoardName }}</span>
      </b-card-title>
    </b-card-header>
    <b-card-body class="pb-1">
      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="dataSources"
            :pagination-options="paginationOptions"
            :line-numbers="true"
          >
            <div
              slot="emptystate"
              style="text-align: center; font-weight: bold"
            >
              Không có bản ghi nào !
            </div>
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'status'">
                {{ getStatusName(props.row.status) }}
              </span>

              <span v-else-if="props.column.field === 'action'">
                <b-button
                  v-if="updatable"
                  v-b-modal.reviewerSaveModal
                  variant="primary"
                  class="btn-icon mr-1"
                  size="sm"
                  @click="onUpdateReviewer(props.row)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button
                  v-if="deletable"
                  variant="danger"
                  class="btn-icon"
                  size="sm"
                  @click="onDeleteReviewer(props.row)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>{{ props.formattedRow[props.column.field] }}</span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Hiển thị 1 đến </span>
                  <b-form-select
                    v-model="itemsPerPage"
                    :options="itemsPerPageOptions"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="itemsPerPage"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  />
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader, BCardTitle,
  BCol,
  BFormSelect, BPagination, BRow,
} from 'bootstrap-vue'
import { STATUSES } from '@/const/status'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'Reviewer',
  components: {
    BButton,
    BPagination,
    BCardTitle,
    BRow,
    BCardHeader,
    BFormSelect,
    BCard,
    BCardBody,
    BCol,
    VueGoodTable,
  },
  props: {
    reviewBoardId: {
      type: Number,
      default: null,
    },
    reviewBoardName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'reviewer/reviewers',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.RESEARCH_REVIEWER)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.RESEARCH_REVIEWER)
    },
    columns() {
      const data = [
        {
          label: 'Tên thành viên',
          field: 'fullName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên thành viên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Chức vụ',
          field: 'role',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập chức vụ',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: STATUSES.map(status => ({ value: status.value, text: status.label })),
            placeholder: 'Chọn trạng thái',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Thứ tự',
          field: 'orderNo',
          thClass: 'text-center',
          tdClass: 'text-right',
        },
      ]
      if (this.updatable || this.deletable) {
        data.push({
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        })
      }
      return data
    },
  },
  watch: {
    async reviewBoardId(val) {
      val ? await this.getDataSourcesFromStore() : this.setDataSources([])
    },
  },
  methods: {
    ...mapMutations({
      setDataSources: 'reviewer/SET_REVIEWERS',
    }),
    ...mapActions({
      getDataSources: 'reviewer/getReviewers',
      deleteReviewer: 'reviewer/deleteReviewer',
    }),
    async getDataSourcesFromStore() {
      this.$emit('onLoading', true)
      try {
        await this.getDataSources({ reviewBoardId: this.reviewBoardId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.$emit('onLoading', false)
      }
    },
    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },
    onDeleteReviewer(reviewer) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa thành viên <span class="text-danger">${reviewer.fullName}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$emit('onLoading', true)
          try {
            const response = await this.deleteReviewer(reviewer.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getDataSources({ reviewBoardId: this.reviewBoardId })
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.$emit('onLoading', false)
          }
        }
      })
    },
    onUpdateReviewer(reviewer) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = reviewer
      this.$emit('onUpdateReviewer', rest)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
