<template>
  <validation-observer
    ref="confirmationSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="LanguageSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm mới điểm ưu tiên, quy đổi' : 'Cập nhật điểm ưu tiên, quy đổi'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="admissionRoundId">
          <template v-slot:label>
            Đợt xét tuyển <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Đợt xét tuyển"
            rules="required"
          >
            <v-select
              v-model="targetItem.admissionRoundId"
              :options="admissionRounds"
              :reduce="option => option.value"
              @input="readAdmissionForm"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="admissionFormId">
          <template v-slot:label>
            Phương thức xét tuyển <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Phương thức xét tuyển"
            rules="required"
          >
            <v-select
              v-model="targetItem.admissionFormId"
              :options="dataListAdmissionForm"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="factAdmissionLanguageCertificateTypeId">
          <template v-slot:label>
            Loại chứng chỉ ngoại ngữ <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Min"
          >
            <v-select
              id="factAdmissionLanguageCertificateTypeId"
              v-model="targetItem.factAdmissionLanguageCertificateTypeId"
              label="label"
              :options="dataListLanguageCertificateType"
              :reduce="option => option.value"
            />
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="minScore">
          <template v-slot:label>
            Điểm tối thiểu <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="minScore"
            v-model="targetItem.minScore"
            name="minScore"
            type="number"
          />
        </b-form-group>
        <b-form-group label-for="maxScore">
          <template v-slot:label>
            Điểm tối đa
          </template>
          <b-form-input
            id="maxScore"
            v-model="targetItem.maxScore"
            name="maxScore"
            type="number"
          />
        </b-form-group>
        <b-form-group label-for="priorityScore">
          <template v-slot:label>
            Điểm ưu tiên
          </template>
          <b-form-input
            id="priorityScore"
            v-model="targetItem.priorityScore"
            name="score"
            type="number"
          />
        </b-form-group>
        <b-form-group label-for="redemptionScore">
          <template v-slot:label>
            Điểm quy đổi
          </template>
          <b-form-input
            id="redemptionScore"
            v-model="targetItem.redemptionScore"
            name="redemptionScore"
            type="number"
            @input="checkRequiredSubject"
          />
        </b-form-group>
        <b-form-group
          v-show="checkRequired"
          label-for="factAdmissionSubjectId">
          <template v-slot:label>
            Môn học <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Môn học"
            rules="required"
          >
            <v-select
              id="factAdmissionSubjectId"
              v-model="targetItem.factAdmissionSubjectId"
              :options="dataListSubject"
              label="label"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="admissionScoreTypeId">
          <template v-slot:label>
            Loại điểm <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Loại điểm"
            rules="required"
          >
            <v-select
              v-model="targetItem.admissionScoreTypeId"
              :options="dataAdmissionScores"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('LanguageSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
        no-wrap
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BModal, BOverlay, BButton, BFormGroup, BFormInvalidFeedback, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { STATUSES } from '@/const/status'
import { Flag } from '@/const/flag'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'

export default {
  name: 'ConfirmationSave',
  components: {
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dataSource: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      currentUser: getUser(),
      targetItem: {
        admissionRoundId: null,
        admissionFormId: null,
        factAdmissionLanguageCertificateTypeId: null,
        admissionScoreTypeId: null,
        minScore: null,
        maxScore: null,
        priorityScore: null,
        redemptionScore: null,
        factAdmissionSubjectId: null,
        orderNo: 1,
        status: Flag.ACTIVE,
      },
      checkRequired: false,
      required,
    }
  },
  computed: {
    ...mapGetters({
      dataAdmissionScores: 'factAdmissionScoreType/admissionScoreTypes',
      admissionRounds: 'admissionRound/admissionRoundsByOrganization',
      dataListAdmissionForm: 'admissionForm/admissionFormByAdmissionRoundId',
      dataListLanguageCertificateType: 'factAdmissionLanguageCertificateType/dataListLanguageCertificateType',
      dataListSubject: 'factAdmissionSubject/dataListSubject',
    }),
    isCreated() {
      return !this.dataSource
    },
    statuses() {
      return STATUSES
    },
  },
  methods: {
    ...mapActions({
      createAdmissionPriorityScores: 'admissionPriorityScore/createAdmissionPriorityScores',
      updateAdmissionPriorityScores: 'admissionPriorityScore/updateAdmissionPriorityScores',
      readAdmissionFormByAdmissionRoundId: 'admissionForm/readAdmissionFormByAdmissionRoundId',
      getAdmissionRoundsByOrganization: 'admissionRound/readAdmissionRoundsByOrganization',
      getLanguageCertificateType: 'factAdmissionLanguageCertificateType/getLanguageCertificateType',
      getListSubject: 'factAdmissionSubject/getListSubject',
      getAllAdmissionScores: 'factAdmissionScoreType/getAdmissionScoreTypes',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await Promise.all([
        this.getAdmissionRoundsByOrganization({ organizationId: getUser().orgId }),
        this.getLanguageCertificateType(),
        this.getListSubject(),
        this.getAllAdmissionScores(),
      ])
      if (this.dataSource) {
        this.targetItem = { ...this.dataSource }
        await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: this.targetItem.admissionRoundId })
      }
    },
    async readAdmissionForm(id) {
      await this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: id })
    },
    checkRequiredSubject() {
      if (this.targetItem.redemptionScore.length > 0) {
        this.checkRequired = true
      } else {
        this.checkRequired = false
      }
    },
    onHide() {
      this.$refs
        .confirmationSaveFormRef
        .reset()
      this.targetItem = {
        admissionRoundId: null,
        admissionFormId: null,
        factAdmissionLanguageCertificateTypeId: null,
        minScore: null,
        maxScore: null,
        priorityScore: null,
        redemptionScore: null,
        factAdmissionSubjectId: null,
        orderNo: 1,
        status: Flag.ACTIVE,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .confirmationSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        const response = this.isCreated
          ? await this.createAdmissionPriorityScores(this.targetItem)
          : await this.updateAdmissionPriorityScores(this.targetItem)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('LanguageSaveModal')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
        this.isLoading = false
      }
    },
  },
}
</script>
