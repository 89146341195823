import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/attendanceMachine',
    name: 'attendanceMachine',
    component: () => import('@/views/attendance-machine/machine/AttendanceMachine.vue'),
    meta: {
      pageTitle: 'Quản lý máy điểm danh',
      breadcrumb: [
        {
          text: 'Quản lý điểm danh',
          active: false,
        },
        {
          text: 'Quản lý máy điểm danh',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.ATTENDANCE_MACHINE,
    },
  },
]
