<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  placeholder="Khoa/bộ môn"
                >
                  <v-select
                    v-model="filter.departmentId"
                    :options="departments"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Khoa/bộ môn"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="2"
                md="1"
                class="text-right"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="onSucceed()"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon icon="FilterIcon" />
                  </span>
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  :columns="columns"
                  :rows="teachers"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers="true"
                  @on-column-filter="onColumnFilter"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'">
                      {{ getStatusName(props.row.status) }}
                    </span>
                    <span v-else-if="props.column.field === 'action'">
                      <b-button
                        v-if="checkUser(props.row.id)"
                        v-b-modal.timeResearchTeacherResultModal
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        @click="onResultTimeResearch(props.row)"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                    </span>
                    <span v-else-if="props.column.field === 'departmentId'">
                      {{ getDepartmentName(props.row.departmentId) }}
                    </span>
                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>
                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <TimeResearchTeacherResultModal
      ref="timeResearchTeacherResultModal"
      :data-form="currentDataSource"
      @succeed="onSucceed"
    />
  </div>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import TeacherTypeSaveModal from '@/views/admin/category/fact-teacher-type/save.vue'
import TimeFrameResearchSaveModal from '@/views/teacher-workload/time-research/tw-time-frame-research/TimeFrameResearchSave.vue'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import TimeResearchTeacherResultModal
from '@/views/teacher-workload/time-research/tw_time_research_teacher/TimeResearchTeacherResult.vue'
import {STATUSES} from "@/const/status";

export default {
  name: 'TimeResearchTeacher',
  directives: {
    Ripple,
  },
  components: {
    TimeResearchTeacherResultModal,
    BButtonGroup,
    BDropdownItem,
    BDropdown,
    BFormGroup,
    TimeFrameResearchSaveModal,
    TeacherTypeSaveModal,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 20,
        organizationId: getUser().orgId,
        departmentId: null,
        name: null,
      },
      currentDataSource: undefined,
      paginationOptions: {
        enabled: true,
      },
      user: getUser(),
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên giảng viên',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Khoa/bộ môn',
          field: 'departmentId',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Tất cả',
            filterDropdownItems: [],
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      teachers: 'twTimeResearchTeacher/teachers',
      totalRows: 'twTimeResearchTeacher/totalRows',
      departments: 'twTimeResearchTeacher/departments',
    }),
    statuses() {
      return STATUSES
    },
  },
  watch: {
    async resource() {
      await this.onLoad()
    },
  },
  async created() {
    await this.onLoad()
  },
  methods: {
    getUser,
    ...mapActions({
      getTeachers: 'twTimeResearchTeacher/getTeachers',
      getDepartments: 'twTimeResearchTeacher/getDepartments',
    }),
    async onLoad() {
      this.isLoading = true
      try {
        await Promise.all([
          this.getDataSourcesFromStore(),
          this.getDepartments({ organizationId: this.filter.organizationId }),
        ])
        if (this.departments.length > 0) {
          this.filter.departmentId = this.departments[0].id
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onResultTimeResearch(dataSource) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = dataSource
      this.currentDataSource = rest
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getTeachers(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ teacherName: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      if (Object.hasOwn(columnFilters, 'departmentId')) {
        this.updateParams({ departmentId: columnFilters.departmentId })
      }
      await this.getDataSourcesFromStore()
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    getDepartmentName(id) {
      return this.departments.find(department => department.id === id)?.name || ''
    },
    checkUser(id) {
      return this.user.roles.find(role => role.code === 'P_DAO_TAO') ? true : getUser().teacherId === id
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
