<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      size="lg"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm mới dữ liệu' : 'Cập nhật dữ liệu'"
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label-for="trainingSystemId">
              <template v-slot:label>
                Chương trình đào tạo/Bồi dưỡng <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chương trình đào tạo/Bồi dưỡng"
                rules="required"
              >
                <v-select
                  v-model="targetDataSource.trainingSystemId"
                  :options="trainingSystems"
                  :reduce="option => option.value"
                  @input="onTrainingSystemsChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label-for="courseId">
              <template v-slot:label>
                Khóa học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khóa học"
                rules="required"
              >
                <v-select
                  v-model="targetDataSource.courseId"
                  :options="courses"
                  :reduce="option => option.value"
                  @input="onCoursesChange"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label-for="departmentId">
          <template v-slot:label>
            Khoa/Bộ môn <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Khoa/Bộ môn"
            rules="required"
          >
            <v-select
              v-model="targetDataSource.departmentId"
              :options="departments"
              :reduce="option => option.value"
              @input="onDepartmentChange($event)"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="majorId">
          <template v-slot:label>
            Ngành học <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Ngành học"
            rules="required"
          >
            <v-select
              v-model="targetDataSource.majorId"
              :options="majors"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group
              label="Ngày mở đăng ký"
              label-for="openAt"
            >
              <flat-pickr
                v-model="targetDataSource.openAt"
                :config="config"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group
              label="Ngày kết thúc đăng ký"
              label-for="closeAt"
            >
              <flat-pickr
                v-model="targetDataSource.closeAt"
                :config="config"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label-for="referCourseSemesterId">
              <template v-slot:label>
                Học kỳ lấy điểm xét duyệt <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Học kỳ lấy điểm xét duyệt"
                rules="required"
              >
                <v-select
                  id="referCourseSemesterId"
                  v-model="targetDataSource.referCourseSemesterId"
                  :options="courseSemesters"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label-for="code"  >
              <template v-slot:label>
                Mã <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetDataSource.code"
                  name="code"
                  placeholder="Nhập mã"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <CategorySave :target-data-source="targetDataSource" />
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
        no-wrap
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BModal, BOverlay, BButton, BFormGroup, BFormInput, BFormInvalidFeedback, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import CategorySave from '@/components/category-save/CategorySave.vue'
import { Flag } from '@/const/flag'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import FlatpickrLanguages from 'flatpickr/dist/l10n'

export default {
  name: 'MajorApprovalStudentSave',
  components: {
    CategorySave,
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormInvalidFeedback,
    BFormInput,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BButton,
    BRow,
    BCol,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    dataSource: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      cUser: getUser(),
      targetDataSource: { status: Flag.ACTIVE, orderNo: 1 },
      config: {
        enableTime: true,
        altInput: true,
        altFormat: 'd-m-Y H:i',
        dateFormat: 'Y-m-d H:i',
        locale: FlatpickrLanguages.vn,
        static: true,
        time_24hr: true,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'majorApproval/statuses',
      maxOrderNo: 'majorApproval/maxOrderNo',
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      courseSemesters: 'dropdown/courseSemesters',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      programmes: 'dropdown/programmes',
    }),
    isCreated() {
      return !this.dataSource
    },
  },
  methods: {
    ...mapActions({
      createDataSource: 'majorApproval/createDataSource',
      updateDataSource: 'majorApproval/updateDataSource',
      getMaxOrderNo: 'majorApproval/getMaxOrderNo',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemestersNew',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getProgrammes: 'dropdown/getProgrammes',
    }),
    async onTrainingSystemsChange(event) {
      this.targetDataSource.courseId = null
      await this.getCourses({ trainingSystemId: event })
    },
    async onCoursesChange(event) {
      this.targetDataSource.referCourseSemesterId = null
      await this.getCourseSemesters({ courseId: event, status: '' })
      if (this.courseSemesters.length > 0) {
        this.targetDataSource.referCourseSemesterId = this.courseSemesters[1].value
      }
    },
    async onDepartmentChange(event) {
      this.targetDataSource.majorId = null
      await this.getMajors({ departmentId: event })
      if (this.majors.length > 0) {
        this.targetDataSource.majorId = this.majors[0].value
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      const userOrgId = this.cUser.orgId ? this.cUser.orgId : 0
      await Promise.all([
        this.getTrainingSystems({ organizationId: userOrgId }),
        this.getDepartments({ organizationId: userOrgId }),
      ])

      if (this.dataSource) {
        this.targetDataSource = { ...this.dataSource }
        await Promise.all([
          this.getCourses({ trainingSystemId: this.targetDataSource.trainingSystemId }),
          this.getCourseSemesters({ courseId: this.targetDataSource.courseId, status: '' }),
          this.getMajors({ departmentId: this.targetDataSource.departmentId }),
          this.getProgrammes({ majorId: this.targetDataSource.majorId }),
        ])
      } else {
        await this.getMaxOrderNo()
        this.targetDataSource.orderNo = this.maxOrderNo + 1
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetDataSource = { status: Flag.ACTIVE, orderNo: 1 }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createDataSource(this.targetDataSource)
            : await this.updateDataSource(this.targetDataSource)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
