import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    reviewers: [],
    reviewersByYear: [],
    employees: [],
    dataDetail: [],
  },
  getters: {
    reviewers: state => state.reviewers,
    reviewersByYear: state => state.reviewersByYear,
    employees: state => state.employees,
    dataDetail: state => state.dataDetail,
  },
  mutations: {
    SET_REVIEWERS: (state, data) => { state.reviewers = data },
    SET_REVIEWERS_BY_YEAR: (state, data) => { state.reviewersByYear = data },
    SET_EMPLOYEES: (state, data) => { state.employees = data },
    SET_DATA_DETAIL: (state, data) => { state.dataDetail = data },
  },
  actions: {
    async getReviewers({ commit }, params) {
      try {
        const response = await axios.get('uni/researchInnovationReviewers', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_REVIEWERS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getReviewersByYear({ commit }, params) {
      try {
        const response = await axios.get('uni/researchInnovationReviewers/getByYearId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_REVIEWERS_BY_YEAR', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getEmployeesByOrganizationId({ commit }) {
      try {
        const response = await axios.get('uni/employees/getAllByOrganizationId')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_EMPLOYEES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createReviewers({ commit }, params) {
      try {
        const response = await axios.post('uni/researchInnovationReviewers', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateReviewer({ commit }, param) {
      try {
        const response = await axios.put(`uni/researchInnovationReviewers/${param.id}`, param)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteReviewer({ commit }, id) {
      try {
        const response = await axios.delete(`uni/researchInnovationReviewers/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async saveDataResearchInnovationEvaluation({ commit }, body) {
      try {
        const response = await axios.post('uni/researchInnovationEvaluations', body)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDetail({ commit }, params) {
      try {
        const response = await axios.get(`uni/researchInnovationEvaluations/${params.researchId}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DATA_DETAIL', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
