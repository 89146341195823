<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="12">
            <div class="text-right">
              <b-button
                v-if="creatable"
                variant="success"
                class="ml-2"
                @click="showModal"
              >
                <span class="text-nowrap">
                  <feather-icon icon="PlusIcon" /> Thêm mới
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-1 mt-0">
              <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
              </span>
            </div>
            <vue-good-table
              mode="remote"
              :columns="columns"
              :rows="dataSources"
              :pagination-options="paginationOptions"
              :total-rows="totalRows"
              :line-numbers="true"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.field === 'startDate'">
                  {{ formatDate(props.row.startDate) }}
                </span>
                <span v-else-if="props.column.field === 'researchAdministrativeLevelId'">
                  {{ getResearchAdministrativeLevel(props.row.researchAdministrativeLevelId) }}
                </span>
                <span v-else-if="props.column.field === 'status'">
                  {{ getStatusName(props.row.status) }}
                </span>
                <span
                  v-else-if="props.column.field === 'action'"
                  class="thead-group-action"
                >
                  <b-button-group>
                    <b-button
                      v-if="updatable"
                      v-b-tooltip.hover
                      title="Chỉnh sửa"
                      placement="bottom"
                      variant="primary"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="editItem(props.row)"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-dropdown
                      right
                      variant="success"
                      no-caret
                      size="sm"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreHorizontalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item
                        variant="success"
                        class="btn-icon"
                        size="sm"
                        @click="onListResearchConferenceMembers(props.row)"
                      >
                        <feather-icon icon="UsersIcon" /> Thành viên tham luận
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="info"
                        class="btn-icon"
                        size="sm"
                        @click="onListResearchOtherAttachDocuments(props.row)"
                      >
                        <feather-icon icon="BookOpenIcon" />
                        Trích yếu
                      </b-dropdown-item>
                      <b-dropdown-item
                        variant="danger"
                        class="btn-icon"
                        size="sm"
                        @click="deleteResearchConference(props.row)"
                      >
                        <feather-icon icon="TrashIcon" /> Xoá
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-button-group>
                </span>
                <!-- Column: Common -->
                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                      đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị </span>
                    <b-form-select
                      v-model="filter.itemsPerPage"
                      :options="itemsPerPageOptions"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap"> bản ghi/trang</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="totalRows"
                      :per-page="filter.itemsPerPage"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    />
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>

    </b-card>

    <b-modal
      id="form"
      ref="form"
      ok-only
      ok-title="Chấp nhận"
      centered
      size="lg"
      :title="!dataForm ? 'Thêm hội thảo khoa học' : 'Cập nhật hội thảo khoa học'"
      no-close-on-backdrop
      :hide-footer="true"
    >
      <Form
        :data-form="dataForm"
        @saveDataSuccess="saveDataSuccess"
      />
    </b-modal>

    <b-modal
      id="modal-list-research-other-attach-document"
      ref="modal-list-research-other-attach-document"
      centered
      size="xl"
      :hide-footer="true"
    >
      <ResearchOtherAttachDocuments :data-send="currentMainData" />
    </b-modal>

    <b-modal
      id="modal-list-research-conference-member"
      ref="modal-list-research-conference-member"
      centered
      size="xl"
      :hide-footer="true"
    >
      <ResearchConferenceMembers :data-send="currentMainData" />
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BButton, BCard, BCol, BFormSelect, BOverlay, BPagination, BRow, VBTooltip,
  BFormGroup, BDropdown, BDropdownItem, BButtonGroup, BFormDatepicker, BFormInvalidFeedback
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
import Form from '@/views/research/research-conferences/Form.vue'
import ResearchConferenceMembers from '@/views/research/research-conferences/ResearchConferenceMembers.vue'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { STATUSES } from '@/const/status'
import ResearchOtherAttachDocuments from '@/views/research/research-conferences/ResearchOtherAttachDocuments.vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Index',
  components: {
    VueGoodTable,
    vSelect,
    BButton,
    BCard,
    BCol,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    Form,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    ResearchConferenceMembers,
    BFormDatepicker,
    BFormInvalidFeedback,
    ResearchOtherAttachDocuments,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      currentMainData: null,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: null,
        name: null,
        code: null,
        status: null,
        sort: null,
        startDate: null,
      },
      columns: [
        {
          label: 'Tên hội thảo',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên hội thảo',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Cấp tổ chức',
          field: 'researchAdministrativeLevelId',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Thời gian tổ chức',
          field: 'startDate',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ấn phẩm',
          field: 'publication',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: [
              { value: 1, text: 'Hoạt động' },
              { value: 0, text: 'Không hoạt động' },
            ],
            placeholder: 'Chọn trạng thái',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      dataForm: undefined,
    }
  },
  computed: {
    ...mapGetters({
      researchAdministrativeLevels: 'researchConference/researchAdministrativeLevels',
      dataSources: 'researchConference/dataArr',
      totalRows: 'researchConference/totalRows',
    }),
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.RESEARCH_CONFERENCE)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.RESEARCH_CONFERENCE)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.RESEARCH_CONFERENCE)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.getBegin(),
        this.getDataSourcesFromStore()
      ])
    } catch (e) {
      this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getBegin: 'researchConference/begin',
      getDataSources: 'researchConference/getResearchConferences',
      deleteData: 'researchConference/deleteResearchConference',
    }),

    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(params) {
      this.updateParams({ currentPage: params.currentPage })
      await this.getDataSourcesFromStore()
    },
    async onPerPageChange(params) {
      this.updateParams({ itemsPerPage: params.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onSortChange(params) {
      if (params.length > 0) {
        const { field, type } = params[0]
        if (field === 'name') {
          this.updateParams({ sort: `0_${type}` })
        }
        if (field === 'researchAdministrativeLevelId') {
          this.updateParams({ sort: `1_${type}` }) 
        }
        if (field === 'startDate') {
          this.updateParams({ sort: `2_${type}` })
        }
      }
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      const fields = this.columns.map(column => column.field)
      fields.forEach(field => {
        if (Object.hasOwn(columnFilters, field)) {
          const data = {}
          data[field] = columnFilters[field]
          this.updateParams(data)
        }
      })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getDataSources(this.filter)
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },

    editItem(data) {
      this.dataForm = data
      this.$bvModal.show('form')
    },

    deleteResearchConference(data) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xoá hội thảo khoa học?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          this.isLoading = true
          try {
            const response = await this.deleteData(data.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.showToast('Đã xóa thành công!', 'CheckIcon', 'success')
                await this.getDataSources(this.filter)
              } else {
                this.showToast(`Có lỗi xảy ra ${message}`, 'XCircleIcon', 'warning')
              }
            }
          } catch (e) {
            this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
          } finally {
            this.isLoading = false
          }
        }
      })
    },

    showModal() {
      this.dataForm = undefined
      this.$bvModal.show('form')
    },

    async saveDataSuccess() {
      await this.getDataSourcesFromStore()
    },

    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },

    getResearchAdministrativeLevel(id) {
      return this.researchAdministrativeLevels.find(level => level.id === id)?.name
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    formatDate(date) {
      return date ? moment(date).format('DD/MM/YYYY') : ''
    },
    onListResearchOtherAttachDocuments(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.$refs['modal-list-research-other-attach-document'].show()
    },

    onListResearchConferenceMembers(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.$bvModal.show('modal-list-research-conference-member')
    },
  },
}
</script>

<style lang="scss">
.research-table {
  min-height: 220px;
}
</style>
