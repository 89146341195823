<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <h4 class="card-title">
            Danh sách thành viên tham luận
          </h4>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="text-right">
              <b-button
                v-if="creatable"
                variant="success"
                class="ml-2"
                @click="showModal"
              >
                <span class="text-nowrap">
                  <feather-icon icon="PlusIcon" /> Thêm thành viên mới
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-1 mt-0">
              <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
              </span>
            </div>
            <vue-good-table
              mode="remote"
              :columns="columns"
              :rows="dataSources"
              :pagination-options="paginationOptions"
              :total-rows="totalRows"
              :line-numbers="true"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              
              <template slot="table-row" slot-scope="props">

                    <span v-if="props.column.field === 'action'">
                      <b-button
                        v-if="updatable"
                        v-b-modal.conferenceMembers
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        @click="editItem(props.row)"
                        title="Chỉnh sửa"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                      <b-button
                        v-if="deletable"
                        variant="danger"
                        class="btn-icon"
                        style="margin-left: 6px"
                        size="sm"
                        @click="deleteMember(props.row)"
                        title="Xoá"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </span>

                    <span v-else-if="props.column.field === 'name'">
                      {{ props.row.memberType == RESEARCH_CONFERENCE_MEMBER_TYPES[0].value ? getMemberName(props.row.employeeId) : props.row.name }}
                    </span>

                    <span v-else-if="props.column.field === 'memberType'">
                      {{ props.row.memberType == RESEARCH_CONFERENCE_MEMBER_TYPES[0].value ? RESEARCH_CONFERENCE_MEMBER_TYPES[0].label : RESEARCH_CONFERENCE_MEMBER_TYPES[1].label }}
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                      đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị </span>
                    <b-form-select
                      v-model="filter.itemsPerPage"
                      :options="itemsPerPageOptions"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap"> bản ghi/trang</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="totalRows"
                      :per-page="filter.itemsPerPage"
                      class="mt-1 mb-0"
                      @input="(value) => props.pageChanged({ currentPage: value })"
                    />
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>

    </b-card>

    <b-modal
      id="conferenceMembers"
      ref="conferenceMembers"
      ok-only
      ok-title="Chấp nhận"
      centered
      size="lg"
      :title="!dataForm ? 'Thêm thành viên' : 'Cập nhật thành viên'"
      no-close-on-backdrop
      :hide-footer="true"
    >
      <ConferenceMember
        :data-form="dataForm"
        :researchConferenceId="dataSend.id"
        @saveDataSuccess="saveDataSuccess"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BButton, BCard, BCol, BFormSelect, BOverlay, BPagination, BRow, VBTooltip,
  BFormGroup, BDropdown, BDropdownItem, BButtonGroup, BFormDatepicker, BFormInvalidFeedback
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
import ConferenceMember from '@/views/research/research-conferences/ConferenceMember.vue'
import ResearchConferenceMembers from '@/views/research/research-conferences/ResearchConferenceMembers.vue'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import ResearchOtherAttachDocuments from '@/views/research/research-conferences/ResearchOtherAttachDocuments.vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { RESEARCH_CONFERENCE_MEMBER_TYPES } from '@/const/type'

export default {
  name: 'Index',
  components: {
    VueGoodTable,
    vSelect,
    BButton,
    BCard,
    BCol,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    ConferenceMember,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    ResearchConferenceMembers,
    BFormDatepicker,
    BFormInvalidFeedback,
    ResearchOtherAttachDocuments,
  },
  props: {
    dataSend: {
      type: Object,
      default: {},
    },
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      currentMainData: null,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      filter: {
        researchConferenceId: null,
        currentPage: 1,
        itemsPerPage: 10,
        name: null,
        sort: null,
      },
      columns: [
        {
          label: 'Tên thành viên',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên thành viên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Là thành viên',
          field: 'memberType',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Thao tác',
          field: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      dataForm: undefined,
      RESEARCH_CONFERENCE_MEMBER_TYPES
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'researchConference/dataListsConferenceMember',
      totalRows: 'researchConference/totalRowsConferenceMember',
      researchAdministrativeLevels: 'researchConference/researchAdministrativeLevels',
      employees: 'employee/employees',
    }),
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.RESEARCH_CONFERENCE_MEMBER)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.RESEARCH_CONFERENCE_MEMBER)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.RESEARCH_CONFERENCE_MEMBER)
    },
  },
  async created() {
    this.isLoading = true
    try {
      this.updateParams({ researchConferenceId: this.dataSend.id })
      await Promise.all([
        this.getEmployees({ page: 1, itemsPerPage: 100 }),
        this.getDataSourcesFromStore()
      ])
    } catch (e) {
      this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getDataSources: 'researchConference/getResearchConferenceMembers',
      deleteData: 'researchConference/deleteResearchConferenceMember',
      getEmployees: 'employee/getEmployee',
    }),

    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(params) {
      this.updateParams({ currentPage: params.currentPage })
      await this.getDataSourcesFromStore()
    },
    async onPerPageChange(params) {
      this.updateParams({ itemsPerPage: params.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onSortChange(params) {
      if (params.length > 0) {
        const { field, type } = params[0]
        if (field === 'name') {
          this.updateParams({ sort: `0_${type}` })
        }
        if (field === 'memberType') {
          this.updateParams({ sort: `1_${type}` }) 
        }
      }
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      const fields = this.columns.map(column => column.field)
      fields.forEach(field => {
        if (Object.hasOwn(columnFilters, field)) {
          const data = {}
          data[field] = columnFilters[field]
          this.updateParams(data)
        }
      })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getDataSources(this.filter)
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
      } finally {
        this.isLoading = false
      }
    },

    editItem(data) {
      this.dataForm = data
      this.$bvModal.show('conferenceMembers')
    },

    deleteMember(data) {
      this.$swal({
        title: 'Bạn chắc chắn muốn xoá thành viên tham luận?',
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          this.isLoading = true
          try {
            const response = await this.deleteData(data.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.showToast('Đã xóa thành công!', 'CheckIcon', 'success')
                await this.getDataSources(this.filter)
              } else {
                this.showToast(`Có lỗi xảy ra ${message}`, 'XCircleIcon', 'warning')
              }
            }
          } catch (e) {
            this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger')
          } finally {
            this.isLoading = false
          }
        }
      })
    },

    showModal() {
      this.dataForm = undefined
      this.$bvModal.show('conferenceMembers')
    },

    async saveDataSuccess() {
      await this.getDataSourcesFromStore()
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    formatDate(date) {
      return date ? moment(date).format('DD/MM/YYYY') : ''
    },
    getMemberName(id) {
      return this.employees.find(member => member.id === id).fullName
    },
  },
}
</script>

<style lang="scss">
.research-table {
  min-height: 220px;
}
</style>
