var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"saveFormRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-modal',{attrs:{"id":"timeResearchTeacherResultSaveModal","body-class":"position-static","centered":"","title":'Cập nhật dữ lệu số giờ cần đạt của giảng viên',"no-close-on-backdrop":"","size":"lg"},on:{"show":_vm.onShow,"hidden":_vm.onHide},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.onSave('hide')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}}),_vm._v("Lưu lại ")],1)]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$bvModal.hide('timeResearchTeacherResultSaveModal')}}},[_c('span',{staticClass:"text-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}}),_vm._v("Hủy ")],1)])],1)]},proxy:true}],null,true)},[_c('b-form',[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"factYearId"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Năm học "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Tên loại giảng viên","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.factYears,"label":"name","disabled":"","reduce":function (option) { return option.id; }},model:{value:(_vm.targetItem.factYearId),callback:function ($$v) {_vm.$set(_vm.targetItem, "factYearId", $$v)},expression:"targetItem.factYearId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"timeResearchRequired"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Số giờ cần đạt"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Nhập số giờ cần đạt","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"timeResearchRequired","placeholder":"Nhập số giờ cần đạt","state":_vm.getElementState(errors)},model:{value:(_vm.targetItem.timeResearchRequired),callback:function ($$v) {_vm.$set(_vm.targetItem, "timeResearchRequired", $$v)},expression:"targetItem.timeResearchRequired"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"timeResult"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Kết quả số giờ quy đổi NCKH"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"Nhập kết quả số giờ quy đổi NCKH","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"timeResult","placeholder":"Nhập kết quả số giờ quy đổi NCKH","state":_vm.getElementState(errors)},model:{value:(_vm.targetItem.timeResult),callback:function ($$v) {_vm.$set(_vm.targetItem, "timeResult", $$v)},expression:"targetItem.timeResult"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }