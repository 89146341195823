<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Loại hình đào tạo:"
                  label-for="trainingSystemsSelected"
                >
                  <v-select
                    v-model="trainingSystemsSelected"
                    :options="trainingSystemsOptions"
                    :reduce="option => option.value"
                    @input="onChangeTrainingSystems"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Khóa học:"
                  label-for="coursesSelected"
                >
                  <v-select
                    v-model="coursesSelected"
                    :options="coursesOptions"
                    :reduce="option => option.value"
                    @input="onChangeCourses"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Kỳ học:"
                  label-for="semestersSelect"
                >
                  <v-select
                    v-model="semestersSelect"
                    :options="semestersOptions"
                    :reduce="option => option.value"
                    @input="onChangeSemesters"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6" v-show="false">
                <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="Ngành:"
                  label-for="majorsSelect"
                >
                  <v-select
                    v-model="majorsSelect"
                    :options="majorsOptions"
                    :reduce="option => option.value"
                    @input="onChangeMajors"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-show="creatable"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.revenuePlanSaveModal
                    variant="success"
                    style="margin-right: 5px"
                    @click="onCreate"
                  >
                    <feather-icon icon="PlusIcon" /> Thêm mới
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="onSelect"
                  >
                    <feather-icon icon="ListIcon" />Danh sách
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div class="d-flex align-items-center mb-1 mt-0">
                  <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                    đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                  </span>
                </div>
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  style-class="vgt-table striped bordered research-table"
                  row-style-class="vgt-row"
                  :columns="columns"
                  :rows="revenuePlans"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :line-numbers = "true"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'stt'">
                      {{ props.row.originalIndex + 1 }}
                    </span>
                    <span v-else-if="props.column.field === 'is_exemption'">
                      {{ props.row.is_exemption === 1 ? 'Có' : 'Không' }}
                    </span>

                    <span v-else-if="props.column.field === 'price'">
                      {{ new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(props.row.price) }}
                    </span>

                    <span v-else-if="props.column.field === 'total'">
                      {{ new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(props.row.total) }}
                    </span>

                    <span v-else-if="props.column.field === 'action'">
                      <b-button
                        v-if="updatable"
                        v-b-modal.revenuePlanSaveModal
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        @click="onEditRevenuePlan(props.row)"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>
                      <b-button
                        v-if="deletable"
                        variant="danger"
                        class="btn-icon"
                        style="margin-left: 6px"
                        size="sm"
                        @click="onDeleteRevenuePlan(props.row)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                          đến {{ (filter.itemsPerPage * (filter.currentPage) >= totalRows)? totalRows : filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
    <revenuePlanSave
      ref="revenuePlansSaveFormRef"
      :revenue-plan="currentRevenuePlan"
      @succeed="onSucceed"
    />
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormGroup, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import revenuePlanSave from '@/views/finance/revenue-plan/RevenuePlanSave.vue'
import vSelect from 'vue-select'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { getUser } from '@/auth/utils'

export default {
  name: 'RevenuePlan',
  directives: {
    Ripple,
  },
  components: {
    revenuePlanSave,
    BFormGroup,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    vSelect,
  },
  data() {
    return {
      user: getUser(),
      trainingSystemsSelected: undefined,
      coursesSelected: undefined,
      semestersSelect: undefined,
      majorsSelect: null,
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        name: null,
        organization_id: getUser().orgId,
        training_system_id: null,
        course_id: null,
        semester_id: null,
        major_id: null,
        sort: null,
      },
      currentRevenuePlan: {
        training_system_id: null,
        course_id: null,
        semester_id: null,
        major_id: null,
        revenue_id: null,
        price: null,
        total: null,
        start_at: null,
        end_at: null,
        course_semester_id: null,
        id: null,
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Loại khoản thu',
          field: 'revenue_type_name',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Tên khoản thu',
          field: 'revenue_name',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Khóa học',
          field: 'course_name',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Số tiền khoản thu',
          field: 'total',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Bắt đầu',
          field: 'start_at',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'dd/mm/yyyy',
        },
        {
          label: 'Kết thúc',
          field: 'end_at',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          type: 'date',
          dateInputFormat: 'yyyy-mm-dd',
          dateOutputFormat: 'dd/mm/yyyy',
        },
        {
          label: 'Kỳ học',
          field: 'semester_name',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Miễn giảm',
          field: 'is_exemption',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          width: '100px',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      revenuePlans: 'revenuePlan/revenuePlans',
      totalRows: 'revenuePlan/totalRows',
      allTrainingSystems: 'revenuePlan/allTrainingSystems',
      allMajors: 'revenuePlan/allMajors',
      listCourses: 'revenuePlan/listCourses',
      listSemesters: 'revenuePlan/listSemesters',
    }),
    trainingSystemsOptions() {
      return this.allTrainingSystems.map(item => ({ value: item.id, label: item.name }))
    },
    coursesOptions() {
      return this.listCourses.map(item => ({ value: item.id, label: item.name }))
    },
    semestersOptions() {
      return this.listSemesters.map(item => ({ value: item.id, label: item.name }))
    },
    majorsOptions() {
      return this.allMajors.map(item => ({ value: item.id, label: item.name }))
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.REVENUE_PLAN)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.REVENUE_PLAN)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.REVENUE_PLAN)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.begin({ organization_id: getUser().orgId })
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      begin: 'revenuePlan/begin',
      getRevenuePlans: 'revenuePlan/getRevenuePlans',
      getCourses: 'revenuePlan/getCourses',
      getSemesters: 'revenuePlan/getSemesters',
      deleteRevenuePlan: 'revenuePlan/deleteRevenuePlan',
    }),
    onCreate() {
      this.currentRevenuePlan = {
        training_system_id: null,
        courseId: null,
        semester_id: null,
        major_id: null,
        revenue_id: null,
        price: null,
        total: null,
        start_at: null,
        end_at: null,
        courseSemesterId: null,
        id: null,
      }
    },
    async onSelect() {
      this.isLoading = true
      try {
        await this.getRevenuePlans(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onChangeTrainingSystems() {
      if (this.trainingSystemsSelected == null) {
        this.reset()
        this.$store.commit('revenuePlan/SET_COURSES', { data: [] })
        this.$store.commit('revenuePlan/SET_SEMESTERS', { data: [] })
        this.coursesSelected = null
        this.semestersSelect = null
        this.currentRevenuePlan.course_semester_id = null
      } else {
        this.currentRevenuePlan.training_system_id = this.trainingSystemsSelected
        this.getCourses({
          training_system_id: this.trainingSystemsSelected,
        })
      }
      this.filter.training_system_id = this.trainingSystemsSelected
    },
    onChangeCourses() {
      if (this.coursesSelected == null) {
        this.reset()
        this.$store.commit('revenuePlan/SET_SEMESTERS', { data: [] })
        this.semestersSelect = null
        this.currentRevenuePlan.course_semester_id = null
      } else {
        this.currentRevenuePlan.course_id = this.coursesSelected
        this.filter.course_id = this.coursesSelected
        this.getSemesters({
          courses_id: this.coursesSelected,
        })
      }
    },
    onChangeSemesters() {
      if (this.semestersSelect == null) {
        this.reset()
        this.currentRevenuePlan.course_semester_id = null
      } else {
        this.currentRevenuePlan.semester_id = this.semestersSelect
        this.filter.semester_id = this.semestersSelect
        const item = this.listSemesters.find(x => x.id === this.semestersSelect)
        this.currentRevenuePlan.course_semester_id = item.courses_semesters_id
      }
    },
    onChangeMajors() {
      if (this.majorsSelect == null) {
        this.currentRevenuePlan.major_id = null
        this.reset()
      } else {
        this.currentRevenuePlan.major_id = this.majorsSelect
        this.filter.major_id = this.majorsSelect
      }
    },
    reset() {
      this.$store.commit('revenuePlan/SET_REVENUEPLANS', { total: 0, records: [] })
    },
    async onSelectRevenuePlan() {
      this.isLoading = true
      try {
        await this.getRevenuePlans(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onEditRevenuePlan(revenuePlan) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = revenuePlan
      this.currentRevenuePlan = { ...this.currentRevenuePlan, ...rest }
    },
    onDeleteRevenuePlan(revenuePlan) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa kế hoạch thu <span class="text-danger">${revenuePlan.revenue_name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteRevenuePlan(revenuePlan.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getRevenuePlans(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getRevenuePlansFromStore()
    },
    async getRevenuePlansFromStore() {
      this.isLoading = true
      try {
        await this.getRevenuePlans(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getRevenuePlansFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        if (field === 'name') this.updateParams({ sort: `1_${type}` })
      }
      await this.getRevenuePlansFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      await this.getRevenuePlansFromStore()
    },

    async onSucceed() {
      await this.getRevenuePlansFromStore()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
