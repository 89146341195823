<template>
  <div class="row">

    <b-container fluid>
      <b-card>
        <b-overlay
            :show="isLoading"
            rounded="sm"
        >
          <b-row>
            <b-col
                cols="12"
            >
              <b-row>
                <b-col
                    cols="12"
                    class="text-right"
                >
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mr-1"
                      @click="btnSearchClick()"
                    >
                      <feather-icon
                        icon="SearchIcon"
                        class="align-text-top"
                      />
                      Tìm kiếm
                    </b-button>
                    <b-button
                        v-if="creatable"
                        v-b-modal.attendanceMachineSaveModal
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        class="mr-1"
                        title="Thêm thông tin máy điểm danh"
                        @click="createPracticalResearch"
                    >
                      <feather-icon icon="PlusIcon" />
                      Thêm mới
                    </b-button>
                  </b-button-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div
              class="m-2"
          >
            <b-row>
              <b-col
                  cols="12"
              >
                <div class="d-flex align-items-center mb-1 mt-0">
                <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                  đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                </span>
                </div>
                <vue-good-table
                    mode="local"
                    class="my-table"
                    row-style-class="vgt-row"
                    style-class="vgt-table striped bordered"
                    :columns="columns"
                    :rows="attendanceMachines"
                    :pagination-options="paginationOptions"
                    :total-rows="totalRows"
                    :line-numbers="true"
                    @on-column-filter="onColumnFilter"
                    @on-page-change="onPageChange"
                    @on-per-page-change="onPerPageChange"
                >
                  <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                      slot="table-row"
                      slot-scope="props"
                  >
                  <span v-if="props.column.field === 'status'">
                    {{ getStatusName(props.row.status) }}
                  </span>

                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    <span
                        v-if="props.column.field === 'action'"
                        class="thead-group-action"
                    >
                    <b-button-group>
                      <b-button
                          v-if="updatable"
                          v-b-modal.attendanceMachineSaveModal
                          class="btn-icon"
                          variant="primary"
                          size="sm"
                          title="Chỉnh sửa"
                          @click="editPracticalResearch(props.row)"
                      >
                        <feather-icon icon="Edit2Icon" />
                      </b-button>

                      <b-button
                          v-if="deletable"
                          class="btn-icon"
                          variant="danger"
                          size="sm"
                          title="Xóa"
                          @click="onDeletePracticalResearch(props.row)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </b-button-group>
                  </span>
                  </template>
                  <template
                      slot="pagination-bottom"
                      slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                        đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                      </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                            v-model="filter.itemsPerPage"
                            :options="itemsPerPageOptions"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                            :value="1"
                            :total-rows="totalRows"
                            :per-page="filter.itemsPerPage"
                            class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
      <AttendanceMachineSave
          ref="attendanceMachineSaveModal"
          :item="currentAttendanceMachine"
          @succeed="getAttendanceMachineFromStore"
      />
    </b-container>
  </div>
</template>
<script>

import Ripple from 'vue-ripple-directive'
import {
  VBTooltip,
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AttendanceMachineSave from '@/views/attendance-machine/machine/AttendanceMachineSave.vue'
import { hasPermissionForResource, isSystemAdmin } from "@/utils";
import { PermissionCode, ResourceCode } from "@/const/code";

export default {
  name: 'PracticalResearch',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    AttendanceMachineSave,
    VueGoodTable,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        name: null,
        status: null,
      },
      currentAttendanceMachine: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'attendanceMachine/statuses',
      attendanceMachines: 'attendanceMachine/attendanceMachines',
      totalRows: 'attendanceMachine/totalRows',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.ATTENDANCE_MACHINE)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.ATTENDANCE_MACHINE)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.ATTENDANCE_MACHINE)
    },
    columns() {
      return [
        {
          label: 'Tên máy',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Mã máy',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          maxWidth: '130px',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    },
  },
  async created() {
    await this.getAttendanceMachineFromStore()
  },
  methods: {
    ...mapActions({
      readAttendanceMachines: 'attendanceMachine/readAttendanceMachines',
      deleteAttendanceMachine: 'attendanceMachine/deleteAttendanceMachine',
    }),
    createPracticalResearch() {
      this.currentAttendanceMachine = undefined
    },
    editPracticalResearch(row) {
      const {
        originalIndex,
        vgt_id,
        ...rest
      } = row
      this.currentAttendanceMachine = rest
    },
    async onDeletePracticalResearch(row) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa máy điểm danh <span class="text-danger">${row.name}</span>?`,
        text: `Xóa đề tài máy điểm danh`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteAttendanceMachine(row.id)
            const { isSuccessful, message } = response
            if (response) {
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.readAttendanceMachines(this.filter)
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async btnSearchClick() {
      this.isLoading = true
      try {
        await this.readAttendanceMachines(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async getAttendanceMachineFromStore() {
      this.isLoading = true
      try {
        await this.readAttendanceMachines(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getAttendanceMachineFromStore()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getAttendanceMachineFromStore()
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.subjectName })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getAttendanceMachineFromStore()
    },
  },
}
</script>
<style scoped lang="scss">

</style>
