<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="researchName">
              <template v-slot:label>
                Tên đề tài <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên đề tài"
                rules="required"
              >
                <b-form-input
                  id="researchName"
                  v-model="dataForm.researchName"
                  name="researchName"
                  disabled
                  placeholder="Nhập tên đề tài"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="3"
          >
            <b-form-group label-for="researchCode">
              <template v-slot:label>
                Mã đề tài <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã đề tài"
                rules="required"
              >
                <b-form-input
                  id="researchCode"
                  v-model="dataForm.researchCode"
                  name="researchCode"
                  disabled
                  placeholder="Nhập mã đề tài"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="9"
          >
            <b-form-group label-for="researchMemberName">
              <template v-slot:label>
                Thành viên <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Thành viên"
                rules="required"
              >
                <b-form-input
                  id="researchMemberName"
                  disabled
                  v-model="dataForm.researchMemberName"
                  name="researchMemberName"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group label-for="departmentName">
              <template v-slot:label>
                Đơn vị công tác <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Đơn vị công tác"
                rules="required"
              >
                <b-form-input
                  id="departmentName"
                  disabled
                  v-model="dataForm.departmentName"
                  name="departmentName"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label-for="researchInnovationReviewerId"
            >
              <template v-slot:label>
                Người nhận xét, đánh giá <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Người nhận xét, đánh giá"
                rules="required"
              >
                <v-select
                  v-model="dataForm.researchInnovationReviewerId"
                  label="label"
                  placeholder="Chọn người nhận xét, đánh giá"
                  :options="researchInnovationReviewers"
                  :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group
              label="Kết quả"
              label-for="score"
              label-class="mb-1"
            >
              <template v-slot:label>
                Kết quả <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Kết quả"
                rules="required"
              >
                <b-form-radio-group
                  id="score"
                  v-model="dataForm.score"
                  :options="dataListOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label-for="evaluation">
              <template v-slot:label>
                Đánh giá, nhận xét
              </template>
              <b-form-textarea
                id="evaluation"
                v-model="dataForm.evaluation"
                name="evaluation"
                placeholder="Nhập đánh giá, nhận xét"
                rows="3"
                max-rows="6"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="float-left">
              <b-button
                v-show="checkShowSaveButton"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="invalid"
                @click.stop.prevent="handleSaveData(-1)"
              >
                Lưu nháp
              </b-button>
              <b-button
                v-show="checkShowSaveButton"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="invalid"
                @click.stop.prevent="handleSaveData(1)"
              >
                Chốt
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BForm,
  BButton,
  BFormRadioGroup,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BOverlay,
  BFormTextarea, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { RESEARCH_RESULT_STATUSES } from '@/const/status'

export default {
  name: 'Form',
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    vSelect,
    BFormRadioGroup,
    BFormTextarea,
    BFormInvalidFeedback,
  },
  props: {
    idResearchInnovationEvaluation: {
      type: Number,
      default: undefined,
    },
    yearId: {
      type: Number,
      default: undefined,
    },
    researchId: {
      type: Number,
      default: undefined,
    },
    checkShowSaveButton: {
      type: Boolean,
      default: undefined,
    },
    researchInnovationReviewerId: {
      type: Number,
      default: undefined,
    },
    score: {
      type: Number,
      default: undefined,
    },
    evaluation: {
      type: String,
      default: undefined,
    },
    dataForm: {
      type: Object,
      default: () => ({
        id: null,
        researchId: null,
        researchName: '',
        researchCode: '',
        researchMemberName: '',
        departmentName: '',
        researchInnovationReviewerId: null,
        evaluation: '',
        score: null,
        status: 1,
      }),
    },
    modalShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      cUser: getUser(),
      numberOption: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      researchInnovationReviewers: [],
      editorConfig: {},
      required,
    }
  },
  computed: {
    ...mapGetters({
      reviewersByYear: 'researchInnovationReviewer/reviewersByYear',
    }),
    dataListOptions() {
      return RESEARCH_RESULT_STATUSES
    },
  },
  async created() {
    this.dataForm.researchName = this.dataForm.name
    this.dataForm.researchCode = this.dataForm.code
    this.dataForm.researchMemberName = this.dataForm.employees
    this.dataForm.researchId = this.researchId
    this.dataForm.id = this.idResearchInnovationEvaluation
    await this.getDataSources({ yearId: this.yearId })
    this.researchInnovationReviewers = this.reviewersByYear.map((item => ({
      value: item.employeeId,
      label: item.fullName,
    })))
    if (this.researchInnovationReviewerId !== null) {
      this.dataForm.researchInnovationReviewerId = this.researchInnovationReviewerId
      this.dataForm.score = this.score
      this.dataForm.evaluation = this.evaluation
    }
  },
  methods: {
    ...mapActions({
      saveData: 'researchInnovationReviewer/saveDataResearchInnovationEvaluation',
      getDataSources: 'researchInnovationReviewer/getReviewersByYear',
    }),
    async handleSaveData(status) {
      this.isLoading = true
      try {
        this.dataForm.status = status;
        const res = await this.saveData(this.dataForm);
        if (res.isSuccessful) {
          this.showToast(res.message, 'CheckIcon', 'success');
          this.$bvModal.hide('formResearchInnovationEvaluation');
          this.$emit('saveDataSuccess', true);
        } else {
          this.showToast(res.message, 'XCircleIcon', 'danger', '')
        }
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'XCircleIcon', 'danger');
      } finally {
        this.isLoading = false;
      }
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>
