import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import {CREDIT_CLASS_STUDENT_STATUSES, ELIGIBLE_STATUSES, STATUS_SYMBOLS} from '@/const/status'

export const baseAttr = Object.freeze({
  basePath: ApiExtRoutes.creditClassStudent,
  maxOrderNoPath: ApiExtRoutes.creditClassStudentMaxOrderNo,
})
export default {
  namespaced: true,
  state: {
    dataLists: [],
    totalRows: 0,
    statuses: CREDIT_CLASS_STUDENT_STATUSES,
    eligible: ELIGIBLE_STATUSES,
    absentStatus: STATUS_SYMBOLS,
    resourceName: {
      fullName: 'Học viên',
      shortName: 'Học viên',
    },
    maxOrderNo: 0,
    studentRelearning: [],
    studentRelearningInCreditClass: [],
    turns: [],
  },
  getters: {
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    eligible: state => state.eligible,
    resourceName: state => state.resourceName,
    maxOrderNo: state => state.maxOrderNo,
    absentStatus: state => state.absentStatus,
    studentRelearning: state => state.studentRelearning,
    studentRelearningInCreditClass: state => state.studentRelearningInCreditClass,
    turns: state => state.turns,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_MAX_ORDER_NO: (state, maxOrderNo) => {
      state.maxOrderNo = maxOrderNo
    },
    SET_STUDENT_RELEARNING: (state, studentRelearning) => {
      state.studentRelearning = studentRelearning
    },
    SET_STUDENT_RELEARNING_IN_CREDIT_CLASS: (state, studentRelearningInCreditClass) => {
      state.studentRelearningInCreditClass = studentRelearningInCreditClass
    },
    SET_TOTAL_ROWS: (state, totalRows) => {
      state.totalRows = totalRows
    },
    SET_TURNS: (state, turns) => {
      state.turns = turns
    },
  },
  actions: {
    async getData({ commit }, params) {
      try {
        const response = await axios.get(baseAttr.basePath, { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async createData(commit, params) {
      try {
        const response = await axios.post(baseAttr.basePath, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateData({ commit }, params) {
      try {
        const response = await axios.put(`${baseAttr.basePath}/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`${baseAttr.basePath}/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getMaxOrderNo({ commit }, id) {
      try {
        const response = await axios.get(`${baseAttr.maxOrderNoPath}${id || ''}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { maxOrderNo } = data.data
          commit('SET_MAX_ORDER_NO', maxOrderNo)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateExamConditionsAutomatically({ commit }, params) {
      try {
        const response = await axios.put('uni/credit_classes/update_exam_conditions_automatically', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    // eslint-disable-next-line no-unused-vars
    async createAbsent({ commit }, params) {
      try {
        const response = await axios.post('uni/students_absent_logs', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    // eslint-disable-next-line no-unused-vars
    async getAbsentTimeByStudentCreditClassId({ commit }, params) {
      try {
        const response = await axios.get('uni/students_absent_logs/get_absent_time_by_student_credit_class_id', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
        return null
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getAbsentTimeByStudentCreditClassIdAndNotLearn({ commit }, params) {
      try {
        const response = await axios.get('uni/students_absent_logs/get_absent_time_by_student_credit_class_id_and_not_learn', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
        return null
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateStudentAbsentLogStatus({ commit }, params) {
      try {
        const response = await axios.put(`uni/students_absent_logs/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getStudentReLearningByCreditClass({ commit }, params) {
      try {
        const response = await axios.get('uni/credit_class/students/student-reLearning', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_STUDENT_RELEARNING', records)
        }
        return null
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async insertStudentReLearningInCreditClass(commit, params) {
      try {
        const response = await axios.post('uni/credit_class_relearning', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getStudentReLearningInCreditClass({ commit }, params) {
      try {
        const response = await axios.get('uni/credit_class_relearning', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_STUDENT_RELEARNING_IN_CREDIT_CLASS', records)
          commit('SET_TOTAL_ROWS', total)
        }
        return null
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getTurns({ commit }) {
      try {
        const response = await axios.get('uni/turns/get_all_turn')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const records = data.data
          commit('SET_TURNS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async deleteCreditClassStudentReLearning({ commit }, params) {
      try {
        const response = await axios.delete(`uni/credit_class_relearning/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
