<template>
  <div>
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div class="m-2">
            <b-row>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Chương trình đào tạo/Bồi dưỡng"
                  label-for="trainingSystemSelected"
                >
                  <v-select
                    v-model="trainingSystemSelected"
                    :options="allTrainingSystems"
                    :reduce="(option) => option.id"
                    label="name"
                    @input="onChangeTrainingSystem"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Khóa học"
                  label-for="courseSelected"
                >
                  <v-select
                    v-model="courseSelected"
                    :options="listTrainingSystemCourses"
                    :reduce="(option) => option.id"
                    label="name"
                    @input="onChangeCourse"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
              >
                <b-form-group
                  label="Lớp học"
                  label-for="classSelect"
                >
                  <v-select
                    v-model="classSelect"
                    :options="listCourseClasses"
                    :reduce="(option) => option.id"
                    label="name"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="6"
                xl="6"
                class="d-flex align-items-center"
              >
                <b-form-checkbox
                  v-model="secondExam"
                  :value="1"
                  :unchecked-value="0"
                >
                  Tính theo điểm thi lần 2
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <div class="text-right">
                  <b-button
                    v-show="classSelect > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="onSelect"
                  >
                    <span class="text-nowrap text-right">Danh sách</span>
                  </b-button>
                  <b-button
                    v-show="itemsTable.length > 0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-right: 5px"
                    @click="exportBangXetTotNghiep"
                  >
                    <span class="text-nowrap text-right">Xuất bảng điểm toàn khóa</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12">
                <div style="overflow: auto;">
                  <b-table
                    ref="table"
                    :small="true"
                    :hover="true"
                    :items="itemsTable"
                    :fields="fieldsTable"
                  >
                    <template #cell(index)="data">
                      {{ data.index + 1 }}
                    </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer, BFormCheckbox,
  BFormGroup,
  BOverlay,
  BRow,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'CourseScoreClass',
  directives: {
    Ripple,
  },
  components: {
    BFormCheckbox,
    BTable,
    BButton,
    BFormGroup,
    BCard,
    BCol,
    BContainer,
    BOverlay,
    BRow,
    vSelect,
  },
  data() {
    return {
      trainingSystemSelected: undefined,
      courseSelected: undefined,
      majorSelected: undefined,
      classSelect: undefined,
      secondExam: 0,
      isLoading: false,
      fieldsTable: [
        'Mã học viên',
        'Họ và tên',
        'Ngày, tháng, năm sinh',
        'Quê quán',
        'Trung bình thi tốt nghiệp',
        'Khóa luận tốt nghiệp',
        'Tổng điểm trung bình toàn khóa',
        'Xếp loại học tập',
        'Điểm rèn luyện',
        'Xếp loại rèn luyện',
        'Ghi chú',
      ],
      itemsTable: [],
    }
  },
  computed: {
    ...mapGetters({
      dataLists: 'courseScoreClass/dataLists',
      allYears: 'courseScoreClass/allYears',
      allTrainingSystems: 'courseScoreClass/allTrainingSystems',
      listTrainingSystemCourses: 'courseScoreClass/listTrainingSystemCourses',
      listCourseMajors: 'courseScoreClass/listCourseMajors',
      listCourseClasses: 'courseScoreClass/listCourseClasses',
      listYearSemesters: 'courseScoreClass/listYearSemesters',
      listSubjectsOrder: 'courseScoreClass/listSubjectsOrder',
      dataExport: 'courseScoreClass/dataExport',
    }),
  },
  async created() {
    this.isLoading = true
    try {
      await this.begin()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      begin: 'courseScoreClass/begin',
      getData: 'courseScoreClass/getData',
      getListTrainingSystemCourses:
          'courseScoreClass/getListTrainingSystemCourses',
      getListCourseMajors: 'courseScoreClass/getListCourseMajors',
      getListCourseClasses: 'courseScoreClass/getListCourseClasses',
      getListSubjectsOrder: 'courseScoreClass/getListSubjectsOrder',
      getDataExport: 'courseScoreClass/downloadExport',
    }),

    async onChangeTrainingSystem() {
      if (this.trainingSystemSelected === null) {
        this.reset()
        this.$store.commit('courseScoreClass/SET_LISTTRAININGSYSTEMCOURSES', {
          data: [],
        })
        this.$store.commit('courseScoreClass/SET_LISTCOURSEMAJORS', {
          data: [],
        })
        this.$store.commit('courseScoreClass/SET_LISTCOURSECLASSES', {
          data: [],
        })
        this.courseSelected = null
        this.majorSelected = null
        this.classSelect = null
      } else {
        this.isLoading = true
        try {
          await this.getListTrainingSystemCourses({
            training_system_id: this.trainingSystemSelected,
          })
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    async onChangeCourse() {
      if (this.courseSelected === null) {
        this.reset()
        this.$store.commit('courseScoreClass/SET_LISTCOURSEMAJORS', {
          data: [],
        })
        this.$store.commit('courseScoreClass/SET_LISTCOURSECLASSES', {
          data: [],
        })
        this.majorSelected = null
        this.classSelect = null
      } else {
        this.isLoading = true
        try {
          await this.getListCourseClasses({
            course_id: this.courseSelected,
          })
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    reset() {
      this.$store.commit('courseScoreClass/SET_DATA', { data: [] })
      this.itemsTable = this.dataLists
      this.$refs.table.refresh()
      this.$store.commit('courseScoreClass/SET_LISTSUBJECTSORDER', {
        data: null,
      })
      if (this.secondExam === 0) {
        this.fieldsTable = [
          'Mã học viên',
          'Họ và tên',
          'Ngày, tháng, năm sinh',
          'Quê quán',
          'Trung bình thi tốt nghiệp',
          'Khóa luận tốt nghiệp',
          'Tổng điểm trung bình toàn khóa',
          'Xếp loại học tập',
          'Điểm rèn luyện',
          'Xếp loại rèn luyện',
          'Ghi chú',
        ]
      } else {
        this.fieldsTable = [
          'Mã học viên',
          'Họ và tên',
          'Ngày, tháng, năm sinh',
          'Quê quán',
          'Khóa luận tốt nghiệp',
        ]
      }
    },
    async onSelect() {
      this.isLoading = true
      try {
        await this.getListSubjectsOrder({
          class_id: this.classSelect,
        })
        if (this.secondExam === 0) {
          this.fieldsTable = [
            'Mã học viên',
            'Họ và tên',
            'Ngày, tháng, năm sinh',
            'Quê quán',
            'Trung bình thi tốt nghiệp',
            'Khóa luận tốt nghiệp',
            'Tổng điểm trung bình toàn khóa',
            'Xếp loại học tập',
            'Điểm rèn luyện',
            'Xếp loại rèn luyện',
            'Ghi chú',
          ]
        } else {
          this.fieldsTable = [
            'Mã học viên',
            'Họ và tên',
            'Ngày, tháng, năm sinh',
            'Quê quán',
            'Khóa luận tốt nghiệp',
          ]
        }
        if (this.listSubjectsOrder !== null) {
          // eslint-disable-next-line prefer-spread
          this.fieldsTable.splice.apply(
            this.fieldsTable,
            [4, 0].concat(this.listSubjectsOrder.split(';')),
          )
          try {
            await this.getData({
              class_id: this.classSelect,
              second_exam: this.secondExam,
            })
            this.itemsTable = this.dataLists
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        } else {
          this.reset()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Không có học phần nào!',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async exportBangXetTotNghiep() {
      this.isLoading = true
      try {
        await this.getDataExport({
          class_id: this.classSelect,
          second_exam: this.secondExam,
        })
        const a = document.createElement('a')
        document.body.appendChild(a)
        const blob = new Blob([this.dataExport.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = 'bang_diem_toan_khoa.xlsx'
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
