<template src="./index.html" />

<script>
import {
  BBreadcrumb, BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BLink, BTooltip, VBTooltip, BFormDatepicker, BForm, BFormGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import '@core/scss/vue/libs/vue-good-table.scss'
import { CATEGORY_LOGS } from '@/const/status'
import moment from 'moment'
import { isSystemAdmin } from '@/utils'
import { getUser } from '@/auth/utils'

export default {
  name: 'Index',
  components: {
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BLink,
    BTooltip,
    VBTooltip,
    VueGoodTable,
    BFormDatepicker,
    BForm,
    BFormGroup,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      table: {
        fields: [
          {
            label: 'STT',
            field: 'rowNum',
            sortable: false,
            width: '5%',
            tdClass: 'text-center',
            thClass: 'text-center',
          },
          {
            label: 'CODE',
            field: 'name',
          },
          {
            label: 'Nôi dung',
            field: 'label',
            sortable: false,
          },
          {
            label: 'IP',
            field: 'ip',
            sortable: false,
          },
          {
            label: 'Trình duyệt',
            field: 'useAgent',
            sortable: false,
          },
          {
            label: 'Thực hiển bởi',
            field: 'createdByName',
            sortable: false,
          },
          {
            label: 'Thời gian thực hiện',
            field: 'createdAt',
            sortable: false,
          },
          // {
          //   label: 'Thao tác',
          //   field: 'action',
          //   thClass: 'text-center',
          //   tdClass: 'text-center',
          //   width: '10%',
          //   sortable: false,
          // },
        ],
        rows: [],
      },
      filter: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 20,
        code: '',
        startAt: '',
        username: '',
        endAt: new Date(),
      },
      cUser: getUser(),
      // categoryLogs: []
    }
  },

  async created() {
    // init data FROM API
    const before7Days = new Date()
    before7Days.setDate(before7Days.getDate() - 7)
    this.filter.startAt = before7Days
    if (isSystemAdmin()) {
      await this.getAccounts()
    } else {
      this.filter.username = this.cUser.username
    }
    await this.getInitData()
  },

  computed: {
    // calculate data from init data
    ...mapGetters({
      logs: 'logActivities/logActivities',
      total: 'logActivities/total',
      accounts: 'account/accounts',
    }),
    startAt() {
      const futureDate = new Date()
      futureDate.setDate(futureDate.getDate() - 7)
      return futureDate
    },
    categoryLogs() {
      return CATEGORY_LOGS
    },
  },
  watch: {
    // listening change data init

  },
  methods: {
    isSystemAdmin,
    // handle even
    ...mapActions({
      getLogs: 'logActivities/getLogActivities',
      getAccounts: 'account/getListAccount',
    }),

    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.getInitData()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      this.getInitData()
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      })
      this.getInitData()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      this.getInitData()
    },

    async getInitData() {
      this.isLoading = true
      try {
        const params = {
          currentPage: this.filter.page,
          itemsPerPage: this.filter.perPage,
          codeModule: this.filter.code,
          startAt: this.format_date(this.filter.startAt),
          endAt: this.format_date(this.filter.endAt),
          username: this.filter.username ? this.filter.username : null,
        }
        await this.getLogs(params)
        this.table.rows = this.logs
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'errors', '')
      } finally {
        this.isLoading = false
      }
    },

    format_date(value) {
      if (value) {
        return moment(String(value)).format('yyyy-MM-DD')
      }
      return 'yyyy-MM-DD'
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },

    getBrowser(userAgent) {
      let browser = ''
      if (userAgent.toLowerCase().includes('msie')) {
        browser = 'IE'
      } else if (userAgent.toLowerCase().includes('trident/7')) {
        browser = 'IE'
      } else if (userAgent.toLowerCase().includes('chrome')) {
        browser = 'CHROME'
      } else if (userAgent.toLowerCase().includes('firefox')) {
        browser = 'FIREFOX'
      } else if (userAgent.toLowerCase().includes('safari')) {
        browser = 'SAFARI'
      } else if (userAgent.toLowerCase().includes('opera')) {
        browser = 'OPERA'
      } else {
        browser = 'OTHER'
      }
      return browser
    },

  },
}
</script>

<style scoped>

</style>
