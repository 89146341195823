import { STATUSES, STATUSES_CREDENTIAL } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    statusesCredential: STATUSES_CREDENTIAL,
    statuses: STATUSES,
    credentials: [],
    totalRows: 0,
  },
  getters: {
    statusesCredential: state => state.statusesCredential,
    statuses: state => state.statuses,
    credentials: state => state.credentials,
    totalRows: state => state.totalRows,
  },
  mutations: {
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
    SET_CREDENTIALS: (state, credentials) => {
      state.credentials = credentials
    },
  },
  actions: {
    async readCredentials({ commit }, params) {
      try {
        const response = await axios.get('certificates/credentials', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_CREDENTIALS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createCredential({ commit }, params) {
      try {
        const response = await axios.post('certificates/credentials', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateCredential({ commit }, params) {
      try {
        const response = await axios.put(`certificates/credentials/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteCredential({ commit }, id) {
      try {
        const response = await axios.delete(`certificates/credentials/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getCredentialById({ commit }, id) {
      try {
        const response = await axios.get(`certificates/credentials/getById?id=${id}`)
        const { data } = response
        return { data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
