import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
    namespaced: true,
    state: {
        totalRows: 0,
        statuses: STATUSES,
        timeQuotaResearch: [],
        titles: [],
    },
    getters: {
        totalRows: state => state.totalRows,
        statuses: state => state.statuses,
        timeQuotaResearch: state => state.timeQuotaResearch,
        titles: state => state.titles,
    },
    mutations: {
        SET_TOTAL_ROWS: (state, totalRows) => {
            state.totalRows = totalRows
        },
        SET_TIME_QUOTA_RESEARCH: (state, timeQuotaResearch) => {
            state.timeQuotaResearch = timeQuotaResearch
        },
        SET_TITLES: (state, titles) => {
            state.titles = titles
        },
    },
    actions: {
        async getTitles({ commit }, params) {
            try {
                const response = await axios.get('uni/factTitles/selection', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { titles } = data.data
                    commit('SET_TITLES', titles)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async readTimeQuotaResearch({ commit }, params) {
            try {
                const response = await axios.get('uni/tw_time_quota_research', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { total, records } = data.data
                    commit('SET_TIME_QUOTA_RESEARCH', records)
                    commit('SET_TOTAL_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async createTimeQuotaResearch({ commit }, params) {
            try {
                const response = await axios.post('uni/tw_time_quota_research', params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async updateTimeQuotaResearch({ commit }, params) {
            try {
                const response = await axios.put(`uni/tw_time_quota_research/${params.id}`, params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async deleteTimeQuotaResearch({ commit }, id) {
            try {
                const response = await axios.delete(`uni/tw_time_quota_research/${id}`)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
    },
}
