import { STATUSES } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    statuses: STATUSES,
    totalRows: 0,
    studentsPracticalResearch: [],
    groupsPracticalResearch: [],
  },
  getters: {
    statuses: state => state.statuses,
    totalRows: state => state.totalRows,
    studentsPracticalResearch: state => state.studentsPracticalResearch,
    groupsPracticalResearch: state => state.groupsPracticalResearch,
  },
  mutations: {
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
    SET_STUDENTS_PRACTICAL_RESEARCH: (state, data) => {
      state.studentsPracticalResearch = data
    },
    SET_GROUPS_PRACTICAL_RESEARCH: (state, data) => {
      state.groupsPracticalResearch = data
    },
  },
  actions: {
    async readGroupsPracticalResearch({ commit }, params) {
      try {
        const response = await axios.get('uni/practical_research_class_groups', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_GROUPS_PRACTICAL_RESEARCH', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async readStudentsPracticalResearch({ commit }, params) {
      try {
        const response = await axios.get('uni/practical_research_class_students', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_STUDENTS_PRACTICAL_RESEARCH', records)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createPracticalResearchClassGroup({ commit }, params) {
      try {
        const response = await axios.post('uni/practical_research_class_groups', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updatePracticalResearchClassGroup({ commit }, params) {
      try {
        const response = await axios.put(`uni/practical_research_class_groups/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
