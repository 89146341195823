import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    schedules: [],
  },
  getters: {
    schedules: state => state.schedules,
  },
  mutations: {
    SET_SCHEDULES: (state, data) => {
      state.schedules = data
    },
  },
  actions: {
    async getSchedules({ commit }) {
      try {
        const response = await axios.get('uni/backupSchedules')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_SCHEDULES', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createSchedule(commit, params) {
      try {
        const response = await axios.post('uni/backupSchedules', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateSchedule({ commit }, params) {
      try {
        const response = await axios.put(`uni/backupSchedules/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteSchedule({ commit }, id) {
      try {
        const response = await axios.delete(`uni/backupSchedules/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async changeScheduleStatus({ commit }, params) {
      try {
        const response = await axios.put('uni/backupSchedules/changeStatus', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
