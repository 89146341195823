import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { STATUSES } from '@/const/status'

export default {
  namespaced: true,
  state: {
    totalRows: 0,
    statuses: STATUSES,
    subjectTypes: [],
    credentialConditions: [],
  },
  getters: {
    totalRows: state => state.totalRows,
    statuses: state => state.statuses,
    subjectTypes: state => state.subjectTypes,
    credentialConditions: state => state.credentialConditions,
  },
  mutations: {
    SET_TOTAL_ROWS: (state, total) => {
      state.totalRows = total
    },
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_SUBJECT_TYPES: (state, subjectTypes) => {
      state.subjectTypes = subjectTypes
    },
    SET_CREDENTIAL_CONDITIONS: (state, credentialConditions) => {
      state.credentialConditions = credentialConditions
    },
  },
  actions: {
    async begin({ commit }, params) {
      try {
        const response = await axios.get('/certificates/credential_conditions/begin', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { subjectTypes } = data.data
          commit('SET_SUBJECT_TYPES', subjectTypes)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async readCredentialConditions({ commit }, params) {
      try {
        const response = await axios.get('certificates/credential_conditions', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_CREDENTIAL_CONDITIONS', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteCredentialCondition({ commit }, id) {
      try {
        const response = await axios.delete(`certificates/credential_conditions/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createCredentialCondition({ commit }, params) {
      try {
        const response = await axios.post('certificates/credential_conditions', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateCredentialCondition({ commit }, params) {
      try {
        const response = await axios.put(`certificates/credential_conditions/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

  },
}
