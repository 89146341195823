import { STATUSES } from '@/const/status'
import axios from '@/libs/axios'
import {ApiCode} from '@/const/api'
import {handleCatch} from '@/utils'

export default {
    namespaced: true,
    state: {
        statuses: STATUSES,
        practicalResearchProgram: [],
        totalRows: 0,
        course: [],
        teachers: [],
        departments: [],
        practicalResearchClassGroupTeachers: [],
    },
    getters: {
        statuses: state => state.statuses,
        totalRows: state => state.totalRows,
        practicalResearchProgram: state => state.practicalResearchProgram,
        course: state => state.course,
        teachers: state => state.teachers,
        departments: state => state.departments,
        practicalResearchClassGroupTeachers: state => state.practicalResearchClassGroupTeachers,
    },
    mutations: {
        SET_STATUSES: (state, statuses) => {
            state.statuses = statuses
        },
        SET_TOTAL_ROWS: (state, data) => {
            state.totalRows = data
        },
        SET_PRACTICAL_RESEARCH_PROGRAM: (state, data) => {
            state.practicalResearchProgram = data
        },
        SET_COURSE: (state, data) => {
            state.course = data
        },
        SET_TEACHERS: (state, data) => {
            state.teachers = data
        },
        SET_DEPARTMENTS: (state, data) => {
            state.departments = data
        },
        SET_PRACTICAL_RESEARCH_CLASS_GROUP_TEACHERS: (state, data) => {
            state.practicalResearchClassGroupTeachers = data
        },
    },
    actions: {
        async readPracticalResearchClassGroupTeacher({ commit }, params) {
            try {
                const response = await axios.get('uni/practical_research_class_group_teachers', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { total, records } = data.data
                    commit('SET_PRACTICAL_RESEARCH_CLASS_GROUP_TEACHERS', records)
                    commit('SET_TOTAL_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async updatePracticalResearchClassGroupTeacher({ commit }, params) {
            try {
                const response = await axios.put(`uni/practical_research_class_group_teachers/${params.id}`, params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async getTeachers({ commit }, params) {
            try {
                const response = await axios.get('uni/teachers', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { records } = data.data
                    commit('SET_TEACHERS', records)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getDepartments({ commit }, params) {
            try {
                const response = await axios.get('uni/departments', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { total, records } = data.data
                    commit('SET_DEPARTMENTS', records)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
    },
}
