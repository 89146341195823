<template>
  <b-modal
    id="reportConfigurationModal"
    body-class="position-static"
    size="lg"
    centered
    hide-footer
    title="Danh sách cấu hình"
    @show="onShow"
    :no-close-on-backdrop="true"
  >
    <div class="m-2">
      <b-row>
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="dataSources"
            :pagination-options="paginationOptions"
            :line-numbers="true"
          >
            <div
              slot="emptystate"
              style="text-align: center; font-weight: bold"
            >
              Không có bản ghi nào !
            </div>
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'action'">
                <b-button
                  v-if="updatable"
                  v-b-modal.reportConfigurationSaveModal
                  variant="primary"
                  class="btn-icon"
                  size="sm"
                  @click="onEditConfiguration(props.row)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>{{ props.formattedRow[props.column.field] }}</span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Hiển thị 1 đến </span>
                  <b-form-select
                    v-model="itemsPerPage"
                    :options="itemsPerPageOptions"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="itemsPerPage"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  />
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </div>
    <b-overlay
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      :show="isLoading"
    />
    <ReportConfigurationSave
      :configuration="currentConfiguration"
      @succeed="onShow"
    />
  </b-modal>
</template>

<script>
import {
  BButton, BCol, BFormSelect, BModal, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import ReportConfigurationSave from '@/views/report/manage/ReportConfigurationSave.vue'

export default {
  name: 'ReportConfiguration',
  components: {
    ReportConfigurationSave,
    BPagination,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BModal,
    BOverlay,
    VueGoodTable,
  },
  props: {
    reportId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên cấu hình',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'Nhập tên cấu hình',
          },
          thClass: 'text-center',
        },
        {
          label: 'Giá trị',
          field: 'value',
          filterOptions: {
            enabled: true,
            trigger: 'Nhập giá trị',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
      currentConfiguration: undefined,
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'manageReport/reportConfigurations',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    updatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.REPORT_CONFIGURATION)
    },
    deletable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.REPORT_CONFIGURATION)
    },
  },
  methods: {
    ...mapActions({
      getReportConfigurationsByReportId: 'manageReport/getReportConfigurationsByReportId',
    }),
    isSystemAdmin,
    async onShow() {
      this.isLoading = true
      try {
        await this.getReportConfigurationsByReportId(this.reportId)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onEditConfiguration(configuration) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = configuration
      this.currentConfiguration = rest
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
