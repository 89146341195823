<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>{{ this.title }}</b-card-title>
    </b-card-header>
    
    <b-card-body>
      <div
        v-for="transaction in transactionData"
        :key="transaction.mode"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="transaction.avatarVariant"
            >
              <feather-icon
                size="18"
                animation="cylon"
                :icon="transaction.avatar"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title font-medium-1">
              {{ transaction.mode }}
            </h6>
            <small>{{ transaction.types }}</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder font-large-1"
          :class="transaction.deduction ? 'text-danger':'text-success'"
        >
          {{ transaction.value }}
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      transactionData: [
        {
          mode: 'Tống số SV',
          types: 'SV',
          // avatar: 'PocketIcon',
          avatarVariant: 'light-primary',
          value: 0,
          deduction: false,
        },
        {
          mode: 'Số lượng SV tăng thêm trong năm',
          types: 'SV',
          // avatar: 'CheckIcon',
          avatarVariant: 'light-success',
          value: 0,
          deduction: false,
        },
        {
          mode: 'Số lượng SV đạt học bổng trong năm',
          types: 'SV',
          avatar: 'GiftIcon',
          avatarVariant: 'light-danger',
          value: 0,
          deduction: false,
        },
        {
          mode: 'Số lượng SV được khen thưởng trong năm',
          types: 'SV',
          avatar: 'AwardIcon',
          avatarVariant: 'light-warning',
          value: 0,
          deduction: false,
        },
        {
          mode: 'Số lượng SV được nhận TCSP và CĐCS trong năm',
          types: 'SV',
          avatar: 'DollarSignIcon',
          avatarVariant: 'light-info',
          value: 0,
          deduction: false,
        },
      ],
    }
  },
  computed: {
    ...mapGetters( {
      countStudent: 'chart/countStudent',
    }),
  },
  watch: {
    'filter.trainingSystemId': async function () {
      await this.getDataFromStore()
    },
    'filter.yearId': async function () {
      await this.getDataFromStore()
    },
    'filter.semesterId': async function () {
      await this.getDataFromStore()
    }
  },
  async created() {
    await this.getDataFromStore()
  },
  methods:{
    ...mapActions({
      getStudentOverview: 'chart/getStudentOverview',
    }),
    async getDataFromStore(){
      this.isLoading = true
      try {
        await this.getStudentOverview(this.filter)
        if (this.countStudent.length > 0) {
          this.transactionData[0].value = this.countStudent[0].countStudentSum
          this.transactionData[1].value = this.countStudent[0].countStudentCurrent
          this.transactionData[2].value = this.countStudent[0].scholarship
          this.transactionData[3].value = this.countStudent[0].award
          this.transactionData[4].value = this.countStudent[0].policy + this.countStudent[0].allowance
        }
        else {
          this.transactionData[0].value = 0
          this.transactionData[1].value = 0
          this.transactionData[2].value = 0
          this.transactionData[3].value = 0
          this.transactionData[4].value = 0
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  }
}
</script>
