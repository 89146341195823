<template>
  <validation-observer
    ref="teacherSubjectFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="teacherSubjectModal"
      body-class="position-static"
      centered
      size="lg"
      :title="'Phân công giảng viên - học phần'"
      @show="onShow"
      @hide="onHide"
      :no-close-on-backdrop="true"
    >
      <b-form>
        <b-form-group label-for="subject">
          <template v-slot:label>
            Học phần <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Học phần"
            rules="required"
          >
            <v-select
              v-model="subjectSelected"
              :options="subjectOptions"
              :reduce="option => option.value"
              @input="onChangeSubject"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

        </b-form-group>
        <b-form-group>
          <v-multiselect-listbox
            :options="teacherOptions"
            :reduce-display-property="(option) => option.name"
            v-model="selected"
            :reduce-value-property="(option) => option.id"
            search-input-class="vgt-input custom-input"
            search-options-placeholder="Tìm kiếm"
            selected-options-placeholder="Tìm kiếm"
            no-options-text="Không có bản ghi nào"
            selected-no-options-text="Không có bản ghi nào"
            highlight-diff
            style="width: 100%"
            @change="onChange"
          />
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('teacherSubjectModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
// import DualListBox from "dual-listbox-vue";
// import "dual-listbox-vue/dist/dual-listbox.css";
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@core/utils/validations/validations'
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  name: 'TeacherTeamAssignment',
  components: {
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      teachers: 'teacher/teachers',
      subjects: 'subject/subjects',
      teacherSubject: 'teacherSubject/teacherSubjects',
    }),
  },
  data() {
    return {
      filterSubject: {
        currentPage: 1,
        itemsPerPage: 2000,
        departmentId: '',
        subjectTypeId: '',
        name: '',
        code: '',
        status: 1,
        sort: '',
      },
      filterTeacher: {
        currentPage: 1,
        itemsPerPage: 1000,
        departmentId: null,
        teamId: null,
        name: '',
        code: '',
        status: 1,
        sort: '',
      },
      filterTeacherSubject: {
        currentPage: 1,
        itemsPerPage: 1000,
        teacherName: '',
        subjectName: '',
        status: 1,
        sort: '',
      },

      teacherOptions: [],
      selected: [],
      subjectOptions: [],
      subjectSelected: null,
      teacherSubjectOption: [],
      isLoading: false,
      required,
    }
  },
  methods: {
    ...mapActions({
      readTeachers: 'teacher/readTeachers',
      readSubjects: 'subject/readSubjects',
      readTeacherSubject: 'teacherSubject/readTeacherSubjects',
      updateListTeacherSubject: 'teacherSubject/updateListTeacherSubjects',
    }),
    async onChangeSubject() {
      this.teacherSubjectOption = this.teacherSubject.filter(item => item.subjectId === this.subjectSelected)
      this.selected = this.teacherSubjectOption.map(item => (item.teacherId))
    },
    async onChange($event) {
      this.selected = $event
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      try {
        await Promise.all([
          this.readSubjects(this.filterSubject),
          this.readTeachers(this.filterTeacher),
          this.readTeacherSubject(this.filterTeacherSubject),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.teacherOptions = this.teachers
        this.selected = [11]
        this.subjectOptions = this.subjects.map(item => ({ value: item.id, label: item.name }))
        this.teacherSubjectOption = this.teacherSubject

        if (this.subjectOptions.length > 0) {
          this.subjectSelected = this.subjectOptions[0].value
          await this.onChangeSubject()
        }
      }
    },
    onHide() {
      this.$refs
        .teacherSubjectFormRef
        .reset()
      this.selected = []
      this.teacherOptions = []
    },
    async onSave(type = null) {
      const valid = this.$refs
        .teacherSubjectFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const list = []
          for (const i of this.selected) {
            const o = {
              code: null,
              id: 0,
              name: null,
              status: 1,
              subjectId: this.subjectSelected,
              teacherId: i,
            }
            list.push(o)
          }
          const objectPost = {
            id: this.subjectSelected,
            teacherSubjects: list,
          }
          const response = await this.updateListTeacherSubject(objectPost)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('teacherSubjectModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style>
.custom-input {
  margin-bottom: 10px !important;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
