import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import { baseAttr } from "@/store/credit-class-teacher";

export default {
  namespaced: true,
  state: {
    lessonContentBySubject: [],
    lessonContentByCreditClass: [],
    dataLists: [],
    totalRows: 0,
    resourceName: {
      fullName: 'Giảng  viên',
      shortName: 'Giảng  viên',
    },
  },
  getters: {
    lessonContentBySubject: state => state.lessonContentBySubject,
    lessonContentByCreditClass: state => state.lessonContentByCreditClass,
    dataLists: state => state.dataLists,
    totalRows: state => state.totalRows,
    resourceName: state => state.resourceName,
  },
  mutations: {
    SET_LISTS: (state, { total, records }) => {
      state.totalRows = total
      state.dataLists = records
    },
    SET_LESSON_CONTENT_BY_SUBJECT: (state, { data }) => {
      state.lessonContentBySubject = data
    },
    SET_LESSON_CONTENT_BY_CREDIT_CLASS: (state, lessonContentByCreditClass) => { state.lessonContentByCreditClass = lessonContentByCreditClass },
  },
  actions: {
    async createData(commit, params) {
      try {
        const response = await axios.post('uni/subject/lessons', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async saveLessonContentsByProjectSubject({ commit }, params) {
      try {
        const response = await axios.put('uni/subject/lessons/saveLessonName', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getLessonContentBySubjects({ commit }, params) {
      try {
        const response = await axios.get('uni/subject/lessons', { params })
        if (response.data.code === ApiCode.SUCCESS) {
          commit('SET_LESSON_CONTENT_BY_SUBJECT', { data: response.data.data })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getLessonContentByCreditClass({ commit }, params) {
      try {
        const response = await axios.get('uni/subject/lessons/getByCreditClass', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_LESSON_CONTENT_BY_CREDIT_CLASS', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createTeacherCreditClassLessonContentData(commit, params) {
      try {
        const response = await axios.post('uni/credit_class/teacher/lesson_content', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updateTeacherCreditClassLessonContentData({ commit }, params) {
      try {
        const response = await axios.put(`uni/credit_class/teacher/lesson_content/${params.id}`, params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getData({ commit }, params) {
      try {
        const response = await axios.get('uni/credit_class/teacher/lesson_content', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records,
          } = data.data
          commit('SET_LISTS', {
            total,
            records,
          })
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async deleteData({ commit }, id) {
      try {
        const response = await axios.delete(`uni/credit_class/teacher/lesson_content/${id}`)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
