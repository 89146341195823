<template>
  <validation-observer
    ref="admissionMajorFormSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="admissionMajorFormSaveModal"
      body-class="position-static"
      centered
      :title="isUpdated ? 'Cập nhật phương thức xét tuyển' : 'Thêm phương thức xét tuyển'"
      :no-close-on-backdrop="true"
      @shown="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="admissionFormId">
          <template v-slot:label>
            Phương thức tuyển sinh <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Phương thức tuyển sinh"
            rules="required"
          >
            <v-select
              id="admissionFormId"
              v-model="targetItem.admissionFormIds"
              :multiple="!isUpdated"
              :disabled="isUpdated"
              :options="dataListAdmissionForm"
              :reduce="option => option.value"
              placeholder="Phương thức xét tuyển"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-if="isUpdated"
          label-for="admissionBlockId">
          <template v-slot:label>
            Tổ hợp môn xét tuyển
          </template>
          <validation-provider
            #default="{ errors }"
            name="Tổ hợp môn xét tuyển"
          >
            <v-select
              id="admissionBlockId"
              v-model="targetItem.admissionBlockIds"
              multiple
              :options="dataListAdmissionBlock"
              :reduce="option => option.value"
              placeholder="Tổ hợp môn xét tuyển"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-if="isUpdated"
          label-for="quota">
          <template v-slot:label>
            Chỉ tiêu <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Chỉ tiêu"
            rules="required"
          >
            <b-form-input
              id="quota"
              v-model="targetItem.quota"
              type="number"
              name="quota"
              placeholder="Nhập chỉ tiêu"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetItem.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-if="!isUpdated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('admissionMajorFormSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        no-wrap
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { STATUSES } from '@/const/status'
import { required } from '@core/utils/validations/validations'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Flag } from '@/const/flag'

export default {
  name: 'AdmissionMajorFormSave',
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BModal,
    BOverlay,
    vSelect,
    BFormInvalidFeedback,
  },
  props: {
    admissionMajorId: {
      type: Number,
      default: null,
    },
    admissionRoundId: {
      type: Number,
      default: null,
    },
    admissionMajorForm: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        id: null,
        admissionFormIds: [],
        admissionBlockIds: [],
        quota: null,
        status: Flag.ACTIVE,
        orderNo: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      dataListAdmissionForm: 'admissionForm/admissionFormByAdmissionRoundId',
      dataListAdmissionBlock: 'factAdmissionBlock/admissionBlockGetAll',
      checkQuota: 'admissionMajorForm/checkQuota',
    }),
    statusOptions() {
      return STATUSES
    },
    isUpdated() {
      return !!this.admissionMajorForm
    },
  },
  methods: {
    ...mapActions({
      readAdmissionFormByAdmissionRoundId: 'admissionForm/readAdmissionFormByAdmissionRoundId',
      getAdmissionBlockStatusAndDelFlag: 'factAdmissionBlock/getAdmissionBlockStatusAndDelFlag',
      createAdmissionMajorForm: 'admissionMajorForm/createAdmissionMajorForm',
      updateAdmissionMajorForm: 'admissionMajorForm/updateAdmissionMajorForm',
      getCheckQuota: 'admissionMajorForm/checkQuota',
    }),
    async onShow() {
      if (this.admissionMajorForm) {
        this.targetItem = { ...this.admissionMajorForm }
        if (this.targetItem.admissionBlockIds && this.targetItem.admissionBlockIds.length > 0) {
          const arrAdmissionBlockId = this.targetItem.admissionBlockIds.split(',')
          this.targetItem.admissionBlockIds = arrAdmissionBlockId.map(str => parseInt(str, 10))
        }
      }
      this.isLoading = true
      try {
        await Promise.all([
          this.readAdmissionFormByAdmissionRoundId({ admissionRoundId: this.admissionRoundId }),
          this.getAdmissionBlockStatusAndDelFlag(),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .admissionMajorFormSaveFormRef
        .reset()
      this.targetItem = {
        id: null,
        admissionFormIds: [],
        status: Flag.ACTIVE,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async checkQuotaAccept() {
      await this.getCheckQuota({
        id: this.targetItem.id,
        quota: this.targetItem.quota,
      })
      if (this.checkQuota) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tổng số lượng chỉ tiêu theo từng phương thức đang lớn hơn chỉ tiêu tuyển sinh của ngành',
            icon: 'XCircleIcon',
            variant: 'warning',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tổng số lượng chỉ tiêu theo từng phương thức đang nhỏ hơn chỉ tiêu tuyển sinh của ngành',
            icon: 'XCircleIcon',
            variant: 'warning',
          },
        })
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .admissionMajorFormSaveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        await this.checkQuotaAccept()
        try {
          const response = this.isUpdated
            ? await this.updateAdmissionMajorForm({
              id: this.targetItem.id,
              admissionBlockIds: this.targetItem.admissionBlockIds,
              admissionMajorForm: {
                id: this.targetItem.id,
                admissionMajorId: this.admissionMajorId,
                admissionFormIds: this.targetItem.admissionFormIds,
                quota: this.targetItem.quota,
                status: this.targetItem.status,
                orderNo: this.targetItem.orderNo,
              }
            })
            : await this.createAdmissionMajorForm({
              admissionMajorId: this.admissionMajorId,
              admissionFormIds: this.targetItem.admissionFormIds,
              status: this.targetItem.status,
              orderNo: this.targetItem.orderNo,
            })
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('admissionMajorFormSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.admissionMajorForm-select {
  ::v-deep .vs__selected {
    color: white;
    background-color: #7367f0;
  }
}
</style>
