<template>
  <div class="row">
    <b-container fluid>
      <b-card>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <b-row>
            <b-col>
              <h3>
                Danh sách học viên {{ dataGroup.name }}
              </h3>
            </b-col>
            <b-col
              class="text-right"
            >
              <b-button
                v-b-modal.practicalResearchStudentRestAddModal
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                class="ml-1 mx-2"
                title="Thêm học viên trong nhóm nghỉ phép"
              >
                <feather-icon icon="PlusIcon" /> Thêm học viên
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="ml-1 mx-2"
                title="Thêm học viên trong nhóm nghỉ phép"
                @click="onAddTopic4Student"
              >
                <feather-icon icon="FileTextIcon" /> Gán đề tài
              </b-button>
            </b-col>
          </b-row>
          <div class="m-s">
            <b-row class="mt-2">
              <b-col cols="12">
                <div>
                  <vue-good-table
                    mode="remote"
                    class="my-table"
                    style-class="vgt-table striped bordered"
                    row-style-class="vgt-row"
                    :columns="columns"
                    :rows="studentsPracticalResearchGroup"
                    :pagination-options="paginationOptions"
                    :line-numbers="true"
                    :select-options="{ enabled: true, selectOnCheckboxOnly: true, selectionText: 'SV được chọn', clearSelectionText: 'Bỏ chọn' }"
                    @on-selected-rows-change="selectionChanged"
                  >
                    <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <span v-if="props.column.field === 'gender'">
                        {{ getGender(props.row.gender) }}
                      </span>
                      <span v-else-if="props.column.field === 'practicalResearchTopicId'">
                        {{ getPracticalResearchTopicName(props.row.practicalResearchTopicId) }}
                      </span>
                      <span v-else-if="props.column.field === 'groupNote'">
                        <b-form-input
                            v-model="props.row.groupNote"
                            type="text"
                            @change="
                            changeCell(
                              props.row.groupNote,
                              props.column.field,
                              props.row.originalIndex
                            )
                          "
                        />
                      </span>
                      <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                      <span
                        v-if="props.column.field === 'action'"
                        class="thead-group-action"
                      >
                        <b-button-group>
                          <b-button
                            class="btn-icon mx-1"
                            variant="primary"
                            size="sm"
                            title="Đánh dấu nghỉ phép"
                            @click="onSetStudentRest(props.row)"
                          >
                            <feather-icon icon="FlagIcon" />
                          </b-button>
                        </b-button-group>
                        <b-button-group>
                          <b-button
                            class="btn-icon"
                            variant="danger"
                            size="sm"
                            title="Xóa"
                            @click="onDelete(props.row)"
                          >
                            <feather-icon icon="TrashIcon" />
                          </b-button>
                        </b-button-group>
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                  class="text-right"
              >
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="mr-1 my-2"
                    @click="onSave()"
                >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
      <b-modal
        id="practicalResearchCreateModal"
        ref="practicalResearchCreateModal"
        no-close-on-backdrop
        centered
        size="xl"
        :hide-footer="true"
      >
        <PracticalResearchCreate
          :item="selectedMainData"
          :class-id="classId"
        />
      </b-modal>
      <PracticalResearchStudentRestAdd
        ref="practicalResearchStudentRestAddModal"
        :data-group="dataGroup"
        @succeed="getStudentsPracticalResearchByGroupFromStore"
      />
      <PracticalResearchTopicStudent
        ref="practicalResearchTopicStudentSaveModal"
        :students="selectedMainData"
        :data-group="dataGroup"
        @succeed="getStudentsPracticalResearchByGroupFromStore"
      />
    </b-container>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem, BForm, BFormGroup, BFormInput,
  BFormSelect, BOverlay, BPagination, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import PracticalResearchCreate from '@/views/practical-research-class-group/PracticalResearchCreate.vue'
import PracticalResearchStudentRestAdd from '@/views/practical-research-class-group/PracticalResearchStudentRestAdd.vue'
import { GENDERS } from '@/const/status'
import PracticalResearchTopicStudent from '@/views/practical-research-class-group/PracticalResearchTopicStudent.vue'
import {ValidationProvider} from "vee-validate";

export default {
  name: 'PracticalResearchStudentList',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BFormInput, ValidationProvider,
    PracticalResearchTopicStudent,
    PracticalResearchStudentRestAdd,
    PracticalResearchCreate,
    BButton,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
  },
  props: {
    dataListStudent: {
      type: Array,
      default: [],
    },
    dataGroup: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 3000,
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
        departmentId: '',
        majorId: '',
      },
      paginationOptions: {
        enabled: false,
      },
      classId: null,
      selectedMainData: [],
      columns: [
        {
          label: 'Mã học viên',
          field: 'code',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Họ và tên',
          field: 'name',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Giới tính',
          field: 'gender',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ngày sinh',
          field: 'birthday',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Đề tài NCTT',
          field: 'practicalResearchTopicId',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Ghi chú',
          field: 'groupNote',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      trainingSystems: 'dropdown/trainingSystems',
      courses: 'dropdown/courses',
      departments: 'dropdown/departments',
      majors: 'dropdown/majors',
      classes: 'dropdown/classes',
      studentsPracticalResearchGroup: 'practicalResearchClassStudent/studentsPracticalResearchGroup',
      practicalResearchTopics: 'practicalResearchClassStudent/practicalResearchTopics',
    }),
  },
  async created() {
    this.isLoading = true
    try {
      const cUser = getUser()
      this.filter.organizationId = (cUser.orgId ? cUser.orgId : 0)
      await Promise.all([
        this.readStudentsPracticalResearchByGroup({ groupId: this.dataGroup.id }),
        this.getTrainingSystems({ organizationId: this.filter.organizationId }),
        this.getDepartments({ organizationId: this.filter.organizationId }),
        this.getPracticalResearchTopic({
          currentPage: 1,
          itemsPerPage: 1000,
        }),
      ])
      if (this.trainingSystems.length > 0) {
        this.filter.trainingSystemId = this.trainingSystems[0].value
        await this.getCourses(this.filter)
      }
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getDepartments: 'dropdown/getDepartments',
      getMajors: 'dropdown/getMajors',
      getClasses: 'dropdown/getClasses',
      readStudentsPracticalResearchByGroup: 'practicalResearchClassStudent/readStudentsPracticalResearchByGroup',
      addPracticalResearchStudentRest: 'practicalResearchClassStudent/addPracticalResearchStudentRest',
      deleteStudentPracticalResearchClassGroup: 'practicalResearchClassStudent/deleteStudentPracticalResearchClassGroup',
      getPracticalResearchTopic: 'practicalResearchClassStudent/getPracticalResearchTopic',
      updateStudentPracticalResearchClass: 'practicalResearchClassStudent/updateStudentPracticalResearchClass',
    }),
    GENDERS() {
      return GENDERS
    },
    getGender(item) {
      return this.GENDERS().find(gender => gender.value === item).label
    },
    async onTrainingSystemsChange(event) {
      this.filter.trainingSystemId = event
      this.filter.courseId = null
      this.courseName = ''
      this.courseSemesterName = ''
      await this.getCourses(this.filter)
    },
    async onCoursesChange(event) {
      this.filter.courseId = event
      this.courseName = this.courses.find(course => course.value === event).label
      this.filter.courseSemesterId = null
      this.courseSemesterName = ''
    },
    async onDepartmentChange(event) {
      this.filter.departmentId = event
      this.filter.majorId = null
      await this.getMajors(this.filter)
    },
    async onMajorChange(event) {
      this.filter.majorId = event
      this.filter.programmeId = null
      await this.getClasses({
        courseId: this.filter.courseId,
        trainingSystemId: this.filter.trainingSystemId,
        departmentId: this.filter.departmentId,
        majorId: this.filter.majorId,
      })
    },
    async onSetStudentRest(row) {
      this.$swal({
        title: 'Bạn có chắc chắn muốn xác nhận nghỉ phép?',
        text: `Học viên ${row.name} - ${row.code}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.addPracticalResearchStudentRest({
              studentClassId: row.studentClassId,
            })
            const { isSuccessful, message } = response
            if (response) {
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getStudentsPracticalResearchByGroupFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
                this.isLoading = false
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async onDelete(row) {
      this.$swal({
        title: 'Bạn có chắc chắn muốn xóa?',
        text: `Xóa học viên ${row.name}-${row.code}-${row.class}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteStudentPracticalResearchClassGroup(row.id)
            const { isSuccessful, message } = response
            if (response) {
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                try {
                  await this.getStudentsPracticalResearchByGroupFromStore()
                } catch (e) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `[${e.code}] ${e.message}`,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                } finally {
                  this.isLoading = false
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
    async getStudentsPracticalResearchByGroupFromStore() {
      this.isLoading = true
      try {
        await this.readStudentsPracticalResearchByGroup({ groupId: this.dataGroup.id })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    selectionChanged(params) {
      this.selectedMainData = []
      params.selectedRows.map(e => (
        this.selectedMainData.push(e.id)
      ))
    },
    onAddTopic4Student() {
      if (this.selectedMainData.length < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn Học viên!',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        return null
      }
      this.$bvModal.show('practicalResearchTopicStudentSaveModal')
    },
    getPracticalResearchTopicName(id) {
      return this.practicalResearchTopics.find(pt => pt.id === id)?.name || ''
    },
    changeCell(data, column, row) {
      this.$set(this.studentsPracticalResearchGroup[row], column, String(data))
    },
    async onSave() {
        this.isLoading = true
        try {
          const response = await this.updateStudentPracticalResearchClass(this.studentsPracticalResearchGroup)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
    },
  },
}
</script>

<style scoped lang="scss">

</style>
