import axios from '@/libs/axios'
import { ApiCode, ApiExtRoutes } from '@/const/api'
import { handleCatch } from '@/utils'
import { defaultParams } from '@/store/common/dropdown'

export default {
    namespaced: true,
    state: {
        totalRows: 0,
        teacherType: [],
        timeResearchTeacherResults: [],
        departments: [],
        teachers: [],
        factYears: [],
        researches: [],
        researchInventions: [],
        researchArticles: [],
        researchTechTransfers: [],
        allTimeResearchConversionCriteria: [],
        timeResearchTeacherCriteria: [],
    },
    getters: {
        totalRows: state => state.totalRows,
        teacherType: state => state.teacherType,
        timeResearchTeacherResults: state => state.timeResearchTeacherResults,
        departments: state => state.departments,
        teachers: state => state.teachers,
        factYears: state => state.factYears,
        researches: state => state.researches,
        researchInventions: state => state.researchInventions,
        researchArticles: state => state.researchArticles,
        researchTechTransfers: state => state.researchTechTransfers,
        allTimeResearchConversionCriteria: state => state.allTimeResearchConversionCriteria,
        timeResearchTeacherCriteria: state => state.timeResearchTeacherCriteria,
    },
    mutations: {
        SET_TOTAL_ROWS: (state, totalRows) => {
            state.totalRows = totalRows
        },
        SET_TEACHER_TYPE: (state, teacherType) => {
            state.teacherType = teacherType
        },
        SET_TIME_RESEARCH_TEACHER_RESULT: (state, timeResearchTeacherResults) => {
            state.timeResearchTeacherResults = timeResearchTeacherResults
        },
        SET_DEPARTMENTS: (state, departments) => {
            state.departments = departments
        },
        SET_TEACHERS: (state, teachers) => {
            state.teachers = teachers
        },
        SET_FACT_YEARS: (state, factYears) => {
            state.factYears = factYears
        },
        SET_RESEARCHES: (state, researches) => {
            state.researches = researches
        },
        SET_RESEARCH_INVENTIONS: (state, researchInventions) => {
            state.researchInventions = researchInventions
        },
        SET_RESEARCH_ARTICLES: (state, researchArticles) => {
            state.researchArticles = researchArticles
        },
        SET_RESEARCH_TECH_TRANSFERS: (state, researchTechTransfers) => {
            state.researchTechTransfers = researchTechTransfers
        },
        SET_TIME_RESEARCH_CONVERSION_CRITERIA: (state, allTimeResearchConversionCriteria) => {
            state.allTimeResearchConversionCriteria = allTimeResearchConversionCriteria
        },
        SET_TIME_RESEARCH_TEACHERS: (state, timeResearchTeacherCriteria) => {
            state.timeResearchTeacherCriteria = timeResearchTeacherCriteria
        },
    },
    actions: {
        async createTimeResearchTeachers({ commit }, params){
            try {
                const response = await axios.post('uni/tw_time_research_teacher', params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async getTimeResearchTeachers({ commit }, params) {
            try {
                const response = await axios.get('uni/tw_time_research_teacher', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_TIME_RESEARCH_TEACHERS', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getTimeResearchConversionCriteria({ commit }, params) {
            try {
                const response = await axios.get('uni/tw_time_research_conversion_criteria/getAll', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_TIME_RESEARCH_CONVERSION_CRITERIA', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async getResearches({ commit }, params) {
            try {
                const response = await axios.get('uni/researches/getAllActive', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_RESEARCHES', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async getResearchesArticles({ commit }, params) {
            try {
                const response = await axios.get('uni/researchArticles/getAllActive', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_RESEARCH_ARTICLES', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async getResearchInventions({ commit }, params) {
            try {
                const response = await axios.get('uni/researchInventions/getAllActive', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_RESEARCH_INVENTIONS', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async getResearchTechTransfers({ commit }, params) {
            try {
                const response = await axios.get('uni/researchTechTransfers/getAllActive', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_RESEARCH_TECH_TRANSFERS', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async getYears({ commit }, params) {
            try {
                const response = await axios.get('uni/factYears/all', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_FACT_YEARS', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async getTeachers({ commit }, params) {
            try {
                const response = await axios.get('uni/teachers', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { total, records } = data.data
                    commit('SET_TEACHERS', records)
                    commit('SET_TOTAL_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async getDepartments({ commit }, parameters) {
            try {
                const ownParams = { organizationId: '', leaderId: '' }
                const params = {
                    ...defaultParams,
                    ...ownParams,
                    ...parameters,
                }
                const response = await axios.get('uni/departments', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_DEPARTMENTS', data.data.records)
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async getTeacherType({ commit }, params) {
            try {
                const response = await axios.get('uni/fact_teacher_type/getAllActive', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    commit('SET_TEACHER_TYPE', data.data)
                }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async readTimeResearchTeacherResults({ commit }, params) {
            try {
                const response = await axios.get('uni/tw_time_research_teacher_result', { params })
                const { data } = response
                if (data.code === ApiCode.SUCCESS) {
                    const { total, records } = data.data
                    commit('SET_TIME_RESEARCH_TEACHER_RESULT', records)
                    commit('SET_TOTAL_ROWS', total)
                }
            } catch (e) {
                handleCatch(e)
            }
        },
        async createTimeResearchTeacherResults({ commit }, params) {
            try {
                const response = await axios.post('uni/tw_time_research_teacher_result', params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
        async updateTimeResearchTeacherResults({ commit }, params) {
            try {
                const response = await axios.put(`uni/tw_time_research_teacher_result/${params.id}`, params)
                const { data } = response
                return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
            } catch (e) {
                handleCatch(e)
            }
            return null
        },
    },
}
