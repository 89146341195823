<template>
  <b-modal
    id="timeResearchTeacherResultModal"
    body-class="position-static"
    centered
    :title="`Kết quả quy đổi giờ NCKH giảng viên - ${dataForm.name}`"
    no-close-on-backdrop
    size="xl"
    @show="onShow"
    @hidden="onHide"
  >
    <div>
      <b-container fluid>
        <b-card no-body>
          <b-overlay
            :show="isLoading"
            rounded="sm"
          >
            <div class="m-2">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <v-select
                      v-model="filter.yearId"
                      :options="factYears"
                      :reduce="option => option.id"
                      label="name"
                      placeholder="Tất cả năm học"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="2"
                  md="1"
                  class="text-right"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="onSucceed()"
                  >
                    <span class="text-nowrap text-right">
                      <feather-icon icon="FilterIcon" />
                    </span>
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="text-right">
                    <b-button
                      v-if="updateTable"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-modal.timeResearchTeacherAddModal
                      variant="success"
                      @click="onCreate"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="PlusIcon" />Thêm thời gian NCKH
                      </span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="d-flex align-items-center mb-1 mt-0">
                    <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                      đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                    </span>
                  </div>
                  <vue-good-table
                    :columns="columns"
                    :rows="timeResearchTeacherResults"
                    :pagination-options="paginationOptions"
                    :total-rows="totalRows"
                    :line-numbers="true"
                    @on-column-filter="onColumnFilter"
                    @on-page-change="onPageChange"
                    @on-per-page-change="onPerPageChange"
                  >
                    <div
                      slot="emptystate"
                      style="text-align: center; font-weight: bold"
                    >
                      Không có bản ghi nào !
                    </div>
                    <template
                      slot="table-row"
                      slot-scope="props"
                    >
                      <span v-if="props.column.field === 'status'">
                        {{ getStatusName(props.row.status) }}
                      </span>
                      <span v-else-if="props.column.field === 'action'">
                        <b-button
                          v-if="updateTable"
                          v-b-modal.timeResearchTeacherResultSaveModal
                          variant="primary"
                          class="btn-icon"
                          size="sm"
                          @click="onResultTimeResearch(props.row)"
                        >
                          <feather-icon icon="Edit2Icon" />
                        </b-button>
                      </span>
                      <span v-else-if="props.column.field === 'factYearId'">
                        {{ getFactYearName(props.row.factYearId) }}
                      </span>
                      <span v-else-if="props.column.field === 'timeResult'">
                        {{ props.row.timeResult !== null ? props.row.timeResult + '(h)' : 0 + '(h)' }}
                      </span>
                      <span v-else-if="props.column.field === 'timeResearchRequired'">
                        {{ props.row.timeResearchRequired !== null ? props.row.timeResearchRequired + '(h)' : 0 + '(h)' }}
                      </span>
                      <!-- Column: Common -->
                      <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                    </template>
                    <!-- pagination -->
                    <template
                      slot="pagination-bottom"
                      slot-scope="props"
                    >
                      <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                            đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                          </span>
                        </div>
                        <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap"> Hiển thị </span>
                          <b-form-select
                            v-model="filter.itemsPerPage"
                            :options="itemsPerPageOptions"
                            class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })"
                          />
                          <span class="text-nowrap"> bản ghi/trang</span>
                        </div>
                        <div>
                          <b-pagination
                            :value="1"
                            :total-rows="totalRows"
                            :per-page="filter.itemsPerPage"
                            class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })"
                          />
                        </div>
                      </div>
                    </template>
                  </vue-good-table>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </b-card>
      </b-container>
      <TimeResearchTeacherAddModal
        ref="timeResearchTeacherAddModal"
        :data-form="dataForm"
        @succeed="onSucceed"
      />
      <TimeResearchTeacherResultSaveModal
        ref="timeResearchTeacherResultSaveModal"
        :item="currentDataSource"
        @succeed="onSucceed"
      />
    </div>
  </b-modal>
</template>
<script>
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import TeacherTypeSaveModal from '@/views/admin/category/fact-teacher-type/save.vue'
import TimeFrameResearchSaveModal from '@/views/teacher-workload/time-research/tw-time-frame-research/TimeFrameResearchSave.vue'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import TimeResearchTeacherResultModal
from '@/views/teacher-workload/time-research/tw_time_research_teacher/TimeResearchTeacherResult.vue'
import TimeResearchTeacherAddModal
from '@/views/teacher-workload/time-research/tw_time_research_teacher/TimeResearchTeacherAdd.vue'
import TimeResearchTeacherResultSaveModal
from '@/views/teacher-workload/time-research/tw_time_research_teacher/TimeResearchTeacherResultSave.vue'
import { STATUSES } from '@/const/status'

export default {
  name: 'TimeResearchTeacherResult',
  directives: {
    Ripple,
  },
  components: {
    TimeResearchTeacherResultSaveModal,
    TimeResearchTeacherAddModal,
    TimeResearchTeacherResultModal,
    BButtonGroup,
    BDropdownItem,
    BDropdown,
    BFormGroup,
    TimeFrameResearchSaveModal,
    TeacherTypeSaveModal,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    vSelect,
  },
  props: {
    dataForm: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 20,
        yearId: null,
        teacherId: null,
      },
      currentDataSource: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Năm học',
          field: 'factYearId',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Số giờ cần đạt',
          field: 'timeResearchRequired',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: 'Kết quả quy đổi giờ NCKH',
          field: 'timeResult',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      teachers: 'twTimeResearchTeacherResult/teachers',
      totalRows: 'twTimeResearchTeacherResult/totalRows',
      departments: 'twTimeResearchTeacherResult/departments',
      timeResearchTeacherResults: 'twTimeResearchTeacherResult/timeResearchTeacherResults',
      factYears: 'twTimeResearchTeacherResult/factYears',
    }),
    statuses() {
      return STATUSES
    },
    updateTable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.TW_TIME_RESEARCH_TEACHER)
    },
  },
  watch: {
    async resource() {
      await this.onLoad()
    },
  },
  async created() {
  },
  methods: {
    ...mapActions({
      getTeachers: 'twTimeResearchTeacherResult/getTeachers',
      readTimeResearchTeacherResult: 'twTimeResearchTeacherResult/readTimeResearchTeacherResults',
      getTeacherType: 'twTimeResearchTeacherResult/getTeacherType',
      getDepartments: 'twTimeResearchTeacherResult/getDepartments',
      getYears: 'twTimeResearchTeacherResult/getYears',
    }),
    async onShow() {
      await this.onLoad()
    },
    async onHide() {
    },
    async onLoad() {
      this.isLoading = true
      try {
        await Promise.all([
          this.getDataSourcesFromStore(),
          this.getDepartments({ organizationId: getUser().orgId }),
          this.getYears({}),
        ])
        if (this.departments.length > 0) {
          this.filter.departmentId = this.departments[0].id
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onCreate() {
      this.currentDataSource = undefined
    },
    onResultTimeResearch(dataSource) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = dataSource
      this.currentDataSource = rest
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.filter.teacherId = this.dataForm.id
      this.isLoading = true
      try {
        await this.readTimeResearchTeacherResult(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getDataSourcesFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ teacherName: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getDataSourcesFromStore()
    },
    async onSucceed() {
      await this.getDataSourcesFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
    getFactYearName(id) {
      return this.factYears.find(year => year.id === id)?.name || ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
