<template>
  <b-overlay :show="isLoading" rounded="sm">
    <validation-observer ref="saveFormRef" #default="{ invalid }">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên hội thảo <span class="text-danger">*</span>
              </template>
              <validation-provider #default="{ errors }" name="Tên hội thảo" rules="required">
                <b-form-input id="name" v-model="dataForm.name" name="name" placeholder="Nhập tên hội thảo"
                  :state="getElementState(errors)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label-for="researchAdministrativeLevelId">
              <template v-slot:label>
                Cấp tổ chức <span class="text-danger">*</span>
              </template>
              <validation-provider #default="{ errors }" name="Cấp tổ chức" rules="required">
                <v-select v-model="dataForm.researchAdministrativeLevelId" label="name" placeholder="Chọn cấp tổ chức"
                  :options="researchAdministrativeLevels" :reduce="option => option.id" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label-for="startDate">
              <template v-slot:label>
                Thời gian tổ chức <span class="text-danger">*</span>
              </template>
              <validation-provider #default="{ errors }" name="Thời gian tổ chức" rules="required">
                <b-form-datepicker id="startDate" v-model="dataForm.startDate"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="vi"
                  placeholder="Chọn thời gian tổ chức" format="dd/mm/yyyy" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="12" md="12" lg="12" xl="12">
            <b-form-group label-for="citationPlanContent">
              <template v-slot:label>
                Trích yếu nội dung kế hoạch <span class="text-danger">*</span>
              </template>
              <validation-provider #default="{ errors }" name="Trích yếu nội dung kế hoạch" rules="required">
                <b-form-input id="citationPlanContent" v-model="dataForm.citationPlanContent" name="citationPlanContent"
                  placeholder="Nhập trích yếu nội dung kế hoạch" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="12" md="8" lg="8" xl="8">
            <b-form-group label="Ấn phẩm" label-for="publication">
              <b-form-input id="publication" v-model="dataForm.publication" name="publication"
                placeholder="Nhập ấn phẩm (nếu có)" />
            </b-form-group>
          </b-col>

          <b-col sm="12" md="4" lg="4" xl="4">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider #default="{ errors }" name="Trạng thái" rules="required">
                <v-select v-model="dataForm.status" :options="statuses" :reduce="option => option.value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Ghi chú" label-for="note">
              <b-form-textarea id="note" v-model="dataForm.note" name="note" placeholder="Ghi chú" rows="3"
                max-rows="5" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="w-100 d-flex justify-content-end mr-1">
              <b-button v-show="isCreated" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
                :disabled="invalid" @click="onSave">
                <span class="text-right">
                  <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
                </span>
              </b-button>

              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" :disabled="invalid"
                @click="onSave('hide')">
                <span class="text-right">
                  <feather-icon icon="CheckIcon" /> Lưu lại
                </span>
              </b-button>

              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary"
                @click="$bvModal.hide('form')">
                <span class="text-right">
                  <feather-icon icon="XIcon" /> Hủy
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BForm,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BOverlay,
  BFormDatepicker,
  BFormTextarea,
  BFormFile,
} from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import { STATUSES } from '@/const/status'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Form',
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({
        id: null,
        name: '',
        researchAdministrativeLevelId: null,
        startDate: null,
        citationPlanContent: '',
        publication: '',
        status: 1,
        note: ''
      }),
    },
    modalShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      required,
      filterGetAll: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
    }
  },
  computed: {
    ...mapGetters({
      researchAdministrativeLevels: 'researchConference/researchAdministrativeLevels',
    }),
    statuses() {
      return STATUSES
    },
    isCreated() {
      return !this.dataForm.id
    },
  },
  async created() {
  },
  async mounted() {
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
      createResearchConference: 'researchConference/createResearchConference',
      updateResearchConference: 'researchConference/updateResearchConference',
    }),
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.dataForm.id ? await this.updateResearchConference(this.dataForm) : await this.createResearchConference(this.dataForm)
          if (response) {
            const {
              isSuccessful,
              message,
            } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('form')
              }
              this.$emit('saveDataSuccess')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>
