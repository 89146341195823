import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    totalRows: 0,
    timeResearchConversionCriteriaType: [],
  },
  getters: {
    totalRows: state => state.totalRows,
    timeResearchConversionCriteriaType: state => state.timeResearchConversionCriteriaType,
  },
  mutations: {
    SET_TOTAL_ROWS: (state, totalRows) => {
      state.totalRows = totalRows
    },
    SET_TIME_FRAME_CONVERSION_CRITERIA_TYPE: (state, timeResearchConversionCriteriaType) => {
      state.timeResearchConversionCriteriaType = timeResearchConversionCriteriaType
    },
  },
  actions: {
    async readTimeResearchConversionCriteriaType({ commit }, params) {
      try {
        const response = await axios.get('uni/tw_time_research_conversion_criteria_type', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_TIME_FRAME_CONVERSION_CRITERIA_TYPE', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async createTimeResearchConversionCriteriaType({ commit }, params) {
      try {
        const response = await axios.post('uni/tw_time_research_conversion_criteria_type', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateTimeResearchConversionCriteriaType({ commit }, params) {
      try {
        const response = await axios.put(`uni/tw_time_research_conversion_criteria_type/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async deleteTimeResearchConversionCriteriaType({ commit }, id) {
      try {
        const response = await axios.delete(`uni/tw_time_research_conversion_criteria_type/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
