<template>
  <validation-observer
    ref="saveModalRef"
    #default="{invalid}"
  >
    <b-modal
      id="teacherHourSaveModal"
      body-class="position-static"
      size="lg"
      centered
      :title="isCreated ? 'Thêm giờ giảng' : 'Cập nhật giờ giảng'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="year">
              <template v-slot:label>
                Năm <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Năm"
                :rules="{ required: true, regex: /^[0-9]{4}$/ }"
              >
                <b-form-input
                  id="year"
                  v-model="targetItem.year"
                  name="year"
                  type="number"
                  @input="calculateTeachingHours"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="mainName">
              <template v-slot:label>
                Giảng viên <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Giảng viên"
                rules="required"
              >
                <v-select
                  label="name"
                  v-model="targetItem.teacherId"
                  :options="teachers"
                  :reduce="option => option.id"
                  placeholder="Giảng viên"
                  :state="getElementState(errors)"
                  @input="calculateTeachingHours"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
          >
            <b-form-group label-for="subjectId">
              <template v-slot:label>
                Học phần <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Học phần"
                rules="required"
              >
                <v-select
                  label="name"
                  v-model="targetItem.subjectId"
                  :options="subjects"
                  :reduce="option => option.id"
                  placeholder="Học phần"
                  :state="getElementState(errors)"
                  @input="calculateTeachingHours"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="teachingHours">
              <template v-slot:label>
                Số giờ giảng
              </template>
              <b-form-input
                id="teachingHours"
                v-model="targetItem.teachingHours"
                name="teachingHours"
                type="number"
                disabled
                placeholder="Số giờ giảng"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="examPreparationHours">
              <template v-slot:label>
                Số giờ ra đề
              </template>
              <b-form-input
                id="examPreparationHours"
                v-model="targetItem.examPreparationHours"
                name="examPreparationHours"
                type="number"
                placeholder="Số giờ ra đề"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="examReviewHours">
              <template v-slot:label>
                Số giờ duyệt đề
              </template>
              <b-form-input
                id="examReviewHours"
                v-model="targetItem.examReviewHours"
                name="examReviewHours"
                type="number"
                placeholder="Số giờ duyệt đề"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="examProctoringHours">
              <template v-slot:label>
                Số giờ coi thi
              </template>
              <b-form-input
                id="examProctoringHours"
                v-model="targetItem.examProctoringHours"
                name="examProctoringHours"
                type="number"
                placeholder="Số giờ coi thi"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="examGradingHours">
              <template v-slot:label>
                Số giờ chấm thi
              </template>
              <b-form-input
                id="examGradingHours"
                v-model="targetItem.examGradingHours"
                name="examGradingHours"
                type="number"
                placeholder="Số giờ chấm thi"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="researchSupervisionHours">
              <template v-slot:label>
                Số giờ hướng dẫn học viên NCTT
              </template>
              <b-form-input
                id="researchSupervisionHours"
                v-model="targetItem.researchSupervisionHours"
                name="researchSupervisionHours"
                disabled
                type="number"
                placeholder="Số giờ hướng dẫn học viên NCTT"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="researchGradingHours">
              <template v-slot:label>
                Số giờ chấm bài thu hoạch NCTT
              </template>
              <b-form-input
                id="researchGradingHours"
                v-model="targetItem.researchGradingHours"
                name="researchGradingHours"
                type="number"
                placeholder="Số giờ chấm bài thu hoạch NCTT"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="thesisSupervisionHours">
              <template v-slot:label>
                Số giờ hướng dẫn KLTN
              </template>
              <b-form-input
                id="thesisSupervisionHours"
                v-model="targetItem.thesisSupervisionHours"
                name="thesisSupervisionHours"
                type="number"
                placeholder="Số giờ hướng dẫn KLTN"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="thesisDefenseCommitteeHours">
              <template v-slot:label>
                Số giờ hội đồng chấm KLTN
              </template>
              <b-form-input
                id="thesisDefenseCommitteeHours"
                v-model="targetItem.thesisDefenseCommitteeHours"
                name="thesisDefenseCommitteeHours"
                type="number"
                placeholder="Số giờ hội đồng chấm KLTN"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="thesisGradingMemberHours">
              <template v-slot:label>
                Số giờ thành viên chấm KLTN
              </template>
              <b-form-input
                id="thesisGradingMemberHours"
                v-model="targetItem.thesisGradingMemberHours"
                name="thesisGradingMemberHours"
                type="number"
                placeholder="Số giờ thành viên chấm KLTN"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="graduationExamCommitteeHours">
              <template v-slot:label>
                Số giờ hội đồng thi TN
              </template>
              <b-form-input
                id="graduationExamCommitteeHours"
                v-model="targetItem.graduationExamCommitteeHours"
                name="graduationExamCommitteeHours"
                type="number"
                placeholder="Số giờ hội đồng thi TN"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="4"
            lg="4"
            xl="4"
          >
            <b-form-group label-for="admissionsCommitteeHours">
              <template v-slot:label>
                Số giờ hội đồng tuyển sinh
              </template>
              <b-form-input
                id="admissionsCommitteeHours"
                v-model="targetItem.admissionsCommitteeHours"
                name="admissionsCommitteeHours"
                type="number"
                placeholder="Số giờ hội đồng tuyển sinh"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="graduationDegreeCommitteeHours">
              <template v-slot:label>
                Số giờ hội đồng xét cấp bằng TN
              </template>
              <b-form-input
                id="graduationDegreeCommitteeHours"
                v-model="targetItem.graduationDegreeCommitteeHours"
                name="graduationDegreeCommitteeHours"
                type="number"
                placeholder="Số giờ hội đồng xét cấp bằng TN"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="teacherClassHours">
              <template v-slot:label>
                Số giờ ban quản lý, chủ nhiệm lớp
              </template>
              <b-form-input
                id="teacherClassHours"
                v-model="targetItem.teacherClassHours"
                name="teacherClassHours"
                type="number"
                disabled
                placeholder="Số giờ ban quản lý, chủ nhiệm lớp"
                @input="calculateTotalHours"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="otherTotalHours">
              <template v-slot:label>
                Tổng giờ khác
              </template>
              <b-form-input
                id="otherTotalHours"
                v-model="targetItem.otherTotalHours"
                name="otherTotalHours"
                type="number"
                disabled
                placeholder="Tổng giờ khác"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group label-for="totalHours">
              <template v-slot:label>
                Tổng giờ
              </template>
              <b-form-input
                id="totalHours"
                v-model="targetItem.totalHours"
                name="totalHours"
                type="number"
                disabled
                placeholder="Tổng giờ"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('teacherHourSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@core/utils/validations/validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'

export default {
  name: 'TeacherSave',
  components: {
    BCol,
    BRow,
    BFormInput,
    BFormGroup,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetItem: {
        year: null,
        teacherId: null,
        subjectId: null,
        teachingHours: 0,
        examPreparationHours: 0,
        examReviewHours: 0,
        examProctoringHours: 0,
        examGradingHours: 0,
        researchSupervisionHours: 0,
        researchGradingHours: 0,
        thesisSupervisionHours: 0,
        thesisDefenseCommitteeHours: 0,
        thesisGradingMemberHours: 0,
        graduationExamCommitteeHours: 0,
        admissionsCommitteeHours: 0,
        graduationDegreeCommitteeHours: 0,
        teacherClassHours: 0,
        otherTotalHours: 0,
        totalHours: 0,
        status: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      teachers: 'twTeacherHour/teachers',
      subjects: 'twTeacherHour/subjects',
      teachingHours: 'twTeacherHour/teachingHours',
      researchSupervisionHours: 'twTeacherHour/researchSupervisionHours',
      teacherClassHours: 'twTeacherHour/teacherClassHours',
    }),
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createTeacherHours: 'twTeacherHour/createTeacherHours',
      updateTeacherHours: 'twTeacherHour/updateTeacherHours',
      readTeachers: 'twTeacherHour/getTeachers',
      getSubjects: 'twTeacherHour/getSubjects',
      getTeachingHours: 'twTeacherHour/getTeachingHours',
      getTeacherClassHours: 'twTeacherHour/getTeacherClassHours',
      getResearchSupervisionHours: 'twTeacherHour/getResearchSupervisionHours',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async calculateTeachingHours() {
      if (this.targetItem.year !== null
      && this.targetItem.teacherId !== null
      && this.targetItem.subjectId !== null) {
        const param = {
          year: this.targetItem.year,
          teacherId: this.targetItem.teacherId,
          subjectId: this.targetItem.subjectId,
        }
        try {
          this.isLoading = true
          await Promise.all([
            this.getTeachingHours(param),
            this.getTeacherClassHours(param),
            this.getResearchSupervisionHours(param),
          ])
          this.targetItem.teachingHours = this.teachingHours
          const subjectCode = this.subjects.find(e => e.id === this.targetItem.subjectId);
          this.targetItem.teacherClassHours = subjectCode.code === 'QLLH' ? this.teacherClassHours : 0
          this.targetItem.researchSupervisionHours = this.researchSupervisionHours
          await this.calculateTotalHours()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    calculateTotalHours() {
      this.targetItem.otherTotalHours = (
        parseFloat(this.targetItem.examPreparationHours) +
        parseFloat(this.targetItem.examReviewHours) +
        parseFloat(this.targetItem.examProctoringHours) +
        parseFloat(this.targetItem.examGradingHours) +
        parseFloat(this.targetItem.researchSupervisionHours) +
        parseFloat(this.targetItem.researchGradingHours) +
        parseFloat(this.targetItem.thesisSupervisionHours) +
        parseFloat(this.targetItem.thesisDefenseCommitteeHours) +
        parseFloat(this.targetItem.thesisGradingMemberHours) +
        parseFloat(this.targetItem.graduationExamCommitteeHours) +
        parseFloat(this.targetItem.admissionsCommitteeHours) +
        parseFloat(this.targetItem.graduationDegreeCommitteeHours) +
        parseFloat(this.targetItem.teacherClassHours)
      )
      this.targetItem.totalHours = (
        parseFloat(this.targetItem.teachingHours) +
        this.targetItem.otherTotalHours
      )
    },
    async onShow() {
      this.isLoading = true
      if (this.item) {
        this.targetItem = this.item
      }
      try {
        await Promise.all([
          this.readTeachers(),
          this.getSubjects({ organizationId: getUser().orgId }),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .saveModalRef
        .reset()
      this.targetItem = {
        year: null,
        teacherId: null,
        subjectId: null,
        teachingHours: 0,
        examPreparationHours: 0,
        examReviewHours: 0,
        examProctoringHours: 0,
        examGradingHours: 0,
        researchSupervisionHours: 0,
        researchGradingHours: 0,
        thesisSupervisionHours: 0,
        thesisDefenseCommitteeHours: 0,
        thesisGradingMemberHours: 0,
        graduationExamCommitteeHours: 0,
        admissionsCommitteeHours: 0,
        graduationDegreeCommitteeHours: 0,
        teacherClassHours: 0,
        otherTotalHours: 0,
        totalHours: 0,
        status: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveModalRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createTeacherHours(this.targetItem)
            : await this.updateTeacherHours(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('teacherHourSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
