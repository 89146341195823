<template src="./index.html" />

<script>
import * as XLSX from 'xlsx'
import {
  BBreadcrumb, BButton, BFormFile, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BFormCheckbox, BFormRadio,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import { Workbook } from '@syncfusion/ej2-excel-export'
import { getUser } from '@/auth/utils'
import { handleCatch, hasPermissionForResource, isSystemAdmin } from '@/utils'
import { ApiCode } from '@/const/api'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'Index',
  components: {
    BBreadcrumb,
    BButton,
    BFormFile,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    XLSX,
    VueGoodTable,
    BFormCheckbox,
    BFormRadio,
    ToastificationContent,
  },
  props: {

  },
  data() {
    return {
      user: getUser(),
      table: {
        fields: [
          {
            field: 'stt', label: 'STT', sortable: false, type: 'string',
          },
          {
            field: 'name', label: 'Tên cán bộ', sortable: false, type: 'string',
          },
          {
            field: 'account', label: 'Tài khoản', sortable: false, type: 'string',
          },
          {
            field: 'department', label: 'Khoa/Bộ môn', sortable: false, type: 'string',
          },
          {
            field: 'isTeacher', label: 'Là giảng viên', sortable: false, type: 'string',
          },
        ],
        items: [],
      },
      tenFile: '',
      dataExcel: [],
      totalRecords: 0,
      serverParams: {
        page: 1,
        perPage: 10,
      },
      loading: false,
      selected: '',
      disCreateAccount: false
    }
  },
  computed: {
    ...mapGetters({
      total: 'rooms/total',
    }),
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.IMPORT_EMPLOYEE)
    },
  },
  methods: {
    ...mapActions({
      importEmployee: 'employee/importEmployee',
    }),
    async importExcel(e) {
      const headerItem = {
        field: 'codeOld', label: 'Mã cán bộ cũ', sortable: false, type: 'string',
      }
      if (this.selected) {
        this.table.fields.push(headerItem)
      }
      const { files } = e.target
      if (!files.length) {
        return
      } if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        // show err
      }
      if (files[0].name) {
        this.tenFile = files[0].name
      }
      this.readFile(files)
    },

    readFile(files) {
      const fileReader = new FileReader()
      fileReader.onload = async ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, {
            type: 'binary',
          })
          const wsname = workbook.SheetNames[0]
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            header: 0,
            defval: '',
          })
          const excellist = []
          // Edit data
          if (ws.length > 0) {
            for (let i = 0; i < ws.length; i++) {
              excellist.push(ws[i])
            }
          } else {
            this.showToast('File không có dữ liệu', 'XCircleIcon', 'danger', '')
            return
          }
          if (
            !excellist[0].hasOwnProperty('TEN')
            || !excellist[0].hasOwnProperty('TAI_KHOAN')
            || !excellist[0].hasOwnProperty('MA_KHOA')
            || !excellist[0].hasOwnProperty('LA_GIANG_VIEN')
          ) {
            this.showToast('File chưa đúng định dạng', 'XCircleIcon', 'danger', '')
            return
          }

          this.dataExcel = []
          for (let k = 0; k < excellist.length; k++) {
            const item = excellist[k]
            const obj = {
              stt: k + 1,
              name: item.TEN,
              account: item.TAI_KHOAN,
              department: item.MA_KHOA,
              isTeacher: item.LA_GIANG_VIEN,
            }
            this.dataExcel.push(obj)
          }
          this.table.items = this.dataExcel
          this.totalRecords = this.dataExcel.length
          if (this.totalRecords > 10) {
            this.table.items = this.dataExcel.slice(0, 10)
          }


          const arrDuplicate = this.checkDuplicateAccount(this.dataExcel);
          if (arrDuplicate.length > 0) {
            this.disCreateAccount = true
            this.showToast('Account đang bị trùng, vui lòng kiểm tra lại dữ liệu trước khi import', 'XCircleIcon', 'warning', '')
          }else{
            this.disCreateAccount = false
          }

          // insert ban ghi
        } catch (e) {
          return alert(`Read failure!${e}`)
        }
      }
      fileReader.readAsBinaryString(files[0])
      this.resetInputFile()
    },

    resetInputFile() {
      this.$refs.inputFileRef.reset()
    },

    async importData(hasCreateAccount = 0) {
      this.loading = true
      try {
        const data = this.dataExcel
        const res = await this.importEmployee(data)
        if (res.code === ApiCode.SUCCESS) {
          this.showToast('Lưu thành công', 'CheckIcon', 'success', '')
          this.resetInputFile()
        } else {
          this.showToast(res.message, 'XCircleIcon', 'danger', '')
          this.resetInputFile()
        }
      } catch (e) {
        handleCatch(e)
      } finally {
        this.loading = false
      }
    },

    downloadFile() {
      const columns = [
        { index: 1, width: 130 },
        { index: 2, width: 130 },
        { index: 3, width: 100 },
        { index: 4, width: 130 },
      ]
      const rows = []
      const headerRow = {
        index: 1,
        cells: [
          {
            index: 1,
            value: 'TEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 2,
            value: 'TAI_KHOAN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 3,
            value: 'MA_KHOA',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
          {
            index: 4,
            value: 'LA_GIANG_VIEN',
            colSpan: 1,
            style: {
              borders: {
                color: '#00000000',
                lineStyle: 'thin',
              },
              fontColor: '#ffffff',
              backColor: '#000000',
            },
          },
        ],
      }
      rows.push(headerRow)
      const worksheets = [{ columns, rows }]
      const workbook = new Workbook({ worksheets }, 'xlsx')
      workbook.save('import_giang_vien.xlsx')
    },
    // handle even

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

    onPageChange(params) {
      const start = params.currentPerPage * (params.currentPage - 1)
      const end = this.dataExcel.length > (params.currentPerPage * params.currentPage) ? (params.currentPerPage * params.currentPage) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end)
    },

    onPerPageChange(params) {
      const start = params.currentPerPage * (params.currentPage - 1)
      const end = this.dataExcel.length > (params.currentPerPage * params.currentPage) ? (params.currentPerPage * params.currentPage) : this.dataExcel.length
      this.table.items = this.dataExcel.slice(start, end)
    },

    handleChecked() {

    },

    checkDuplicateAccount(arrays) {
      const accountSet = new Set();
      const duplicates = [];
      for (const arr of arrays) {
        if (accountSet.has(arr.account)) {
          duplicates.push(arr);
        } else {
          accountSet.add(arr.account);
        }
      }

      return duplicates;
  }

  },
}
</script>

<style type="text/css">
.form_file{
  width: auto !important;
}
</style>
