<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >

    <b-modal
        id="timeFrameResearchSaveModal"
        body-class="position-static"
        centered
        :title="isCreated ? 'Thêm mới dữ liệu' : 'Cập nhật dữ lệu'"
        no-close-on-backdrop
        size="lg"
        @show="onShow"
        @hidden="onHide"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group label-for="factTeacherTypeId">
              <template v-slot:label>
                Loại giảng viên <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Tên loại giảng viên"
                  rules="required"
              >
                <v-select
                    v-model="targetItem.factTeacherTypeId"
                    :options="teacherType"
                    label="name"
                    :reduce="option => option.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="timeStandard">
              <template v-slot:label>
                Số giờ chuẩn<span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Nhập số giờ chuẩn"
                  rules="required"
              >
                <b-form-input
                    id="timeStandard"
                    v-model="targetItem.timeStandard"
                    placeholder="Nhập số giờ chuẩn"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Trạng thái"
                  rules="required"
              >
                <v-select
                    v-model="targetItem.status"
                    :options="statusOptions"
                    :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" />Lưu lại
            </span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('timeFrameResearchSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" />Hủy
            </span>

          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { required } from '@validations'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'TimeFrameResearchSaveModal',
  components: {
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        factTeacherTypeId: '',
        timeStandard: null,
        status: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statues: 'twTimeFrameResearch/statuses',
      teacherType: 'twTimeFrameResearch/teacherType',
    }),
    statusOptions() {
      return this.statues
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      getTeacherType: 'twTimeFrameResearch/getTeacherType',
      createTimeFrameResearch: 'twTimeFrameResearch/createTimeFrameResearch',
      updateTimeFrameResearch: 'twTimeFrameResearch/updateTimeFrameResearch',
    }),
    async onSave(type = null) {
      try {
        const response = this.isCreated ? await this.createTimeFrameResearch(this.targetItem) : await this.updateTimeFrameResearch(this.targetItem)
        if (response) {
          const {isSuccessful, message} = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('timeFrameResearchSaveModal')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async onShow() {
      if (this.item) {
        this.targetItem = {...this.item}
      }
      this.isLoading = true
      try {
        await this.getTeacherType({})
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.targetItem = {
        factTeacherTypeId: '',
        timeStandard: null,
        status: 1,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped lang="scss">

</style>
