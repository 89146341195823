<template>
  <validation-observer
      ref="resourceSaveFormRef"
      #default="{invalid}"
  >
    <b-modal
        id="practicalResearchStudentRestAddModal"
        ref="practicalResearchStudentRestAddModal"
        body-class="position-static"
        centered
        size="l"
        title="Thêm học viên từ danh sách xin nghỉ phép"
        :no-close-on-backdrop="true"
        @hide="onHide"
        @show="onShow"
    >
      <b-row>
        <b-col cols="12">
          <b-form
              ref="formRef"
              :style="{height: trHeight}"
          >
            <b-row
                v-for="(item, index) in studentsRest"
                :key="item.id"
                ref="row"
            >
              <b-col md="10">
                <b-form-group label-for="studentClassId">
                  <template v-slot:label>
                    Học viên đã xin nghỉ phép <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                      #default="{ errors }"
                      name="Loại yêu cầu"
                      rules="required"
                  >
                    <v-select
                        label="label"
                        :options="studentRestPracticalResearch"
                        :reduce="option => option"
                        @input="event => onStudentRestChange(event, index)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                  lg="2"
                  md="1"
                  class="mb-50"
              >
                <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                >
                  <span>Xóa</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              @click="repeatAgain"
          >
            <feather-icon
                icon="PlusIcon"
                class="mr-25"
            />
            <span>Thêm mới</span>
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="invalid || studentsRest.length === 0"
              variant="primary"
              class="mr-1"
              @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Thêm vào danh sách
            </span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('credentialProcessTypeSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
          variant="white"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
          no-wrap
          :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem, BForm, BFormDatepicker, BFormGroup, BFormInput, BFormInvalidFeedback,
  BFormSelect, BModal, BOverlay, BPagination, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'PracticalResearchStudentRestAdd',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BContainer,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    BCard,
    ValidationObserver,
    ValidationProvider,
    VueGoodTable,
    AppCollapse,
    AppCollapseItem,
    BFormDatepicker,
  },
  mixins: [heightTransition],
  props: {
    dataGroup: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      studentsRest: [{
        id: 1,
        studentClassId: null,
        practicalResearchGroupId: this.dataGroup.practicalResearchGroupId,
      }],
      nextId: 2,
      required,
      filterGetAll: {
        currentPage: 1,
        itemsPerPage: 1000,
      },
    }
  },
  mounted() {
    this.initTrHeight()
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  computed: {
    ...mapGetters({
      studentRestPracticalResearch: 'practicalResearchClassStudent/studentRestPracticalResearch',
    }),
  },
  methods: {
    ...mapActions({
      readPracticalResearchStudentsRest: 'practicalResearchClassStudent/readPracticalResearchStudentsRest',
      addStudentPracticalResearchClassGroup: 'practicalResearchClassStudent/addStudentPracticalResearchClassGroup',
    }),
    repeatAgain() {
      this.studentsRest.push({
        id: this.nextId++,
        studentClassId: null,
        practicalResearchClassGroupId: this.dataGroup.id
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.studentsRest.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.formRef.scrollHeight)
      })
    },
    async onShow() {
      await this.readPracticalResearchStudentsRest()
    },
    onHide() {
      this.studentsRest = [{
        id: 1,
        studentClassId: null,
      }]
      this.nextId = 2
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onStudentRestChange(event, index) {
      this.studentsRest[index] = {
        id: index + 1,
        label: event.label,
        value: event.value,
        studentClassId: event.value,
        practicalResearchClassGroupId: this.dataGroup.id,
        name: event.name,
        code: event.code,
        status: 0,
      }
    },
    async onSave(type = null) {
      this.isLoading = true
      try {
        const response = await this.addStudentPracticalResearchClassGroup(this.studentsRest)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('practicalResearchStudentRestAddModal')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },

  },
}
</script>
<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
