import { STATUSES } from '@/const/status'
import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    statuses: STATUSES,
    practicalResearchTopic: [],
    totalRows: 0,
    practicalResearchPrograms: [],
  },
  getters: {
    statuses: state => state.statuses,
    totalRows: state => state.totalRows,
    practicalResearchTopic: state => state.practicalResearchTopic,
    practicalResearchPrograms: state => state.practicalResearchPrograms,
  },
  mutations: {
    SET_STATUSES: (state, statuses) => {
      state.statuses = statuses
    },
    SET_TOTAL_ROWS: (state, data) => {
      state.totalRows = data
    },
    SET_PRACTICAL_RESEARCH_TOPIC: (state, data) => {
      state.practicalResearchTopic = data
    },
    SET_PRACTICAL_RESEARCH_PROGRAMS: (state, records) => {
      state.practicalResearchPrograms = records
    },
  },
  actions: {
    async getPracticalResearchPrograms({commit}, params){
      try {
        const response = await axios.get('uni/practical_research_programs', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_PRACTICAL_RESEARCH_PROGRAMS', data.data.records)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async readPracticalResearchTopic({ commit }, params) {
      try {
        const response = await axios.get('uni/practical_research_topics', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { total, records } = data.data
          commit('SET_PRACTICAL_RESEARCH_TOPIC', records)
          commit('SET_TOTAL_ROWS', total)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createPracticalResearchTopic({ commit }, params) {
      try {
        const response = await axios.post('uni/practical_research_topics', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updatePracticalResearchTopic({ commit }, params) {
      try {
        const response = await axios.put(`uni/practical_research_topics/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async deletePracticalResearchTopic({ commit }, id) {
      try {
        const response = await axios.delete(`uni/practical_research_topics/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
