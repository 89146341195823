<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >

    <b-modal
      id="practicalResearchProgramSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm mới dữ liệu' : 'Cập nhật dữ lệu'"
      no-close-on-backdrop
      size="lg"
      @show="onShow"
      @hidden="onHide"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên chương trình nghiên cứu thực tế <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Tên chương trình nghiên cứu thực tế"
                  rules="required"
              >
                <b-form-input
                    id="description"
                    v-model="targetItem.name"
                    placeholder="Tên chương trình nghiên cứu thực tế"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="description">
              <template v-slot:label>
                Nội dung chương trình nghiên cứu thực tế <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Nội dung chương trình nghiên cứu thực tế"
                  rules="required"
              >
                <b-form-textarea
                    id="description"
                    v-model="targetItem.description"
                    type="text"
                    placeholder="Nội dung chương trình nghiên cứu thực tế"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="address">
              <template v-slot:label>
                Địa điểm nghiên cứu <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Địa điểm nghiên cứu"
                  rules="required"
              >
                <b-form-input
                    id="address"
                    v-model="targetItem.address"
                    placeholder="Địa điểm nghiên cứu"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="mainTeacherId">
              <template v-slot:label>
                Trưởng đoàn <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Trạng thái"
                  rules="required"
              >
                <v-select
                    v-model="targetItem.mainTeacherId"
                    :options="teachers"
                    :reduce="option => option.id"
                    label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="courseId">
              <template v-slot:label>
                Khóa học <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Trạng thái"
                  rules="required"
              >
                <v-select
                    v-model="targetItem.courseId"
                    :options="course"
                    :reduce="option => option.id"
                    label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label-for="timeStudy">
              <template v-slot:label>Thời gian học(ngày)<span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Thời gian học"
                  rules="required"
              >
                <b-form-input
                    id="timeStudy"
                    v-model="targetItem.timeStudy"
                    placeholder="Nhập số ngày học"
                    type="number"
                    :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                  #default="{ errors }"
                  name="Trạng thái"
                  rules="required"
              >
                <v-select
                    v-model="targetItem.status"
                    :options="statusOptions"
                    :reduce="option => option.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" />Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('practicalResearchSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" />Hủy
            </span>

          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { required } from '@validations'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'PracticalResearchProgramSave',
  components: {
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        name: '',
        description: '',
        address: '',
        timeStudy: null,
        status: 1,
        courseId: null,
        mainTeacherId: null,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statues: 'practicalResearchProgram/statuses',
      course: 'practicalResearchProgram/course',
      teachers: 'practicalResearchProgram/teachers',
    }),
    statusOptions() {
      return this.statues
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createPracticalResearchProgram: 'practicalResearchProgram/createPracticalResearchProgram',
      updatePracticalResearchProgram: 'practicalResearchProgram/updatePracticalResearchProgram',
      getListCourses: 'practicalResearchProgram/getListCourses',
      getTeachers: 'practicalResearchProgram/getTeachers',
    }),
    async onSave(type = null) {
      try {
        const response = this.isCreated ? await this.createPracticalResearchProgram(this.targetItem) : await this.updatePracticalResearchProgram(this.targetItem)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('practicalResearchProgramSaveModal')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async onShow() {
      if (this.item) {
        this.targetItem = { ...this.item }
      }
      this.isLoading = true
      try {
        const param = {
          currentPage: 1,
          itemsPerPage: 5000,
        }
        await Promise.all([
          this.getListCourses(param),
          this.getTeachers(param),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.targetItem = {
        name: '',
        description: '',
        address: '',
        timeStudy: null,
        status: 1,
        courseId: null,
        mainTeacherId: null,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>

<style scoped lang="scss">

</style>
