<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="credentialStorageSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Bổ sung' : 'Chỉnh sửa'"
      no-close-on-backdrop
      size="lg"
      @show="onShow"
      @hidden="onHide"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group label-for="credentialId">
              <template v-slot:label>
                Văng bằng, chứng chỉ<span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Văn bằng, chứng chỉ"
                rules="required"
              >
                <v-select
                  v-model="targetItem.credentialId"
                  :options="credentialsDropdown"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              v-if="credentialProcessReq && isCreated"
            >
              <template v-slot:label>
                Lựa chọn file bản sao văn bằng, chứng chỉ của học viên để thêm vào hệ thống<span class="text-danger">*</span>
                <p style="color: red; font-style: italic;">
                  Chú ý: File bản sao cần phải ở định dạng file pdf
                </p>
              </template>
              <b-form-radio-group
                ref="credentialFileGroup"
                v-model="targetItem.credentialFileId"
                :options="options"
                name="radios-stacked"
                stacked
                option-value="value"
                option-text="text"
                @change="checkPdfExtension"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="4"
          >
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên học viên<span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên học viên"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetItem.name"
                  placeholder="Tên học viên"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
          >
            <b-form-group label-for="genderCode">
              <template v-slot:label>
                Giới tính<span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Giới tính"
                rules="required"
              >
                <v-select
                  v-model="targetItem.genderCode"
                  :options="GENDERS()"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
          >
            <b-form-group
              label="Ngày tháng năm sinh"
              label-for="birthday"
            >
              <b-form-datepicker
                id="datepicker-dateformat2"
                v-model="targetItem.birthday"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="vi"
                format="dd/mm/yyyy"
                placeholder="dd/mm/yyyy"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label-for="address">
          <template v-slot:label>
            Nơi sinh
          </template>
          <b-form-input
            id="address"
            v-model="targetItem.address"
            placeholder="Nơi sinh"
          />
        </b-form-group>

        <b-form-group label-for="nationality">
          <template v-slot:label>
            Quốc tịch
          </template>
          <v-select
            v-model="targetItem.nationality"
            label="name"
            :options="allFactNationalities"
            :reduce="option => option.code"
          />
        </b-form-group>

        <b-form-group label-for="ethnicCode">
          <template v-slot:label>
            Dân tộc
          </template>
          <v-select
            v-model="targetItem.ethnicCode"
            label="name"
            :options="allFactEthnics"
            :reduce="option => option.code"
          />
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group label-for="trainingSystemId">
              <template v-slot:label>
                Hình thức đào tạo
              </template>
              <v-select
                v-model="targetItem.trainingSystemId"
                label="label"
                :options="trainingSystems"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label-for="majorId">
              <template v-slot:label>
                Ngành đào tạo
              </template>
              <v-select
                v-model="targetItem.majorId"
                :options="majors"
                :reduce="option => option.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label-for="yearId">
          <template v-slot:label>
            Năm cấp
          </template>
          <v-select
            v-model="targetItem.yearId"
            :options="schoolYears"
            :reduce="option => option.value"
          />
        </b-form-group>

        <b-form-group label-for="graduationText">
          <template v-slot:label>
            Xếp loại
          </template>
          <b-form-input
            id="graduationText"
            v-model="targetItem.graduationText"
            placeholder="Xếp loại"
          />
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group label-for="code">
              <template v-slot:label>
                Số hiệu
              </template>
              <b-form-input
                id="code"
                v-model="targetItem.code"
                placeholder="Số hiệu"
                :disabled="!isCreated"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label-for="codeCredentialStorage">
              <template v-slot:label>
                Số vào sổ gốc
              </template>
              <b-form-input
                id="codeCredentialStorage"
                v-model="targetItem.codeCredentialStorage"
                placeholder="Số vào sổ gốc"
                :disabled="!isCreated"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row
          v-if="!credentialProcessReq"
        >
          <b-col>
            <b-form-group label-for="credentialFileId">
              <template v-slot:label>
                File văn bằng, chứng chỉ của sinh viên
              </template>
              <b-form-file
                ref="inputFileRef"
                :placeholder="placeholder"
                @change="upload"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Trạng thái"
            rules="required"
          >
            <v-select
              v-model="targetItem.status"
              :options="statusOptions"
              :reduce="option => option.value"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('credentialStorageSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>
<script>

import {
  BButton, BCol,
  BForm,
  BFormDatepicker, BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback, BFormRadioGroup,
  BModal,
  BOverlay, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { required } from '@core/utils/validations/validations'
import { mapActions, mapGetters } from 'vuex'
import { GENDERS } from '@/const/status'
import { Flag } from '@/const/flag'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CredentialStorageSave',
  components: {
    BFormFile,
    BRow,
    BCol,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    credentialProcessReq: {
      type: Object,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        credentialId: '',
        name: '',
        birthday: '',
        address: '',
        genderCode: '',
        ethnicCode: '',
        nationality: '',
        graduationText: '',
        yearId: '',
        statusPrint: '',
        majorId: '',
        trainingSystemId: '',
        classId: '',
        status: '',
        studentId: '',
        credentialFileId: null,
      },
      required,
      options: [],
      placeholder: 'File văn bằng, chứng chỉ của sinh viên...',
      mediaIds: [],
    }
  },
  computed: {
    ...mapGetters({
      credentialsDropdown: 'dropdown/credentials',
      allFactNationalities: 'curriculumVitae/allFactNationalities',
      allFactEthnics: 'curriculumVitae/allFactEthnics',
      majors: 'dropdown/majors',
      schoolYears: 'dropdown/schoolYears',
      statuses: 'credentialStorage/statuses',
      trainingSystems: 'dropdown/trainingSystems',
    }),
    isCreated() {
      return !this.item
    },
    statusOptions() {
      return this.statuses
    },
  },
  methods: {
    GENDERS() {
      return GENDERS
    },
    ...mapActions({
      getAllCredentials: 'dropdown/getAllCredentials',
      getFactNationalities: 'curriculumVitae/getFactNationalities',
      getFactEthnics: 'curriculumVitae/getFactEthnics',
      getMajors: 'dropdown/getMajors',
      getSchoolYears: 'dropdown/getSchoolYears',
      updateCredentialStorage: 'credentialStorage/updateCredentialStorage',
      addCredentialsStorage: 'credentialCreate/addCredentialsStorage',
      updateStatusProcess: 'processRequest/updateStatusProcess',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      uploadFile: 'credentialStorage/uploadFile',
    }),
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    checkPdfExtension(selectedOption) {
      const option = this.options.find(opt => opt.value === selectedOption)
      if (option && option.text && !option.text.endsWith('.pdf')) {
        this.targetItem.credentialFileId = ''
        this.showToast('Định dạng file cần ở dạng pdf', 'XCircleIcon', 'danger')
      } else {
        this.targetItem.credentialFileId = option.value
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      this.isLoading = true
      try {
        await Promise.all([
          this.getFactNationalities({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
          this.getFactEthnics(),
          this.getMajors({ currentPage: 1, itemsPerPage: 1000, status: Flag.ACTIVE }),
          this.getSchoolYears(),
          this.getTrainingSystems({ currentPage: 1, itemsPerPage: 1000 }),
        ])
        if (this.item) {
          this.targetItem = { ...this.item }
        }
        if (this.credentialProcessReq) {
          this.targetItem.studentId = this.credentialProcessReq.studentId
          this.credentialProcessReq.filesId.forEach((fileId, index) => {
            this.options.push({
              text: this.credentialProcessReq.filesName[index],
              value: fileId,
            })
          })
        }
        if (this.targetItem.birthday) {
          const [day, month, year] = this.targetItem.birthday.split('/')
          this.targetItem.birthday = new Date(`${year}-${month}-${day}`)
        }
        if (this.targetItem.credentialFileId) {
          this.placeholder = this.item.credentialFileName
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.options = []
      this.targetItem = {
        credentialId: '',
        name: '',
        birthday: '',
        address: '',
        genderCode: '',
        ethnicCode: '',
        nationality: '',
        graduationText: '',
        yearId: '',
        statusPrint: '',
        majorId: '',
        trainingSystemId: '',
        classId: '',
        status: '',
        studentId: '',
        credentialFileId: null,
      }
      this.placeholder = 'File văn bằng, chứng chỉ của sinh viên...'
    },
    async onSave(type = null) {
      if (this.targetItem.birthday) {
        const date = new Date(this.targetItem.birthday)
        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0') // Tháng trong JavaScript bắt đầu từ 0
        const year = date.getFullYear()
        this.targetItem.birthday = `${day}/${month}/${year}`
      }
      try {
        const response = this.isCreated ? await this.addCredentialsStorage([this.targetItem]) : await this.updateCredentialStorage(this.targetItem)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (this.credentialProcessReq) {
              try {
                const responseProcessReq = await this.updateStatusProcess({
                  id: this.credentialProcessReq.id,
                  params: { status: 2 },
                })
                if (responseProcessReq) {
                  const { isSuccessfulReq, messageReq } = responseProcessReq
                  if (isSuccessfulReq) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: messageReq,
                        icon: 'CheckIcon',
                        variant: 'success',
                      },
                    })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: messageReq,
                        icon: 'XCircleIcon',
                        variant: 'danger',
                      },
                    })
                  }
                }
              } catch (e) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `[${e.code}] ${e.message}`,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
            if (type === 'hide') {
              this.$bvModal.hide('credentialStorageSaveModal')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async upload(e) {

      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File tài liệu đang bị trống',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      if (!(files[0].name.endsWith('.pdf') || files[0].name.endsWith('.xlsx') || files[0].name.endsWith('.xls'))) {
        this.targetItem.credentialFileId = ''
        this.showToast('Định dạng file cần ở dạng pdf hoặc excel', 'XCircleIcon', 'danger')
        return
      }
      files.forEach(file => formData.append('files', file))
      this.isLoading = true
      try {
        this.mediaIds = await this.uploadFile(formData)
        if (this.mediaIds.length > 0) {
          this.targetItem.credentialFileId = this.mediaIds[0]
          this.showToast('Upload file thành công', 'CheckIcon', 'success')
        } else {
          this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
        }
      } catch (event) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${event.code}] ${event.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">

</style>
