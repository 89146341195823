<template>
  <validation-observer
    ref="saveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="saveModal"
      size="lg"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm đợt xét tuyển' : 'Cập nhật đợt xét tuyển'"
      no-close-on-backdrop
      @show="onShow"
      @hide="onHide"
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group label-for="name">
              <template v-slot:label>
                Tên đợt xét tuyển <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Tên đợt xét tuyển"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="targetItem.name"
                  name="name"
                  placeholder="Nhập tên đợt xét tuyển"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="code">
              <template v-slot:label>
                Mã đợt xét tuyển <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mã đợt xét tuyển"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetItem.code"
                  name="code"
                  placeholder="Nhập mã đợt xét tuyển"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="trainingSystem">
              <template v-slot:label>
                Chương trình đào tạo/Bồi dưỡng <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Chương trình đào tạo/Bồi dưỡng"
                rules="required"
              >
                <v-select
                  v-model="targetItem.trainingSystemId"
                  :options="trainingSystems"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="year">
              <template v-slot:label>
                Năm học <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Năm học"
                rules="required"
              >
                <v-select
                  v-model="targetItem.yearId"
                  :options="years"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="target">
              <template v-slot:label>
                Số lượng dự kiến tuyển <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Số lượng dự kiến tuyển"
                rules="required"
              >
                <b-form-input
                  id="code"
                  v-model="targetItem.target"
                  name="code"
                  placeholder="Nhập số lượng"
                  :state="getElementState(errors)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label-for="status">
              <template v-slot:label>
                Trạng thái <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Trạng thái"
                rules="required"
              >
                <v-select
                  v-model="targetItem.status"
                  :options="statusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label-for="openDate"
            >
              <template v-slot:label>
                Ngày bắt đầu <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ngày bắt đầu"
                rules="required"
              >
                <flat-pickr
                  v-model="targetItem.openDate"
                  class="form-control"
                  :config="config"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label-for="closeDate"
            >
              <template v-slot:label>
                Ngày kết thúc <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Ngày kết thúc"
                rules="required"
              >
                <flat-pickr
                  v-model="targetItem.closeDate"
                  class="form-control"
                  :config="config"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label-for="status">
              <template v-slot:label>
                Khóa tuyển sinh <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Khóa tuyển sinh"
                rules="required"
              >
                <v-select
                  v-model="targetItem.locked"
                  :options="lockStatusOptions"
                  :reduce="option => option.value"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Ngày mở đăng ký"
              label-for="openRegisterDate"
            >
              <flat-pickr
                v-model="targetItem.openRegisterDate"
                class="form-control"
                :config="config"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Ngày đóng đăng ký"
              label-for="closeRegisterDate"
            >
              <flat-pickr
                v-model="targetItem.closeRegisterDate"
                class="form-control"
                :config="config"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Ngày mở xét duyệt"
              label-for="openAdmissionDate"
            >
              <flat-pickr
                v-model="targetItem.openAdmissionDate"
                class="form-control"
                :config="config"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Ngày đóng xét duyệt"
              label-for="closeAdmissionDate"
            >
              <flat-pickr
                v-model="targetItem.closeAdmissionDate"
                class="form-control"
                :config="config"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Ngày mở phúc khảo"
              label-for="openCheckExaminationDate"
            >
              <flat-pickr
                v-model="targetItem.openCheckExaminationDate"
                class="form-control"
                :config="config"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Ngày đóng phúc khảo"
              label-for="closeCheckExaminationDate"
            >
              <flat-pickr
                v-model="targetItem.closeCheckExaminationDate"
                class="form-control"
                :config="config"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Hạn xử lý phúc khảo"
              label-for="closeHandleCheckExaminationDate"
            >
              <flat-pickr
                v-model="targetItem.closeHandleCheckExaminationDate"
                class="form-control"
                :config="config"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Ngày công bố kết quả"
              label-for="proclaimDate"
            >
              <flat-pickr
                v-model="targetItem.proclaimDate"
                class="form-control"
                :config="config"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Hạn xác nhận nhập học"
              label-for="enrollmentDeadline"
            >
              <flat-pickr
                v-model="targetItem.enrollmentDeadline"
                class="form-control"
                :config="config"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('saveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import FlatpickrLanguages from 'flatpickr/dist/l10n'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'Save',
  components: {
    flatPickr,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        name: '',
        code: '',
        organizationId: getUser().orgId,
        trainingSystemId: null,
        openDate: '',
        closeDate: '',
        openRegisterDate: '',
        closeRegisterDate: '',
        openAdmissionDate: '',
        closeAdmissionDate: '',
        openCheckExaminationDate: '',
        closeCheckExaminationDate: '',
        closeHandleCheckExaminationDate: '',
        proclaimDate: '',
        enrollmentDeadline: '',
        locked: 1,
        yearId: null,
        target: null,
        status: 1,
        orderNo: 1,
      },
      config: {
        enableTime: true,
        altInput: true,
        altFormat: 'd/m/Y H:i',
        dateFormat: 'd/m/Y H:i',
        locale: FlatpickrLanguages.vn,
        time_24hr: true,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'admissionRound/statuses',
      trainingSystems: 'dropdown/trainingSystems',
      years: 'dropdown/schoolYears',
      lockStatuses: 'admissionRound/lockStatuses',
    }),
    statusOptions() {
      return this.statuses
    },
    lockStatusOptions() {
      return this.lockStatuses
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createAdmissionRounds: 'admissionRound/createAdmissionRounds',
      updateAdmissionRounds: 'admissionRound/updateAdmissionRounds',
      getYears: 'dropdown/getSchoolYears',
      getTrainingSystems: 'dropdown/getTrainingSystems',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShow() {
      await this.getTrainingSystems({ organizationId: getUser().orgId })
      await this.getYears({})
      if (this.item) {
        this.targetItem = { ...this.item }
      }
    },
    onHide() {
      this.$refs
        .saveFormRef
        .reset()
      this.targetItem = {
        name: '',
        code: '',
        organizationId: getUser().orgId,
        trainingSystemId: null,
        openDate: '',
        closeDate: '',
        openRegisterDate: '',
        closeRegisterDate: '',
        openAdmissionDate: '',
        closeAdmissionDate: '',
        openCheckExaminationDate: '',
        closeCheckExaminationDate: '',
        closeHandleCheckExaminationDate: '',
        proclaimDate: '',
        enrollmentDeadline: '',
        locked: 1,
        yearId: null,
        target: null,
        status: 1,
        orderNo: 1,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createAdmissionRounds(this.targetItem)
            : await this.updateAdmissionRounds(this.targetItem)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('saveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
