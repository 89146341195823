<template>
  <validation-observer
    ref="exemptionSaveFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="exemptionSaveModal"
      body-class="position-static"
      centered
      :title="isCreated ? 'Thêm khoản miễn giảm' : 'Cập nhật khoản miễn giảm'"
      @show="onShow"
      @hide="onHide"
      no-close-on-backdrop
    >
      <b-form>
        <b-form-group label-for="training_system_id">
          <template v-slot:label>
            Chương trình đào tạo/Bồi dưỡng <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Chương trình đào tạo/Bồi dưỡng"
            rules="required"
          >
            <v-select
              v-model="targetExemption.training_system_id"
              :options="trainingSystemOptions"
              :reduce="option => option.value"
              :disabled="!isCreated"
              @input="onChangeTrainingSystemSave"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="course_id">
          <template v-slot:label>
            Khóa học <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Khóa học"
            rules="required"
          >
            <v-select
              v-model="targetExemption.course_id"
              :options="courseOptions"
              :reduce="option => option.value"
              :disabled="!isCreated"
              @input="onChangeCourseSave"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="semester_id">
          <template v-slot:label>
            Kỳ học <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Kỳ học"
            rules="required"
          >
            <v-select
              v-model="targetExemption.semester_id"
              :options="semesterOptions"
              :reduce="option => option.value"
              :disabled="!isCreated"
              @input="onChangeSemesterSave"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="major_id" v-show="false">
          <template v-slot:label>
            Ngành học <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Ngành học"
          >
            <v-select
              v-model="targetExemption.major_id"
              :options="majorOptions"
              :reduce="option => option.value"
              :disabled="!isCreated"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="policies_id">
          <template v-slot:label>
            Đối tượng <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Đối tượng"
            rules="required"
          >
            <v-select
              v-model="targetExemption.policies_id"
              :options="policiesOptions"
              :reduce="option => option.value"
              :disabled="!isCreated"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="revenue_id">
          <template v-slot:label>
            Khoản thu <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Khoản thu"
            rules="required"
          >
            <v-select
              v-model="targetExemption.revenue_id"
              :options="revenueOptions"
              :reduce="option => option.value"
              :disabled="!isCreated"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="amount">
          <template v-slot:label>
            Số tiền <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Số tiền"
            rules="required"
          >
            <b-form-input
              id="amount"
              v-model="targetExemption.amount"
              type="number"
              min="0"
              name="amount"
              placeholder="Nhập số tiền"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-show="isCreated"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu và tiếp tục
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('exemptionSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
      <b-overlay
        no-wrap
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="isLoading"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BModal, BOverlay, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'RevenuePlanSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    exemption: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      targetExemption: {
        training_system_id: null,
        course_id: null,
        semester_id: null,
        major_id: null,
        revenue_id: null,
        amount: null,
        course_semester_id: null,
        policies_id: null,
        id: null,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      allTrainingSystemsSave: 'exemption/allTrainingSystems',
      allMajorsSave: 'exemption/allMajors',
      allPoliciesSave: 'exemption/allPolicies',
      listRevenuesExemptions: 'exemption/listRevenuesExemptions',
      listCoursesSave: 'exemption/listCourses',
      listSemestersSave: 'exemption/listSemesters',
    }),
    isCreated() {
      return this.exemption.id == null
    },
    trainingSystemOptions() {
      return this.allTrainingSystemsSave.map(item => ({ value: item.id, label: item.name }))
    },
    courseOptions() {
      return this.listCoursesSave.map(item => ({ value: item.id, label: item.name }))
    },
    semesterOptions() {
      return this.listSemestersSave.map(item => ({ value: item.id, label: item.name }))
    },
    majorOptions() {
      return this.allMajorsSave.map(item => ({ value: item.id, label: item.name }))
    },
    revenueOptions() {
      return this.listRevenuesExemptions.map(item => ({ value: item.id, label: item.name }))
    },
    policiesOptions() {
      return this.allPoliciesSave.map(item => ({ value: item.id, label: item.name }))
    },
  },
  methods: {
    ...mapActions({
      begin: 'exemption/begin',
      createExemption: 'exemption/createExemption',
      updateExemption: 'exemption/updateExemption',
      getExemptions: 'exemption/getExemptions',
      getCourses: 'exemption/getCourses',
      getSemesters: 'exemption/getSemesters',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onShow() {
      if (this.exemption) {
        this.targetExemption = { ...this.exemption }
      }
    },
    onHide() {
      this.$refs.exemptionSaveFormRef.reset()
      this.targetExemption = {
        training_system_id: null,
        courseId: null,
        semester_id: null,
        major_id: null,
        revenue_id: null,
        amount: null,
        courseSemesterId: null,
        id: null,
        policies_id: null,
      }
    },
    async onSave(type = null) {
      const valid = this.$refs.exemptionSaveFormRef.validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = this.isCreated
            ? await this.createExemption(this.targetExemption)
            : await this.updateExemption(this.targetExemption)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              if (type === 'hide') {
                this.$bvModal.hide('exemptionSaveModal')
              }
              this.$emit('succeed')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    onChangeTrainingSystemSave() {
      if (this.targetExemption.training_system_id == null) {
        this.$store.commit('exemption/SET_COURSES', { data: [] })
        this.$store.commit('exemption/SET_SEMESTERS', { data: [] })
        this.targetExemption.course_id = null
        this.targetExemption.semester_id = null
        this.targetExemption.course_semester_id = null
      } else {
        this.getCourses({
          training_system_id: this.targetExemption.training_system_id,
        })
      }
    },
    onChangeCourseSave() {
      if (this.targetExemption.course_id == null) {
        this.$store.commit('exemption/SET_SEMESTERS', { data: [] })
        this.targetExemption.semester_id = null
        this.targetExemption.course_semester_id = null
      } else {
        this.getSemesters({
          courses_id: this.targetExemption.course_id,
        })
      }
    },
    onChangeSemesterSave() {
      if (this.targetExemption.semester_id !== null) {
        const item = this.listSemestersSave.find(x => x.id === this.targetExemption.semester_id)
        this.targetExemption.course_semester_id = item.courses_semesters_id
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
