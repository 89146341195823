<template>
  <div class="row font-weight-bold" id="barRaceChartAnswer">
    <b-container fluid>
      <b-card no-body>
        <b-overlay variant="white" spinner-variant="primary" blur="0" opacity=".75" rounded="sm" :show="isLoading">
          <div class="m-2">
            <b-form>
              <b-row>
                <b-col cols="12" md="2">
                  <b-form-group label="Năm" label-for="year">
                    <v-select v-model="filter.year" :options="yearsSelect"
                      placeholder="Năm" :reduce="(option) => option.value" @input="yearChanged()"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="5">
                  <b-form-group label="Đợt khảo sát/đánh giá" label-for="setOfCriteriaObjectId">
                    <v-select v-model="filter.setOfCriteriaObjectId" :options="setOfCriteriaObjects" label="name"
                      placeholder="Đợt khảo sát/đánh giá" :reduce="(option) => option.id" @input="setOfCriteriaObjectIdChanged($event)"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3" v-if="isShowDropdownSubjectsAndCreditClasses">
                  <b-form-group label="Học Phần" label-for="setOfCriteriaObjectId">
                    <v-select v-model="filter.subjectId" :options="subjectsSetOfCriteriaObject" label="name"
                      placeholder="Học Phần" :reduce="(option) => option.id" @input="subjectIdChanged($event)"/>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="2" v-if="isShowDropdownSubjectsAndCreditClasses">
                  <b-form-group label="Lớp tín chỉ" label-for="setOfCriteriaObjectId">
                    <v-select v-model="filter.creditClassId" :options="creditClassesSetOfCriteriaObject" label="code"
                      placeholder="Lớp tín chỉ" :reduce="(option) => option.id" @input="creditClassIdChanged($event)"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="4">
                  <b-form-group label="Loại thống kê" label-for="setOfCriteriaObjectId">
                    <v-select v-model="filter.statisticType" :options="statisticTypes" placeholder="Loại thống kê"
                      :reduce="(option) => option.value" :clearable="false" @input="statisticType"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div class="text-right">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-0 mt-0 mb-sm-1"
                      @click="onDataStatistic">
                      <feather-icon icon="FilterIcon" /> Xem dữ liệu thống kê mới nhất theo đợt
                    </b-button>
                  </div>
                </b-col>
              </b-row>
              <div>
                <b-row class="">
                  <b-col cols="12" class="text-right">
                    <b-button
                      v-if="isShowStatisticButton"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="ml-1"
                      @click="exportExcel"
                    >
                      <span class="text-nowrap text-right">
                        <feather-icon icon="DownloadIcon" /> Xuất excel
                      </span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <div v-if="statisticForSetOfCriteriaObjectAdvance.createdAt && isShowStatisticButton">
                <b-row class="my-1">
                  <b-col cols="6">
                  </b-col>
                  <b-col cols="6" class="text-right">
                    <b>Dữ liệu thống kê lúc {{ statisticForSetOfCriteriaObjectAdvance.createdAt }} </b>
                  </b-col>
                </b-row>
              </div>
            </b-form>
            <b-form v-if="this.statisticForSetOfCriteriaObjectAdvance.totalEvaluated > 0 && filter.statisticType === statisticTypes[0].value && isShowStatisticButton">
              <b-card-header v-if="levelReceivedOfSetOfCriteriaObject && statisticForSetOfCriteriaObjectAdvance.totalPercentPointReceived">
                <b-card-title>
                  <feather-icon
                    icon="EditIcon"
                    size="22"
                  />
                  Thang đo khảo sát/đánh giá tổng quan
                </b-card-title>
              </b-card-header>
              <div class="mx-3" v-if="levelReceivedOfSetOfCriteriaObject && statisticForSetOfCriteriaObjectAdvance.totalPercentPointReceived" >
                <b-row>
                  <b-col cols="12" md="12">
                    {{ 'Sử dụng ' }} <b>{{ statisticForSetOfCriteriaObjectAdvance.setOfCriteriaLevel.name + "-" + statisticForSetOfCriteriaObjectAdvance.setOfCriteriaLevel.code + " (" + textEvaluateLevels + ")" }}</b>
                  </b-col>
                  <b-col cols="12" md="12">
                    {{ 'Đợt khảo sát/đánh giá nhận được số điểm trung bình cộng các câu hỏi là ' }} <b>{{ statisticForSetOfCriteriaObjectAdvance.totalPercentPointReceived + "%" }}</b> {{ "tương đương" }} <b>{{ levelReceivedOfSetOfCriteriaObject }}</b>
                  </b-col>
                </b-row>
              </div>
              <b-card-header v-if="statisticForSetOfCriteriaObjectAdvance.teachers != null && statisticForSetOfCriteriaObjectAdvance.teachers.length > 0">
                <b-card-title>
                  <feather-icon
                    icon="EditIcon"
                    size="22"
                  />
                  Danh sách giảng viên được đánh giá
                </b-card-title>
              </b-card-header>
              <div v-for="(item, index) in statisticForSetOfCriteriaObjectAdvance.teachers" :key="index" class="mx-3">
                <b-row>
                  <b-col cols="12" md="12">
                    <b-form-group :label-for="item.fullName">
                      <template v-slot:label>
                        {{ (index + 1) + ". " + item.fullName + " (Có " + totalNumberRateTeacher(item.rates) + " đánh giá - Sao trung bình nhận được: " + item.rateAverage + " / 5 sao)" }}
                      </template>
                      <PieChartTeacher class="chart-teacher" :totalEvaluated="statisticForSetOfCriteriaObjectAdvance.totalEvaluated" :teacher="item"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <b-card-header v-if="statisticForSetOfCriteriaObjectAdvance.statisticCriterias != null && statisticForSetOfCriteriaObjectAdvance.statisticCriterias.length > 0 && isShowStatisticButton">
                <b-card-title>
                  <feather-icon
                    icon="EditIcon"
                    size="22"
                  />
                  Danh sách câu hỏi trong phiếu khảo sát
                </b-card-title>
              </b-card-header>
              <div v-for="(item, index) in statisticForSetOfCriteriaObjectAdvance.statisticCriterias" :key="index" class="mx-3">
                <b-row>
                  <b-col cols="12" md="12">
                    <b-form-group :label-for="item.criteriaName">
                      <template v-slot:label>
                        {{ (index + 1) + ". " + item.criteriaName + " (" + CRITERIA_TYPES.filter((el) => el.value === item.criteriaType )[0].label + (item.criteriaType === CRITERIA_TYPES[1].value ? (item.multipleSelect === 1 ? "-Chọn nhiều" : "-Chọn một") : "") + ")" }} <span class="text-danger" v-if="item.required">*</span>
                      </template>
                      <div class="mx-3">{{ "Có " + statisticForSetOfCriteriaObjectAdvance.totalEvaluated + " câu trả lời" + (item.pointAverage > 0 ? "(Điểm trung bình nhận được: " + item.pointAverage + " / " + item.maxPoint + ")" : "") }}</div>
                      <div v-if="item.criteriaType === CRITERIA_TYPES[0].value">
                        <BarRaceChartAnswer :criteria="item"
                          :totalEvaluated="statisticForSetOfCriteriaObjectAdvance.totalEvaluated" />
                      </div>
                      <div v-if="item.criteriaType === CRITERIA_TYPES[1].value">
                        <BarRaceChartOption :criteria="item"
                          :totalEvaluated="statisticForSetOfCriteriaObjectAdvance.totalEvaluated" />
                      </div>
                      <div v-if="item.criteriaType === CRITERIA_TYPES[2].value">
                        <vue-good-table line-numbers :columns="columns" :rows="item.contents"
                          :pagination-options="paginationOptions"
                          class="my-3">
                          <div slot="emptystate" style="text-align: center; font-weight: bold">
                            Không có bản ghi nào !
                          </div>
                          <template slot="table-row" slot-scope="props">
                            <!-- Column: Common -->
                            <span>{{ props.formattedRow[props.column.field] }}</span>
                          </template>

                          <!-- pagination -->
                          <template slot="pagination-bottom" slot-scope="props">
                            <div class="d-flex justify-content-between flex-wrap">
                              <div class="d-flex align-items-center mb-0 mt-1 ml-1">
                                <span class="text-nowrap"> Hiển thị {{ itemsPerPage * (currentPage - 1) + 1 }}
                                  đến {{ itemsPerPage * currentPage }} &nbsp; của {{ props.total }} bản ghi
                                </span>
                              </div>

                              <div class="d-flex align-items-center mb-1 mt-1">
                                <span class="text-nowrap">Hiển thị</span>
                                <b-form-select v-model="itemsPerPage" :options="itemsPerPageOptions" class="mx-1"
                                  @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap">bản ghi/trang</span>
                              </div>
                              <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="itemsPerPage"
                                  class="mt-1 mb-0 mr-1" @input="(value) => props.pageChanged({ currentPage: value })" />
                              </div>
                            </div>
                          </template>
                        </vue-good-table>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <b-card-header v-if="statisticForSetOfCriteriaObjectAdvance.rooms != null && statisticForSetOfCriteriaObjectAdvance.rooms.length > 0 && isShowStatisticButton">
                <b-card-title>
                  <feather-icon
                    icon="EditIcon"
                    size="22"
                  />
                  Danh sách câu hỏi đánh giá phòng học trong phiếu khảo sát
                </b-card-title>
              </b-card-header>
              <div v-for="(room, index) in statisticForSetOfCriteriaObjectAdvance.rooms" :key="index" class="mx-3">
                <b-card-header v-if="statisticForSetOfCriteriaObjectAdvance.statisticCriterias != null && statisticForSetOfCriteriaObjectAdvance.statisticCriterias.length > 0 && isShowStatisticButton">
                  <b-card-title>
                    <feather-icon
                      icon="HomeIcon"
                      size="22"
                    />
                    {{ "Phòng học " + room.roomName + " - " + room.buildingName + "(" + room.roomType + ")" }}
                  </b-card-title>
                </b-card-header>
                <div v-for="(item, index) in room.objectGroupEvaluateRoomCriterias" :key="index" class="mx-4">
                <b-row>
                  <b-col cols="12" md="12">
                    <b-form-group :label-for="item.criteriaName">
                      <template v-slot:label>
                        {{ (index + 1) + ". " + item.criteriaName + " (" + CRITERIA_TYPES.filter((el) => el.value === item.criteriaType )[0].label + (item.criteriaType === CRITERIA_TYPES[1].value ? (item.multipleSelect === 1 ? "-Chọn nhiều" : "-Chọn một") : "") + ")" }} <span class="text-danger" v-if="item.required">*</span>
                      </template>
                      <div class="mx-3">{{ "Có " + statisticForSetOfCriteriaObjectAdvance.totalEvaluated + " câu trả lời" + (item.pointAverage > 0 ? "(Điểm trung bình nhận được: " + item.pointAverage + " / " + item.maxPoint + ")" : "") }}</div>
                      <div v-if="item.criteriaType === CRITERIA_TYPES[0].value">
                        <BarRaceChartAnswer :criteria="item"
                          :totalEvaluated="statisticForSetOfCriteriaObjectAdvance.totalEvaluated" />
                      </div>
                      <div v-if="item.criteriaType === CRITERIA_TYPES[1].value">
                        <BarRaceChartOption :criteria="item"
                          :totalEvaluated="statisticForSetOfCriteriaObjectAdvance.totalEvaluated" />
                      </div>
                      <div v-if="item.criteriaType === CRITERIA_TYPES[2].value">
                        <vue-good-table line-numbers :columns="columns" :rows="item.contents"
                          :pagination-options="paginationOptions"
                          class="my-3">
                          <div slot="emptystate" style="text-align: center; font-weight: bold">
                            Không có bản ghi nào !
                          </div>
                          <template slot="table-row" slot-scope="props">
                            <!-- Column: Common -->
                            <span>{{ props.formattedRow[props.column.field] }}</span>
                          </template>

                          <!-- pagination -->
                          <template slot="pagination-bottom" slot-scope="props">
                            <div class="d-flex justify-content-between flex-wrap">
                              <div class="d-flex align-items-center mb-0 mt-1 ml-1">
                                <span class="text-nowrap"> Hiển thị {{ itemsPerPage * (currentPage - 1) + 1 }}
                                  đến {{ itemsPerPage * currentPage }} &nbsp; của {{ props.total }} bản ghi
                                </span>
                              </div>

                              <div class="d-flex align-items-center mb-1 mt-1">
                                <span class="text-nowrap">Hiển thị</span>
                                <b-form-select v-model="itemsPerPage" :options="itemsPerPageOptions" class="mx-1"
                                  @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap">bản ghi/trang</span>
                              </div>
                              <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="itemsPerPage"
                                  class="mt-1 mb-0 mr-1" @input="(value) => props.pageChanged({ currentPage: value })" />
                              </div>
                            </div>
                          </template>
                        </vue-good-table>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              </div>
            </b-form>
            <b-form v-else-if="this.statisticForSetOfCriteriaObjectAdvance.totalEvaluated > 0 && filter.statisticType === statisticTypes[1].value && isShowStatisticButton">
              <b-card-header v-if="statisticForSetOfCriteriaObjectAdvance.userEvaluated && statisticForSetOfCriteriaObjectAdvance.userEvaluated.length > 0">
                <b-card-title>Danh sách đối tượng thực hiện khảo sát/đánh giá</b-card-title>
              </b-card-header>
              <vue-good-table line-numbers :columns="columnsEvaluateObjectGroup" :rows="statisticForSetOfCriteriaObjectAdvance.userEvaluated"
                :pagination-options="paginationOptions"
                class="my-1"
                v-if="statisticForSetOfCriteriaObjectAdvance.userEvaluated && statisticForSetOfCriteriaObjectAdvance.userEvaluated.length > 0"
                >
                <div slot="emptystate" style="text-align: center; font-weight: bold">
                  Không có bản ghi nào !
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'action'">
                    <b-button
                        v-if="statisticForSetOfCriteriaObjectAdvance.objectGroupType === OBJECT_GROUP_TYPES[0].value"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        title="Danh sách lớp tín chỉ được đánh giá"
                        @click="onListCriterias(props.row)"
                      >
                        <feather-icon icon="ListIcon" />
                      </b-button>
                      <b-button
                        v-else
                        v-b-modal.evaluateCommonSaveModal
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        title="Kết quả đánh giá chi tiết"
                        @click="onEdit(props.row)"
                      >
                        <feather-icon icon="EyeIcon" />
                      </b-button>
                  </span>
                  <span v-else-if="props.column.field === 'totalNeedEvaluated'">
                    {{ props.row.studentCreditClassId && props.row.totalCreditClassMustEvaluate && props.row.totalCreditClassMustEvaluate > 0 ? props.row.totalCreditClassMustEvaluate : 1 }}
                  </span>
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1 ml-1">
                      <span class="text-nowrap"> Hiển thị {{ itemsPerPage * (currentPage - 1) + 1 }}
                        đến {{ itemsPerPage * currentPage }} &nbsp; của {{ props.total }} bản ghi
                      </span>
                    </div>

                    <div class="d-flex align-items-center mb-1 mt-1">
                      <span class="text-nowrap">Hiển thị</span>
                      <b-form-select v-model="itemsPerPage" :options="itemsPerPageOptions" class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                      <span class="text-nowrap">bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination :value="1" :total-rows="props.total" :per-page="itemsPerPage"
                        class="mt-1 mb-0 mr-1" @input="(value) => props.pageChanged({ currentPage: value })" />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-form>
            <b-form v-else-if="this.statisticForSetOfCriteriaObjectAdvance.totalEvaluated > 0 && filter.statisticType === statisticTypes[2].value && isShowStatisticButton && statisticForSetOfCriteriaObjectAdvance.evaluatedObjectGroups">
              <b-card-header v-if="statisticForSetOfCriteriaObjectAdvance.userEvaluated && statisticForSetOfCriteriaObjectAdvance.userEvaluated.length > 0">
                <b-card-title>Danh sách đối tượng được khảo sát/đánh giá</b-card-title>
              </b-card-header>
              <vue-good-table line-numbers :columns="columnsEvaluatedObjectGroup" :rows="statisticForSetOfCriteriaObjectAdvance.evaluatedObjectGroups"
                :pagination-options="paginationOptions"
                class="my-1"
                v-if="statisticForSetOfCriteriaObjectAdvance.userEvaluated && statisticForSetOfCriteriaObjectAdvance.userEvaluated.length > 0"
                >
                <div slot="emptystate" style="text-align: center; font-weight: bold">
                  Không có bản ghi nào !
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'action'">
                    <b-button
                        v-if="statisticForSetOfCriteriaObjectAdvance.objectGroupType === OBJECT_GROUP_TYPES[0].value"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        title="Danh sách đối tượng đã đánh giá lớp tín chỉ"
                        @click="onListUserEvaluated(props.row)"
                      >
                        <feather-icon icon="ListIcon" />
                      </b-button>
                      <!-- <b-button
                        v-else-if="statisticForSetOfCriteriaObjectAdvance.objectGroupType === OBJECT_GROUP_TYPES[0].value"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon"
                        size="sm"
                        title="Danh sách đối tượng đã đánh giá phòng học lớp tín"
                        @click="onListUserEvaluated(props.row)"
                      >
                        <feather-icon icon="ListIcon" />
                      </b-button> -->
                  </span>
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1 ml-1">
                      <span class="text-nowrap"> Hiển thị {{ itemsPerPage * (currentPage - 1) + 1 }}
                        đến {{ itemsPerPage * currentPage }} &nbsp; của {{ props.total }} bản ghi
                      </span>
                    </div>

                    <div class="d-flex align-items-center mb-1 mt-1">
                      <span class="text-nowrap">Hiển thị</span>
                      <b-form-select v-model="itemsPerPage" :options="itemsPerPageOptions" class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                      <span class="text-nowrap">bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination :value="1" :total-rows="props.total" :per-page="itemsPerPage"
                        class="mt-1 mb-0 mr-1" @input="(value) => props.pageChanged({ currentPage: value })" />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-form>
            <b-form v-if="this.statisticForSetOfCriteriaObjectAdvance.totalEvaluated > 0 && filter.statisticType === statisticTypes[2].value && isShowStatisticButton && !statisticForSetOfCriteriaObjectAdvance.evaluatedObjectGroups">
              <b-row>
                <b-col cols="12" md="12">
                  <div class="text-center my-2"><b>Không có nhóm đối tượng được khảo sát/đánh giá để thống kê!</b></div>
                </b-col>
              </b-row>
            </b-form>
            <b-form v-if="statisticForSetOfCriteriaObjectAdvance.totalEvaluated === 0 && isShowStatisticButton">
              <b-row>
                <b-col cols="12" md="12">
                  <div class="text-center my-2"><b>Không có khảo sát/đánh giá nào để thống kê!</b></div>
                </b-col>
              </b-row>
            </b-form>
          </div>
          <b-modal
          id="modal-criteria-save"
          ref="modal-criteria-save"
          centered
          size="xl"
          :hide-footer="true"
        >
          <SetOfCriteriaSave
            ref="setOfCriteriaSaveModalRef1"
            :setOfCriteriaObjectId="setOfCriteriaObjectIdInCurrentMainData"
            :accountId="accountId"
            @succeed="onSucceed"
          />
        </b-modal>

          <b-modal
            id="modal-list-criteria"
            ref="modal-list-criteria"
            centered
            size="xl"
            :hide-footer="true"
          >
            <CreditClasses :data-send="currentMainData" />
          </b-modal>

          <b-modal
            id="modal-list-user-evaluated"
            ref="modal-list-user-evaluated"
            centered
            size="xl"
            :hide-footer="true"
          >
            <UsersEvaluated :data-send="currentMainData" :evaluate-levels="statisticForSetOfCriteriaObjectAdvance.evaluateLevels"/>
          </b-modal>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton,
  BDropdown,
  BButtonGroup,
  BDropdownItem,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
  BFormGroup, BForm,
  BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { CREDIT_CLASS_STATUSES } from '@/const/status'
import { CRITERIA_TYPES, OBJECT_GROUP_TYPES, MULTIPLE_CRITERIA_OPTION_TYPES } from '@/const/type'
import BarRaceChartOption from '@/views/evaluate-management/BarRaceChartOption.vue'
import BarRaceChartAnswer from '@/views/evaluate-management/BarRaceChartAnswer.vue'
import PieChartSlider from '@/views/evaluate-management/PieChartSlider.vue'
import PieChartTeacher from '@/views/evaluate-management/PieChartTeacher.vue'
import * as XLSX from 'xlsx'
import SetOfCriteriaSave from '@/views/evaluate-management/SetOfCriteriaSave.vue'
import CreditClasses from '@/views/evaluate-management/CreditClasses.vue'
import UsersEvaluated from '@/views/evaluate-management/UsersEvaluated.vue'

export default {
  name: 'ListCreditClass',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BButton,
    BDropdown,
    BButtonGroup,
    BDropdownItem,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
    BFormGroup,
    BForm,
    vSelect,
    BCardHeader, BCardTitle,
    BarRaceChartOption,
    BarRaceChartAnswer,
    PieChartSlider,
    PieChartTeacher,
    SetOfCriteriaSave,
    CreditClasses,
    UsersEvaluated,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 10,
      filter: {
        organizationId: null,
        year: null,
        setOfCriteriaObjectId: null,
        subjectId: null,
        creditClassId: null,
        setOfCriteriaObjectId: null,
        creditClassCode: null,
        subjectCode: null,
        subjectName: null,
        teachers: null,
        sort: null,
        statisticType: 1,
      },
      currentMainData: undefined,
      setOfCriteriaObjectIdNeedSeeResult: undefined,
      paginationOptions: {
        enabled: true,
      },
      textEvaluateLevels: '',
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      statisticTypes: [
        { value: 1, label: 'Đợt khảo sát/đánh giá' },
        { value: 2, label: 'Nhóm đối tượng thực hiện khảo sát/đánh giá' },
        { value: 3, label: 'Nhóm đối tượng được khảo sát/đánh giá' },
      ],
      columnsEvaluateObjectGroup: [
        {
          label: 'Mã học viên',
          field: 'codeStudent',
          sortable: false,
          thClass: 'text-center',
          width: '150px',
        },
        {
          label: 'Tên học viên',
          field: 'nameStudent',
          sortable: false,
          thClass: 'text-center',
          width: '220px',
        },
        {
          label: 'Mã học viên',
          field: 'codeStudentCreditClass',
          sortable: false,
          thClass: 'text-center',
          width: '150px',
        },
        {
          label: 'Tên học viên',
          field: 'nameStudentCreditClass',
          sortable: false,
          thClass: 'text-center',
          width: '220px',
        },
        {
          label: 'Tên cán bộ',
          field: 'employeeFullName',
          sortable: false,
          thClass: 'text-center',
          width: '220px',
        },
        {
          label: 'Cán bộ thuộc khoa',
          field: 'employeeDepartment',
          sortable: false,
          thClass: 'text-center',
          width: '220px',
        },
        {
          label: 'Số phiếu đã thực hiện đánh giá',
          field: 'numberEvaluated',
          sortable: false,
          thClass: 'text-center',
          width: '300px',
        },
        {
          label: 'Tổng số phiếu cần thực hiện',
          field: 'totalNeedEvaluated',
          sortable: false,
          thClass: 'text-center',
          width: '300px',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      columnsEvaluatedObjectGroup: [
        {
          label: 'Tên học phần',
          field: 'subjectName',
          sortable: false,
          thClass: 'text-center',
          width: '320px',
        },
        {
          label: 'Mã lớp tín chỉ',
          field: 'creditClassCode',
          sortable: false,
          thClass: 'text-center',
          width: '200px',
        },
        {
          label: 'Số đánh giá',
          field: 'numberUserEvaluated',
          sortable: false,
          thClass: 'text-center',
          width: '200px',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      columns: [
        {
          label: 'Học phần',
          field: 'subjectName',
          sortable: false,
          thClass: 'text-center',
          width: '130px',
        },
        {
          label: 'Mã lớp độc lập',
          field: 'creditClassCode',
          sortable: false,
          thClass: 'text-center',
          width: '130px',
        },
        {
          label: 'Nội dung',
          field: 'content',
          sortable: false,
          thClass: 'text-center',
        },
      ],
      CRITERIA_TYPES,
      OBJECT_GROUP_TYPES,
      MULTIPLE_CRITERIA_OPTION_TYPES,
      levelReceivedOfSetOfCriteriaObject: null,
      isEvaluateCreditClass: false,
      isShowStatisticButton: false,
      setOfCriteriaObjectIdOld: null,
      subjectIdOld: null,
      creditClassIdOld: null,
      statisticTypeOld: null,
      setOfCriteriaObjectIdInCurrentMainData: null,
      accountId: null,
      isShowDropdownSubjectsAndCreditClasses: false,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'setOfCriteria/statuses',
      departments: 'dropdown/departments',
      subjects: 'dropdown/subjects',
      setOfCriterias: 'evaluateManagement/allSetOfCriterias',
      evaluateObjectGroups: 'evaluateManagement/allEvaluateObjectGroups',
      evaluatedObjectGroups: 'evaluateManagement/allEvaluatedObjectGroups',
      setOfCriteriaObjects: 'evaluateManagement/setOfCriteriaObjects',
      yearsSetOfCriteriaObjects: 'evaluateManagement/yearsSetOfCriteriaObjects',
      dataSource: 'evaluateManagement/dataSource',
      totalRows: 'evaluateManagement/totalRows',
      statisticForSetOfCriteriaObjectAdvance: 'evaluateManagement/statisticForSetOfCriteriaObjectAdvance',
      subjectsSetOfCriteriaObject: 'evaluateManagement/subjectsSetOfCriteriaObject',
      creditClassesSetOfCriteriaObject: 'evaluateManagement/creditClassesSetOfCriteriaObject',
    }),
    yearsSelect() {
      return this.yearsSetOfCriteriaObjects.map(year => ({ value: year, label: year }));
    },
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    columnsTongPhieuKhaoSatDanhGiaExport() {
      return [
        {
          label: 'Tổng số phiếu khảo sát/đánh giá đã được thực hiện',
          field: 'totalEvaluated',
        },
      ]
    },
    columnsDanhSachNguoiDungDaDanhGiaExport() {
      return [
        {
          label: 'STT',
          field: 'no',
        },
        {
          label: 'Mã học viên',
          field: 'codeStudent',
        },
        {
          label: 'Tên học viên',
          field: 'nameStudent',
        },
        {
          label: 'Mã học viên',
          field: 'codeStudentCreditClass',
        },
        {
          label: 'Tên học viên',
          field: 'nameStudentCreditClass',
        },
        {
          label: 'Tên cán bộ',
          field: 'employeeFullName',
        },
        {
          label: 'Cán bộ thuộc khoa',
          field: 'employeeDepartment',
        },
        {
          label: 'Số phiếu khảo sát/đánh giá đã thực hiện',
          field: 'numberEvaluated',
        },
      ]
    },
    columnsThangDoDanhGiaTongQuanExport() {
      return [
        {
          label: 'Đợt khảo sát/đánh giá nhận được số điểm trung bình cộng các câu hỏi ở mức',
          field: 'levelReceivedOfSetOfCriteriaObject',
        },
      ]
    },
    columnsDanhSachGiangVienDuocDanhGiaExport() {
      return [
        {
          label: 'STT',
          field: 'no',
        },
        {
          label: 'Họ Tên',
          field: 'fullName',
        },
        {
          label: '1 điểm',
          field: 'oneRate',
        },
        {
          label: '2 điểm',
          field: 'twoRate',
        },
        {
          label: '3 điểm',
          field: 'threeRate',
        },
        {
          label: '4 điểm',
          field: 'fourRate',
        },
        {
          label: '5 điểm',
          field: 'fiveRate',
        },
        {
          label: 'Điểm trung bình nhận được',
          field: 'rateAverage',
        },
      ]
    },
    columnsDanhSachCauHoiTrongPhieuKhaoSatExport() {
      return [
        {
          label: 'STT',
          field: 'no',
        },
        {
          label: 'Loại câu hỏi',
          field: 'criteriaType',
        },
        {
          label: 'Chọn nhiều',
          field: 'multipleSelect',
        },
        {
          label: 'Nội dung câu hỏi',
          field: 'criteriaName',
        },
        {
          label: 'STT tuỳ chọn',
          field: 'noOption',
        },
        {
          label: 'Tuỳ chọn',
          field: 'optionName',
        },
        {
          label: 'Số đánh giá',
          field: 'numberSelected',
        },
        {
          label: 'Phần trăm',
          field: 'percentSelectd',
        },
        {
          label: 'STT câu trả lời',
          field: 'noAnswer',
        },
        {
          label: 'Học phần',
          field: 'subjectName',
        },
        {
          label: 'Mã lớp độc lập',
          field: 'creditClassCode',
        },
        {
          label: 'Nội dung câu trả lời',
          field: 'content',
        },
        {
          label: 'Phòng học',
          field: 'roomName',
        },
        {
          label: 'Loại phòng',
          field: 'roomType',
        },
        {
          label: 'Toà nhà',
          field: 'buildingName',
        },
      ]
    },
  },
  async created() {
    await this.begin({ organizationId: this.user.orgId })
    this.updateParams({ organizationId: this.user.orgId })
    await this.getSetOfCriteriaObjectsByYear(this.filter)
    this.isLoading = true
    try {
      // await this.getMainDataLastTime()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  mounted() {
    const grid = document.querySelector('div.vgt-responsive')
    grid.classList.add('overlay')
    grid.classList.add('custom')
  },
  methods: {
    ...mapMutations({
      setSubjects: 'dropdown/SET_SUBJECTS',
      setDepartments: 'dropdown/SET_DEPARTMENTS',
      setDataSource: 'evaluateManagement/SET_DATA_SOURCE',
      setTotalRows: 'evaluateManagement/SET_TOTAL_ROWS',
      setStatisticForSetOfCriteriaObjectAdvance: 'evaluateManagement/SET_STATISTIC_FOR_SET_OF_CRITERIA_OBJECT_ADVANCE',
    }),
    ...mapActions({
      getCreditClasses: 'evaluateManagement/getCreditClasses',
      begin: 'evaluateManagement/begin',
      getSetOfCriteriaObjectsByYear: 'evaluateManagement/getSetOfCriteriaObjectsByYear',
      getDepartments: 'dropdown/getDepartments',
      getSubjects: 'dropdown/getSubjects',
      getStatisticForSetOfCriteriaObjectAdvance: 'evaluateManagement/getStatisticForSetOfCriteriaObjectAdvance',
      getCountCreditClassesBySetOfCriteriaObject: 'creditClass/getCountCreditClassesBySetOfCriteriaObject',
      getSubjectsBySetOfCriteriaObject: 'evaluateManagement/getSubjectsBySetOfCriteriaObject',
      getCreditClassesSubjectBySetOfCriteriaObject: 'evaluateManagement/getCreditClassesSubjectBySetOfCriteriaObject',
    }),
    statisticType() {},
    onListStudentEvaluatedAccordingToSetOfCriteriaObject() {
      this.setOfCriteriaObjectIdNeedSeeResult = this.filter.setOfCriteriaObjectId
      this.$refs['modal-list-student-evaluated-of-set-of-criteria-object'].show()
    },
    onListStudentEvaluateAccordingToSetOfCriteriaObject() {
      this.setOfCriteriaObjectIdNeedSeeResult = this.filter.setOfCriteriaObjectId
      this.$refs['modal-list-student-not-evaluated-of-set-of-criteria-object'].show()
    },
    onViewResultOfSetOfCriteriaObject() {
      this.setOfCriteriaObjectIdNeedSeeResult = this.filter.setOfCriteriaObjectId
    },
    onViewEvaluateCommonCreditClass(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onListStudentEvaluated(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.$refs['modal-list-student'].show()
    },
    onEdit(mainData) {
      this.setOfCriteriaObjectIdInCurrentMainData = mainData.setOfCriteriaObjectId
      this.accountId = mainData.accountId
      this.$refs['modal-criteria-save'].show()
    },
    onListCriterias(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.$refs['modal-list-criteria'].show()
    },
    onListUserEvaluated(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
      this.$refs['modal-list-user-evaluated'].show()
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async getDataStatisticAdvance() {
      this.isLoading = true
      try {
        this.isShowStatisticButton = true
        this.setOfCriteriaObjectIdOld = null
        this.subjectIdOld = null
        this.creditClassIdOld = null
        this.statisticTypeOld = null
        if (this.filter.setOfCriteriaObjectId != null) {
          await this.getStatisticForSetOfCriteriaObjectAdvance(this.filter)
          this.setOfCriteriaObjectIdOld = this.filter.setOfCriteriaObjectId
          this.subjectIdOld = this.filter.subjectId
          this.creditClassIdOld = this.filter.creditClassId
          this.statisticTypeOld = this.filter.statisticType
          const found = this.setOfCriteriaObjects.find(item => item.id === this.setOfCriteriaObjectIdOld)
          if(found?.objectGroupType === OBJECT_GROUP_TYPES[0].value) {
            this.isEvaluateCreditClass = true
          } else {
            this.isEvaluateCreditClass = false
          }
        } else {
          this.showToast('Bạn phải chọn đợt khảo sát/đánh giá', 'InfoIcon', 'warning', 3000)
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getStatusName(id) {
      return CREDIT_CLASS_STATUSES.find(status => status.value === id)?.label
    },
    async onDataStatistic() {
      if (this.filter.setOfCriteriaObjectId != null) {
        this.updateParams({ setOfCriteriaObjectId: this.filter.setOfCriteriaObjectId, subjectId: this.filter.subjectId, creditClassId: this.filter.creditClassId })
        await this.getDataStatisticAdvance()
      } else {
        this.showToast('Bạn phải chọn đợt khảo sát/đánh giá', 'InfoIcon', 'warning', 3000)
      }
    },
    async onSucceed() {
    },
    async setOfCriteriaObjectIdChanged(event) {
      this.setStatisticForSetOfCriteriaObjectAdvance([])
      this.filter.subjectId = null
      this.filter.creditClassId = null
      this.isShowStatisticButton = true
      if(this.filter.setOfCriteriaObjectId != this.setOfCriteriaObjectIdOld) {
        this.isShowStatisticButton = false
      }
      const found = this.setOfCriteriaObjects.find(item => item.id === event)
      if(found?.objectGroupType === OBJECT_GROUP_TYPES[0].value) {
        await this.getSubjectsBySetOfCriteriaObject({ organizationId: this.user.orgId, evaluateObjectGroupId: found.evaluateObjectGroupId })
        this.isShowDropdownSubjectsAndCreditClasses = true
      } else {
        this.isShowDropdownSubjectsAndCreditClasses = false
      }
    },
    subjectIdChanged(event) {
      this.setStatisticForSetOfCriteriaObjectAdvance([])
      this.isShowStatisticButton = true
      if(this.filter.setOfCriteriaObjectId != this.setOfCriteriaObjectIdOld || this.filter.subjectId != this.subjectIdOld) {
        this.isShowStatisticButton = false
      }
      const found = this.setOfCriteriaObjects.find(item => item.id === this.filter.setOfCriteriaObjectId)
      this.filter.creditClassId = null
      if(event != null) {
        this.getCreditClassesSubjectBySetOfCriteriaObject({ organizationId: this.user.orgId, subjectId: event, evaluateObjectGroupId: found.evaluateObjectGroupId })
      }
    },
    creditClassIdChanged() {
      this.setStatisticForSetOfCriteriaObjectAdvance([])
      this.isShowStatisticButton = true
      if(this.filter.setOfCriteriaObjectId != this.setOfCriteriaObjectIdOld || this.filter.subjectId != this.subjectIdOld || this.filter.creditClassId != this.creditClassIdOld) {
        this.isShowStatisticButton = false
      }
    },
    headerStyleClassFn(row) {
      return this.currentMainData && row.id === this.currentMainData.id ? 'row-selected' : ''
    },
    showToast(title, icon, variant, autoHideDelay = 1000) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          autoHideDelay,
        },
      })
    },
    async yearChanged() {
      this.isLoading = true
      try {
        this.setStatisticForSetOfCriteriaObjectAdvance([])
        this.filter.setOfCriteriaObjectId = null
        this.filter.subjectId = null
        this.filter.creditClassId = null
        this.isShowStatisticButton = true
        if(this.filter.setOfCriteriaObjectId != this.setOfCriteriaObjectIdOld) {
          this.isShowStatisticButton = false
        }
        await this.getSetOfCriteriaObjectsByYear(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    exportExcel() {
      if (this.statisticForSetOfCriteriaObjectAdvance.totalEvaluated === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Không có dữ liệu để xuất!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      // Tạo dữ liệu cho mỗi sheet
      const dataSheet2 = this.createExcelData(this.statisticForSetOfCriteriaObjectAdvance.userEvaluated, 'DanhSachNguoiDungDaDanhGia', this.statisticForSetOfCriteriaObjectAdvance.totalEvaluated)
      let dataSheet3 = null
      if(this.levelReceivedOfSetOfCriteriaObject !== null) {
        dataSheet3 = this.createExcelData(this.levelReceivedOfSetOfCriteriaObject, 'ThangDoDanhGiaTongQuan')
      }
      let dataSheet4 = null
      if(this.statisticForSetOfCriteriaObjectAdvance.teachers !== null && this.statisticForSetOfCriteriaObjectAdvance.teachers.length > 0) {
        dataSheet4 = this.createExcelData(this.statisticForSetOfCriteriaObjectAdvance.teachers, 'DanhSachGiangVienDuocDanhGia')
      }
      const dataSheet5 = this.createExcelData(this.statisticForSetOfCriteriaObjectAdvance.statisticCriterias, 'DanhSachCauHoiTrongPhieuKhaoSat', this.statisticForSetOfCriteriaObjectAdvance.totalEvaluated, this.statisticForSetOfCriteriaObjectAdvance?.rooms)

      // Tạo các sheet
      const ws2 = XLSX.utils.json_to_sheet(dataSheet2)
      let ws3 = null
      if(this.levelReceivedOfSetOfCriteriaObject !== null) {
        ws3 = XLSX.utils.json_to_sheet(dataSheet3)
      }
      let ws4 = null
      if(this.statisticForSetOfCriteriaObjectAdvance.teachers !== null && this.statisticForSetOfCriteriaObjectAdvance.teachers.length > 0) {
        ws4 = XLSX.utils.json_to_sheet(dataSheet4)
      }
      const ws5 = XLSX.utils.json_to_sheet(dataSheet5)

      // Tạo workbook và thêm các sheet vào workbook
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws2, 'DanhSachNguoiDungDaDanhGia')
      if(this.levelReceivedOfSetOfCriteriaObject !== null) {
        XLSX.utils.book_append_sheet(wb, ws3, 'ThangDoDanhGiaTongQuan')
      }
      if(this.statisticForSetOfCriteriaObjectAdvance.teachers !== null && this.statisticForSetOfCriteriaObjectAdvance.teachers.length > 0) {
        XLSX.utils.book_append_sheet(wb, ws4, 'DanhSachGiangVienDuocDanhGia')
      }
      XLSX.utils.book_append_sheet(wb, ws5, 'DanhSachCauHoiTrongPhieuKhaoSat')

      // Ghi file Excel
      XLSX.writeFile(wb, 'BaoCaoThongKeTheoDot.xlsx')
    },
    createExcelData(dataExport, sheetName, totalEvaluated = null, dataRoomsExport = null) {
      let data = []
      if(sheetName === 'DanhSachNguoiDungDaDanhGia') {
        dataExport.forEach((item, index) => {
          data.push({'no': index + 1, 'codeStudent': item.codeStudent, 'nameStudent': item.nameStudent, 'codeStudentCreditClass': item.codeStudentCreditClass, 'nameStudentCreditClass': item.nameStudentCreditClass, 'employeeFullName': item.employeeFullName, 'employeeDepartment': item.employeeDepartment, 'numberEvaluated': item.numberEvaluated })
        })
        data.push({'no': 'Tổng', 'numberEvaluated': totalEvaluated })
      } else if(sheetName === 'ThangDoDanhGiaTongQuan') {
        data.push({'levelReceivedOfSetOfCriteriaObject': this.levelReceivedOfSetOfCriteriaObject})
      } else if(sheetName === 'DanhSachGiangVienDuocDanhGia') {
        dataExport.forEach((teacher, index) => {
          data.push({'no': index + 1, 'fullName': teacher.fullName, 'oneRate': teacher.rates.filter(function (el) {
            return el.rate === 1;
          })[0]?.numberRate || '', 'twoRate': teacher.rates.filter(function (el) {
            return el.rate === 2;
          })[0]?.numberRate || '', 'threeRate': teacher.rates.filter(function (el) {
            return el.rate === 3;
          })[0]?.numberRate || '', 'fourRate': teacher.rates.filter(function (el) {
            return el.rate === 4;
          })[0]?.numberRate || '', 'fiveRate': teacher.rates.filter(function (el) {
            return el.rate === 5;
          })[0]?.numberRate || '', 'rateAverage': teacher.rateAverage })
        })
      } else if(sheetName === 'DanhSachCauHoiTrongPhieuKhaoSat') {
        let countIndex = 0
        if(dataExport) {
          dataExport.forEach((criteria, index) => {
            data.push({'no': index + 1, 'criteriaType': CRITERIA_TYPES.filter(function (el) {
              return el.value === criteria.criteriaType;
            })[0]?.label || 'Không xác định', 'multipleSelect': MULTIPLE_CRITERIA_OPTION_TYPES.filter(function (el) {
              return el.value === criteria.multipleSelect;
            })[0]?.label || '', 'criteriaName': criteria.criteriaName, 'noOption': '', 'optionName': '', 'numberSelected': '', 'percentSelectd': '', 'noAnswer': '', 'subjectName': '', 'creditClassCode': '', 'content': '', 'roomName': '', 'roomType': '', 'buildingName': '', })
            countIndex = index + 1
            if(criteria.criteriaType === 1) {
              criteria.sliders.forEach((slider, index1) => {
                data.push({'no': '', 'criteriaType': '', 'multipleSelect': '', 'criteriaName': '', 'noOption': index1 + 1, 'optionName': slider.point + ' điểm', 'numberSelected': slider.numberSelected, 'percentSelectd': Math.round((slider.numberSelected/totalEvaluated) * 100), 'noAnswer': '', 'subjectName': '', 'creditClassCode': '', 'content': '', 'roomName': '', 'roomType': '', 'buildingName': '', })
              })
            } else if(criteria.criteriaType === 2) {
              criteria.options.forEach((option, index2) => {
                data.push({'no': '', 'criteriaType': '', 'multipleSelect': option.multipleSelect, 'criteriaName': '', 'noOption': index2 + 1, 'optionName': option.name, 'numberSelected': option.numberSelected, 'percentSelectd': Math.round((option.numberSelected/totalEvaluated) * 100), 'noAnswer': '', 'subjectName': '', 'creditClassCode': '', 'content': '', 'roomName': '', 'roomType': '', 'buildingName': '', })
              })
            } else if(criteria.criteriaType === 3) {
              criteria.contents.forEach((item, index3) => {
                data.push({'no': '', 'criteriaType': '', 'multipleSelect': '', 'criteriaName': '', 'noOption': '', 'optionName': '', 'numberSelected': '', 'percentSelectd': '', 'noAnswer': index3 + 1, 'subjectName': item.subjectName, 'creditClassCode': item.creditClassCode, 'content': item.content, 'roomName': '', 'roomType': '', 'buildingName': '', })
              })
            }
          })
        }

        if(dataRoomsExport[0] && dataRoomsExport[0].objectGroupEvaluateRoomCriterias) {
          dataRoomsExport[0].objectGroupEvaluateRoomCriterias.forEach((criteria, index) => {
          countIndex++
          data.push({'no': countIndex, 'criteriaType': CRITERIA_TYPES.filter(function (el) {
            return el.value === criteria.criteriaType;
          })[0]?.label || 'Không xác định', 'multipleSelect': MULTIPLE_CRITERIA_OPTION_TYPES.filter(function (el) {
            return el.value === criteria.multipleSelect;
          })[0]?.label || '', 'criteriaName': criteria.criteriaName, 'noOption': '', 'optionName': '', 'numberSelected': '', 'percentSelectd': '', 'noAnswer': '', 'subjectName': '', 'creditClassCode': '', 'content': '', 'roomName': '', 'roomType': '', 'buildingName': '', })

          dataRoomsExport.forEach((room) => {
            room.objectGroupEvaluateRoomCriterias.forEach((criteria2, index2) => {
              if(criteria.criteriaId === criteria2.criteriaId) {
                if(criteria.criteriaType === 1) {
                  criteria2.sliders.forEach((slider, index3) => {
                    data.push({'no': '', 'criteriaType': '', 'multipleSelect': '', 'criteriaName': '', 'noOption': index3 + 1, 'optionName': slider.point + ' điểm', 'numberSelected': slider.numberSelected, 'percentSelectd': Math.round((slider.numberSelected/totalEvaluated) * 100), 'noAnswer': '', 'subjectName': '', 'creditClassCode': '', 'content': '', 'roomName': room.roomName, 'roomType': room.roomType, 'buildingName': room.buildingName, })
                  })
                } else if(criteria.criteriaType === 2) {
                  criteria2.options.forEach((option, index3) => {
                    data.push({'no': '', 'criteriaType': '', 'multipleSelect': option.multipleSelect, 'criteriaName': '', 'noOption': index3 + 1, 'optionName': option.name, 'numberSelected': option.numberSelected, 'percentSelectd': Math.round((option.numberSelected/totalEvaluated) * 100), 'noAnswer': '', 'subjectName': '', 'creditClassCode': '', 'content': '', 'roomName': room.roomName, 'roomType': room.roomType, 'buildingName': room.buildingName, })
                  })
                } else if(criteria.criteriaType === 3) {
                  criteria2.contents.forEach((item, index3) => {
                    data.push({'no': '', 'criteriaType': '', 'multipleSelect': '', 'criteriaName': '', 'noOption': '', 'optionName': '', 'numberSelected': '', 'percentSelectd': '', 'noAnswer': index3 + 1, 'subjectName': item.subjectName, 'creditClassCode': item.creditClassCode, 'content': item.content, 'roomName': room.roomName, 'roomType': room.roomType, 'buildingName': room.buildingName, })
                  })
                }
              }
            })
          })
          })
        }
      }

      const excelData = []
      const header = {}
      if(sheetName === 'TongPhieuKhaoSatDanhGia') {
        this.columnsTongPhieuKhaoSatDanhGiaExport.forEach(column => {
          header[column.field] = column.label
        })
      } else if(sheetName === 'DanhSachNguoiDungDaDanhGia') {
        this.columnsDanhSachNguoiDungDaDanhGiaExport.forEach(column => {
          if(this.statisticForSetOfCriteriaObjectAdvance.objectGroupType === OBJECT_GROUP_TYPES[0].value && (column.field === 'no' || column.field === 'codeStudentCreditClass' || column.field === 'nameStudentCreditClass' || column.field === 'numberEvaluated')) {
            header[column.field] = column.label
          } else if(this.statisticForSetOfCriteriaObjectAdvance.objectGroupType === OBJECT_GROUP_TYPES[1].value && (column.field === 'no' || column.field === 'codeStudent' || column.field === 'nameStudent' || column.field === 'employeeFullName' || column.field === 'employeeDepartment' || column.field === 'numberEvaluated')) {
            header[column.field] = column.label
          }
        })
      } else if(sheetName === 'ThangDoDanhGiaTongQuan') {
        this.columnsThangDoDanhGiaTongQuanExport.forEach(column => {
          header[column.field] = column.label
        })
      } else if(sheetName === 'DanhSachGiangVienDuocDanhGia') {
        this.columnsDanhSachGiangVienDuocDanhGiaExport.forEach(column => {
          header[column.field] = column.label
        })
      } else if(sheetName === 'DanhSachCauHoiTrongPhieuKhaoSat') {
        if(this.isEvaluateCreditClass) {
          this.columnsDanhSachCauHoiTrongPhieuKhaoSatExport.forEach(column => {
            if(column.field === 'no' || column.field === 'criteriaType' || column.field === 'multipleSelect' || column.field === 'criteriaName' || column.field === 'noOption' || column.field === 'optionName' || column.field === 'numberSelected' || column.field === 'percentSelectd' || column.field === 'noAnswer' || column.field === 'content' || column.field === 'subjectName' || column.field === 'creditClassCode' || column.field === 'roomName' || column.field === 'roomType' || column.field === 'buildingName') {
              header[column.field] = column.label
            }
          })
        } else {
          this.columnsDanhSachCauHoiTrongPhieuKhaoSatExport.forEach(column => {
            if(column.field === 'no' || column.field === 'criteriaType' || column.field === 'multipleSelect' || column.field === 'criteriaName' || column.field === 'noOption' || column.field === 'optionName' || column.field === 'numberSelected' || column.field === 'percentSelectd' || column.field === 'noAnswer' || column.field === 'content') {
              header[column.field] = column.label
            }
          })
        }
      }

      const headerKeys = Object.keys(header)
      const dataSourceKeys = Object.keys(data[0])
      const duplicates = dataSourceKeys.filter(val => headerKeys.includes(val))
      duplicates.sort((a, b) => headerKeys.indexOf(a) - headerKeys.indexOf(b))
      data.forEach(dataSource => {
        const elm = {}
        duplicates.forEach(key => {
          elm[header[key]] = dataSource[key]
        })
        excelData.push(elm)
      })
      return excelData
    },
    totalNumberRateTeacher(rates) {
      let totalNumberRate = 0
      rates.forEach((item) => {
        totalNumberRate += item.numberRate
      })
      return totalNumberRate
    },
    // async fetchData() {
    //   for (let i = 0; i < this.statisticForSetOfCriteriaObjectAdvance.userEvaluated.length; i++) {
    //     const user = this.statisticForSetOfCriteriaObjectAdvance.userEvaluated[i];
    //     if(user.studentCreditClassId) {
    //       // Sử dụng await để đảm bảo giá trị được lấy chính xác
    //       const result1 = await this.getCountCreditClassesBySetOfCriteriaObject({
    //         organizationId: this.user.orgId,
    //         evaluateObjectGroupId: user.evaluateObjectGroupId,
    //         studentId: user.studentId,
    //       });

    //       // Gán giá trị trả về vào thuộc tính numberCreditClassMustEvaluate
    //       this.$set(user, 'totalCreditClassMustEvaluate', result1);
    //     }
    //   }
    // },
  },
  watch: {
      'statisticForSetOfCriteriaObjectAdvance': function () {
      this.columns.forEach(item => {
        item.hidden = false
      })
      for (let i = 0; i < this.statisticForSetOfCriteriaObjectAdvance.statisticCriterias.length; i++) {
        if(this.statisticForSetOfCriteriaObjectAdvance.statisticCriterias[i].criteriaType === CRITERIA_TYPES[2].value) {
          if(this.statisticForSetOfCriteriaObjectAdvance.objectGroupType === OBJECT_GROUP_TYPES[1].value) {
            this.columns.forEach(item => {
              if(item.field === 'subjectName' || item.field === 'creditClassCode')
              item.hidden = true
            })
          }
        }
      }
      // this.fetchData()

      this.columnsEvaluateObjectGroup.forEach(item => {
        item.hidden = false
      })
      if(this.statisticForSetOfCriteriaObjectAdvance.objectGroupType === OBJECT_GROUP_TYPES[0].value) {
        this.columnsEvaluateObjectGroup.forEach(item => {
        if(item.field === 'codeStudent' || item.field === 'nameStudent' || item.field === 'employeeFullName' || item.field === 'employeeDepartment')
          item.hidden = true
        })
      } else if(this.statisticForSetOfCriteriaObjectAdvance.objectGroupType === OBJECT_GROUP_TYPES[1].value) {
        this.columnsEvaluateObjectGroup.forEach(item => {
        if(item.field === 'codeStudentCreditClass' || item.field === 'nameStudentCreditClass')
          item.hidden = true
        })
      }

      this.textEvaluateLevels = ''
      const evaluateLevelsReverse = this.statisticForSetOfCriteriaObjectAdvance.evaluateLevels?.slice().reverse();
      for (let i = 0; i < evaluateLevelsReverse?.length; i++) {
        if(i < evaluateLevelsReverse?.length - 1) {
          this.textEvaluateLevels += (evaluateLevelsReverse[i].minimumPercent + "% <= " + evaluateLevelsReverse[i].name + " < ")
        } else {
          this.textEvaluateLevels += (evaluateLevelsReverse[i].minimumPercent + "% <= " + evaluateLevelsReverse[i].name)
        }
      }

      this.levelReceivedOfSetOfCriteriaObject = null
      for (let j = 0; j < this.statisticForSetOfCriteriaObjectAdvance.evaluateLevels?.length; j++) {
        if(this.statisticForSetOfCriteriaObjectAdvance.totalPercentPointReceived >= this.statisticForSetOfCriteriaObjectAdvance.evaluateLevels[j].minimumPercent) {
          this.levelReceivedOfSetOfCriteriaObject = this.statisticForSetOfCriteriaObjectAdvance.evaluateLevels[j].name
          break;
        }
      }
    },
  },
}
</script>
<style lang="scss">
#barRaceChartAnswer .apexcharts-canvas {
  margin-left: 8% !important;
}
#barRaceChartAnswer .chart-teacher .apexcharts-canvas {
  margin-left: 18% !important;
}
@import '@core/scss/vue/libs/vue-good-table.scss';
.row-selected {
  background-color: rgba(251, 205, 68, 0.89);
  color: #FFF;
}
</style>
